/**
 * @flow
 * @relayHash 3a9556e87afc2876d89be399c01c7a8f
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebEmployeesWidget_employees_QueryResponse = {|
  +viewer: {|
    +departmentOptions: $ReadOnlyArray<?{|
      +label: ?string;
      +value: ?string;
    |}>;
    +employeeOptions: $ReadOnlyArray<?{|
      +label: ?string;
      +value: ?string;
    |}>;
  |};
  +node: ?{|
    +id?: string;
    +webEmployees?: $ReadOnlyArray<{| |}>;
  |};
|};
*/


/*
query WebEmployeesWidget_employees_Query(
  $id: ID!
) {
  viewer {
    departmentOptions {
      label
      value
    }
    employeeOptions {
      label
      value
    }
    id
  }
  node(id: $id) {
    __typename
    ... on Website {
      id
      webEmployees {
        ...WebEmployeesWidget_webEmployees
        id
      }
    }
    id
  }
}

fragment WebEmployeesWidget_webEmployees on WebEmployee {
  id
  employee {
    firstName
    photoUrl(width: 200, height: 200)
    id
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebEmployeesWidget_employees_Query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "LabelValuePair",
            "name": "departmentOptions",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "label",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "LabelValuePair",
            "name": "employeeOptions",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "label",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Website",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "WebEmployee",
                "name": "webEmployees",
                "plural": true,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "WebEmployeesWidget_webEmployees",
                    "args": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebEmployeesWidget_employees_Query",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "WebEmployeesWidget_employees_Query",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "LabelValuePair",
            "name": "departmentOptions",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "label",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "LabelValuePair",
            "name": "employeeOptions",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "label",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "type": "Website",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "WebEmployee",
                "name": "webEmployees",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "type": "WebEmployee",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "args": null,
                        "concreteType": "Employee",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "args": null,
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "height",
                                "value": 200,
                                "type": "Int!"
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 200,
                                "type": "Int!"
                              }
                            ],
                            "name": "photoUrl",
                            "storageKey": "photoUrl{\"height\":200,\"width\":200}"
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "args": null,
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query WebEmployeesWidget_employees_Query(\n  $id: ID!\n) {\n  viewer {\n    departmentOptions {\n      label\n      value\n    }\n    employeeOptions {\n      label\n      value\n    }\n    id\n  }\n  node(id: $id) {\n    __typename\n    ... on Website {\n      id\n      webEmployees {\n        ...WebEmployeesWidget_webEmployees\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment WebEmployeesWidget_webEmployees on WebEmployee {\n  id\n  employee {\n    firstName\n    photoUrl(width: 200, height: 200)\n    id\n  }\n}\n"
};

module.exports = batch;
