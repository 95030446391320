import React from 'react'
import PropTypes from 'prop-types'
import './CmsPageContainer.css'
import { graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { BlogWidget } from '../list'
import { XMLPageCmsEditor } from '../cms'

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
	}

	render() {
		const { pageId } = this.props
		if (pageId === undefined || pageId === null) {
			return <div />
		}
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: pageId,
				}}
				query={graphql`
					query BlogPageContainerQuery($id: ID!) {
						node(id: $id) {
							... on BlogPage {
								id
								title
								link
								blogListEnabled
								content {
									id
									link
									title
									content
									teaserText

									status
									publicationStartDate
									publicationEndDate

									browserTitle
									browserDescription
									browserKeywords
									cmsForm
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <BlogContainer item={props.node} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class BlogContainer extends React.Component {
	static propTypes = {
		item: PropTypes.object,
	}

	setContentDirtyState = (value) => {}

	render() {
		const { item } = this.props
		if (item === null || item === undefined) {
			return <div className="blogpage-container">Pagina niet gevonden.</div>
		}
		return (
			<div className="blogpage-container">
				{item.blogListEnabled && <BlogWidget />}
				{!item.blogListEnabled && item.content !== null && (
					<XMLPageCmsEditor item={item.content} pageId={item.id} setDirtyState={this.setContentDirtyState} />
				)}
				{!item.blogListEnabled && item.content === null && (
					<div>Neem contact op met uw accountmanager voor het wijzigen van deze pagina.</div>
				)}
			</div>
		)
	}
}
