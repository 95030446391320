import React from 'react'
import PropTypes from 'prop-types'
import PartnerLogoList from '../PartnerLogoList'
import environment from '../../../createRelayEnvironment'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import { connect } from 'react-redux'
import {
	buildOrderMutationList,
	currentWebsiteProps,
	onMutationCompleted,
	removeDeletedIDReferenceFromStore,
} from '../../shared'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	render() {
		const { websiteId } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query HeaderPartnerLogoWidgetQuery($id: ID!) {
						node(id: $id) {
							... on Website {
								id
								headerPartnerLogos {
									...HeaderPartnerLogoWidget_items
								}
							}
						}
					}
				`}
				render={({ error, props }) => {
					if (error) {
						return <div>Er is een error opgetreden</div>
					} else if (props) {
						return (
							<PartnerLogoWidget
								items={props.node.headerPartnerLogos}
								onItemsChange={this.onItemsChange}
								createItem={this.createItem}
								deleteItem={this.deleteItem}
							/>
						)
					} else {
						return <div>Laden...</div>
					}
				}}
			/>
		)
	}

	createItem = (item) => {
		const mutation = graphql`
			mutation HeaderPartnerLogoWidgetCreateMutation($input: CreateImageMenuItemInput!) {
				createHeaderPartnerLogo(input: $input) {
					value {
						id
						order
						imagePath
						title
						subtitle
						link
					}
				}
			}
		`

		const variables = {
			input: { ...item, websiteId: this.props.websiteId },
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createHeaderPartnerLogo')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const viewer = store.get(this.props.websiteId)
			const listItems = viewer.getLinkedRecords('headerPartnerLogos')
			if (listItems) {
				viewer.setLinkedRecords([...listItems, valueItem], 'headerPartnerLogos')
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	deleteItem = (item) => {
		const { websiteId } = this.props

		const mutation = graphql`
			mutation HeaderPartnerLogoWidgetDeleteMutation($input: EntityIdInput!) {
				deleteHeaderPartnerLogo(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		const updater = (store) => {
			removeDeletedIDReferenceFromStore(store, websiteId, 'deleteHeaderPartnerLogo', 'deletedId', 'headerPartnerLogos')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			configs,
			updater,
		})
	}

	onItemsChange = (items) => {
		const updateList = buildOrderMutationList(items)

		const mutation = graphql`
			mutation HeaderPartnerLogoWidgetOrderMutation($input: UpdateOrderInput!) {
				updateHeaderPartnerLogoList(input: $input) {
					value {
						id
						order
					}
				}
			}
		`

		const variables = {
			input: {
				items: updateList,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}
}

const PartnerLogoWidget = createFragmentContainer(
	PartnerLogoList,
	graphql`
		fragment HeaderPartnerLogoWidget_items on ImageMenuItem @relay(plural: true) {
			id
			order
			imagePath
			title
			subtitle
			link
		}
	`,
)

PartnerLogoWidget.prototype = React.Component.prototype
