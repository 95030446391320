import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Switch, Form } from 'antd'

const { Item: FormItem } = Form

const SwitchButtonComponent = ({ name, label, help, initialValue, rules, onChange }) => {
	const checked = initialValue === true || initialValue === 'true' ? true : false
	return (
		<FormItem valuePropName="checked" name={name} rules={rules} initialValue={checked} label={label} help={help}>
			<Switch defaultChecked={checked} onChange={onChange} />
		</FormItem>
	)
}

SwitchButtonComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.string,
	rules: PropTypes.array,
}

export default SwitchButtonComponent

/**
 * Tbv storybook
 */
export { SwitchButtonComponent }
