/**
 * @flow
 * @relayHash 3828eb7d69e849fc332ab225d8b23629
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type XMLPageCmsQueriesUpdateMutationVariables = {|
  input: {
    browserDescription?: ?string;
    browserKeywords?: ?string;
    browserTitle?: ?string;
    cmsForm?: ?"CALL_ME_FORM" | "CONTACT_FORM" | "NONE";
    content?: ?string;
    id: string;
    publicationEndDate?: ?any;
    publicationStartDate?: ?any;
    status?: ?"CONCEPT" | "FINAL";
    teaserText?: ?string;
    title?: ?string;
  };
|};
export type XMLPageCmsQueriesUpdateMutationResponse = {|
  +updateXMLPageCmsContent: ?{|
    +value: {|
      +id: string;
      +link: ?string;
      +title: ?string;
      +content: ?string;
      +teaserText: ?string;
      +status: ?"CONCEPT" | "FINAL";
      +publicationStartDate: ?any;
      +publicationEndDate: ?any;
      +browserTitle: ?string;
      +browserDescription: ?string;
      +browserKeywords: ?string;
      +cmsForm: ?"CALL_ME_FORM" | "CONTACT_FORM" | "NONE";
    |};
  |};
|};
*/


/*
mutation XMLPageCmsQueriesUpdateMutation(
  $input: UpdateXMLPageCmsContentInput!
) {
  updateXMLPageCmsContent(input: $input) {
    value {
      id
      link
      title
      content
      teaserText
      status
      publicationStartDate
      publicationEndDate
      browserTitle
      browserDescription
      browserKeywords
      cmsForm
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateXMLPageCmsContentInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "XMLPageCmsQueriesUpdateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateXMLPageCmsContentInput!"
          }
        ],
        "concreteType": "XMLPageCmsContentPayload",
        "name": "updateXMLPageCmsContent",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsContent",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "teaserText",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "status",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "publicationStartDate",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "publicationEndDate",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserTitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserDescription",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserKeywords",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsForm",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "XMLPageCmsQueriesUpdateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateXMLPageCmsContentInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "XMLPageCmsQueriesUpdateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateXMLPageCmsContentInput!"
          }
        ],
        "concreteType": "XMLPageCmsContentPayload",
        "name": "updateXMLPageCmsContent",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsContent",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "teaserText",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "status",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "publicationStartDate",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "publicationEndDate",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserTitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserDescription",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "browserKeywords",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsForm",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation XMLPageCmsQueriesUpdateMutation(\n  $input: UpdateXMLPageCmsContentInput!\n) {\n  updateXMLPageCmsContent(input: $input) {\n    value {\n      id\n      link\n      title\n      content\n      teaserText\n      status\n      publicationStartDate\n      publicationEndDate\n      browserTitle\n      browserDescription\n      browserKeywords\n      cmsForm\n    }\n  }\n}\n"
};

module.exports = batch;
