import React from 'react'
import { Carousel, Tooltip } from 'antd'
import './SliderComponent.css'
import Classnames from 'classnames'
import PropTypes from 'prop-types'

const itemShape = PropTypes.shape({
	value: PropTypes.string.isRequired,
	urlThumbSmall: PropTypes.string.isRequired,
	urlThumbLarge: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
})

export default class SliderComponent extends React.Component {
	static propTypes = {
		items: PropTypes.arrayOf(itemShape).isRequired,
		onChange: PropTypes.func,
		currentItem: PropTypes.string,
		thumbnailInnerStyle: PropTypes.object,
	}

	render() {
		const config = {
			slidesToShow: 6,
			slidesToScroll: 6,
			arrows: true,
			dots: false,
			infinite: false,
			variableWidth: false,
			prevArrow: <LeftNavButton onClick={this.prev} />,
			nextArrow: <RightNavButton onClick={this.next} />,
		}

		const { currentItem, onChange, items, thumbnailInnerStyle } = this.props
		return (
			<div className="theme-carousel">
				<Carousel {...config}>
					{items.map((item) => {
						return (
							<div key={item.label} className="carousel-item">
								<Tooltip
									placement="bottom"
									title={<img className="thumbnail-tooltip" src={item.urlThumbLarge} alt={item.label} />}
								>
									<div
										className={Classnames('thumbnail-wrapper', currentItem === item.value ? 'selected' : '')}
										onClick={() => onChange(item.value)}
									>
										<img
											className="thumbnail-inner"
											src={item.urlThumbSmall}
											alt={item.label}
											style={thumbnailInnerStyle}
										/>
									</div>
								</Tooltip>
								<div className="label">{item.label}</div>
							</div>
						)
					})}
				</Carousel>
			</div>
		)
	}
}

class LeftNavButton extends React.Component {
	render() {
		const { onClick } = this.props
		return (
			<div className="link-arrowed link-left" onClick={onClick}>
				<svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
					<g fill="none" stroke="#909090" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
						<circle className="arrow-icon-circle" cx="16" cy="16" r="15.12" />
						<path className="arrow-icon-arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98" />
					</g>
				</svg>
			</div>
		)
	}
}

class RightNavButton extends React.Component {
	render() {
		const { onClick } = this.props
		return (
			<div className="link-arrowed link-right" onClick={onClick}>
				<svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
					<g fill="none" stroke="#909090" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
						<circle className="arrow-icon-circle" cx="16" cy="16" r="15.12" />
						<path className="arrow-icon-arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98" />
					</g>
				</svg>
			</div>
		)
	}
}
