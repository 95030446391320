import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImageEditor from '../imageeditor/ImageEditor'
import FilePicker, { FILTER_TYPES } from '../filepicker/FilePicker'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Modal } from 'antd'
import styled from 'styled-components'
import HoveredTextOverlay from '../overlays/hoveredtextoverlay/HoveredTextOverlay'
import './EditableImage.css'

const LOGO_WIDTH = 300

export default class EditableImage extends React.Component {
	static propTypes = {
		imagePath: PropTypes.string,
		showNoImageAlert: PropTypes.bool,
		enableEditor: PropTypes.bool,

		thumbnailWidth: PropTypes.number,
		thumbnailHeight: PropTypes.number,

		resultWidth: PropTypes.number,
		resultHeight: PropTypes.number,

		onImageChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		showNoImageAlert: false,
		enableEditor: false,
		thumbnailWidth: LOGO_WIDTH,
		thumbnailHeight: LOGO_WIDTH,
		resultWidth: LOGO_WIDTH,
		resultHeight: LOGO_WIDTH,
	}

	state = {
		showCmsFilePicker: false,
		showImageEditor: false,
		imageToBeCroppedPath: null,
		imageBlobUrl: null,
		imagePath: this.props.imagePath,
		deleteLogoModalvisible: false,
	}

	renderImage = () => {
		const { thumbnailWidth, thumbnailHeight, enableEditor } = this.props
		const { imagePath, imageBlobUrl } = this.state

		return (
			<div
				className={'editable-image-container'}
				style={{
					width: thumbnailWidth + 'px',
					height: thumbnailHeight + 'px',
					backgroundImage: `url('${imageBlobUrl ? imageBlobUrl : imagePath}')`,
					backgroundColor: 'whitesmoke',
					position: enableEditor ? undefined : 'relative',
				}}
			>
				<CloseCircleOutlined
					onClick={this.showDeleteModal}
					style={{ position: 'absolute', right: 20, top: 20, fontSize: 22 }}
				/>
			</div>
		)
	}

	render() {
		const { thumbnailWidth, thumbnailHeight, showNoImageAlert, enableEditor, resultWidth, resultHeight } = this.props
		const { imagePath, showCmsFilePicker, imageBlobUrl, imageToBeCroppedPath, deleteLogoModalvisible } = this.state

		return (
			<div>
				{!(imagePath || imageBlobUrl) && (
					<div>
						{showNoImageAlert && (
							<Fragment>
								<Alert
									type={'error'}
									message={'U heeft nog geen afbeelding geupload!'}
									description={'Voor goede herkenbaarheid dient u deze te uploaden'}
								/>
								<br />
							</Fragment>
						)}
						<ImagePlaceholder width={thumbnailWidth} height={thumbnailHeight}>
							<Button type="primary" onClick={() => this.setState({ showCmsFilePicker: true })}>
								Upload afbeelding
							</Button>
						</ImagePlaceholder>
					</div>
				)}

				{showCmsFilePicker && (
					<FilePicker
						onCancel={() => {
							this.setState({ showCmsFilePicker: false })
						}}
						onSelectFile={this.onSelectImage}
						filter={{ q: '', type: FILTER_TYPES.IMAGES }}
					/>
				)}

				{imageToBeCroppedPath && (
					<ImageEditor
						width={resultWidth}
						height={resultHeight}
						showResolutionWarning={false}
						imageUrl={imageToBeCroppedPath}
						onCrop={this.onImageEditSave}
						onCancel={this.onImageEditCancel}
					/>
				)}

				{(imagePath || imageBlobUrl) && (
					<div
						style={{
							width: thumbnailWidth + 'px',
							height: thumbnailHeight + 'px',
							marginBottom: '10px',
						}}
					>
						{enableEditor && (
							<HoveredTextOverlay
								title={'Wijzigen'}
								onClick={() => this.setState({ imageToBeCroppedPath: this.lastSelectedImage || imagePath })}
							>
								{this.renderImage()}
							</HoveredTextOverlay>
						)}
						{!enableEditor && this.renderImage()}
					</div>
				)}

				<Modal
					title="Logo verwijderen?"
					visible={deleteLogoModalvisible}
					okType={'danger'}
					okText={'Verwijderen'}
					onOk={this.deleteLogo}
					onCancel={() => this.setState({ deleteLogoModalvisible: false })}
				>
					<p>Weet u zeker dat u het logo wilt verwijderen?</p>
				</Modal>
			</div>
		)
	}

	onSelectImage = (cmsFile: CmsFile) => {
		const { enableEditor } = this.props
		// read the static file, otherwise the transparency is lost
		this.lastSelectedImage = cmsFile.staticUrl
		this.setState({
			showCmsFilePicker: false,
			imageToBeCroppedPath: enableEditor ? this.lastSelectedImage : null,
			imagePath: enableEditor ? null : this.lastSelectedImage,
		})
		if (!enableEditor) {
			this.props.onImageChange(this.lastSelectedImage, null)
		}
	}

	onImageEditSave = async (blob) => {
		const blobUrl = URL.createObjectURL(blob)
		this.setState({
			showImageEditor: false,
			imageToBeCroppedPath: false,
			imageBlobUrl: blobUrl,
		})

		this.props.onImageChange(null, blobUrl)
	}

	showDeleteModal = (e) => {
		e.stopPropagation()
		this.setState({
			deleteLogoModalvisible: true,
		})
	}

	deleteLogo = () => {
		this.setState({
			deleteLogoModalvisible: false,
			imageBlobUrl: null,
			imagePath: null,
		})

		this.props.onImageChange(null, null)
	}

	onImageEditCancel = () => {
		this.setState({ imageToBeCroppedPath: null })
	}
}

const ImagePlaceholder = styled.div`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	background-color: whitesmoke;
	align-items: center;
	display: flex;
	justify-content: center;
	border: 1px dashed #00000038;
	border-style: dashed;
`
