// import React from 'react'
import PropTypes from 'prop-types'

const WithLayout = (props, context) => {
	return props.render({ layout: context.layout })
}

WithLayout.contextTypes = {
	layout: PropTypes.string.isRequired,
}

export default WithLayout
