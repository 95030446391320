import React from 'react'
import PropTypes from 'prop-types'

const withFieldsValue = (WrappedComponent) => {
	const Wrapped = (props, context) => {
		return <WrappedComponent {...props} setFieldsValue={context.setFieldsValue} />
	}

	Wrapped.contextTypes = {
		setFieldsValue: PropTypes.func.isRequired,
	}

	return Wrapped
}

export default withFieldsValue
