import { commitMutation, graphql } from 'react-relay'
import { onMutationCompleted, removeDeletedIDReferenceFromStore } from './index'
import environment from '../../createRelayEnvironment'

const deleteItem = async (id, websiteId, storeWebsiteListFieldName) => {
	const mutation = graphql`
		mutation mutations_deleteItem_Mutation($input: EntityIdInput!) {
			deleteItem(input: $input) {
				deletedId
			}
		}
	`

	const variables = {
		input: { id: id },
	}

	const configs = [
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedId',
		},
	]

	const updater = (store) => {
		removeDeletedIDReferenceFromStore(store, websiteId, 'deleteItem', 'deletedId', storeWebsiteListFieldName)
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		configs,
		updater,
	})
}

export { deleteItem }
