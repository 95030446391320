const initialState = {}

const viewer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_HELPDESK_USER':
			return {
				...state,
				isHelpdeskUser: action.isHelpdeskUser,
			}
		default:
			return state
	}
}

export { viewer }
