import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import '@ant-design/compatible/assets/index.css'
import { Button, Col, Row } from 'antd'

class FormComponent extends React.Component {
	//IMPORTANT: NEW PROPS ALSO NEED TO BE ADDED TO THE REALWRAPPEDFORM COMPONENT.
	static propTypes = {
		onSubmit: PropTypes.func,
		showSubmit: PropTypes.bool,
		onCancel: PropTypes.func,
		showCancel: PropTypes.bool,
		layout: PropTypes.string,
		className: PropTypes.string,
	}

	static defaultProps = {
		showSubmit: true,
		showCancel: false,
		layout: 'vertical',
		buttonAlign: 'left',
	}

	state = {
		saving: false,
	}

	getChildContext() {
		/** @type WrappedFormUtils */
		const { layout } = this.props

		return {
			setFieldsValue: this.setFieldsValue,
			layout: layout,
		}
	}

	onSubmitFailed = ({ values, errorFields, outOfDate }) => {
		if (this.props.onValidationError !== undefined) {
			this.props.onValidationError(errorFields)
		}
	}

	setFieldsValue = (values) => {
		if (this.formInstance) {
			this.formInstance.setFieldsValue(values)
			//onValuesChange is normally called when changing something in a field directly. When using setFieldsValue (like in the case of ckeditor) this is not triggered.
			//This would cause that the data is not set to the parent and eventually not even being saved properly.
			//On https://ant.design/components/form/#setFieldsValue-do-not-trigger-onFieldsChange-or-onValuesChange
			//it states that this is by design: "It's by design. Only user interactive can trigger the change event. This design is aim to avoid call setFieldsValue in change event which may makes loop calling."
			//Maybe this is not a great pattern, but at this moment it is necessary.
			if (this.props.onValuesChange) {
				this.props.onValuesChange(values)
			}
		}
	}

	resetFields = (fields) => {
		if (this.formInstance) {
			this.formInstance.resetFields(fields)
		}
	}

	setFields = (fields) => {
		if (this.formInstance) {
			this.formInstance.setFields(fields)
		}
	}

	submit = () => {
		if (this.formInstance) {
			this.formInstance.submit()
		}
	}

	onSubmit = (values) => {
		console.info('success')
		if (this.props.onSubmit !== undefined) {
			this.props.onSubmit(values)
		}
	}

	render() {
		const { children, showSubmit, layout, className, buttonAlign, showCancel, onCancel } = this.props
		const { saving } = this.state
		return (
			<Form
				ref={(ref) => {
					this.formInstance = ref
				}}
				onValuesChange={(changedValues, values) => {
					if (this.props.onValuesChange) {
						this.props.onValuesChange(values)
					}
				}}
				onFinishFailed={this.onSubmitFailed}
				onFinish={this.onSubmit}
				layout={layout}
				className={className}
			>
				{/*Doe dit ipv {children} zodat je de button eventueel op andere/meerdere plaatsen kan gebruiken in het formulier.**/}
				{React.Children.toArray(children)}

				{(showSubmit || showCancel) && (
					<Row>
						<Col span={24} style={{ textAlign: buttonAlign }}>
							{showSubmit && (
								<Button
									type="primary"
									loading={saving}
									onClick={() => {
										if (this.formInstance) {
											this.formInstance.submit()
										}
									}}
								>
									Bewaar
								</Button>
							)}
							{showCancel && (
								<Button type="secondary" onClick={onCancel} style={{ marginLeft: '8px' }}>
									Annuleren
								</Button>
							)}
						</Col>
					</Row>
				)}
			</Form>
		)
	}
}

FormComponent.childContextTypes = {
	layout: PropTypes.string,
	setFieldsValue: PropTypes.func.isRequired,
}

class RealWrappedForm extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func,
		onValidationError: PropTypes.func,
		showSubmit: PropTypes.bool,
		onCancel: PropTypes.func,
		showCancel: PropTypes.bool,
		onValuesChange: PropTypes.func,
		layout: PropTypes.string,
		className: PropTypes.string,
	}

	componentDidMount(props) {
		this.setFieldsValue = this.wrappedComponent.setFieldsValue
		this.resetFields = this.wrappedComponent.resetFields
		this.setFields = this.wrappedComponent.setFields
		this.submit = this.wrappedComponent.submit
	}

	render() {
		return (
			<FormComponent
				ref={(c) => {
					this.wrappedComponent = c
				}}
				{...this.props}
			/>
		)
	}
}

export default RealWrappedForm
