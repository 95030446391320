export default ({ settings }) => ({
	id: 'woningFoto',
	title: 'Spotlight',
	icon: 'star',
	description: 'Voor deze widget zijn geen instellingen nodig',
	size: {
		minWidth: 1,
		maxWidth: 1,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [],
})
