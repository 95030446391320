import React, { Component } from 'react'
import './SettingsPage.css'
import HeaderSection from './settings/HeaderSection'
import WebEmployeesSection from './settings/WebEmployeesSection'
import Joyride from 'react-joyride'
import ThemeSection from './settings/ThemeSection'
import WebsiteLogoSection from './settings/WebsiteLogoSection'
import WebsiteBackgroundSection from './settings/WebsiteBackgroundSection'
import FooterSection from './settings/FooterSection'
import PresentationSection from './settings/PresentationSection'
import GeneralSection from './settings/GeneralSection'
import ListingSection from './settings/ListingSection'
import VariantSection from './settings/VariantSection'

export default class SettingsPage extends Component {
	state = {
		steps: [],
		showGuider: true,
	}

	addSteps = (newSteps) => {
		console.log('NEWSTEPS : ', newSteps)
		const allSteps = this.state.steps.concat(newSteps)
		this.setState({ steps: allSteps })
	}

	renderPage = () => {
		const { steps, showGuider } = this.state
		const { route, router } = this.props
		return (
			<div>
				<Joyride
					ref={(ref) => (this.joyride = ref)}
					run={showGuider} // or some other boolean for when you want to start it
					debug={false}
					steps={steps}
					continuous={true}
				/>

				<ThemeSection />
				<WebsiteLogoSection />
				<WebsiteBackgroundSection />
				<HeaderSection addSteps={this.addSteps} />
				<VariantSection />
				<GeneralSection />
				<ListingSection />
				<PresentationSection />
				<WebEmployeesSection />
				<FooterSection />
			</div>
		)
	}

	render() {
		const { children } = this.props

		return (
			<div>
				{!children && this.renderPage()}
				{children && this.props.children}
			</div>
		)
	}
}
