import React, { Component } from 'react'

import { CheckboxGroup, Form, Input, Modal } from '../general'
import { Col, message, Row } from 'antd'
import environment from '../../createRelayEnvironment'
import { commitMutation, graphql } from 'react-relay'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import PropTypes from 'prop-types'

@connect(currentWebsiteProps)
export default class extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		showModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		onPageCreated: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			autoGenerateLink: true,
		}
	}

	createItem = (item, callBack) => {
		const mutation = graphql`
			mutation CreateCmsPageModalMutation($input: CreateCmsPageInput!) {
				createCmsPage(input: $input) {
					value {
						id
						title
						link
						pageType
					}
				}
			}
		`

		const variables = {
			input: {
				websiteId: this.props.websiteId,
				...item,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createCmsPage')
			if (mutationRoot === null) {
				return
			}
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.websiteId)
			const listItems = node.getLinkedRecords('pages')
			if (listItems) {
				node.setLinkedRecords([...listItems, valueItem], 'pages')
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				console.log('Response received from server.')
				console.log(response)

				if (errors === undefined || errors === null) {
					message.success('De pagina is aangemaakt.', 3)

					callBack(response.createCmsPage.value)
					return
				}

				let errorMessage = 'Er is een fout opgetreden. Probeer het later opnieuw.'

				errors.forEach((error) => {
					if (error.message.indexOf('er_bestaat_al_een_cms_pagina_voor_website_department_en_naam_2') >= -1) {
						errorMessage = 'Er bestaat al een cms pagina met deze link'
					}
				})

				message.error(errorMessage, 3)
				console.log(errors)
			},
			onError: (err) => console.error('error' + err),
			updater,
		})
	}

	onSubmit = (values) => {
		let item = {
			title: values.title,
			link: values.link,
		}
		this.createItem(item, this.onPageCreated)
	}

	onPageCreated = (item) => {
		this.props.onPageCreated(item)
		this.onClose()
	}

	onClose = () => {
		this.form.resetFields()
		this.props.closeModal()
	}

	onTitleChange = (e) => {
		const { autoGenerateLink } = this.state
		if (!autoGenerateLink) {
			return
		}
		let link = e.target.value.toLowerCase().replace(new RegExp(' ', 'g'), '-')
		link = link.replace(/[^a-zA-Z0-9-]/gi, '')
		if (link.length === 0) {
			link = '/'
		} else {
			link = '/' + link + '/'
		}
		this.form.setFieldsValue({
			link: link,
		})
	}

	onGenerateChange = (checkedOptions) => {
		this.setState({
			autoGenerateLink: checkedOptions.length === 1,
		})
	}

	render() {
		const { autoGenerateLink } = this.state
		return (
			<Modal onCancel={this.onClose} visible={this.props.showModal}>
				<div className="create-cmspage-modal">
					<Form
						ref={(c) => {
							this.form = c
						}}
						onSubmit={this.onSubmit}
						buttonAlign="right"
					>
						<Row gutter={24}>
							<Col span={12}>
								<Input label="Titel" name="title" onChange={this.onTitleChange} required={true} />
								<CheckboxGroup
									name="autoGenerateLink"
									initialValue={['true']}
									onChange={this.onGenerateChange}
									rules={[{ required: false }]}
									options={[{ value: 'true', label: 'Genereer link automatisch.' }]}
								/>
							</Col>
							<Col span={12}>
								<Input
									label="Link"
									name="link"
									required={true}
									disabled={autoGenerateLink}
									rules={[
										{
											pattern: '^[!\\/][\\/[a-zA-Z\\d-_]*\\/]*$',
											message: 'De link mag geen spaties bevatten en moet beginnen en eindigen met een /',
										},
									]}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		)
	}
}
