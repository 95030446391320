/**
 * @flow
 * @relayHash a5f9231c3fe1d43d906f18874590ae2f
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsDeleteFilesMutationVariables = {|
  directory: string;
  parentDirectoryId?: ?number;
  filenames: $ReadOnlyArray<string>;
  fileIds: $ReadOnlyArray<string>;
  maxNumberOfResults?: ?number;
|};
export type mutationsDeleteFilesMutationResponse = {|
  +deleteFiles: ?{|
    +value: $ReadOnlyArray<?{|
      +id: string;
      +previewUrl: string;
      +name: string;
      +type: "FILE" | "FOLDER";
    |}>;
  |};
|};
*/


/*
mutation mutationsDeleteFilesMutation(
  $directory: String!
  $parentDirectoryId: Int
  $filenames: [String!]!
  $fileIds: [ID!]!
  $maxNumberOfResults: Int
) {
  deleteFiles(directory: $directory, parentDirectoryId: $parentDirectoryId, filenames: $filenames, fileIds: $fileIds, maxNumberOfResults: $maxNumberOfResults) {
    value {
      id
      previewUrl
      name
      type
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "directory",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "filenames",
        "type": "[String!]!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "fileIds",
        "type": "[ID!]!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsDeleteFilesMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "directory",
            "variableName": "directory",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "fileIds",
            "variableName": "fileIds",
            "type": "[ID!]!"
          },
          {
            "kind": "Variable",
            "name": "filenames",
            "variableName": "filenames",
            "type": "[String!]!"
          },
          {
            "kind": "Variable",
            "name": "maxNumberOfResults",
            "variableName": "maxNumberOfResults",
            "type": "Int"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "deleteFiles",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsDeleteFilesMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "directory",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "filenames",
        "type": "[String!]!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "fileIds",
        "type": "[ID!]!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsDeleteFilesMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "directory",
            "variableName": "directory",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "fileIds",
            "variableName": "fileIds",
            "type": "[ID!]!"
          },
          {
            "kind": "Variable",
            "name": "filenames",
            "variableName": "filenames",
            "type": "[String!]!"
          },
          {
            "kind": "Variable",
            "name": "maxNumberOfResults",
            "variableName": "maxNumberOfResults",
            "type": "Int"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "deleteFiles",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsDeleteFilesMutation(\n  $directory: String!\n  $parentDirectoryId: Int\n  $filenames: [String!]!\n  $fileIds: [ID!]!\n  $maxNumberOfResults: Int\n) {\n  deleteFiles(directory: $directory, parentDirectoryId: $parentDirectoryId, filenames: $filenames, fileIds: $fileIds, maxNumberOfResults: $maxNumberOfResults) {\n    value {\n      id\n      previewUrl\n      name\n      type\n    }\n  }\n}\n"
};

module.exports = batch;
