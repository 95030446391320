import React from 'react'
import PropTypes from 'prop-types'
import './CmsPageContainer.css'
import { commitMutation, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row } from 'antd'
import { CmsFormSubContentEditor, CmsPageContentEditor, CmsSubContentEditor, PopupEditor } from '../cms/index'
import { onMutationCompleted } from '../shared'
import { withBlocking } from '../../helpers/general/WithBlockingHoc'

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
	}

	confirmDeletePage = (item) => {
		Modal.confirm({
			content: 'Deze actie kan niet ongedaan worden gemaakt',
			title: 'Pagina verwijderen?',
			okText: 'Verwijderen',
			okType: 'danger',
			cancelText: 'Annuleren',
			onOk: () => this._deletePage(item),
		})
	}

	_deletePage(item) {
		const mutation = graphql`
			mutation CmsPageContainerDeleteMutation($input: EntityIdInput!) {
				deletePage(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted('Pagina is verwijderd'),
			onError: (err) => console.error(err),
			configs,
		})
	}

	render() {
		const { pageId, router, route } = this.props
		if (pageId === undefined || pageId === null) {
			return <div />
		}
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: pageId,
				}}
				query={graphql`
					query CmsPageContainerQuery($id: ID!) {
						node(id: $id) {
							... on CmsPage {
								id
								title
								link
								pageDisplaySetting
								content {
									id
									link
									title
									content
									teaserText

									status
									publicationStartDate
									publicationEndDate

									browserTitle
									browserDescription
									browserKeywords
									cmsForm
								}
								subContent {
									id
									title
									content
								}
								popup {
									id
									title
									content
									cmsForm
								}
								formSubContent {
									id
									title
									content
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <CmsPageContainerWithBlocking item={props.node} deletePage={this.confirmDeletePage} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class CmsPageContainer extends React.Component {
	static propTypes = {
		item: PropTypes.object,
	}

	state = {
		isContentDirty: false,
		isSubContentDirty: false,
		isPopupDirty: false,
		isFormContentDirty: false,
	}

	componentDidUpdate(prevProps, prevState) {
		const { setPrompt } = this.props
		const { isContentDirty, isSubContentDirty, isPopupDirty, isFormContentDirty } = this.state
		// listen for when user is navigating away from page, so we can notify if changes aren't saved
		setPrompt(
			'Weet u het zeker dat u de pagina wilt verlaten? Uw wijzigingen worden niet bewaard.',
			isContentDirty || isSubContentDirty || isPopupDirty || isFormContentDirty,
		)
	}

	setContentDirtyState = (value) => {
		this.setState({
			isContentDirty: value,
		})
	}

	setSubConentDirtyState = (value) => {
		this.setState({
			isSubContentDirty: value,
		})
	}
	setPopupDirtyState = (value) => {
		this.setState({
			isPopupDirty: value,
		})
	}
	setFormContentDirtyState = (value) => {
		this.setState({
			isFormContentDirty: value,
		})
	}

	render() {
		const { item, router } = this.props
		if (item === null || item === undefined) {
			return <div className="cmspage-container">Pagina niet gevonden.</div>
		}
		return (
			<div className="cmspage-container">
				<Row gutter={24}>
					<Col span={18}>
						<CmsPageContentEditor
							item={item.content}
							pageId={item.id}
							setDirtyState={this.setContentDirtyState}
							router={router}
						/>
					</Col>
					<Col span={6}>
						<div className="button-container">
							<Button
								type="danger"
								ghost
								icon={<DeleteOutlined />}
								size="small"
								onClick={() => {
									this.props.deletePage(item)
								}}
							>
								Verwijder pagina
							</Button>
						</div>
						{item.pageDisplaySetting !== '1' && (
							<CmsSubContentEditor
								item={item.subContent}
								pageId={item.id}
								setDirtyState={this.setSubConentDirtyState}
							/>
						)}
						<PopupEditor item={item.popup} pageId={item.id} setDirtyState={this.setPopupDirtyState} />
						<CmsFormSubContentEditor
							item={item.formSubContent}
							pageId={item.id}
							setDirtyState={this.setFormContentDirtyState}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}

const CmsPageContainerWithBlocking = withBlocking(CmsPageContainer)
