import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TITLE } from './regexps'

export default ({ settings }) => ({
	id: 'smoelenboek',
	title: 'Smoelenboek',
	icon: 'users',
	size: {
		minWidth: 3,
		maxWidth: 3,
		defaultWidth: 3,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			name: 'smoelenboekTitel',
			label: 'Titel',
			defaultValue: 'Medewerkers',
			help: 'De titel die boven dit blok komt te staan.',
			type: FieldTypes.TEXT,
			rules: [{ required: true, message: 'Vul een waarde in', pattern: TITLE }],
		},
	],
})
