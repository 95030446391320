import React from 'react'
import { ColorInput } from '../../general'
import './ThemeSettingsPage.css'
import { Col, Row, Tabs } from 'antd'
import 'animate.css'
import { updateSettings } from '../../settings/settingsQueries'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../../shared'

const TabPane = Tabs.TabPane

@connect(currentWebsiteProps)
export default class ColorSection extends React.Component {
	state = {
		showSavedMessage: false,
	}

	render() {
		const {
			menucolor,
			menufontcolor,
			footerlinkcolor,
			blokbackgroundcolor,
			blokfontcolor,
			linkcolor,
			contentcolor,
			contentbackgroundcolor,
			objectstatusnieuwlabelcolor,
			objectstatusnieuwfontcolor,
			objectstatustekoophuurlabelcolor,
			objectstatustekoophuurfontcolor,
			objectstatusondervoorbehoudlabelcolor,
			objectstatusondervoorbehoudfontcolor,
			objectstatusverkochtlabelcolor,
			objectstatusverkochtfontcolor,
			objectstatusopenhuislabelcolor,
			objectstatusopenhuisfontcolor,
			objectstatusaangekochtlabelcolor,
			objectstatusaangekochtfontcolor,
		} = this.props.colors

		return (
			<div className="color-subsection-container">
				<Tabs defaultActiveKey="header" tabPosition="left">
					<TabPane tab="Header" key="header">
						<ColorSubSection>
							<ColorItem
								label="Header & footer"
								onChange={(hexValue) => this.onColorChange('menucolor', hexValue)}
								color={menucolor}
							/>
							<ColorItem
								label="Header & footer tekstkleur"
								onChange={(hexValue) => this.onColorChange('menufontcolor', hexValue)}
								color={menufontcolor}
							/>
							<ColorItem
								label="Footer linkkleur"
								onChange={(hexValue) => this.onColorChange('footerlinkcolor', hexValue)}
								color={footerlinkcolor}
							/>
						</ColorSubSection>
					</TabPane>
					<TabPane tab="Content" key="content">
						<ColorSubSection>
							<Row>
								<Col span={12}>
									<ColorItem
										label="Blok achtergrondkleur"
										onChange={(hexValue) => this.onColorChange('blokbackgroundcolor', hexValue)}
										color={blokbackgroundcolor}
									/>
									<ColorItem
										label="Blok tekst kleur"
										onChange={(hexValue) => this.onColorChange('blokfontcolor', hexValue)}
										color={blokfontcolor}
									/>
									<ColorItem
										label="Link kleur"
										onChange={(hexValue) => this.onColorChange('linkcolor', hexValue)}
										color={linkcolor}
									/>
								</Col>
								<Col span={12}>
									<ColorItem
										label="Tekstkleur"
										onChange={(hexValue) => this.onColorChange('contentcolor', hexValue)}
										color={contentcolor}
									/>
									<ColorItem
										label="Content achtergrondkleur"
										onChange={(hexValue) => this.onColorChange('contentbackgroundcolor', hexValue)}
										color={contentbackgroundcolor}
									/>
								</Col>
							</Row>
						</ColorSubSection>
					</TabPane>
					<TabPane tab="Aanbodlijsten" key="aanbodlijsten">
						<ColorSubSection>
							<Row>
								<Col>
									<h2>Object status</h2>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<b>Label kleur</b>
									<ColorItem
										label="Nieuw"
										onChange={(hexValue) => this.onColorChange('objectstatusnieuwlabelcolor', hexValue)}
										color={objectstatusnieuwlabelcolor}
									/>
									<ColorItem
										label="Te Koop / Te Huur"
										onChange={(hexValue) => this.onColorChange('objectstatustekoophuurlabelcolor', hexValue)}
										color={objectstatustekoophuurlabelcolor}
									/>
									<ColorItem
										label="Verkocht/Verhuurd o.v., Onder bod, Onder optie"
										onChange={(hexValue) => this.onColorChange('objectstatusondervoorbehoudlabelcolor', hexValue)}
										color={objectstatusondervoorbehoudlabelcolor}
									/>
									<ColorItem
										label="Verkocht, Verhuurd, Geveild"
										onChange={(hexValue) => this.onColorChange('objectstatusverkochtlabelcolor', hexValue)}
										color={objectstatusverkochtlabelcolor}
									/>
									<ColorItem
										label="Openhuis"
										onChange={(hexValue) => this.onColorChange('objectstatusopenhuislabelcolor', hexValue)}
										color={objectstatusopenhuislabelcolor}
									/>
									<ColorItem
										label="Aangekocht"
										onChange={(hexValue) => this.onColorChange('objectstatusaangekochtlabelcolor', hexValue)}
										color={objectstatusaangekochtlabelcolor}
									/>
								</Col>
								<Col span={12}>
									<b>Text kleur</b>
									<ColorItem
										label="Nieuw"
										onChange={(hexValue) => this.onColorChange('objectstatusnieuwfontcolor', hexValue)}
										color={objectstatusnieuwfontcolor}
									/>
									<ColorItem
										label="Te Koop / Te Huur"
										onChange={(hexValue) => this.onColorChange('objectstatustekoophuurfontcolor', hexValue)}
										color={objectstatustekoophuurfontcolor}
									/>
									<ColorItem
										label="Verkocht/Verhuurd o.v., Onder bod, Onder optie"
										onChange={(hexValue) => this.onColorChange('objectstatusondervoorbehoudfontcolor', hexValue)}
										color={objectstatusondervoorbehoudfontcolor}
									/>
									<ColorItem
										label="Verkocht, Verhuurd, Geveild"
										onChange={(hexValue) => this.onColorChange('objectstatusverkochtfontcolor', hexValue)}
										color={objectstatusverkochtfontcolor}
									/>
									<ColorItem
										label="Openhuis"
										onChange={(hexValue) => this.onColorChange('objectstatusopenhuisfontcolor', hexValue)}
										color={objectstatusopenhuisfontcolor}
									/>
									<ColorItem
										label="Aangekocht"
										onChange={(hexValue) => this.onColorChange('objectstatusaangekochtfontcolor', hexValue)}
										color={objectstatusaangekochtfontcolor}
									/>
								</Col>
							</Row>
						</ColorSubSection>
					</TabPane>
				</Tabs>
			</div>
		)
	}

	onColorChange = (property, hexValue) => {
		const { websiteId } = this.props
		updateSettings(
			[
				{
					name: property,
					value: hexValue,
				},
			],
			websiteId,
		)
	}
}

const ColorSubSection = ({ title, children }) => (
	<div className="color-subsection">
		<div className="title">{title}</div>
		{children}
	</div>
)

const ColorItem = ({ label, onChange, color, property }) => {
	return (
		<div style={{ display: 'flex', marginTop: '30px' }}>
			<ColorInput onChange={onChange} color={color} />
			<span style={{ marginLeft: '10px' }}>{label}</span>
		</div>
	)
}
