import React from 'react'
import PropTypes from 'prop-types'
import { Input, RadioGroup } from '../../../general'

class YoutubeEmbed extends React.Component {
	static propTypes = {
		parameters: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		const playlistId = props.parameters['playlistId']
		this.state = {
			selectedRadioValue: playlistId ? 'playlistId' : 'videoUrl',
		}
	}

	onTypeChange = (e) => {
		this.setState({
			selectedRadioValue: e.target.value,
		})
	}

	onChangePlaylistId = (e) => {
		const { form } = this.props
		form.setFieldsValue({
			videoUrl: '',
		})
	}

	onChangeVideoUrl = (e) => {
		const { form } = this.props
		form.setFieldsValue({
			playlistId: '',
		})
	}

	render() {
		const { parameters } = this.props
		const { selectedRadioValue } = this.state
		const playlistId = parameters['playlistId'] || ''
		const videoUrl = parameters['videoUrl'] || ''
		return (
			<div>
				<RadioGroup
					name="type"
					label={'Type video'}
					initialValue={selectedRadioValue}
					onChange={this.onTypeChange}
					options={[
						{ value: 'videoUrl', label: 'Video' },
						{ value: 'playlistId', label: 'Playlist' },
					]}
				/>
				<div style={{ display: selectedRadioValue === 'playlistId' ? 'block' : 'none' }}>
					<div style={{ marginBottom: '10px' }}>
						De optie 'Playlist' is voor het tonen van een playlist op de homepagina. Binnen uw Youtube pagina kunt u een
						playlist samenstellen. Kopier het onderstaande gedeelte van de url en voer deze in als 'Playlist ID'.
						<br />
						<br />
						<div
							style={{
								border: '1px solid #108ee9',
								background: 'rgba(16,142,233, 0.1)',
								fontSize: '11px',
								padding: '5px',
							}}
						>
							{'https://www.youtube.com/playlist?list='}
							<b>PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG</b>
						</div>
					</div>
					<Input name="playlistId" label="Playlist ID" initialValue={playlistId} onChange={this.onChangePlaylistId} />
				</div>
				<div style={{ display: selectedRadioValue === 'videoUrl' ? 'block' : 'none' }}>
					<div style={{ marginBottom: '10px' }}>
						De optie 'Video' is voor het tonen van een video op de homepagina. De url die hier opgegeven moet worden
						kunt u als volgt ophalen: <br />
						<br />
						<ol>
							<li>Open de video in een nieuw tabblad. Bijvoorbeeld: https://www.youtube.com/watch?v=POfho5nN8LI</li>
							<li>Klik onder de video op 'Delen' en kies vervolgens voor 'Insluiten'</li>
							<li>
								In het venster staat een code vergelijkbaar met het onderstaande voorbeeld. Kopieer het dikgedrukte
								gedeelte en voer deze in als 'Video Url'.
							</li>
						</ol>
						<div
							style={{
								border: '1px solid #108ee9',
								background: 'rgba(16,142,233, 0.1)',
								fontSize: '11px',
								padding: '5px',
							}}
						>
							{'<iframe width="560" height="315" src="'}
							<b>https://www.youtube.com/embed/POfho5nN8LI</b>
							{
								'" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
							}
						</div>
					</div>
					<Input name="videoUrl" label="Video URL" initialValue={videoUrl} onChange={this.onChangeVideoUrl} />
				</div>
			</div>
		)
	}
}

export default ({ settings }) => {
	return {
		id: 'youtubeEmbedBlok',
		title: 'Youtube Embed',
		icon: 'youtube',
		size: {
			minWidth: 1,
			maxWidth: 3,
			defaultWidth: 1,

			minHeight: 1,
			maxHeight: 1,
			defaultHeight: 1,
		},
		isDisabled: false,
		fieldTypes: [
			{
				component: (props) => {
					return <YoutubeEmbed {...props} />
				},
			},
		],
	}
}
