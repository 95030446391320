import { combineReducers, createStore } from 'redux'
import { basePage } from './reducers/basePage'
import { viewer } from './reducers/viewer'
import { guider } from './reducers/guiderSteps'

let store = createStore(
	combineReducers({
		basePage,
		guider,
		viewer,
	}),
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export default store
