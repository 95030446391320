import React, { Fragment } from 'react'
import { CloudOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { FilePicker, Input } from '../../../general'
import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TITLE } from './regexps'
import RealWrappedForm from '../../../general/form/form/Form'

class LinkSelector extends React.Component {
	static propTypes = {
		form: PropTypes.instanceOf(RealWrappedForm).isRequired,
	}
	state = {
		showFilePicker: false,
		link: this.props.value,
	}

	render() {
		const { showFilePicker, link } = this.state

		return (
			<Fragment>
				<Input
					name={'afbeeldingUrl'}
					label={'Bron URL'}
					help={'URL van de afbeelding'}
					type={FieldTypes.TEXT}
					defaultValue={'https://'}
					initialValue={link}
					rules={[{ required: true }]}
					addonAfter={<CloudOutlined onClick={() => this.setState({ showFilePicker: true })} />}
					onChange={this.onInputChange}
				/>

				{showFilePicker && (
					<FilePicker onSelectFile={this.onSelectFile} onCancel={() => this.setState({ showFilePicker: false })} />
				)}
			</Fragment>
		)
	}

	onInputChange = (e) => {
		this.props.form.setFieldsValue({ afbeeldingUrl: e.target.value })
	}

	onSelectFile = (file: CmsFile) => {
		this.props.form.setFieldsValue({ afbeeldingUrl: file.staticUrl })
	}
}

export default ({ settings }) => ({
	id: 'image',
	title: 'Afbeelding',
	icon: 'image',
	size: {
		minWidth: 1,
		maxWidth: 3,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 3,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			component: (props) => <LinkSelector value={props.parameters.afbeeldingUrl || ''} form={props.form} />,
		},
		{
			name: 'afbeeldingLink',
			label: 'Link',
			help: 'Link om te openen bij klikken op de afbeelding',
			defaultValue: 'https://',
			type: FieldTypes.TEXT,
			rules: [{ required: true }],
		}, // TODO: url pattern maken
		{
			name: 'afbeeldingTitel',
			label: 'Titel',
			help: 'Titel van de afbeelding',
			type: FieldTypes.TEXT,
			rules: [{ required: true, pattern: TITLE }],
			defaultValue: '',
		},
		{
			name: 'afbeeldingAltTekst',
			label: 'Alt tekst',
			help: 'Omschrijving van de afbeelding tbv SEO',
			type: FieldTypes.TEXT,
			rules: [{ required: true, pattern: TITLE }],
			defaultValue: '',
		},
		{
			name: 'afbeeldingWeergaveOpties',
			label: 'Weergave opties',
			defaultValue: 'opvullen-bijsnijden',
			type: FieldTypes.SELECT,
			rules: [{ required: true }],
			options: [
				{ value: 'opvullen-bijsnijden', label: 'Opvullen + bijsnijden' },
				{ value: 'opvullen-meeschalen', label: 'Opvullen + /meeschalen' },
				{ value: 'maximale-grootte', label: 'Maximale grootte' },
				{ value: 'originele-grootte', label: 'Originele grootte' },
			],
		},
	],
})
