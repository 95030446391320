import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { updateCurrentWebsiteSettings } from '../../actions'
import store from '../../store'

const setEntityImage = ({ websiteId, imageFile, settingName }) => {
	const mutation = graphql`
		mutation EntityImageQueries_updateImage_Mutation($input: EntityImageInput!) {
			updateEntityImage(input: $input) {
				value
			}
		}
	`

	const variables = {
		input: {
			websiteId,
			settingName,
		},
	}

	let uploadables = {}
	if (imageFile) {
		const files = [imageFile]
		Object.keys(files).forEach((key) => {
			uploadables['file_' + key] = files[key]
		})
	}

	const updater = async (relayStore) => {
		// TODO: should we update the relay store here as well? What is our single source of truth?
		const newImagePath = relayStore.getRootField('updateEntityImage').getValue('value')
		const { websiteId, websiteSettings } = store.getState().basePage

		const newSettings = {
			brokerSettings: { ...websiteSettings.brokerSettings, [settingName]: newImagePath },
			systemSettings: { ...websiteSettings.systemSettings },
		}

		const action = updateCurrentWebsiteSettings(websiteId, newSettings)
		store.dispatch(action)
	}

	return new Promise((resolve, reject) => {
		commitMutation(environment, {
			mutation,
			variables,
			uploadables,
			onCompleted: resolve,
			onError: reject,
			updater,
		})
	})
}

const deleteEntityImage = ({ websiteId, settingName }) => {
	const mutation = graphql`
		mutation EntityImageQueries_deleteImage_Mutation($input: EntityImageInput!) {
			deleteEntityImage(input: $input) {
				value
			}
		}
	`

	const variables = {
		input: {
			websiteId,
			settingName,
		},
	}

	const updater = async () => {
		// TODO: should we update the relay store here as well? What is our single source of truth?
		const { websiteSettings } = store.getState().basePage

		const newSettings = {
			brokerSettings: { ...websiteSettings.brokerSettings, [settingName]: '' },
			systemSettings: { ...websiteSettings.systemSettings },
		}

		const action = updateCurrentWebsiteSettings(websiteId, newSettings)
		store.dispatch(action)
	}

	return new Promise((resolve, reject) => {
		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: resolve,
			onError: reject,
			updater,
		})
	})
}

export { setEntityImage, deleteEntityImage }
