import React from 'react'
import PropTypes from 'prop-types'
import { graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { Col, Collapse, Row } from 'antd'
import { XMLPageCmsEditor } from '../cms'
import { createItem, createSubscriberPageLandingContent, updateItem } from '../cms/xmlpage/XMLPageCmsQueries'

const Panel = Collapse.Panel

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
	}

	render() {
		const { pageId } = this.props
		if (pageId === undefined || pageId === null) {
			return <div />
		}
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: pageId,
				}}
				query={graphql`
					query SubscriberPageContainerQuery($id: ID!) {
						node(id: $id) {
							... on SubscriberPage {
								id
								title
								link
								pageType
								content {
									id
									link
									title
									content
									teaserText

									status
									publicationStartDate
									publicationEndDate

									browserTitle
									browserDescription
									browserKeywords
									cmsForm
								}
								landingContent {
									id
									link
									title
									content
									teaserText

									status
									publicationStartDate
									publicationEndDate

									browserTitle
									browserDescription
									browserKeywords
									cmsForm
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <SubscriberPageContainer item={props.node} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class SubscriberPageContainer extends React.Component {
	static propTypes = {
		item: PropTypes.object,
	}

	setContentDirtyState = (value) => {}

	render() {
		const { item } = this.props
		if (item === null || item === undefined) {
			return <div className="subscriberpage-container">Pagina niet gevonden.</div>
		}
		return (
			<div className="subscriberpage-container">
				<Row gutter={24}>
					<Col span={24}>
						<h2>{item.title}</h2>
						<p>
							Het inschrijfformulier heeft twee teksten die aangepast kunnen worden. De hoofdtekst bevind zich boven het
							formulier, de landingspagina tekst wordt getoond na het versturen van het formulier.
						</p>
						<Collapse style={{ marginTop: '20px', marginBottom: '20px' }} accordion>
							<Panel header="Hoofdtekst" id="hoofdtekst" forceRender={true}>
								<Row gutter={8}>
									<Col span={24}>
										<XMLPageCmsEditor
											item={item.content}
											pageId={item.id}
											setDirtyState={this.setContentDirtyState}
											createItem={createItem}
											updateItem={updateItem}
										/>
									</Col>
								</Row>
							</Panel>
							<Panel header="Landingspagina tekst" id="landingpagetekst" forceRender={true}>
								<Row gutter={8}>
									<Col span={24}>
										<XMLPageCmsEditor
											item={item.landingContent}
											pageId={item.id}
											setDirtyState={this.setContentDirtyState}
											createItem={createSubscriberPageLandingContent}
											updateItem={updateItem}
										/>
									</Col>
								</Row>
							</Panel>
						</Collapse>
					</Col>
				</Row>
			</div>
		)
	}
}
