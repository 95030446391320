import { FieldTypes } from '../../../dnd/FieldRenderer'

export default ({ settings }) => {
	const possibleOptionsBySetting = []
	possibleOptionsBySetting.push({ setting: 'enableWonen', option: { value: 'wonen', label: 'Wonen' } })
	possibleOptionsBySetting.push({ setting: 'enableAlv', option: { value: 'alv', label: 'ALV' } })
	possibleOptionsBySetting.push({ setting: 'enableBog', option: { value: 'bog', label: 'BOG' } })
	possibleOptionsBySetting.push({ setting: 'enableVgmcomplex', option: { value: 'vgmcomplex', label: 'VGM' } })
	possibleOptionsBySetting.push({ setting: 'enableNieuwbouw', option: { value: 'nieuwbouw', label: 'Nieuwbouw' } })
	possibleOptionsBySetting.push({ setting: 'enableAankoop', option: { value: 'aankoop', label: 'Aankoop' } })

	possibleOptionsBySetting.push({ setting: 'enableWonen', option: { value: 'verkocht', label: 'Verkocht' } })
	possibleOptionsBySetting.push({ setting: 'enableWonen', option: { value: 'openhuis', label: 'Openhuis' } })
	possibleOptionsBySetting.push({
		setting: 'enableWonen',
		option: { value: 'aankomendaanbod', label: 'Aankomend aanbod' },
	})

	const options = []
	possibleOptionsBySetting.forEach((option) => {
		const settingValue = settings[option.setting]
		if (settingValue === true) {
			options.push(option.option)
		}
	})

	return {
		id: 'spotlight',
		title: 'Recent aanbod',
		icon: 'list',
		size: {
			minWidth: 3,
			maxWidth: 3,
			defaultWidth: 3,

			minHeight: 1,
			maxHeight: 1,
			defaultHeight: 1,
		},
		isDisabled: false,
		fieldTypes: [
			{
				name: 'spotlightTitel',
				label: 'Recent aanbod',
				help: 'De titel die boven het blok komt te staan',
				placeholder: '',
				type: FieldTypes.TEXT,
				defaultValue: 'Recent aanbod',
				rules: [{ required: true, message: 'Vul een waarde in' }],
			},
			{
				name: 'spotlightModule',
				label: 'Module',
				help: 'Welk objecttype huizen getoond moeten worden',
				placeholder: '',
				type: FieldTypes.SELECT,
				rules: [{ required: true, message: 'Vul een waarde in' }],
				defaultValue: options.length >= 1 ? options[0].value : null,
				options: options,
			},
			{
				name: 'spotlightCycle',
				label: 'Automatisch scrollen',
				help: 'Moet er automatisch om de 5 seconden gescrolled worden?',
				type: FieldTypes.RADIO,
				defaultValue: 'Ja',
				rules: [{ required: true, message: 'Vul een waarde in' }],
				options: (function () {
					return [
						{ value: 'Ja', label: 'Ja' },
						{ value: 'Nee', label: 'Nee' },
					]
				})(),
			},
		],
	}
}
