export function updateCurrentWebsiteId(websiteId, websiteName) {
	return {
		type: 'UPDATE_CURRENT_WEBSITE_ID',
		websiteId,
		websiteName,
	}
}

export function updateCurrentWebsiteSettings(websiteId, websiteSettings) {
	return {
		type: 'UPDATE_CURRENT_WEBSITE_SETTINGS',
		websiteId,
		websiteSettings,
	}
}

export function updateHelpdeskUser(isHelpdeskUser) {
	return {
		type: 'UPDATE_HELPDESK_USER',
		isHelpdeskUser,
	}
}
