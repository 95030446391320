/**
 * @flow
 * @relayHash 7f4536d2f34cbfdb6d24ad55e559bac5
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsUpdateMutationVariables = {|
  input: {
    cmsContentId?: ?string;
    id: string;
    inactive?: ?boolean;
    link?: ?string;
    title?: ?string;
  };
|};
export type mutationsUpdateMutationResponse = {|
  +updateMenuItem: ?{|
    +value: {|
      +id: string;
      +order: ?number;
      +parentId: ?string;
      +title: ?string;
      +link: ?string;
      +cmsContentId: ?string;
      +inactive: ?boolean;
    |};
  |};
|};
*/


/*
mutation mutationsUpdateMutation(
  $input: UpdateMenuItemInput!
) {
  updateMenuItem(input: $input) {
    value {
      id
      order
      parentId
      title
      link
      cmsContentId
      inactive
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateMenuItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsUpdateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateMenuItemInput!"
          }
        ],
        "concreteType": "MenuItemPayload",
        "name": "updateMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsContentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsUpdateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateMenuItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsUpdateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateMenuItemInput!"
          }
        ],
        "concreteType": "MenuItemPayload",
        "name": "updateMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsContentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsUpdateMutation(\n  $input: UpdateMenuItemInput!\n) {\n  updateMenuItem(input: $input) {\n    value {\n      id\n      order\n      parentId\n      title\n      link\n      cmsContentId\n      inactive\n    }\n  }\n}\n"
};

module.exports = batch;
