import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from './general'
import { Form } from 'antd'
import withFieldsValue from './general/form/fieldtypes/hoc/withFieldsValue'
import '@ant-design/compatible/assets/index.css'

const { Item: FormItem } = Form

class CKEditor extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		initialValue: PropTypes.string,
	}

	render() {
		const { id, name, initialValue } = this.props

		return (
			<React.Fragment>
				<div id={'ckeditor-' + id} ref={(ref) => (this.ckEditor = ref)} />
				<div style={{ display: 'none' }}>
					<Input name={name} initialValue={initialValue} />
				</div>
			</React.Fragment>
		)
	}

	componentWillUnmount() {
		const { id } = this.props
		/* eslint-disable */
		const instance = CKEDITOR.instances['ckeditor-' + id]
		if (instance != null) {
			instance.destroy(true)
		}
		/*eslint-enable*/
	}

	componentDidMount() {
		const { name, initialValue } = this.props

		const config = {}

		// pass the 'image' parameter, so we get returned an images.realworks.nl url instead of the static.realworks.nl
		// which we need to have so we can change the width/height parameters in there further below in the 'addImageResizePlugin'
		// TODO: dit uitgezet omdat je van een link elke bestandtype moet kunnen selecteren
		config.filebrowserBrowseUrl = '/components/filepicker/?tokenserver=' + window.GLOBAL_CONSTANTS_TOKENSERVER

		// TODO: dit uitegzet omdat je in de Image dialog ook een tabje hebt met Link waar je een bestand moet kiezen,
		// totdat dat is gefixt de filebrowserBrowseUrl gebruiken ipv de regel hieronder
		// config.filebrowserImageBrowseUrl =
		// 	'/components/filepicker/?tokenserver=' + window.GLOBAL_CONSTANTS_TOKENSERVER

		config.language = 'nl'

		// Dit is overgenomen van realworks_cke4.js in crm code, en zorgt dat html niet wordt gestrip van css/js etc, zie BMW-2164:
		//
		// 		"De acf (advanced content filter) uit zetten. Dit maakt een boel templates kapot.
		// 		Zie http://ckeditor.com/blog/CKEditor-4.1-Released
		// 		eventueel kan dit apart geconfigureerd worden als we dit wel willen hebben."
		config.allowedContent = true

		// Zie BMW-2613
		config.extraPlugins = 'dialogadvtab'
		// deze moeten blijven staan, omdat anders de Advanced tab (zie 'dialogadvtab' hierboven de Link tab weghaald, dat willen we niet
		// Zie BMW-2613
		config.removeDialogTabs = ''

		config.removeButtons = 'Font'

		/* eslint-disable */
		const editor = CKEDITOR.replace(this.ckEditor, {
			...config,

			// these vars cannot be defined in the config since that will throw a "CKEDITOR not defined" error, strange, but reality
			enterMode: CKEDITOR.ENTER_BR,
		})

		//Zorg ervoor dat de initialValue ook terug te zien is in het ckeditor scherm.
		editor.setData(initialValue)

		//Onchange listener om nieuwe waarde in lokale state te zetten, welke de ant-d input weer update.
		CKEDITOR.instances[editor.name].on(
			'change',
			function () {
				let data = CKEDITOR.instances[editor.name].getData()

				this.props.setFieldsValue({
					[name]: data,
				})
			}.bind(this),
		)

		CKEDITOR.on('instanceReady', function () {
			// see https://jira.realworks.nl/browse/BMW-2604
			CKEDITOR.lang['nl'].clipboard.pasteNotification =
				'Druk %1 om te plakken. Uw browser biedt namelijk geen ondersteuning om te plakken met de toolbar knop of het context menu.'
			CKEDITOR.lang['nl'].pastetext.pasteNotification =
				'Druk %1 om te plakken. Uw browser biedt namelijk geen ondersteuning om te plakken met de toolbar knop of het context menu.'
		})

		/*eslint-enable*/
		// this.addImageResizePlugin()

		// this.addWidthPlugin()
	}

	// otherApproach = () => {
	// 	dialog.on('show', function(e) {
	// 		console.log("showwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", e)
	// 		var dialogDefinition = e.sender
	// 		var prevUrl = dialog.getValueOf('info', 'txtUrl')
	// 		dialog.getContentElement('info', 'txtWidth').on('change', function(e) {
	//
	// 			var currentValue = dialog.getValueOf('info', 'txtWidth')
	// 			var currentUrl = dialog.getValueOf('info', 'txtUrl')
	// 			var newValue = e.data.value
	//
	// 			console.log("Onchange current", currentValue, ', new value', newValue, 'prevUrl', prevUrl)
	// 			if (currentValue == newValue && currentUrl == prevUrl) {
	// 				console.log("same, skipping...")
	// 				return
	// 			}
	//
	// 			dialog.setValueOf('info', 'txtWidth', '100%')
	// }

	/**
	 * NB: Not production ready yet
	 */
	addWidthPlugin = () => {
		/* eslint-disable */
		CKEDITOR.on('dialogDefinition', function (ev) {
			const dialogName = ev.data.name
			const dialogDefinition = ev.data.definition
			const dialog = dialogDefinition.dialog
			const editor = ev.editor

			if (dialogName === 'image') {
				var originalOnOk = dialogDefinition.onOk
				dialogDefinition.onOk = function (e) {
					originalOnOk.call(dialog, e)

					var imageElement = e.sender.imageElement
					var linkElement = e.sender.imageElement

					var maxWidth = dialog.getValueOf('info', 'txtWidth')
					console.log({ maxWidth })
					imageElement.$.style.width = '100%'
					imageElement.$.style.maxWidth = maxWidth + 'px'
					imageElement.$.style.height = ''

					if (linkElement) {
						console.log('inserting linkElement')
						editor.insertElement(linkElement)
					} else {
						console.log('inserting imageElement')
						editor.insertElement(imageElement)
					}
				}
			}
		})
	}

	addImageResizePlugin = () => {
		// snippet found on: https://ckeditor.com/old/forums/CKEditor-3.x/Change-code-inserted-for-image
		/* eslint-disable */
		CKEDITOR.on('dialogDefinition', function (ev) {
			const dialogName = ev.data.name
			const dialogDefinition = ev.data.definition
			const dialog = dialogDefinition.dialog
			const editor = ev.editor

			if (dialogName === 'image') {
				dialogDefinition.onOk = function (e) {
					const preview = e.sender.preview.$

					const imageSrcUrl = e.sender.originalElement.$.src

					const styleWidth = preview.style.width
					const styleHeight = preview.style.height

					let newUrl

					// what happens here you ask? This: https://jira.realworks.nl/browse/BMW-2856
					// check if thw user ha changed the 'width' to percentage. In that case we fetch the 'original' image
					// (still we use 900, but we could have decided to fetch the image with '?resize=4' but that is not
					// SEO friendly) If somehting similar needs to happen for the height we will fix it the, but for now
					// we only do it for 'width' because that is the most common use case
					if (styleWidth.endsWith('%')) {
						newUrl = imageSrcUrl.replace(/height=(\d)*/i, 'height=900')
						newUrl = newUrl.replace(/width=(\d)*/i, 'width=900')
					} else {
						// it must be specified in px
						const widthInPixels = styleWidth.substr(0, styleWidth.length - 'px'.length)
						const heightInPixels = styleWidth.substr(0, styleHeight.length - 'px'.length)
						newUrl = imageSrcUrl.replace(/height=(\d)*/i, 'height=' + heightInPixels)
						newUrl = newUrl.replace(/width=(\d)*/i, 'width=' + widthInPixels)
					}

					// In the end all the changes of the style throug the width/height/etc fields are reflected in the
					// 'Style' field under 'Advanced' tab. Copy this value and convert it to a style notation so we can
					// put that on the image.
					// We have to style the image ourselves now, since we are overwriting the returned image ourselves
					// Next snippet is inspirated by the source of advanced image plugin we use:
					// https://github.com/ckeditor/ckeditor-dev/blob/master/plugins/dialogadvtab/plugin.js
					const advancedStyle = e.sender.getContentElement('advanced', 'txtdlgGenStyle').getValue()
					var tmp = editor.document.createElement('span')
					tmp.setAttribute('style', advancedStyle)
					let newStyles = CKEDITOR.tools.normalizeCssText(tmp.getAttribute('style'))
					if (newStyles === '') {
						// if the advanced style field is empty the style from the preview is all we need. This is the
						// case when you add an image and close the dialog without changing the value of the advanced style field
						newStyles = CKEDITOR.tools.normalizeCssText(preview.getAttribute('style'))
					}

					const imgHtml = CKEDITOR.dom.element.createFromHtml(
						'<img src="' + newUrl + '" alt="" style="' + newStyles + '" />',
					)
					editor.insertElement(imgHtml)

					// Do we need following line as found on snippet? Disabled for now
					//element.attributes['data-cke-saved-src'] = imgsrc;
				}
			}
		})
	}
}

export default withFieldsValue(CKEditor)
