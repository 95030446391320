/**
 * @flow
 * @relayHash b1f5a8747a68ff953206d8d9e1a4f627
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type NieuwsWidgetUpdateMutationVariables = {|
  input: {
    cmsContent?: ?{
      browserDescription?: ?string;
      browserKeywords?: ?string;
      browserTitle?: ?string;
      content?: ?string;
      id: string;
      link?: ?string;
      publicationEndDate?: ?any;
      publicationStartDate?: ?any;
      status?: ?"CONCEPT" | "FINAL";
      teaserText?: ?string;
      title?: ?string;
    };
    id: string;
    imagePath?: ?string;
    order?: ?number;
  };
|};
export type NieuwsWidgetUpdateMutationResponse = {|
  +updateNewsItem: ?{|
    +value: {|
      +id: string;
      +order: ?number;
      +inactive: ?boolean;
      +imagePath: ?string;
      +cmsContent: ?{|
        +id: string;
        +title: ?string;
        +link: ?string;
        +content: ?string;
        +teaserText: ?string;
        +browserTitle: ?string;
        +browserDescription: ?string;
        +browserKeywords: ?string;
        +status: ?"CONCEPT" | "FINAL";
        +publicationStartDate: ?any;
        +publicationEndDate: ?any;
      |};
    |};
  |};
|};
*/


/*
mutation NieuwsWidgetUpdateMutation(
  $input: UpdateCmsListItemInput!
) {
  updateNewsItem(input: $input) {
    value {
      id
      order
      inactive
      imagePath
      cmsContent {
        id
        title
        link
        content
        teaserText
        browserTitle
        browserDescription
        browserKeywords
        status
        publicationStartDate
        publicationEndDate
      }
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateCmsListItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NieuwsWidgetUpdateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateCmsListItemInput!"
          }
        ],
        "concreteType": "CmsListItemPayload",
        "name": "updateNewsItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsListItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagePath",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "CmsContent",
                "name": "cmsContent",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "teaserText",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserTitle",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserDescription",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserKeywords",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationStartDate",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationEndDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "NieuwsWidgetUpdateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateCmsListItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "NieuwsWidgetUpdateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateCmsListItemInput!"
          }
        ],
        "concreteType": "CmsListItemPayload",
        "name": "updateNewsItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsListItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagePath",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "CmsContent",
                "name": "cmsContent",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "teaserText",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserTitle",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserDescription",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserKeywords",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationStartDate",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationEndDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation NieuwsWidgetUpdateMutation(\n  $input: UpdateCmsListItemInput!\n) {\n  updateNewsItem(input: $input) {\n    value {\n      id\n      order\n      inactive\n      imagePath\n      cmsContent {\n        id\n        title\n        link\n        content\n        teaserText\n        browserTitle\n        browserDescription\n        browserKeywords\n        status\n        publicationStartDate\n        publicationEndDate\n      }\n    }\n  }\n}\n"
};

module.exports = batch;
