import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Checkbox, Form } from 'antd'

const { Item: FormItem } = Form

const CheckboxComponent = ({ name, label, help, initialValue, rules, onChange }) => {
	const checked = initialValue === true || initialValue === 'true' ? true : false
	return (
		<FormItem valuePropName="checked" name={name} initialValue={checked} help={help} rules={rules}>
			<Checkbox onChange={onChange} defaultChecked={checked}>
				{label}
			</Checkbox>
		</FormItem>
	)
}

CheckboxComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,

	initialValue: PropTypes.bool,
	rules: PropTypes.array,
	onChange: PropTypes.func,
}

export default CheckboxComponent

/**
 * Tbv storybook
 */
export { CheckboxComponent }
