/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteFragment} from 'relay-runtime';
export type PageSelector_items = $ReadOnlyArray<{|
  +id: string;
  +title: ?string;
  +link: ?string;
  +pageType: ?"BLOG" | "CMS" | "DEELDIENSTEN" | "DISCLAIMER" | "NEWS" | "PRODUCTIERECHTEN" | "SUBSCRIBER" | "WONENSUBSCRIBER" | "XML";
|}>;
*/


const fragment /*: ConcreteFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PageSelector_items",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "link",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "pageType",
      "storageKey": null
    }
  ],
  "type": "Page"
};

module.exports = fragment;
