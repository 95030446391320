import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Checkbox, Form } from 'antd'

const { Item: FormItem } = Form
const CheckboxGroup = Checkbox.Group

const CheckboxGroupComponent = ({ name, label, help, initialValue, options, rules, onChange }) => {
	return (
		<FormItem name={name} initialValue={initialValue} label={label} help={help} rules={rules}>
			<CheckboxGroup onChange={onChange}>
				{options.map((option) => (
					<Checkbox defaultChecked={initialValue ? initialValue : undefined} key={option.value} value={option.value}>
						{option.label}
					</Checkbox>
				))}
			</CheckboxGroup>
		</FormItem>
	)
}

CheckboxGroupComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.array,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	rules: PropTypes.array,
	onChange: PropTypes.func,
}

export default CheckboxGroupComponent

/**
 * Tbv storybook
 */
export { CheckboxGroupComponent }
