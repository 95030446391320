/**
 * @flow
 * @relayHash 4ee8f87b35f7d97102a84a79ec45d761
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type FooterMenuWidgetCreateMutationVariables = {|
  input: {
    cmsContentId?: ?string;
    link?: ?string;
    order?: ?number;
    parentId?: ?string;
    title?: ?string;
    websiteId: string;
  };
|};
export type FooterMenuWidgetCreateMutationResponse = {|
  +createFooterMenuItem: ?{|
    +value: {|
      +id: string;
      +order: ?number;
      +parentId: ?string;
      +title: ?string;
      +link: ?string;
      +cmsContentId: ?string;
      +inactive: ?boolean;
    |};
  |};
|};
*/


/*
mutation FooterMenuWidgetCreateMutation(
  $input: CreateMenuItemInput!
) {
  createFooterMenuItem(input: $input) {
    value {
      id
      order
      parentId
      title
      link
      cmsContentId
      inactive
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateMenuItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FooterMenuWidgetCreateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateMenuItemInput!"
          }
        ],
        "concreteType": "MenuItemPayload",
        "name": "createFooterMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsContentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "FooterMenuWidgetCreateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateMenuItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "FooterMenuWidgetCreateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateMenuItemInput!"
          }
        ],
        "concreteType": "MenuItemPayload",
        "name": "createFooterMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "cmsContentId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation FooterMenuWidgetCreateMutation(\n  $input: CreateMenuItemInput!\n) {\n  createFooterMenuItem(input: $input) {\n    value {\n      id\n      order\n      parentId\n      title\n      link\n      cmsContentId\n      inactive\n    }\n  }\n}\n"
};

module.exports = batch;
