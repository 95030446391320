import React from 'react'
import { message } from 'antd'

const mapStateToProps = (state) => ({
	websiteSelectorId: state.basePage.websiteSelectorId,
	websiteId: state.basePage.websiteId,
	websiteName: state.basePage.websiteName,
	websiteSettings: state.basePage.websiteSettings,
})

const removeDeletedIDReferenceFromStore = (store, websiteId, mutationName, valueName, listName) => {
	const mutationRoot = store.getRootField(mutationName)
	if (mutationRoot === null) {
		return
	}
	const valueItem = mutationRoot.getValue(valueName)

	const websiteNode = store.get(websiteId)
	const originalList = websiteNode.getLinkedRecords(listName)

	if (!originalList) {
		return
	}

	const filteredList = [...originalList].filter((item) => item.getDataID() !== valueItem)
	websiteNode.setLinkedRecords(filteredList, listName)
}

const buildOrderMutationList = (items) => {
	const updateList = [items.length]

	items.forEach((item, index) => {
		updateList[index] = {
			id: item.id,
			order: item.order,
		}
	})

	return updateList
}

const onMutationCompleted = (successMessage, callBack) => {
	return (response, errors) => {
		console.log('Response received from server.')

		if (errors === undefined || errors === null) {
			message.success(successMessage ? successMessage : 'De gegevens zijn bewaard.', 3)

			if (callBack !== undefined && callBack !== null) {
				callBack(response)
			}
			return
		}

		console.log(errors)

		let errorElement = (
			<div style={{ display: 'inline-block' }}>
				<div style={{ fontWeight: 'bold' }}>Er is een fout opgetreden. Probeer het later opnieuw</div>
				{errors.map((error) => {
					return (
						<div key={error} style={{ fontSize: 'smaller' }}>
							{error.message}
						</div>
					)
				})}
			</div>
		)

		message.error(errorElement, 3)
	}
}

export {
	mapStateToProps as currentWebsiteProps,
	removeDeletedIDReferenceFromStore,
	buildOrderMutationList,
	onMutationCompleted,
}
