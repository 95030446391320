import PropTypes from 'prop-types'
import {
	ActionList,
	ActionSlider,
	Afbeelding,
	Blog,
	Cms,
	ContactForm,
	Facebook,
	GoogleMaps,
	HouseOfTheMonth,
	HouseOfTheWeek,
	InschrijvenNieuwsbrief,
	Instagram,
	NewsList,
	RecentAanbod,
	RssFeed,
	SearchForm,
	Smoelenboek,
	Spotlight,
	Twitter,
	WoningInVerkoop,
	YoutubeEmbed,
} from './widgets'
import { FieldTypes } from '../../dnd/FieldRenderer'

const init = ({ settings }) => {
	const widgets = [
		Afbeelding({ settings }),
		Cms({ settings }),

		SearchForm({ settings }),

		HouseOfTheWeek({ settings }),
		HouseOfTheMonth({ settings }),
		Spotlight({ settings }),
		RecentAanbod({ settings }),

		ContactForm({ settings }),

		ActionList({ settings }),
		ActionSlider({ settings }),

		Facebook({ settings }),
		Instagram({ settings }),
		Twitter({ settings }),
		YoutubeEmbed({ settings }),

		GoogleMaps({ settings }),

		Smoelenboek({ settings }),
		RssFeed({ settings }),
		InschrijvenNieuwsbrief({ settings }),
		WoningInVerkoop({ settings }),

		NewsList({ settings }),
		Blog({ settings }),
	]

	validateWidgetConfigs(widgets)
	return widgets
}

const propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	size: PropTypes.shape({
		minWidth: PropTypes.number.isRequired,
		maxWidth: PropTypes.number.isRequired,
		defaultWidth: PropTypes.number,

		minHeight: PropTypes.number,
		maxHeight: PropTypes.number,
		defaultHeight: PropTypes.number,
	}).isRequired,
	isDisabled: PropTypes.bool.isRequired,
	fieldTypes: PropTypes.arrayOf(
		PropTypes.shape({
			// only required when 'component' prop is empty. It may be specified when 'component' props it specified,
			// in that case the  value will be send to the server and the 'component' element is responsible
			// for setting the value of the field
			name: PropTypes.string,
			defaultValue: PropTypes.string,

			// custom renderer for the field, mutual exclusive with the props below. If a 'componennt' is
			// specified, it may render whata it wants without that data in it will be saved to the server.
			// It however data must be sent to the server the 'name' prop must be defined and the 'component'
			// is responsible for setting that value. It can do so by using the 'form' that is passed in the
			// props argument, f.e: props.form.setFieldsValue({ field: value })
			component: PropTypes.func,

			// all props below here are mutual exlisive with the 'component' prop
			label: PropTypes.string,
			type: PropTypes.oneOf(Object.keys(FieldTypes)),
			rules: PropTypes.array, // see antd specs

			// only required when of type @FieldTypes.SELECT
			options: PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.string,
					label: PropTypes.string,
				}),
			),
		}),
	),
}

function validateWidgetConfigs(widgets) {
	widgets.forEach((widget) => {
		PropTypes.checkPropTypes(propTypes, widget, 'prop', `${widget.id} (${widget.title})`)
	})
}

export default init
export { propTypes }
