import React from 'react'
import classnames from 'classnames'
import SliderComponent from './SliderComponent'
import { ActionHeader, ActionLink } from '../../general'
import './ThemeSettingsPage.css'
import { Tabs } from 'antd'
import { FooterPartnerLogoWidget } from '../../partnerlogo/index'
import { FooterMenuWidget } from '../../menubuilder/index'
import { FooterCmsSubContentEditor } from '../../cms/index'
import { FOOTER_IMAGES_PER_THEME } from '../../../constants'

import 'animate.css'

import { updateSettings } from '../../settings/settingsQueries'
import { currentWebsiteProps } from '../../shared'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TabPane = Tabs.TabPane

@connect(currentWebsiteProps)
export default class FooterSection extends React.Component {
	state = {
		isActive: false,
		currentFooter: null,
	}

	render() {
		const { route, router, websiteSettings } = this.props

		if (!websiteSettings) {
			return <div>loading...</div>
		}

		const { footerNummer } = websiteSettings.brokerSettings
		let currentFooter = {}
		if (footerNummer) {
			currentFooter = FOOTER_IMAGES_PER_THEME[websiteSettings.brokerSettings.websiteTheme][footerNummer - 1]
		}

		const { isActive } = this.state

		const { brokerSettings } = websiteSettings

		const theme = brokerSettings.websiteTheme

		return (
			<div className={classnames('theme-picker-container theme-wrapper', { active: isActive })}>
				<ActionHeader title="Footer">
					<ActionLink label="Annuleren" onClick={this.close} />
				</ActionHeader>

				<Tabs defaultActiveKey="theme">
					<TabPane tab="Footer" key="theme">
						{theme !== null && (
							<SliderComponent
								items={FOOTER_IMAGES_PER_THEME[brokerSettings.websiteTheme]}
								onChange={this.onFooterSelect}
								currentItem={currentFooter.value}
							/>
						)}

						{theme === null && <p>Selecteer eerst een thema.</p>}
					</TabPane>

					{currentFooter.showText && (
						<TabPane tab="Teksten" key="teksten">
							<FooterCmsSubContentEditor />
						</TabPane>
					)}
					{currentFooter.showLogo && (
						<TabPane tab="Partner logo's" key="logos">
							<FooterPartnerLogoWidget />
						</TabPane>
					)}
					{currentFooter.showMenu && (
						<TabPane tab="Menu" key="menu">
							<FooterMenuWidget />
						</TabPane>
					)}
				</Tabs>
			</div>
		)
	}

	open = () => {
		this.setState({ isActive: true })
	}

	close = () => {
		this.setState({ isActive: false })
	}

	onFooterSelect = (value) => {
		const { websiteId } = this.props

		updateSettings(
			[
				{
					name: 'footerNummer',
					value: value,
				},
			],
			websiteId,
		)
	}
}
