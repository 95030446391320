import React, { Component } from 'react'
import NestableMenuList from './NestableMenuList'
import './MenuList.css'
import FontAwesome from 'react-fontawesome'
import { deleteItem, moveItem } from '../../reducers/menuList'
import cloneDeep from 'lodash/cloneDeep'
import { Button } from 'antd'

const ButtonGroup = Button.Group

export default class MenuList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			displayMode: props.itemsFromServer.length > 7 ? 'vertical' : 'horizontal',
			items: props.itemsFromServer,
		}
	}

	/**
	 * Bereken de diepte van een object.
	 * @param item
	 * @param level
	 * @returns {*|number}
	 */
	depthOf = (item, level) => {
		level = level || 1

		if (item.children.length === 0) {
			return level
		}

		var nextLevel = level + 1
		for (const child of item.children) {
			let childLevel = this.depthOf(child, nextLevel)
			if (childLevel > level) {
				level = childLevel
			}
		}
		return level
	}

	getLevelOfItem = (id) => {
		const items = this.state.items

		const findLevel = (id, items, level) => {
			for (const item of items) {
				if (item.id === id) {
					return level
				}
				if (item.children && item.children.length) {
					const result = findLevel(id, item.children, level + 1)
					if (result > level) {
						return result
					}
				}
			}

			return -1
		}

		return findLevel(id, items, 1)
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const currentItemsFromServer = this.props.itemsFromServer
		if (currentItemsFromServer === undefined || currentItemsFromServer !== nextProps.itemsFromServer) {
			this.setState({
				items: this.filterNullEntries(nextProps.itemsFromServer),
			})
		}
	}

	filterNullEntries = (items) => {
		const filter = (items) => {
			let filteredItems = items.filter((item) => item !== null)
			for (let item of filteredItems) {
				item.children = filter(item.children)
			}
			return filteredItems
		}

		let clonedItems = cloneDeep(items)
		return filter(clonedItems)
	}

	setDisplayMode = (displayMode) => {
		this.setState({
			displayMode: displayMode,
		})
	}

	moveMenuItem = (id, afterId, nodeId) => {
		const originalItems = this.state.items
		const items = moveItem(originalItems, id, afterId, nodeId)
		this.setState({
			items: items,
		})
	}

	deleteMenuItem = (id) => {
		const originalItems = this.state.items
		const items = deleteItem(originalItems, id)
		this.setState({
			items: items,
		})
		//TODO: make this a real node.
		this.props.deleteItem({ id: id })
	}

	confirmDragAction = () => {
		this.props.onItemsChange(this.state.items)
	}

	renderButton = (orientation) => {
		const { displayMode } = this.state
		return (
			<Button
				type="default"
				onClick={() => {
					this.setDisplayMode(orientation)
				}}
				className={'btn-' + orientation}
				disabled={displayMode === orientation}
			>
				<FontAwesome className="icon" name="align-left" size="lg" />
			</Button>
		)
	}

	render() {
		const { items, displayMode } = this.state
		const { openCreateModal, openUpdateModal } = this.props

		return (
			<div className="menubuilder-wrapper">
				<ButtonGroup className="menubuilder-buttons">
					{this.renderButton('horizontal')}
					{this.renderButton('vertical')}
				</ButtonGroup>
				<div className={'menubuilder ' + displayMode}>
					<NestableMenuList
						items={items}
						move={this.moveMenuItem}
						deleteItem={this.deleteMenuItem}
						level={1}
						maxLevel={2}
						parent={null}
						openCreateModal={openCreateModal}
						openUpdateModal={openUpdateModal}
						getLevelOfItem={this.getLevelOfItem}
						depthOf={this.depthOf}
						confirmDragAction={this.confirmDragAction}
					/>
					<div className="clearfix" />
				</div>
			</div>
		)
	}
}
