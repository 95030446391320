import React, { PureComponent } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import Bugsnag from '@bugsnag/js'
import Fingerprint2 from 'fingerprintjs2'
import RenderError from './RenderError'

const isProduction = process.env.NODE_ENV === 'production'

/**
 * Dit component is een <a href="https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html">ErrorBoundary</a>
 * en rendert een error (namelijk {@link RenderError}) wanneer er een fout optreedt.
 *
 * NB: Deze ErrorBoundary handelt alle reporting af nadat de gebruiker is ingelogd. Voor eventuele fouten bij het inloggen
 * of fouten die niet worden afgehandeld door deze ErrorBoundary is er nog een toplevel ErrorBoundary in index.js.
 */
export default class RenderErrorBoundary extends PureComponent {
	fingerprint = null

	constructor() {
		super()
		this.onError = this.onError.bind(this)
	}

	onError = (report, me) => {
		// https://docs.bugsnag.com/platforms/browsers/react/customizing-error-reports/
		if (!isProduction) {
			return false
		}
		report.user = {
			id: me,
			fingerprint: this.fingerprint,
		}
	}

	componentDidMount() {
		const getFingerPrint = async () => {
			Fingerprint2.getV18({ excludes: { webgl: true, webglVendorAndRenderer: true } }, (murmur, _) => {
				this.fingerprint = murmur
			})
		}
		setTimeout(getFingerPrint, 0)
	}

	render() {
		const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

		return (
			<QueryRenderer
				environment={environment}
				query={graphql`
					query RenderErrorBoundaryQuery {
						viewer {
							userid
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						let me = undefined
						if (props.viewer.userid) {
							me = props.viewer.userid
						}
						console.log(me)
						return (
							<ErrorBoundary onError={(report) => this.onError(report, me)} FallbackComponent={RenderError}>
								{this.props.children}
							</ErrorBoundary>
						)
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}
