import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import FontAwesome from 'react-fontawesome'
import './Dropzone.css'

export default class DragAndDropApp extends React.Component {
	static props = {
		onDragEnter: PropTypes.func,
		onDragLeave: PropTypes.func,
		onDragOver: PropTypes.func,
		onDrop: PropTypes.func,
		style: PropTypes.object,
	}

	state = {
		dropzoneActive: false,
	}

	render() {
		const { dropzoneActive } = this.state

		return (
			<Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
				{({ getRootProps, getInputProps }) => (
					<>
						{this.props.children}
						{dropzoneActive && (
							<div className="overlay" {...getRootProps()}>
								<input {...getInputProps()} />
								<div className="icon-container">
									<FontAwesome name="download" size="lg" className="icon upload-icon" />
								</div>
							</div>
						)}
					</>
				)}
			</Dropzone>
		)

		return (
			<Dropzone
				noClick
				{...this.props}
				style={{}}
				onDragEnter={() => this.setState({ dropzoneActive: true })}
				onDragLeave={() => this.setState({ dropzoneActive: false })}
				onDrop={this.onDrop}
			>
				{this.props.children}
				{dropzoneActive && (
					<div className="overlay">
						<div className="icon-container">
							<FontAwesome name="download" size="lg" className="icon upload-icon" />
						</div>
					</div>
				)}
			</Dropzone>
		)
	}

	onDragEnter = () => {
		this.setState({ dropzoneActive: true })
	}

	onDragLeave = () => {
		this.setState({ dropzoneActive: false })
	}

	onDrop = (files) => {
		this.setState({
			files,
			dropzoneActive: false,
		})

		this.props.onDrop(files)
	}
}
