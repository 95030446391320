export default ({ settings }) => ({
	id: 'woningVerkoopStatistieken',
	title: 'Woning verkoop statistieken',
	description: 'Voor deze widget zijn geen instellingen nodig',
	icon: 'bar-chart',
	size: {
		minWidth: 1,
		maxWidth: 2,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [],
})
