/**
 * @flow
 * @relayHash 0eec4d135c1edcfe15e1bea61fc4e993
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type BannerTabsWidgetUpdateMutationVariables = {|
  input: {
    content?: ?string;
    id: string;
    order?: ?number;
    subtitle?: ?string;
    title?: ?string;
  };
|};
export type BannerTabsWidgetUpdateMutationResponse = {|
  +updateBannerTabItem: ?{|
    +value: {|
      +id: string;
      +order: ?number;
      +title: ?string;
      +subtitle: ?string;
      +content: ?string;
    |};
  |};
|};
*/


/*
mutation BannerTabsWidgetUpdateMutation(
  $input: UpdateBannerTabItemInput!
) {
  updateBannerTabItem(input: $input) {
    value {
      id
      order
      title
      subtitle
      content
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateBannerTabItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BannerTabsWidgetUpdateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateBannerTabItemInput!"
          }
        ],
        "concreteType": "BannerTabItemPayload",
        "name": "updateBannerTabItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "BannerTabItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "subtitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "BannerTabsWidgetUpdateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateBannerTabItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "BannerTabsWidgetUpdateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateBannerTabItemInput!"
          }
        ],
        "concreteType": "BannerTabItemPayload",
        "name": "updateBannerTabItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "BannerTabItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "subtitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation BannerTabsWidgetUpdateMutation(\n  $input: UpdateBannerTabItemInput!\n) {\n  updateBannerTabItem(input: $input) {\n    value {\n      id\n      order\n      title\n      subtitle\n      content\n    }\n  }\n}\n"
};

module.exports = batch;
