/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteFragment} from 'relay-runtime';
export type WebEmployeeQueries_payloadFields = {|
  +id: string;
  +group: ?string;
  +initialsOrNameFormat: ?number;
  +internalText: ?string;
  +office: ?number;
  +showPhoto: ?boolean;
  +showEmail: ?boolean;
  +showFunction: ?boolean;
  +showPhone: ?boolean;
  +showPhoneWork: ?boolean;
  +socialMediaChannelOptions: $ReadOnlyArray<?{|
    +label: ?string;
    +value: ?string;
  |}>;
  +socialMediaChannelSelections: $ReadOnlyArray<?string>;
  +employee: ?{|
    +id: string;
    +title: ?string;
    +initials: ?string;
    +firstName: ?string;
    +middleName: ?string;
    +lastName: ?string;
    +gender: ?string;
    +functionDescription: ?string;
    +email: ?string;
    +mobilePhoneNr: ?string;
    +workPhoneNr: ?string;
    +photoUrl: ?string;
  |};
|};
*/


const fragment /*: ConcreteFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebEmployeeQueries_payloadFields",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "group",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "initialsOrNameFormat",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "internalText",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "office",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "showPhoto",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "showEmail",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "showFunction",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "showPhone",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "showPhoneWork",
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "args": null,
      "concreteType": "LabelValuePair",
      "name": "socialMediaChannelOptions",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "label",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "socialMediaChannelSelections",
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "name": "employee",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "initials",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "firstName",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "middleName",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "lastName",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "gender",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "functionDescription",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "email",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "mobilePhoneNr",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "workPhoneNr",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "height",
              "value": 200,
              "type": "Int!"
            },
            {
              "kind": "Literal",
              "name": "width",
              "value": 200,
              "type": "Int!"
            }
          ],
          "name": "photoUrl",
          "storageKey": "photoUrl{\"height\":200,\"width\":200}"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebEmployee"
};

module.exports = fragment;
