import React from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'

const cardTarget = {
	hover(props, monitor) {
		const imageSource = monitor.getItem().item
		if (imageSource === props.item) {
			return
		}
		props.moveItem(imageSource, props.item)
	},
}
const cardSource = {
	beginDrag(props) {
		return {
			item: props.item,
		}
	},
	endDrag(props, monitor) {
		if (!monitor.didDrop()) {
			props.resetDragAction()
		} else {
			props.confirmDragAction()
		}
	},
	isDragging(props, monitor) {
		return props.item.id === monitor.getItem().item.id
	},
}
class SortableListItem extends React.Component {
	static propTypes = {
		// the item is just being passed, the may be of any type
		item: PropTypes.any.isRequired,

		connectDragSource: PropTypes.func,
		connectDropTarget: PropTypes.func,

		isDragging: PropTypes.bool,
		isDraggable: PropTypes.bool,

		// proxy functie voor DragTarget
		moveItem: PropTypes.func.isRequired,
		confirmDragAction: PropTypes.func.isRequired,
		resetDragAction: PropTypes.func.isRequired,
		dropTargetType: PropTypes.string.isRequired,

		renderChildren: PropTypes.func.isRequired,
	}

	state = {
		isHover: false,
	}

	onMouseOver() {
		this.setState({ isHover: true })
	}
	onMouseLeave() {
		this.setState({ isHover: false })
	}

	render() {
		const { connectDragSource, connectDropTarget, isDragging, item, isDraggable, renderChildren } = this.props
		const { isHover } = this.state
		const childProps = {
			isHover: isHover,
			isDragging: isDragging,
			isDraggable: isDraggable,
		}

		let className = 'sortable-list-item'
		if (isDragging) {
			className += ' is-dragging'
		}

		const comp = (
			<div className={className} onMouseOver={() => this.onMouseOver()} onMouseLeave={() => this.onMouseLeave()}>
				{renderChildren(childProps, item)}
			</div>
		)

		if (!isDraggable) {
			return comp
		}
		return connectDragSource(connectDropTarget(comp))
	}
}

export default DropTarget(
	(props) => {
		return props.dropTargetType
	},
	cardTarget,
	(connect) => ({
		connectDropTarget: connect.dropTarget(),
	}),
)(
	DragSource(
		(props) => {
			return props.dropTargetType
		},
		cardSource,
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
		}),
	)(SortableListItem),
)
