import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../../createRelayEnvironment'
import { currentWebsiteProps, onMutationCompleted } from '../../shared/'
import { connect } from 'react-redux'
import { Input } from '../../general'
import { Col, Row } from 'antd'
import CKEditor from '../../CKEditor'
import Form from '../../general/form/form/Form'
import { withBlocking } from '../../../helpers/general/WithBlockingHoc'

@connect(currentWebsiteProps)
class FooterCmsSubContentEditorClass extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	state = {
		isContentDirty: false,
	}

	componentDidUpdate(prevProps, prevState) {
		const { setPrompt } = this.props
		const { isContentDirty } = this.state
		// listen for when user is navigating away from page, so we can notify if changes aren't saved
		setPrompt(
			'Weet u het zeker dat u de pagina wilt verlaten? De wijzigingen aan de footertekst worden niet bewaard.',
			isContentDirty,
		)
	}

	setContentDirtyState = (value) => {
		this.setState({
			isContentDirty: value,
		})
	}

	createItem = (item) => {
		const { websiteId } = this.props
		const mutation = graphql`
			mutation FooterCmsSubContentEditorCreateMutation($input: CreateFooterCmsSubContentInput!) {
				createFooterCmsSubContent(input: $input) {
					value {
						id
						title
						content
					}
				}
			}
		`

		const variables = {
			input: {
				title: item.title,
				content: item.content,
				websiteId: websiteId,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createFooterCmsSubContent')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.websiteId)
			const footerConfig = node.getLinkedRecord('footerConfig')
			footerConfig.setLinkedRecord(valueItem, 'subContent')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateItem = (item) => {
		const { websiteId } = this.props
		const mutation = graphql`
			mutation FooterCmsSubContentEditorUpdateMutation($input: UpdateCmsSubContentInput!) {
				updateCmsSubContent(input: $input) {
					value {
						id
						title
						content
					}
				}
			}
		`

		const variables = {
			input: {
				...item,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('updateCmsSubContent')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(websiteId)
			const footerConfig = node.getLinkedRecord('footerConfig')
			footerConfig.setLinkedRecord(valueItem, 'subContent')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	render() {
		const { errorMessage, websiteId } = this.props
		return (
			<div>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
					}}
					query={graphql`
						query FooterCmsSubContentEditor_Query($id: ID!) {
							node(id: $id) {
								... on Website {
									id
									footerConfig {
										subContent {
											...FooterCmsSubContentEditor_item
										}
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{errorMessage || 'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<FooterSubContentEditorContainer
									item={props.node.footerConfig.subContent}
									createItem={this.createItem}
									updateItem={this.updateItem}
									setDirtyState={this.setContentDirtyState}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

class FooterCmsSubContentEditor extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		createItem: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		setDirtyState: PropTypes.func,
	}

	constructor(props) {
		super(props)
		this.state = {
			item: this.props.item,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.item === this.props.item) {
			return
		}

		this.setState({
			item: nextProps.item,
		})
	}

	onSubmit = (values) => {
		const item = {
			...this.state.item,
			title: values.title,
			content: values.content,
		}

		if (item.id === undefined) {
			this.props.createItem(item)
		} else {
			this.props.updateItem(item)
		}
		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
		return true
	}

	onCancel = () => {
		this.form.resetFields()
		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
	}

	onValuesChange = (values) => {
		if (this.props.setDirtyState) {
			this.props.setDirtyState(true)
		}
	}

	render() {
		const { item } = this.state
		return (
			<div className="editpanel">
				<div className="editpanel-content">
					<Form
						ref={(c) => {
							this.form = c
						}}
						onSubmit={this.onSubmit}
						showCancel={true}
						onCancel={this.onCancel}
						onValuesChange={this.onValuesChange}
					>
						<Input label="Titel" name="title" initialValue={item !== null ? item.title : ''} />
						<Row gutter={8}>
							<Col span={24}>
								<CKEditor
									id={item !== null && item.id !== null ? 'footerCms' + item.id : 'footerCms-new'}
									name="content"
									initialValue={item !== null ? item.content : ''}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		)
	}
}

const FooterSubContentEditorContainer = createFragmentContainer(
	FooterCmsSubContentEditor,
	graphql`
		fragment FooterCmsSubContentEditor_item on CmsSubContent {
			id
			title
			content
		}
	`,
)

FooterSubContentEditorContainer.prototype = React.Component.prototype

export default withBlocking(FooterCmsSubContentEditorClass)
