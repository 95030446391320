import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import environment from '../../../createRelayEnvironment'
import { onMutationCompleted } from '../../shared'
import { commitMutation, graphql } from 'react-relay'

export default class extends React.Component {
	static propTypes = {
		nodeId: PropTypes.string.isRequired,
		inactive: PropTypes.bool.isRequired,
		extraClass: PropTypes.string,
	}

	setInactive = () => {
		const { nodeId, inactive } = this.props

		const mutation = graphql`
			mutation SetInActiveMutation($input: NodeInactiveInput!) {
				setNodeInactive(input: $input) {
					id
					inactive
				}
			}
		`

		const variables = {
			input: {
				id: nodeId,
				inactive: !inactive,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('setNodeInactive')
			const id = mutationRoot.getValue('id')
			const inactive = mutationRoot.getValue('inactive')

			const node = store.get(id)
			node.setValue(inactive, 'inactive')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	render() {
		const { extraClass } = this.props
		return (
			<FontAwesome
				className={'set-inactive-icon ' + (extraClass ? extraClass : '')}
				name="eye-slash"
				alt="Zet op (in)actief"
				onClick={this.setInactive}
				size="2x"
			/>
		)
	}
}
