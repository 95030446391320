import cloneDeep from 'lodash/cloneDeep'

const removeItem = (id, items) => {
	for (const item of items) {
		if (item.id === id) {
			items.splice(items.indexOf(item), 1)
			return
		}

		if (item.children && item.children.length) {
			removeItem(id, item.children)
		}
	}
}

const findItem = (id, items) => {
	for (const item of items) {
		if (item.id === id) return item
		if (item.children && item.children.length) {
			const result = findItem(id, item.children)
			if (result) {
				return result
			}
		}
	}

	return false
}

const moveItem = (originalItems, id, afterId, nodeId) => {
	if (id === afterId) {
		return originalItems
	}

	let items = cloneDeep(originalItems)

	const item = { ...findItem(id, items) }
	if (!item.id) {
		return originalItems
	}

	let dest
	let parentId

	if (nodeId) {
		let parent = findItem(nodeId, items)
		dest = parent.children
		parentId = parent.id
	} else {
		dest = items
		parentId = null
	}

	item.parentId = parentId

	/*const dest = nodeId ? findItem(nodeId, items).children : items*/

	if (!afterId) {
		removeItem(id, items)
		dest.push(item)
	} else {
		const index = dest.indexOf(dest.filter((v) => v.id === afterId).shift())
		removeItem(id, items)
		dest.splice(index, 0, item)
	}

	dest.map((item, index) => {
		item.order = '' + (index + 1)
		return item
	})

	return items
}

const deleteItem = (originalItems, id) => {
	let items = cloneDeep(originalItems)
	if (id === undefined) {
		return items
	}
	removeItem(id, items)
	return items
}

export { moveItem, deleteItem }
