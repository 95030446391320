/**
 * @flow
 * @relayHash 816e42e5cd59b52b58b37b6a9e80096a
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type RewriteRulesWidgetMutationVariables = {|
  input: {
    parameters?: ?string;
    pattern: string;
    replace: string;
    status: number;
    websiteId: string;
  };
|};
export type RewriteRulesWidgetMutationResponse = {|
  +createRewriteRule: ?{|
    +value: {|
      +id: string;
      +pattern: string;
      +replace: string;
      +parameters: ?string;
      +status: number;
    |};
  |};
|};
*/


/*
mutation RewriteRulesWidgetMutation(
  $input: CreateRewriteRuleInput!
) {
  createRewriteRule(input: $input) {
    value {
      id
      pattern
      replace
      parameters
      status
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateRewriteRuleInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RewriteRulesWidgetMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateRewriteRuleInput!"
          }
        ],
        "concreteType": "RewriteRulePayload",
        "name": "createRewriteRule",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "RewriteRule",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "pattern",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "replace",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parameters",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "RewriteRulesWidgetMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateRewriteRuleInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "RewriteRulesWidgetMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateRewriteRuleInput!"
          }
        ],
        "concreteType": "RewriteRulePayload",
        "name": "createRewriteRule",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "RewriteRule",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "pattern",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "replace",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parameters",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation RewriteRulesWidgetMutation(\n  $input: CreateRewriteRuleInput!\n) {\n  createRewriteRule(input: $input) {\n    value {\n      id\n      pattern\n      replace\n      parameters\n      status\n    }\n  }\n}\n"
};

module.exports = batch;
