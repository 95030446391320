import environment from '../../createRelayEnvironment'
import { onMutationCompleted } from '../shared'
import { commitMutation, graphql } from 'react-relay'

const updateMenuItem = (item) => {
	const mutation = graphql`
		mutation mutationsUpdateMutation($input: UpdateMenuItemInput!) {
			updateMenuItem(input: $input) {
				value {
					id
					order
					parentId
					title
					link
					cmsContentId
					inactive
				}
			}
		}
	`

	const variables = {
		input: {
			...item,
		},
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
	})
}

const deleteMenuItem = (item, websiteId, listName) => {
	const mutation = graphql`
		mutation mutationsDeleteMutation($input: EntityIdInput!) {
			deleteMenuItem(input: $input) {
				deletedIds
			}
		}
	`

	const variables = {
		input: { id: item.id },
	}

	const updater = (store) => {
		const websiteNode = store.get(websiteId)
		const originalList = websiteNode.getLinkedRecords(listName)

		if (!originalList) {
			return
		}

		const mutationRoot = store.getRootField('deleteMenuItem')
		const deletedIds = mutationRoot.getValue('deletedIds')

		const filteredList = [...originalList].filter((item) => deletedIds.indexOf(item.getDataID()) === -1)
		websiteNode.setLinkedRecords(filteredList, listName)
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted('Het menu item is verwijderd.'),
		onError: (err) => console.error(err),
		updater,
	})
}

const buildMenuOrderMutationList = (items) => {
	const updateList = [items.length]

	items.forEach((item, index) => {
		updateList[index] = {
			id: item.id,
			order: item.order,
			parentId: item.parentId,
		}
	})

	return updateList
}

const onMenuItemsOrderChange = (items) => {
	const updateList = buildMenuOrderMutationList(items)

	const mutation = graphql`
		mutation mutationsOrderMutation($input: UpdateMenuItemOrderInput!) {
			updateMenuOrder(input: $input) {
				value {
					id
					order
					parentId
				}
			}
		}
	`

	const variables = {
		input: {
			items: updateList,
		},
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted('De wijzigingen zijn opgeslagen.'),
		onError: (err) => console.error(err),
	})
}

export { updateMenuItem, deleteMenuItem, onMenuItemsOrderChange }
