import { PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'
import CKEditor from '../CKEditor'
import { Input, Select } from '../general'
import Form from '../general/form/form/Form'
import { onMutationCompleted } from '../shared'
import { CMS_FORM_OPTIONS } from './cmsconstants'
import './CmsSubContentForm.css'

export default class extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		pageId: PropTypes.string.isRequired,
		setDirtyState: PropTypes.func.isRequired,
	}

	createItem = (item) => {
		const mutation = graphql`
			mutation PopupEditorCreateMutation($input: CreatePopupInput!) {
				createPopup(input: $input) {
					value {
						id
						title
						content
						cmsForm
					}
				}
			}
		`

		const variables = {
			input: {
				title: item.title,
				content: item.content,
				cmsForm: item.cmsForm,
				pageId: this.props.pageId,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createPopup')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.pageId)
			node.setLinkedRecord(valueItem, 'popup')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateItem = (item) => {
		const mutation = graphql`
			mutation PopupEditorUpdateMutation($input: UpdatePopupInput!) {
				updatePopup(input: $input) {
					value {
						id
						title
						content
						cmsForm
					}
				}
			}
		`

		const variables = {
			input: {
				...item,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('updatePopup')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.pageId)
			node.setLinkedRecord(valueItem, 'popup')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	deleteItem = (item) => {
		const mutation = graphql`
			mutation PopupEditorDeleteMutation($input: EntityIdInput!) {
				deletePopup(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			configs,
		})
	}

	render() {
		return (
			<PopupEditorComponent
				item={this.props.item}
				updateItem={this.updateItem}
				createItem={this.createItem}
				deleteItem={this.deleteItem}
				setDirtyState={this.props.setDirtyState}
			/>
		)
	}
}

class PopupEditorComponent extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		updateItem: PropTypes.func.isRequired,
		setDirtyState: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			isEditMode: false,
		}
	}

	onOpenEditMode = () => {
		const { isEditMode } = this.state
		if (isEditMode) {
			return
		}
		this.setState({ isEditMode: true })
	}

	onCloseEditMode = () => {
		this.setState({ isEditMode: false })
	}

	onCreateNew = () => {
		this.setState({
			isEditMode: true,
		})
	}

	onSubmit = (values) => {
		const item = {
			...this.props.item,
			title: values.title,
			content: values.content,
			cmsForm: values.cmsForm,
		}

		if (item.id === undefined) {
			this.props.createItem(item)
		} else {
			this.props.updateItem(item)
		}

		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
		this.onCloseEditMode()
		return true
	}

	onCancel = () => {
		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
		this.onCloseEditMode()
	}

	onDeleteClick = (event) => {
		event.stopPropagation()
		this.props.deleteItem(this.props.item)
	}

	renderAddButton = () => {
		return (
			<div className="contentblok">
				<div className="plus" onClick={this.onCreateNew}>
					<PlusOutlined className={'icon'} />
					<span className={'label'}>Popup</span>
				</div>
			</div>
		)
	}

	renderInformation = () => {
		const { item } = this.props
		return (
			<div className="infopanel">
				<FontAwesome name="times" size="lg" className="icon delete-icon" onClick={(e) => this.onDeleteClick(e)} />
				<div className="title">Popup</div>
				<div className="subtitle">{item ? item.title : ''}</div>
			</div>
		)
	}

	onValuesChange = (values) => {
		if (this.props.setDirtyState) {
			this.props.setDirtyState(true)
		}
	}

	renderEditPanel = () => {
		const { item } = this.props
		return (
			<div className="editpanel">
				<div className="editpanel-content">
					<Form
						ref={(c) => {
							this.form = c
						}}
						onSubmit={this.onSubmit}
						showCancel={true}
						onCancel={this.onCancel}
						onValuesChange={this.onValuesChange}
					>
						<Input label="Titel" name="title" initialValue={item ? item.title : ''} />
						<Row gutter={8}>
							<Col span={24}>
								<CKEditor
									id={item !== null && item.id !== null ? 'popupCms' + item.id : 'popupCms-new'}
									name="content"
									initialValue={item ? item.content : ''}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={24}>
								<Select
									label="Formulier"
									name="cmsForm"
									initialValue={item && item.cmsForm !== null ? item.cmsForm : 'NONE'}
									options={CMS_FORM_OPTIONS}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		)
	}

	render() {
		const { isEditMode } = this.state
		const { item } = this.props

		if (item === null && !isEditMode) {
			return this.renderAddButton()
		}

		return (
			<div className="contentblok" onClick={this.onOpenEditMode}>
				{!isEditMode && this.renderInformation()}
				{isEditMode && this.renderEditPanel()}
			</div>
		)
	}
}
