import environment from '../../createRelayEnvironment'
import { commitMutation, graphql } from 'react-relay'
import { onMutationCompleted } from '../shared'

const updateCmsSubContent = (item, pageId, parentFieldName) => {
	const mutation = graphql`
		mutation CmsSubContentHelperUpdateMutation($input: UpdateCmsSubContentInput!) {
			updateCmsSubContent(input: $input) {
				value {
					id
					title
					content
				}
			}
		}
	`

	const variables = {
		input: {
			...item,
		},
	}

	const updater = (store) => {
		const mutationRoot = store.getRootField('updateCmsSubContent')
		const valueItem = mutationRoot.getLinkedRecord('value')
		const node = store.get(pageId)
		node.setLinkedRecord(valueItem, parentFieldName)
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		updater,
	})
}

const deleteCmsSubContent = (item) => {
	const mutation = graphql`
		mutation CmsSubContentHelperDeleteMutation($input: EntityIdInput!) {
			deleteCmsSubContent(input: $input) {
				deletedId
			}
		}
	`

	const variables = {
		input: { id: item.id },
	}

	const configs = [
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedId',
		},
	]

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		configs,
	})
}

export { updateCmsSubContent, deleteCmsSubContent }
