import React from 'react'
import PropTypes from 'prop-types'
import '../cmslists/CmsListItem.css'
import FontAwesome from 'react-fontawesome'
import { Form, Input, DatePicker, Select } from '../../general'
import { Col, Row } from 'antd'
import moment from 'moment'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import CKEditor from '../../CKEditor'
import { cloneDeep } from 'lodash'
import { SetInActive } from './../../general'

export default class NieuwsListItem extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		isDraggable: PropTypes.bool,
		isDragging: PropTypes.bool,
		enableEditMode: PropTypes.func,
		disableEditMode: PropTypes.func,
		removeItem: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			isHover: false,
			isEditMode: false,
			item: props.item,
			originalItem: props.item,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.item !== nextProps.item) {
			this.setState({
				item: nextProps.item,
			})
		}
	}

	onMouseOver() {
		if (this.state.isEditMode) {
			return
		}
		this.setState({ isHover: true })
	}
	onMouseLeave() {
		if (this.state.isEditMode) {
			return
		}
		this.setState({ isHover: false })
	}

	onClosePanel = () => {
		this.setState({ isEditMode: false })
		this.props.disableEditMode()
	}

	onOpenPanel = () => {
		this.setState({ isEditMode: true })
		this.props.enableEditMode()
	}

	onDelete = () => {
		this.props.removeItem(this.props.item)
	}

	onSubmit = (values) => {
		const item = cloneDeep(this.state.item)

		item.subject = values.subject
		item.cmsContent.title = values.title
		item.cmsContent.link = values.link
		item.cmsContent.content = values.content
		item.cmsContent.status = values.status
		item.cmsContent.publicationStartDate =
			values.publicationStartDate !== null ? values.publicationStartDate.format('YYYY-MM-DD') : null
		item.cmsContent.publicationEndDate =
			values.publicationEndDate !== null ? values.publicationEndDate.format('YYYY-MM-DD') : null

		item.cmsContent.browserTitle = values.browserTitle
		item.cmsContent.browserDescription = values.browserDescription
		item.cmsContent.browserKeywords = values.browserKeywords

		this.props.editItem(item)
		this.onClosePanel()
		return true
	}

	onCancel = () => {
		const { originalItem } = this.state

		this.setState({
			item: originalItem,
		})

		this.onClosePanel()
	}

	renderEditPanel = () => {
		const { item } = this.state
		return (
			<CSSTransition classNames="editpanel" timeout={{ exit: 300, enter: 500 }}>
				<div className="editpanel">
					<div className="editpanel-content">
						<Form
							ref={(c) => {
								this.form = c
							}}
							onSubmit={this.onSubmit}
							showCancel={true}
							onCancel={this.onCancel}
						>
							<Input label="Onderwerp" name="subject" initialValue={item.subject} />
							<Input label="Titel" name="title" initialValue={item.cmsContent.title} maxLength={250} />

							<Input
								label="Link"
								name="link"
								initialValue={item.cmsContent.link}
								disabled={true}
								required={true}
								maxLength={95}
								help="Het wijzigen van een link is op dit moment niet mogelijk."
							/>

							<Input
								label="Browser Titel"
								name="browserTitle"
								initialValue={item.cmsContent.browserTitle}
								maxLength={250}
							/>
							<Input
								label="Browser Omschrijving"
								name="browserDescription"
								initialValue={item.cmsContent.browserDescription}
								maxLength={250}
							/>
							<Input
								label="Browser Trefwoorden"
								name="browserKeywords"
								initialValue={item.browserKeywords}
								disabled={item.cmsContent.browserKeywords === null || item.cmsContent.browserKeywords.length === 0}
								help="Trefwoorden worden niet meer gebruikt door Google voor het indexeren van de pagina. Reeds ingevoerde trefwoorden kunnen nog gewijzigd worden, voor nieuwe pagina's is dit niet meer mogelijk."
								maxLength={250}
							/>
							<Row gutter={8}>
								<Col span={5}>
									<Select
										label="Status"
										name="status"
										initialValue={item.cmsContent.status === null ? item.status : 'FINAL'}
										rules={[{ required: true }]}
										options={[
											{ label: 'Concept', value: 'CONCEPT' },
											{ label: 'Definitief', value: 'FINAL' },
										]}
									/>
								</Col>
								<Col span={4}>
									<DatePicker
										label=" "
										name="publicationStartDate"
										initialValue={
											item.cmsContent.publicationStartDate
												? moment(item.cmsContent.publicationStartDate, 'YYYY-MM-DD')
												: null
										}
										placeholder="Publicatie vanaf"
										required={true}
									/>
								</Col>
								<Col span={4}>
									<DatePicker
										label="."
										name="publicationEndDate"
										initialValue={
											item.cmsContent.publicationEndDate
												? moment(item.cmsContent.publicationEndDate, 'YYYY-MM-DD')
												: null
										}
										placeholder="Publicatie tot"
									/>
								</Col>
							</Row>

							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={item !== null && item.id !== null ? 'newsLetterCms' + item.id : 'newsLetterCms-new'}
										name="content"
										initialValue={item.cmsContent.content || ''}
									/>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</CSSTransition>
		)
	}

	render() {
		const { isDragging, isDraggable } = this.props
		const { isEditMode, item } = this.state

		return (
			<div
				className={
					'nieuwslist-item' +
					(isDragging ? ' is-dragging' : '') +
					(isEditMode ? ' editmode' : '') +
					(item.inactive ? ' inactive' : '') +
					(isDraggable ? ' draggable' : '')
				}
				onMouseOver={() => this.onMouseOver()}
				onMouseLeave={() => this.onMouseLeave()}
			>
				<div className="nieuwslist-item-handle">
					<div className="nieuwslist-item-title">{item.subject}</div>
					<div className={'nieuwslist-item-buttonpanel ' + (this.state.isHover && isDraggable ? '' : 'hidden')}>
						<SetInActive nodeId={item.id} inactive={item.inactive} />
						<FontAwesome className="nieuwslist-item-icon" name="pencil" onClick={this.onOpenPanel} size="2x" />
						<FontAwesome className="nieuwslist-item-icon" name="trash" onClick={this.onDelete} size="2x" />
					</div>
				</div>

				<TransitionGroup>{this.state.isEditMode && this.renderEditPanel()}</TransitionGroup>
			</div>
		)
	}
}
