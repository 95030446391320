/**
 * @flow
 * @relayHash 3a27a1e7a3f80d1169191c5d04251dce
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsCreateDirMutationVariables = {|
  parent: string;
  parentDirectoryId?: ?number;
  newDir: string;
|};
export type mutationsCreateDirMutationResponse = {|
  +createDir: ?{|
    +value: $ReadOnlyArray<?{|
      +name: string;
    |}>;
  |};
|};
*/


/*
mutation mutationsCreateDirMutation(
  $parent: String!
  $parentDirectoryId: Int
  $newDir: String!
) {
  createDir(parent: $parent, parentDirectoryId: $parentDirectoryId, newDir: $newDir) {
    value {
      name
      id
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "parent",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "newDir",
        "type": "String!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsCreateDirMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "newDir",
            "variableName": "newDir",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "parent",
            "variableName": "parent",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "createDir",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsCreateDirMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "parent",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "newDir",
        "type": "String!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsCreateDirMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "newDir",
            "variableName": "newDir",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "parent",
            "variableName": "parent",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "createDir",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsCreateDirMutation(\n  $parent: String!\n  $parentDirectoryId: Int\n  $newDir: String!\n) {\n  createDir(parent: $parent, parentDirectoryId: $parentDirectoryId, newDir: $newDir) {\n    value {\n      name\n      id\n    }\n  }\n}\n"
};

module.exports = batch;
