import React from 'react'
import classnames from 'classnames'
import './ThemeSettingsPage.css'
import { ActionLink } from '../../general/form/actionheader/ActionHeader'
import ColorSection from './ColorSection'
import SliderComponent from './SliderComponent'
import { Tabs } from 'antd'
import 'animate.css'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../../shared'
import { updateSettings } from '../../settings/settingsQueries'
import { THEMES } from '../../../constants'
import { ActionHeader, Select, Form } from '../../general'

const TabPane = Tabs.TabPane

@connect(currentWebsiteProps)
export default class ThemeSection extends React.Component {
	state = {
		isActive: false,
	}

	onSubmit = (values) => {
		const { websiteId } = this.props
		let dataToMutate = Object.entries(values).map(([key, value]) => {
			return {
				name: key,
				value: value,
			}
		})
		updateSettings(dataToMutate, websiteId)
	}

	render() {
		const { isActive } = this.state

		const { websiteSettings, websiteId } = this.props

		const currentTheme =
			websiteSettings && websiteSettings.brokerSettings && websiteSettings.brokerSettings.websiteTheme

		console.log(currentTheme)

		const brokerSettings = websiteSettings.brokerSettings

		return (
			<div className={classnames('theme-picker-container theme-wrapper', { active: isActive })}>
				<ActionHeader title="Huisstijl">
					<ActionLink label="Annuleren" onClick={this.close} />
				</ActionHeader>

				<Tabs defaultActiveKey={'theme'}>
					<TabPane tab="Thema" key="theme">
						<SliderComponent
							items={THEMES}
							onChange={(value) => {
								updateSettings(
									[
										{
											name: 'websiteTheme',
											value,
										},
									],
									websiteId,
								)
							}}
							currentItem={currentTheme}
							thumbnailInnerStyle={{ height: '100%' }}
						/>
					</TabPane>

					<TabPane tab="Kleuren" key="colors">
						<ColorSection colors={websiteSettings.brokerSettings} />
					</TabPane>

					{(currentTheme === 'theme-c' || currentTheme === 'theme-d') && (
						<TabPane tab="Overige instellingen" key="overigeinstellingen" forceRender={true}>
							<Form key={'themeSectionForm' + websiteId} onSubmit={this.onSubmit}>
								<Select
									name={'ogZoekformulierMenu'}
									label="Soort zoekformulier menu"
									options={[
										{ value: 'geen', label: 'Geen' },
										{ value: '', label: 'Automatisch' },
										{ value: 'wonen', label: 'Wonen' },
										{ value: 'bog', label: 'BOG' },
										{ value: 'alv', label: 'ALV' },
										{ value: 'nieuwbouw', label: 'Nieuwbouw' },
										{ value: 'vgmcomplex', label: 'VGM' },
									]}
									initialValue={brokerSettings.ogZoekformulierMenu ? brokerSettings.ogZoekformulierMenu : ''}
								/>
							</Form>
						</TabPane>
					)}
				</Tabs>
			</div>
		)
	}

	open = () => {
		this.setState({ isActive: true })
	}

	close = () => {
		this.setState({ isActive: false })
	}
}
