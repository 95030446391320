import React from 'react'
import PropTypes from 'prop-types'
import { FormSection, HoveredTextOverlay, Form, Input, Select } from '../general/'

export default class HeaderBannerPanel extends React.Component {
	static propTypes = {
		imagepath: PropTypes.string.isRequired,
		link: PropTypes.string,
		target: PropTypes.string,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		onImageClick: PropTypes.func.isRequired,
	}

	state = {
		link: this.props.link,
		target: this.props.target,
	}

	UNSAFE_componentWillReceiveProps(next) {
		// when user clicks another item in HeaderBannerWidget, update the state here
		this.setState({ ...next })
	}

	onLinkChange = (e) => {
		const item = { ...this.state.item }
		item.link = e.target.value
		this.setState({
			item: item,
		})
	}
	onTargetChange = (value) => {
		this.setState({
			item: { ...this.state.item, target: value },
		})
	}

	onSubmit = (values) => {
		const { imagepath } = this.props
		this.props.onSave({
			imagepath: imagepath,
			link: values.link,
			target: values.target,
		})
	}

	onCancel = () => {
		this.props.onCancel()
	}

	render() {
		const { link, target } = this.state
		const { onImageClick, imagepath } = this.props

		return (
			<div className="partnerlogo-panel" style={{ width: 600 }}>
				<div className="icon-thumbnail" style={{ cursor: 'pointer' }} onClick={onImageClick}>
					<HoveredTextOverlay title={'Wijzigen'}>
						<img src={imagepath} width={600} alt={'preview'} />
					</HoveredTextOverlay>
				</div>
				<FormSection>
					<Form onSubmit={this.onSubmit} showCancel={true} onCancel={this.onCancel}>
						<Input
							initialValue={link}
							name={'link'}
							label="Link (optioneel)"
							type="text"
							placeholder="Enter text"
							onChange={this.onLinkChange}
						/>
						<Select
							initialValue={target}
							name={'target'}
							label="URL openen in"
							onChange={this.onTargetChange}
							options={[
								{ value: '_blank', label: 'Nieuw tabblad' },
								{
									value: '_self',
									label: 'Huidige tabblad',
								},
							]}
						/>
					</Form>
				</FormSection>
			</div>
		)
	}
}
