import { message } from 'antd'
import React from 'react'
import { withSearchParams } from '../../helpers/general/WithSearchParams'
import { FilePicker, FILTER_TYPES } from '../general'
import { makePreviewUrlWithWidthAndHeightParameters } from '../general/helper'

const FilePickerPage = (props) => {
	const { searchParams } = props

	//const tokenServer = searchParams.get('tokenServer')
	//const CKEditorParam = searchParams.get('CKEditor')
	//const langCode = searchParams.get('langCode')
	const CKEditorFuncNum = searchParams.get('CKEditorFuncNum')
	const sourceType = searchParams.get('sourceType')
	const type = searchParams.get('type')
	const origin = searchParams.has('origin') ? searchParams.get('origin') : window.location.origin
	const host = searchParams.has('host') ? searchParams.get('host') : window.location.host

	let accept = '*/*'
	let filter = {
		q: '',
		type: FILTER_TYPES.ALL,
	}

	if (type === 'images') {
		accept = 'image/*'
		filter = {
			q: '',
			type: FILTER_TYPES.IMAGES,
		}
	}

	const isCalledFromCkeditor = CKEditorFuncNum ? true : false

	const isInIFrame = sourceType === 'iframe'

	const callback = isCalledFromCkeditor
		? (fileUrl) => onSelectFileCkeditorCallback(fileUrl, CKEditorFuncNum, type)
		: (fileUrl) => onSelectFileUsingPostMessage(fileUrl, type, host, origin)

	function cmsFileToImageUrl(type, fileUrl) {
		// the 900x900 is arbitrair. An alternative could be to pass the width/height to this page as a (query) parameter
		return type === 'images'
			? makePreviewUrlWithWidthAndHeightParameters(fileUrl.previewUrl, 900, 900)
			: fileUrl.staticUrl
	}

	const onSelectFileCkeditorCallback = (fileUrl, CKEditorFuncNum, type) => {
		if (!fileUrl) {
			message.error('Geen bestand geselecteerd')
			return
		}
		const url = cmsFileToImageUrl(type, fileUrl)

		window.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, url)
		window.close()
	}

	const onSelectFileUsingPostMessage = (fileUrl, type, host, origin) => {
		if (!fileUrl) {
			message.error('Geen bestand geselecteerd')
			return
		}
		const url = cmsFileToImageUrl(type, fileUrl)

		// better to be strict, so only allowe "realworks.nl' domains
		if (!host.endsWith('.realworks.nl')) {
			throw new Error('Origin not allowed: ' + origin)
		}

		const payload = {
			source: 'realworks-cmsfile-picker',
			eventName: 'file-picked',
			url,
		}

		if (window.parent) {
			window.parent.postMessage(payload, origin)
		} else {
			// we o
			throw new Error('No valid origin window found')
		}
	}

	const onCancel = () => {
		window.close()
	}

	return (
		<FilePicker
			accept={accept}
			filter={filter}
			onCancel={onCancel}
			fullScreen={true}
			onSelectFile={callback}
			isInIframe={isInIFrame}
		/>
	)
}

export default withSearchParams(FilePickerPage)
