import React from 'react'
import PropTypes from 'prop-types'
import './BannerTabsListItem.css'
import FontAwesome from 'react-fontawesome'
import Form from '../general/form/form/Form'
import { Input } from '../general'
import { Button, Col, Row } from 'antd'
import CKEditor from '../CKEditor'
import { cloneDeep } from 'lodash'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
export default class BannerTabsListItem extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		isDraggable: PropTypes.bool,
		isDragging: PropTypes.bool,
		enableEditMode: PropTypes.func,
		disableEditMode: PropTypes.func,
		updateItem: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
	}

	constructor(props) {
		//Als het een nieuw item betreft dan kan het component alleen in editMode beginnen. Bij de overige modes
		//is dat niet het geval, maar triggert isEditMode pas wanneer je op de editknop drukt.
		const isEditMode = props.mode === 'NEW' ? true : false
		super(props)
		this.state = {
			isHover: false,
			isEditMode: isEditMode,
			item: props.item,
			originalItem: props.item,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.item !== nextProps.item) {
			this.setState({
				item: nextProps.item,
			})
		}
	}

	onMouseOver() {
		this.setState({ isHover: true })
	}
	onMouseLeave() {
		this.setState({ isHover: false })
	}

	onClosePanel = () => {
		this.setState({ isEditMode: false })
		this.props.disableEditMode()
	}

	onOpenPanel = () => {
		this.setState({ isEditMode: true })
		this.props.enableEditMode()
	}

	onDelete = () => {
		this.props.deleteItem(this.props.item)
	}

	onSubmit = (values) => {
		const item = cloneDeep(this.state.item)
		item.title = values.title
		item.subtitle = values.subtitle
		item.content = values.content

		if (this.props.mode && this.props.mode === 'NEW') {
			this.props.createItem(item)
		}
		if (this.props.mode && this.props.mode === 'EDIT') {
			this.props.updateItem(item)
		}
		this.onClosePanel()
		return true
	}

	onCancel = () => {
		const { originalItem } = this.state
		this.setState({
			item: originalItem,
		})
		this.onClosePanel()
	}

	renderEditPanel = () => {
		const { item } = this.state
		return (
			<CSSTransition classNames="editpanel" timeout={{ exit: 300, enter: 500 }}>
				<div className="editpanel">
					<div className="editpanel-content">
						<h2>Edit scherm</h2>
						<Form
							ref={(c) => {
								this.form = c
							}}
							showSubmit={false}
							showCancel={false}
							onCancel={this.onCancel}
							onSubmit={this.onSubmit}
						>
							<Input label="Titel" name="title" initialValue={item.title} rules={[{ required: true }]} />
							<Input label="Ondertitel" name="subtitle" initialValue={item.subtitle} rules={[{ required: true }]} />
							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={item !== null && item.id !== null ? 'bannerTabsItem' + item.id : 'bannerTabsItem-new'}
										name="content"
										initialValue={item.content || ''}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={5}>
									<Button
										type="primary"
										onClick={() => {
											this.form.submit()
										}}
									>
										Bewaar
									</Button>
								</Col>
								<Col span={5} offset={1}>
									<Button
										type="secondary"
										onClick={() => {
											this.onCancel()
										}}
									>
										Annuleren
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</CSSTransition>
		)
	}

	render() {
		const { isDragging, isDraggable } = this.props
		const { isEditMode, item } = this.state

		return (
			<div
				className={
					'banner-tab-item' +
					(isDragging ? ' is-dragging' : '') +
					(isEditMode ? ' editmode' : '') +
					(isDraggable ? ' draggable' : '')
				}
				onMouseOver={() => this.onMouseOver()}
				onMouseLeave={() => this.onMouseLeave()}
			>
				<div className="item-handle">
					<div className="item-title">
						{item.order} {item.title}
					</div>
					<div className={'item-buttonpanel ' + (this.state.isHover && isDraggable ? '' : 'hidden')}>
						<FontAwesome className="item-icon" name="pencil" onClick={this.onOpenPanel} size="2x" />
						<FontAwesome className="item-icon" name="trash" onClick={this.onDelete} size="2x" />
					</div>
				</div>

				<TransitionGroup>{this.state.isEditMode && this.renderEditPanel()}</TransitionGroup>
			</div>
		)
	}
}
