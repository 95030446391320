import React, { Component } from 'react'
import BaseNavigator from '../../components/BaseNavigator'
import WebsiteSelector from '../WebsiteSelector'
import './BasePage.css'
import { connect } from 'react-redux'
import { updateCurrentWebsiteId, updateCurrentWebsiteSettings, updateHelpdeskUser } from '../../actions'
import { currentWebsiteProps, onMutationCompleted } from '../shared/index'
import { getAllSettings } from '../settings/settingsQueries'
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Affix, Button, Col, Row, Tooltip } from 'antd'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { isHelpdeskUser } from '../viewer/viewerQueries'
import NoWebsitePage from './NoWebsitePage'
import { Outlet } from 'react-router-dom'
import { withRouter } from '../../helpers/general/WithRouterHoc'

const mapDispatchToProps = (dispatch) => ({
	updateCurrentWebsiteId(websiteId, websiteName) {
		dispatch(updateCurrentWebsiteId(websiteId, websiteName))
	},
	updateCurrentWebsiteSettings(websiteId, settings) {
		dispatch(updateCurrentWebsiteSettings(websiteId, settings))
	},
	updateHelpdeskUser(isHelpdeskUser) {
		dispatch(updateHelpdeskUser(isHelpdeskUser))
	},
})

const mapStateToProps = (state) => {
	const currentWebsitePropsValues = currentWebsiteProps(state)

	return {
		...currentWebsitePropsValues,
		isHelpdeskUser: state.viewer.isHelpdeskUser,
		steps: state.guider.steps,
		addedSteps: state.guider.addedSteps,
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class BasePage extends Component {
	state = {
		steps: [],
		showGuider: true,
	}

	async componentDidMount() {
		/* this.props.router.listen((value) => {
			window.scrollTo(0, 0)
		}) */
		if (localStorage.showGuider !== 'false') {
			this.setState({ showGuider: true })
		}
	}

	async UNSAFE_componentWillReceiveProps(next) {
		const { websiteSelectorId } = this.props

		// disable joyride for now
		// if (next.steps !== this.props.steps) {
		// 	this.joyride.reset()
		//
		// 	// we need to explicitly stop joyride first to reset it, as seen in the joyride example code, see:
		// 	// https://github.com/gilbarbara/react-joyride/blob/demo/app/scripts/containers/App.jsx#L80
		// 	this.setState(
		// 		{
		// 			showGuider: false,
		// 		},
		// 		() => {
		// 			this.setState({
		// 				showGuider: true,
		// 				steps: next.steps,
		// 			})
		// 		},
		// 	)
		// } else if (next.addedSteps !== this.props.addedSteps) {
		// 	this.setState({
		// 		steps: next.addedSteps,
		// 	})
		// }

		if (next.isHelpdeskUser === undefined) {
			const result = await isHelpdeskUser()
			this.props.updateHelpdeskUser(result)
		}

		if (next.websiteSelectorId !== websiteSelectorId) {
			// reload settings when website changes
			const settings = await getAllSettings(next.websiteSelectorId)
			this.props.updateCurrentWebsiteSettings(next.websiteSelectorId, settings)
		}
	}

	afterJoyrideClose = (data) => {
		if (!data.step) {
			//TODO: update database settings flag for joyride
			// if(data.type === "finished") {
			// 	localStorage.setItem("showGuider", false)
			// }
			//this.setState({ showGuider: false})
			return
		}

		// if a step has a 'callback', then fire that with router (so we can navigate) and propts as arguments
		if (data.step.callback && data.type === 'step:after') {
			data.step.callback(data, this.props.router)
		}
	}

	switchToOldVersion = () => {
		const mutation = graphql`
			mutation BasePageSwitchMutation($value: Boolean!) {
				switchToOldWebsiteBeheer(value: $value) {
					value
				}
			}
		`

		const variables = {
			value: false,
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: () => {
				onMutationCompleted('Alle vereiste onderdelen zijn aangemaakt.')
				let url = window.GLOBAL_CONSTANTS_CRM_PATH + '/servlets/objects/settings.websitebeheer/welcome'
				window.location.href = url
			},
			onError: (err) => console.error(err),
		})
	}

	onWebsitesLoaded = (websites) => {
		this.setState({
			nrOfWebsites: websites.length,
		})
	}

	onSupportClick = () => {
		const url =
			window.GLOBAL_CONSTANTS_CRM_PATH +
			'/servlets/objects/supportdashboard.supportdashboard/supportdashboard?category=Website%203.0'
		window.open(url, 'Support', 'height=700,width=1280')
	}

	renderWebsite = () => {
		const { websiteId, children, websiteSettings } = this.props
		const { nrOfWebsites } = this.state

		if (websiteId === null) {
			if (nrOfWebsites !== undefined && nrOfWebsites === 0) {
				return <NoWebsitePage />
			}
			return <div />
		}

		return websiteSettings && <Outlet />
	}

	render() {
		const { isHelpdeskUser } = this.props
		// const { showGuider } = this.state
		// const {steps} = this.props
		// const { steps } = this.state

		return (
			<div id="main">
				{/*<Joyride*/}
				{/*ref={(ref) => (this.joyride = ref)}*/}
				{/*run={showGuider} // or some other boolean for when you want to start it*/}
				{/*debug={false}*/}
				{/*callback={this.afterJoyrideClose}*/}
				{/*steps={steps}*/}
				{/*stepIndex={0}*/}
				{/*type={'continuous'}*/}
				{/*/>*/}

				<Affix>
					<div>
						<div
							className={'websiteSelectorTarget'}
							style={{ position: 'absolute', right: 10, marginTop: 10, width: 380, height: 32 }}
						>
							<Row gutter={8}>
								<Col span={4}>
									{isHelpdeskUser && (
										<Tooltip title={'Terug naar oude website beheer'} placement="bottom">
											<Button type="primary" className={'servicedesk-button'} onClick={this.switchToOldVersion}>
												<LeftOutlined />
											</Button>
										</Tooltip>
									)}
								</Col>
								<Col span={16}>
									<WebsiteSelector
										onWebsitesLoaded={this.onWebsitesLoaded}
										onWebsiteSelected={this.props.updateCurrentWebsiteId}
									/>
								</Col>
								<Col span={4}>
									<Tooltip title={'Support'} placement="bottomRight">
										<QuestionCircleOutlined className="support-icon" onClick={this.onSupportClick} />
									</Tooltip>
								</Col>
							</Row>
						</div>
						<BaseNavigator isHelpdeskUser={isHelpdeskUser} />
					</div>
				</Affix>

				{this.renderWebsite()}
			</div>
		)
	}
}

export default withRouter(BasePage)
