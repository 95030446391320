import React from 'react'
import PropTypes from 'prop-types'
import BlogList from '../CmsList'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../../../createRelayEnvironment'
import { connect } from 'react-redux'
import {
	buildOrderMutationList,
	currentWebsiteProps,
	onMutationCompleted,
	removeDeletedIDReferenceFromStore,
} from '../../../shared/index'
import { message } from 'antd'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	createItem = (item, callBack) => {
		const mutation = graphql`
			mutation BlogWidgetMutation($input: CreateCmsListItemInput!) {
				createBlogItem(input: $input) {
					value {
						id
						order
						inactive
						imagePath
						cmsContent {
							id
							title
							link
							content
							teaserText
							browserTitle
							browserDescription
							browserKeywords
							status
							publicationStartDate
							publicationEndDate
						}
					}
				}
			}
		`

		const variables = {
			input: { ...item.cmsContent, websiteId: this.props.websiteId, imagePath: item.imagePath },
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createBlogItem')
			if (mutationRoot === null) {
				return
			}
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.websiteId)
			const listItems = node.getLinkedRecords('blogItems')
			if (listItems) {
				node.setLinkedRecords([valueItem, ...listItems], 'blogItems')
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				if (errors === undefined || errors === null) {
					message.success('De pagina is aangemaakt.', 3)
					callBack()
					return
				}

				let errorMessage = 'Er is een fout opgetreden. Probeer het later opnieuw.'

				errors.forEach((error) => {
					if (error.message.indexOf('er_bestaat_al_een_cms_pagina_voor_website_department_en_naam_2') >= -1) {
						errorMessage = 'Er bestaat al een pagina met deze link'
					}
				})

				message.error(errorMessage, 3)
			},
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateItem = (item) => {
		const mutation = graphql`
			mutation BlogWidgetUpdateMutation($input: UpdateCmsListItemInput!) {
				updateBlogItem(input: $input) {
					value {
						id
						order
						inactive
						imagePath
						cmsContent {
							id
							title
							link
							content
							teaserText
							browserTitle
							browserDescription
							browserKeywords
							status
							publicationStartDate
							publicationEndDate
						}
					}
				}
			}
		`

		const variables = {
			input: {
				id: item.id,
				order: item.order,
				imagePath: item.imagePath,
				cmsContent: item.cmsContent,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	deleteItem = (item) => {
		const { websiteId } = this.props

		const mutation = graphql`
			mutation BlogWidgetDeleteMutation($input: EntityIdInput!) {
				deleteBlogItem(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		const updater = (store) => {
			removeDeletedIDReferenceFromStore(store, websiteId, 'deleteBlogItem', 'deletedId', 'blogItems')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			configs,
			updater,
		})
	}

	onItemsChange = (items) => {
		const updateList = buildOrderMutationList(items)

		const mutation = graphql`
			mutation BlogWidgetOrderMutation($input: UpdateOrderInput!) {
				updateBlogListOrder(input: $input) {
					value {
						id
						order
					}
				}
			}
		`

		const variables = {
			input: {
				items: updateList,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	render() {
		const { errorMessage, websiteId } = this.props
		return (
			<div>
				<h3 className="margin-bottom-15">Blog</h3>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
					}}
					query={graphql`
						query BlogWidget_Query($id: ID!) {
							node(id: $id) {
								... on Website {
									id
									blogItems {
										...BlogWidget_items
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{errorMessage || 'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<BlogContainer
									items={props.node.blogItems}
									onItemsChange={this.onItemsChange}
									createItem={this.createItem}
									updateItem={this.updateItem}
									deleteItem={this.deleteItem}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

const BlogContainer = createFragmentContainer(
	BlogList,
	graphql`
		fragment BlogWidget_items on CmsListItem @relay(plural: true) {
			id
			order
			inactive
			imagePath
			cmsContent {
				id
				title
				link
				content
				teaserText
				browserTitle
				browserDescription
				browserKeywords
				status
				publicationStartDate
				publicationEndDate
			}
		}
	`,
)

BlogContainer.prototype = React.Component.prototype
