import React from 'react'

import { DownCircleOutlined, LeftCircleOutlined, RightCircleOutlined, UpCircleOutlined } from '@ant-design/icons'

import FontAwesome from 'react-fontawesome'
import classnames from 'classnames'

const Widget = ({
	enableExpandHorizontal,
	onExpandHorizontal,
	enableCollapseHorizontal,
	onCollapseHorizontal,
	enableExpandVertical,
	onExpandVertical,
	enableCollapseVertical,
	onCollapseVertical,
	index,
	maxWidth,
	icon,
	onTrash,
	isActive,
	title,
}) => {
	return (
		<div
			className={classnames('widget', { active: isActive })}
			style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}
		>
			{enableCollapseHorizontal && (
				<LeftCircleOutlined
					style={{ cursor: 'pointer', position: 'absolute', right: 40, top: '50%', fontSize: 22, color: 'white' }}
					onMouseDown={(e) => onCollapseHorizontal(e, index)}
				/>
			)}
			{enableExpandHorizontal && (
				<RightCircleOutlined
					style={{ cursor: 'pointer', position: 'absolute', right: 10, top: '50%', fontSize: 22, color: 'white' }}
					onMouseDown={(e) => onExpandHorizontal(e, index)}
				/>
			)}

			{enableCollapseVertical && (
				<UpCircleOutlined
					style={{ cursor: 'pointer', position: 'absolute', right: '50%', bottom: 40, fontSize: 22, color: 'white' }}
					onMouseDown={(e) => onCollapseVertical(e, index)}
				/>
			)}
			{enableExpandVertical && (
				<DownCircleOutlined
					style={{ cursor: 'pointer', position: 'absolute', right: '50%', bottom: 10, fontSize: 22, color: 'white' }}
					onMouseDown={(e) => onExpandVertical(e, index)}
				/>
			)}

			<div className="icon">
				<FontAwesome name={icon} style={{ fontSize: '40px' }} />
				<div className="icon-title">{title}</div>
			</div>

			<div
				className="trash-icon"
				onMouseDown={(e) => {
					e.stopPropagation()
					e.preventDefault()
				}}
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()

					onTrash()
				}}
			>
				X
			</div>
		</div>
	)
}

export default Widget
