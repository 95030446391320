import React from 'react'
import PropTypes from 'prop-types'
import ImageEditor from '../general/imageeditor/ImageEditor'
import FilePicker, { FILTER_TYPES } from '../general/filepicker/FilePicker'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Alert, Button, message, Modal } from 'antd'
import { currentWebsiteProps } from '../shared'
import { connect } from 'react-redux'
import styled from 'styled-components'
import HoveredTextOverlay from '../general/overlays/hoveredtextoverlay/HoveredTextOverlay'
import { deleteEntityImage, setEntityImage } from './EntityImageQueries'

const LOGO_WIDTH = 300

@connect(currentWebsiteProps)
export default class EntityImageEditor extends React.Component {
	static propTypes = {
		settingName: PropTypes.string.isRequired,
		mimeType: PropTypes.string,
		quality: PropTypes.number,
	}

	static defaultProps = {
		mimeType: 'image/png',
		quality: 1,
	}

	state = {
		showCmsFilePicker: false,
		showImageEditor: false,
		imageToBeCroppedPath: null,
		imageBlobUrl: null,
		deleteLogoModalvisible: false,
	}

	render() {
		const { websiteSettings, settingName, quality, mimeType } = this.props

		if (!websiteSettings) {
			return <div>loading...</div>
		}

		const imagePath = websiteSettings.brokerSettings[settingName]
		const { showCmsFilePicker, imageBlobUrl, imageToBeCroppedPath, deleteLogoModalvisible } = this.state

		return (
			<div>
				{!(imagePath || imageBlobUrl) && (
					<div>
						<Alert
							type={'error'}
							message={'U heeft nog geen afbeelding geupload!'}
							description={'Voor goede herkenbaarheid dient u deze te uploaden'}
						/>
						<br />
						<ImagePlaceholder>
							<Button type="primary" onClick={() => this.setState({ showCmsFilePicker: true })}>
								Upload afbeelding
							</Button>
						</ImagePlaceholder>
					</div>
				)}

				{showCmsFilePicker && (
					<FilePicker
						onCancel={() => {
							this.setState({ showCmsFilePicker: false })
						}}
						onSelectFile={this.onSelectImage}
						accept="image/*"
						filter={{ q: '', type: FILTER_TYPES.IMAGES }}
					/>
				)}

				{imageToBeCroppedPath && (
					<ImageEditor
						width={LOGO_WIDTH}
						height={LOGO_WIDTH}
						showResolutionWarning={false}
						imageUrl={imageToBeCroppedPath}
						onCrop={this.onImageEditSave}
						onCancel={this.onImageEditCancel}
						mimeType={mimeType}
						quality={quality}
					/>
				)}

				{(imagePath || imageBlobUrl) && (
					<div style={{ display: 'inline-block', position: 'relative' }}>
						<HoveredTextOverlay
							title={'Wijzigen'}
							onClick={() => this.setState({ imageToBeCroppedPath: this.lastSelectedImage || imagePath })}
						>
							<TransparentBackgroundImage src={imageBlobUrl ? imageBlobUrl : imagePath} alt="logo" width={LOGO_WIDTH} />
							<CloseCircleOutlined
								onClick={this.showDeleteModal}
								style={{
									position: 'absolute',
									right: 20,
									top: 20,
									fontSize: 22,
									color: 'white',
									background: 'rgba(0, 0, 0, 0.2)',
									borderRadius: '11px',
									padding: '1px 0 1px 1px',
									cursor: 'pointer',
								}}
							/>
						</HoveredTextOverlay>
					</div>
				)}

				<Modal
					title="Logo verwijderen?"
					visible={deleteLogoModalvisible}
					okType={'danger'}
					okText={'Verwijderen'}
					onOk={this.deleteLogo}
					onCancel={() => this.setState({ deleteLogoModalvisible: false })}
				>
					<p>Weet u zeker dat u het logo wilt verwijderen?</p>
				</Modal>
			</div>
		)
	}

	onSelectImage = (cmsFile) => {
		// read the static file, otherwise the transparency is lost
		this.lastSelectedImage = cmsFile.staticUrl
		this.setState({
			showCmsFilePicker: false,
			imageToBeCroppedPath: this.lastSelectedImage,
		})
	}

	onImageEditSave = async (blob) => {
		const blobUrl = URL.createObjectURL(blob)
		this.setState({
			showImageEditor: false,
			imageToBeCroppedPath: false,
			imageBlobUrl: blobUrl,
		})

		this.saveImage(blobUrl, blob)
	}

	async saveImage(blobUrl, blob) {
		const { websiteId, settingName, mimeType } = this.props

		let fileName = 'file-' + Date.now()
		if (mimeType === 'image/png') {
			fileName = fileName + '.png'
		} else if (mimeType === 'image/jpeg') {
			fileName = fileName + '.jpg'
		}

		let imageFile = blob
		if (/Edge/.test(navigator.userAgent)) {
			// workaround for Edge that doesn't have File API
			// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/9551546/
			blob.lastModifiedDate = new Date()
			blob.name = fileName
		} else {
			const response = await fetch(blobUrl)
			const blob = await response.blob()
			imageFile = new File([blob], fileName, { type: mimeType })
		}

		await setEntityImage({
			websiteId: websiteId,
			imageFile: imageFile,
			settingName: settingName,
		})
		message.success('Wijzingen zijn bewaard', 3)
	}

	showDeleteModal = (e) => {
		e.stopPropagation()
		this.setState({
			deleteLogoModalvisible: true,
		})
	}

	deleteLogo = async () => {
		const { settingName, websiteId } = this.props

		await deleteEntityImage({
			websiteId,
			settingName,
		})

		this.setState({
			deleteLogoModalvisible: false,
			imageBlobUrl: null,
		})
	}

	onImageEditCancel = () => {
		this.setState({ imageToBeCroppedPath: null })
	}
}

const ImagePlaceholder = styled.div`
	width: ${LOGO_WIDTH}px;
	height: ${LOGO_WIDTH}px;
	background-color: whitesmoke;
	align-items: center;
	display: flex;
	justify-content: center;
	border: 1px dashed #00000038;
	border-style: dashed;
	background-image: linear-gradient(45deg, #80808030 25%, transparent 25%),
		linear-gradient(-45deg, #80808030 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #80808030 75%),
		linear-gradient(-45deg, transparent 75%, #80808030 75%);
	background-size: 20px 20px;
	background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`

const TransparentBackgroundImage = styled.img`
	background-image: linear-gradient(45deg, #80808030 25%, transparent 25%),
		linear-gradient(-45deg, #80808030 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #80808030 75%),
		linear-gradient(-45deg, transparent 75%, #80808030 75%);
	background-size: 20px 20px;
	background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`
