import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Modal } from 'antd'
import 'cropperjs/dist/cropper.css'
import styled from 'styled-components'
import Cropper from 'react-cropper'
import FontAwesome from 'react-fontawesome'

const ButtonGroup = Button.Group

const ALLOWED_IMAGE_DOMAINS = new RegExp(
	`^(${process.env.REACT_APP_IMAGE_SERVLET_URL}|${process.env.REACT_APP_CMS_DIR_URL})`,
)

export default class ImageEditor extends React.Component {
	static propTypes = {
		onCrop: PropTypes.func.isRequired,
		onCancel: PropTypes.func,
		// this can only be an image from realworks.nl domain
		imageUrl: function (props, propName, componentName) {
			// very basic check
			if (!ALLOWED_IMAGE_DOMAINS.test(props[propName])) {
				return new Error(
					'Invalid prop `' +
						propName +
						'` supplied to' +
						' `' +
						componentName +
						'`. Only images from realworks.nl domain allowed.',
				)
			}
		},
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		aspectRatio: PropTypes.number,
		showResolutionWarning: PropTypes.bool.isRequired,
		mimeType: PropTypes.string,
		quality: PropTypes.number,
	}

	static defaultProps = {
		enableSave: true,
		showResolutionWarning: false,
		mimeType: 'image/png',
		quality: 1,
	}

	state = {
		imageUrl: this.props.imageUrl,
		image: null,
		pixelCrop: {},
	}

	constructor(props) {
		super(props)
		this.cropper = React.createRef()
	}

	UNSAFE_componentWillReceiveProps(next) {
		if (next.imageUrl !== this.props.imageUrl) {
			this.setState({
				imageUrl: next.imageUrl,
			})
		}
	}

	render() {
		const { onCancel, width, height, showResolutionWarning, aspectRatio } = this.props
		const { imageUrl, image, pixelCrop } = this.state

		const selectionIsBigEnough = pixelCrop.width >= width && pixelCrop.height >= height

		const toolbarProps = {
			onRotateLeft: () => this.rotate(-45),
			onRotateRight: () => this.rotate(45),
			flipHorizontal: this.flipHorizontal,
			flipVertical: this.flipVertical,
			zoomIn: this.zoomIn,
			zoomOut: this.zoomOut,
		}

		return (
			<Modal
				title={`Afbeelding bewerken`}
				visible={true}
				width={'80%'}
				onOk={this.onSave}
				onCancel={onCancel}
				centered={false}
			>
				{!ALLOWED_IMAGE_DOMAINS.test(imageUrl) && (
					<Alert
						message={'Ongeldige afbeelding'}
						description={
							<span>Enkel afbeeldingen uit uw website kunnen worden bewerkt, deze komt van: {imageUrl}</span>
						}
						type="warning"
					/>
				)}

				{ALLOWED_IMAGE_DOMAINS.test(imageUrl) && (
					<Container>
						<span style={{ color: selectionIsBigEnough ? 'black' : showResolutionWarning ? 'red' : 'black' }}>
							Huidige afmeting {pixelCrop.width} x {pixelCrop.height}
						</span>

						{showResolutionWarning && (
							<div style={{ textAlign: 'center' }}>
								{!selectionIsBigEnough && (
									<div>
										Uw huidige selectie kleiner dan de resolutie{' '}
										<b>
											{width} x {height}
										</b>{' '}
										die op de website wordt gebruikt. Door uit te zoomen (scrollen) kunt u uw selectie vergroten.
									</div>
								)}
							</div>
						)}

						<Toolbar {...toolbarProps} />

						{showResolutionWarning && image && image.width < width && (
							<Alert
								message={'Tip'}
								description={
									<span>
										Deze afbeelding is (
										<b>
											{image.width} x {image.height}
										</b>
										) is kleiner dan het formaat waarin het op uw website wordt getoond (
										<b>
											{width} x {height}
										</b>
										). Als u niet wilt dat uw afbeelding wordt uitgerekt upload dan een grotere afbeelding
									</span>
								}
								type="warning"
							/>
						)}
						<div style={{ margin: 20 }}>
							<Cropper
								viewMode={1}
								ref={this.cropper}
								src={process.env.REACT_APP_FETCH_SERVLET_URL + imageUrl}
								checkCrossOrigin={true}
								style={{ height: 600, width: '800' }}
								aspectRatio={aspectRatio}
								guides={true}
								crop={this.onCropChange}
							/>
						</div>
					</Container>
				)}
			</Modal>
		)
	}

	onCropChange = (val) => {
		const { width, height } = val.detail
		const pixelCrop = {
			width: parseInt(width, 10),
			height: parseInt(height, 10),
		}

		this.setState({ pixelCrop })
	}

	onSave = async (event) => {
		const { onCrop, mimeType, quality } = this.props
		try {
			this.cropper.current.getCroppedCanvas().toBlob(onCrop, mimeType, quality)
		} catch (exc) {
			console.log(exc)
		}
	}

	rotate = (degrees) => {
		this.cropper.current.rotate(degrees)
	}

	flipHorizontal = (degrees) => {
		const scaleX = this.cropper.current.getImageData().scaleX
		this.cropper.current.scaleX(scaleX * -1)
	}

	flipVertical = (degrees) => {
		const scaleY = this.cropper.current.getImageData().scaleY
		this.cropper.current.scaleY(scaleY * -1)
	}

	zoomIn = () => {
		this.cropper.current.zoom(0.5)
	}

	zoomOut = () => {
		this.cropper.current.zoom(-0.5)
	}
}

const Toolbar = ({ onRotateLeft, onRotateRight, flipHorizontal, flipVertical, zoomIn, zoomOut, resetZoom }) => {
	return (
		<div style={{ marginTop: 20 }}>
			<ButtonGroup>
				<Button onClick={onRotateLeft}>
					<FontAwesome name="rotate-left" />
				</Button>
				<Button onClick={onRotateRight}>
					<FontAwesome name="rotate-right" />
				</Button>
				<Button onClick={flipHorizontal}>
					<FontAwesome name="arrows-h" />
				</Button>
				<Button onClick={flipVertical}>
					<FontAwesome name="arrows-v" />
				</Button>
			</ButtonGroup>

			<ButtonGroup style={{ marginLeft: 30 }}>
				<Button onClick={zoomIn}>
					<FontAwesome name="search-plus" />
				</Button>
				<Button onClick={zoomOut}>
					<FontAwesome name="search-minus" />
				</Button>
			</ButtonGroup>
		</div>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-items: center;
	flex-direction: column;
`
