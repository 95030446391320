import React from 'react'
import { Form, Select, ColorInput, ActionHeader } from '../../general'
import EntityImageEditor from '../../websitelogo/EntityImageEditor'
import { currentWebsiteProps } from '../../shared'
import { connect } from 'react-redux'
import { updateSettings } from '../../settings/settingsQueries'

@connect(currentWebsiteProps)
export default class RepeatSection extends React.Component {
	state = {
		backgroundcolor: null,
	}

	componentDidMount() {
		if (this.props.websiteSettings !== null) {
			this.setState({ backgroundcolor: this.props.websiteSettings.brokerSettings.backgroundcolor })
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.websiteSettings !== null) {
			this.setState({ backgroundcolor: nextProps.websiteSettings.brokerSettings.backgroundcolor })
		}
	}

	render() {
		const { websiteSettings } = this.props
		const { websiteBackground, websiteTheme } = websiteSettings.brokerSettings
		const { backgroundcolor } = this.state

		if (!websiteSettings) {
			return <div>Laden...</div>
		}

		const isAllowedToHaveBackgroundImage = websiteTheme !== 'theme-j' && websiteTheme !== 'theme-k'

		return (
			<div className="theme-wrapper">
				<ActionHeader title="Achtergrond" />

				<Form onSubmit={this.onSubmit}>
					{isAllowedToHaveBackgroundImage && (
						<div>
							<EntityImageEditor settingName={'websiteBackground'} mimeType={'image/jpeg'} quality={0.8} />
							{websiteBackground && (
								<div>
									<br />
									<Select
										name={'backgroundRepeat'}
										label={'Herhaling'}
										initialValue={websiteSettings.brokerSettings.backgroundRepeat}
										options={[
											{ value: 'no-repeat', label: 'Niet herhalen' },
											{ value: 'repeat-x', label: 'Herhaal horizontaal' },
											{ value: 'repeat-y', label: 'Herhaal verticaal' },
											{ value: 'repeat', label: 'Herhalen' },
											{ value: 'fill', label: 'Uitvullen' },
											{ value: 'center', label: 'Centreren' },
										]}
									/>
								</div>
							)}
						</div>
					)}

					<br />
					<br />

					<p>Achtergrondkleur</p>
					<ColorInput color={backgroundcolor || '#FFF'} onChange={this.onBackgroundColorChange} />

					<br />
					<br />
				</Form>
			</div>
		)
	}

	onBackgroundColorChange = (value) => {
		this.setState({ backgroundcolor: value })
	}

	onSubmit = async (values) => {
		const { websiteId } = this.props
		const { backgroundcolor } = this.state
		const { backgroundRepeat } = values

		const settings = [{ name: 'backgroundcolor', value: backgroundcolor }]

		if (backgroundRepeat) {
			settings.push({ name: 'backgroundRepeat', value: backgroundRepeat })
		}

		updateSettings(settings, websiteId)
	}
}
