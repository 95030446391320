import { commitMutation, graphql } from 'react-relay'
import Environment from '../../createRelayEnvironment'

function initFragmentFields() {
	/**
	 * Wrap the graphql query in a function to prevent this error on jenkins:
	 * 	Invariant Violation: graphql: Unexpected invocation at runtime. Either the Babel transform was not set up, or it failed to identify this call site. Make sure it is being used verbatim as `graphql`.
	 */
	graphql`
		fragment WebEmployeeQueries_payloadFields on WebEmployee {
			id
			group
			initialsOrNameFormat
			internalText
			office
			showPhoto
			showEmail
			showFunction
			showPhone
			showPhoneWork
			socialMediaChannelOptions {
				label
				value
			}
			socialMediaChannelSelections
			employee {
				id
				title
				initials
				firstName
				middleName
				lastName
				gender
				functionDescription
				email
				mobilePhoneNr
				workPhoneNr
				photoUrl(width: 200, height: 200)
			}
		}
	`
}

initFragmentFields()

const updateWebEmployee = async (id, input) => {
	const mutation = graphql`
		mutation WebEmployeeQueries_updateWebEmployee_Mutation($input: UpdateWebEmployeeInput!) {
			updateWebEmployee(input: $input) {
				value {
					...WebEmployeeQueries_payloadFields
				}
			}
		}
	`

	const variables = {
		input: {
			...input,
			id,
		},
	}

	commitMutation(Environment, {
		mutation,
		variables,
		onError: (err) => console.error(err),
	})
}

const createWebEmployee = async (input, websiteId) => {
	const mutation = graphql`
		mutation WebEmployeeQueries_createWebEmployee_Mutation($input: UpdateWebEmployeeInput!) {
			createWebEmployee(input: $input) {
				value {
					...WebEmployeeQueries_payloadFields
				}
			}
		}
	`

	const variables = {
		input: {
			...input,
			websiteId,
		},
	}

	const updater = (store) => {
		const savedEmployee = store.getRootField('createWebEmployee').getLinkedRecord('value')
		const viewer = store.get(websiteId)
		const webEmployees = viewer.getLinkedRecords('webEmployees')
		if (webEmployees) {
			viewer.setLinkedRecords([...webEmployees, savedEmployee], 'webEmployees')
		}
	}

	commitMutation(Environment, {
		mutation,
		variables,
		onError: (err) => console.error(err),
		updater,
	})
}

const updateOrder = async (orderIds) => {
	const mutation = graphql`
		mutation WebEmployeeQueries_updateOrder_Mutation($input: UpdateOrderInput!) {
			updateWebEmployeeOrder(input: $input) {
				value {
					id
				}
			}
		}
	`

	const variables = {
		input: {
			items: orderIds,
		},
	}

	commitMutation(Environment, {
		mutation,
		variables,
		onError: (err) => console.error(err),
	})
}

export { updateWebEmployee, createWebEmployee, updateOrder }
