import React from 'react'
import PropTypes from 'prop-types'
import { graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { Col, Row } from 'antd'
import {
	DeeldienstenFormContentEditor,
	DeeldienstenLeftSubContentEditor,
	DeeldienstenRightSubContentEditor,
} from '../cms/index'

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
	}

	render() {
		const { pageId } = this.props
		if (pageId === undefined || pageId === null) {
			return <div />
		}
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: pageId,
				}}
				query={graphql`
					query DeeldienstenPageContainerQuery($id: ID!) {
						node(id: $id) {
							... on DeeldienstenPage {
								id
								title
								link
								formTitle
								formContent
								formInformation
								formLandingContent
								leftSubContent {
									id
									title
									content
								}
								rightSubContent {
									id
									title
									content
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <DeeldienstenPageContainer item={props.node} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class DeeldienstenPageContainer extends React.Component {
	static propTypes = {
		item: PropTypes.object,
	}

	render() {
		const { item } = this.props
		console.log(item)
		if (item === null || item === undefined) {
			return <div className="deeldienstenpage-container">Pagina niet gevonden.</div>
		}
		return (
			<div className="deeldienstenpage-container">
				<Row gutter={24}>
					<Col span={24}>
						<DeeldienstenFormContentEditor
							pageId={item.id}
							formTitle={item.formTitle}
							formContent={item.formContent}
							formInformation={item.formInformation}
							formLandingContent={item.formLandingContent}
						/>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={12}>
						<DeeldienstenLeftSubContentEditor item={item.leftSubContent} pageId={item.id} />
					</Col>
					<Col span={12}>
						<DeeldienstenRightSubContentEditor item={item.rightSubContent} pageId={item.id} />
					</Col>
				</Row>
			</div>
		)
	}
}
