/**
 * @flow
 * @relayHash 69da92ef62b597d3063244726e722255
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebEmployeeQueries_updateOrder_MutationVariables = {|
  input: {
    items: $ReadOnlyArray<{
      id: string;
      order: number;
    }>;
  };
|};
export type WebEmployeeQueries_updateOrder_MutationResponse = {|
  +updateWebEmployeeOrder: ?{|
    +value: $ReadOnlyArray<{|
      +id: string;
    |}>;
  |};
|};
*/


/*
mutation WebEmployeeQueries_updateOrder_Mutation(
  $input: UpdateOrderInput!
) {
  updateWebEmployeeOrder(input: $input) {
    value {
      id
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebEmployeeQueries_updateOrder_Mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateOrderInput"
          }
        ],
        "concreteType": "UpdateWebEmployeeOrderPayload",
        "name": "updateWebEmployeeOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "WebEmployee",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebEmployeeQueries_updateOrder_Mutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "WebEmployeeQueries_updateOrder_Mutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateOrderInput"
          }
        ],
        "concreteType": "UpdateWebEmployeeOrderPayload",
        "name": "updateWebEmployeeOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "WebEmployee",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation WebEmployeeQueries_updateOrder_Mutation(\n  $input: UpdateOrderInput!\n) {\n  updateWebEmployeeOrder(input: $input) {\n    value {\n      id\n    }\n  }\n}\n"
};

module.exports = batch;
