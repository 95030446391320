import React from 'react'
import PropTypes from 'prop-types'
import './WidgetPicker.css'
import { DragSource } from 'react-dnd'
import { DndTypes } from './Types'
import FontAwesome from 'react-fontawesome'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Tooltip } from 'antd'
import classnames from 'classnames'

const boxSource = {
	beginDrag(props) {
		const { id, name, description, fieldTypes, size, icon } = props.widgetConfig
		return {
			id,
			name,
			size,
			description,
			fieldTypes,
			icon,
		}
	},

	endDrag(props, monitor) {
		const item = monitor.getItem()
		const dropResult = monitor.getDropResult()

		if (dropResult) {
			console.log(`You dropped ${item.name} into ${dropResult.name}!`)
		}
	},
}

@DragSource(DndTypes.WIDGET, boxSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging(),
}))
export default class extends React.Component {
	static propTypes = {
		widgetConfig: PropTypes.object.isRequired,
	}

	componentDidMount() {
		this.props.connectDragPreview(getEmptyImage(), {
			// IE fallback: specify that we'd rather screenshot the node
			// when it already knows it's being dragged so we can hide it with CSS.
			captureDraggingState: true,
		})
	}

	render() {
		const { isDragging, connectDragSource, widgetConfig } = this.props
		const opacity = isDragging ? 0.4 : 1

		const widget = (
			<div className={classnames('widget', widgetConfig.isDisabled ? 'disabled' : '')} style={{ opacity: opacity }}>
				<Tooltip title={widgetConfig.title}>
					<div className="icon-container">
						<FontAwesome name={widgetConfig.icon} />
						<div className="icon-title">
							{widgetConfig.title} {widgetConfig.isDisabled ? '(Niet beschikbaar)' : ''}
						</div>
					</div>
				</Tooltip>
			</div>
		)

		if (widgetConfig.isDisabled) {
			return widget
		}

		return connectDragSource(widget)
	}
}
