import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { DropTarget } from 'react-dnd'
import _ from 'lodash'
import './Dnd.css'
import { DndTypes } from './Types'
import { clamp, moveAllUp, range, reorder } from './helper'
import classnames from 'classnames'
import styled from 'styled-components'

/**
 * 
 * Een aantal callbacks kunnen worden meegegeven. Het is belangrijk dat de callbacks die je meegeeft aan Dnd widget in 
 * een bepaalde volgorde wordt uitgevoerd, zodat je de juiste informatie op de juist tijd krijgt. De flow is nu zo:
 * 		1 - onItemAdd
 		2 - onLayoutChange
 		3-  renderWidget
 * 
 * TODO:
 * - max ROWS afdwingen
 */

const boxTarget = {
	drop() {
		return { name: 'Dustbin' }
	},
}

@DropTarget(DndTypes.WIDGET, boxTarget, (connect, monitor) => ({
	widgetConfig: monitor.getItem(),
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
	didDrop: monitor.didDrop(),
	getDifferenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
	getSourceClientOffset: monitor.getSourceClientOffset(),
}))
export default class Dnd extends React.Component {
	static propTypes = {
		layout: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				x: PropTypes.number.isRequired,
				y: PropTypes.number.isRequired,
				width: PropTypes.number.isRequired,
				height: PropTypes.number.isRequired,
			}),
		).isRequired,
		onLayoutChange: PropTypes.func,
		maxColumns: PropTypes.number.isRequired,
		maxRows: PropTypes.number.isRequired,
		colWidth: PropTypes.number.isRequired,
		colHeight: PropTypes.number.isRequired,
		widgetPreviewState: PropTypes.shape({
			initialOffset: PropTypes.shape({
				x: PropTypes.number,
				y: PropTypes.number,
			}),
			currentOffset: PropTypes.shape({
				x: PropTypes.number,
				y: PropTypes.number,
			}),
		}),
		renderItem: PropTypes.func.isRequired,
		/**
		 * Callback voor als een widget erin wordt toegevoegd. De props van de dragSource worden meegegeven als
		 * argument tezamen met de positie. Dit is handig omdat je beide nodig hebt om het geheel te kunnen opslaan/renderen
		 *
		 * NB: deze callback wordt al aangeroepen op het drag event (dus niet op drop event)
		 */
		onItemAdd: PropTypes.func,
		onItemRemove: PropTypes.func,

		/**
		 * arguments: id (internal id)
		 */
		onItemFocus: PropTypes.func.isRequired, // nodig om WidgetPicker en DnD aan elkaar te binden
		className: PropTypes.string,
		showGrid: PropTypes.bool,
	}

	static defaultProps = {
		showGrid: true,
	}

	state = {
		itemXY: [0, 0],
		itemMouseDelta: [0, 0],
		lastPress: null,
		order: this.props.layout,
	}

	previewAddedAndDragging = false

	componentDidMount() {
		document.addEventListener('mousemove', this.handleMouseMove)
		document.addEventListener('mouseup', this.handleMouseUp)

		this.boundingClientReact = ReactDOM.findDOMNode(this).getBoundingClientRect()
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.handleMouseMove)
		document.removeEventListener('mouseup', this.handleMouseUp)
	}

	handleMouseMove = ({ pageX, pageY }) => {
		const { itemMouseDelta } = this.state
		const itemX = pageX - itemMouseDelta[0]
		// console.log(`pageX: ${pageX} itemMouseDelta[0]: ${itemMouseDelta[0]} = ${itemX}`);
		const itemY = pageY - itemMouseDelta[1]

		this.moveItem(itemX, itemY)
	}

	moveItem(itemX, itemY) {
		const { order, lastPress } = this.state
		const { onLayoutChange, maxColumns, maxRows, colWidth, colHeight } = this.props

		if (!lastPress) {
			return
		}

		let col = clamp(Math.floor((itemX + colWidth / 2) / colWidth), 0, maxColumns - lastPress.width)
		let row = clamp(Math.floor((itemY + colHeight / 2) / colHeight), 0, maxRows)

		// update dragging node
		lastPress.x = col
		lastPress.y = row

		reorder(order, lastPress.key)

		// make sure we cannot drag the item outside the drag area
		const maxX = clamp(itemX, 0, colWidth * (maxColumns - lastPress.width))
		const maxY = clamp(itemY, 0, colHeight * (maxRows - 1))

		this.setState({
			itemXY: [maxX, maxY],
			order: order,
		})

		onLayoutChange(order)
	}

	handleMouseUp = ({ pageX, pageY }) => {
		this.setState({
			lastPress: null,
		})
	}
	handleMouseDown = (item, [itemX, itemY], { pageX, pageY }) => {
		console.log('handleMouseDown')
		this.setState({
			itemXY: [itemX, itemY],
			itemMouseDelta: [pageX - itemX, pageY - itemY],
			lastPress: item,
		})

		this.props.onItemFocus(item.key)
	}

	UNSAFE_componentWillReceiveProps(next) {
		// als de layout van buitenaf wordt veranderd, voer dat dan door in de state (dat is immers de datastructuur waarmee wordt gewerkt)
		if (next.layout !== this.props.layout) {
			this.setState({ order: next.layout })

			// and reorder widgets
			moveAllUp(next.layout)
			return
		}

		// widget is daadwerkelijk gedropped (toegevoegd)
		if (next.didDrop === true && this.props.didDrop === false) {
			this.previewAddedAndDragging = false
			this.setState({
				lastPress: null,
			})
			return
		}

		const { canDrop, isOver } = next
		const { canDrop: prevCanDrop, isOver: prevIsOver } = this.props
		const isActive = canDrop && isOver
		const prevIsActive = prevCanDrop && prevIsOver

		if (prevIsActive === false && isActive === true) {
			this.addWidget({ ...this.props.getDifferenceFromInitialOffset })
		}

		if (this.previewAddedAndDragging) {
			const { currentOffset } = next.widgetPreviewState
			const { currentOffset: prevCurrentOffset } = this.props.widgetPreviewState
			if (currentOffset.x !== prevCurrentOffset.x || currentOffset.y !== prevCurrentOffset.y) {
				const boundingClientReact = ReactDOM.findDOMNode(this).getBoundingClientRect()
				const itemX = currentOffset.x - boundingClientReact.left - 20
				const itemY = currentOffset.y - boundingClientReact.top - 80
				this.moveItem(itemX, itemY)
			}
		}

		if (!isActive && prevIsActive && this.previewAddedAndDragging) {
			this.removeWidget(this.state.lastPress)
		}
	}

	removeWidget(item) {
		const { widgetConfig, onItemRemove } = this.props
		const { order } = this.state
		const newItems = order.filter((n) => {
			return n.key !== item.key
		})

		// reset state
		this.previewAddedAndDragging = false
		this.setState(
			{
				order: newItems,
				lastPress: null,
			},
			() => {
				// reorder alle widget weer
				moveAllUp(newItems)
				onItemRemove(item, widgetConfig)
			},
		)
	}

	/**
	 * Dit voegt enkel tijdelijk de widget toe, dus nog niet 'gedropped', zie daarvoor @UNSAFE_componentWillReceiveProps
	 * @param currentOffset
	 */
	addWidget(currentOffset) {
		const { widgetConfig, onItemAdd, onLayoutChange } = this.props

		// deep clone
		let order = _.map(this.state.order, _.clone)

		const key = Math.round(Math.random() * 10000)
		const newItem = {
			key: key,
			x: 0,
			y: 0,
			width: widgetConfig.size.defaultWidth || 1,
			height: widgetConfig.size.defaultHeight || 1,
		}

		order.push(newItem)

		const pageX = this.props.getSourceClientOffset.x
		const pageY = this.props.getSourceClientOffset.y

		this.previewAddedAndDragging = true

		// zorg dat dit wordt uitgevoerd *voor* de state wijziging, zodat de data in de closure (ie 'renderWidget')
		// aanwezig is zodra de rendering wordt gedaan
		onItemAdd(newItem, widgetConfig)
		onLayoutChange(order)

		this.setState(
			{
				order: order,
				lastPress: newItem,
			},
			() => {
				const boundingClientReact = ReactDOM.findDOMNode(this).getBoundingClientRect()
				const itemX = pageX - boundingClientReact.left
				const itemY = pageY - boundingClientReact.top
				this.moveItem(itemX, itemY)
			},
		)
	}

	render() {
		const { itemXY, lastPress, order } = this.state
		const { maxColumns, maxRows, renderItem, colWidth, colHeight, className, showGrid } = this.props

		// sort by key, so the DOM order isn't changed, which causes the transition animation not to work
		const ordered = order.sort((a, b) => a.key - b.key)

		// drag & drop
		const { connectDropTarget } = this.props
		// const { canDrop, isOver, connectDropTarget } = this.props;
		// const isActive = canDrop && isOver;

		return connectDropTarget(
			<div
				className={classnames('dnd', className)}
				style={{ width: maxColumns * colWidth, height: Math.max(1, maxRows) * colHeight }}
			>
				{showGrid && (
					<GridOverlay cols={maxColumns} rows={Math.max(1, maxRows)} colWidth={colWidth} colHeight={colHeight} />
				)}

				{lastPress && (
					<PredictionBlock
						width={lastPress.width * colWidth}
						height={lastPress.height * colHeight}
						left={lastPress.x * colWidth}
						top={lastPress.y * colHeight}
					/>
				)}

				{ordered.map((item) => {
					const key = item.key

					let x,
						y,
						scale = 1,
						transition = 'all 300ms'

					if (lastPress === item) {
						;[x, y] = itemXY
						scale = 1.1
						transition = 'none'
					} else {
						x = item.x * colWidth
						y = item.y * colHeight
					}

					return (
						<div
							data-debug-key={key}
							key={key}
							className="block"
							onMouseDown={this.handleMouseDown.bind(null, item, [x, y])}
							style={{
								transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
								transition: transition,
								width: item.width * colWidth,
								height: item.height * colHeight,
								zIndex: item === lastPress ? 1 : 0,
							}}
						>
							{renderItem(item)}
						</div>
					)
				})}
			</div>,
		)
	}
}

const PredictionBlock = styled.div`
	background: #108ee921;
	position: absolute;
	left: ${(props) => props.left}px;
	top: ${(props) => props.top}px;
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
`

const GridOverlay = ({ cols, rows, colWidth, colHeight }) => {
	return (
		<div className="grid">
			{range(rows - 1).map((rowIndex) => (
				<div key={rowIndex} className="row">
					{range(cols - 1).map((colIndex) => (
						<div
							key={colIndex}
							className="col"
							style={{
								width: colWidth,
								height: colHeight,
								transform: `translate3d(${colIndex * colWidth}px, ${rowIndex * colHeight}px, 0)`,
							}}
						/>
					))}
				</div>
			))}
		</div>
	)
}
