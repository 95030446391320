import React from 'react'
import styled from 'styled-components'

const HoveredTextOverlay = ({ title, onClick, children }) => {
	return (
		<Container onClick={onClick}>
			<Overlay>{title}</Overlay>
			{children}
		</Container>
	)
}

const Overlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	color: white;
	text-align: center;
	justify-content: center;
	align-items: center;
	background: #00000073;
	display: flex;
	opacity: 0;
	transition: opacity 300ms;
`
const Container = styled.div`
	position: relative;
	${Overlay}:hover {
		opacity: 1;
	}
`
export default HoveredTextOverlay
