import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import BannerTabsList from './BannerTabsList'
import { buildOrderMutationList, currentWebsiteProps } from '../shared'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		listId: PropTypes.string.isRequired,
	}

	createItem = (item) => {
		const { websiteId, listId } = this.props

		const mutation = graphql`
			mutation BannerTabsWidgetMutation($input: CreateBannerTabItemInput!) {
				createBannerTabItem(input: $input) {
					value {
						id
						order
						title
						subtitle
						content
					}
				}
			}
		`

		const variables = {
			input: {
				websiteId: websiteId,
				listId: listId,
				...item,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createBannerTabItem')
			const valueItem = mutationRoot.getLinkedRecord('value')

			let args = { listId: listId }
			const node = store.get(websiteId)
			const listItems = node.getLinkedRecords('bannerTabItems', args)
			if (listItems) {
				node.setLinkedRecords([...listItems, valueItem], 'bannerTabItems', args)
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				console.log('Response received from server.')
				console.log(response)
			},
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateItem = (item) => {
		console.log(item)
		const mutation = graphql`
			mutation BannerTabsWidgetUpdateMutation($input: UpdateBannerTabItemInput!) {
				updateBannerTabItem(input: $input) {
					value {
						id
						order
						title
						subtitle
						content
					}
				}
			}
		`

		const variables = {
			input: {
				...item,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				console.log('Response received from server.')
				console.log(response)
			},
			onError: (err) => console.error(err),
		})
	}

	deleteItem = (item) => {
		const { websiteId, listId } = this.props

		const mutation = graphql`
			mutation BannerTabsWidgetDeleteMutation($input: EntityIdInput!) {
				deleteBannerTabItem(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		const updater = (store) => {
			const mutationRoot = store.getRootField('deleteBannerTabItem')
			const valueItem = mutationRoot.getValue('deletedId')

			let args = { listId: listId }
			const websiteNode = store.get(websiteId)
			const originalList = websiteNode.getLinkedRecords('bannerTabItems', args)

			if (!originalList) {
				return
			}

			const filteredList = [...originalList].filter((item) => item.getDataID() !== valueItem)
			websiteNode.setLinkedRecords(filteredList, 'bannerTabItems', args)
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				console.log('Response received from server.')
				console.log(response)
			},
			onError: (err) => console.error(err),
			configs,
			updater,
		})
	}

	onItemsChange = (items) => {
		const updateList = buildOrderMutationList(items)

		const mutation = graphql`
			mutation BannerTabsWidgetOrderMutation($input: UpdateBannerTabItemListOrderInput!) {
				updateBannerTabItemListOrder(input: $input) {
					value {
						id
						order
					}
				}
			}
		`

		const variables = {
			input: {
				items: updateList,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				console.log('Response received from server.', response)
			},
			onError: (err) => console.error(err),
		})
	}

	render() {
		const { websiteId, listId } = this.props
		return (
			<div>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
						listId: listId,
					}}
					query={graphql`
						query BannerTabsWidget_Query($id: ID!, $listId: String) {
							node(id: $id) {
								... on Website {
									id
									bannerTabItems(listId: $listId) {
										...BannerTabsWidget_items
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<BannerTabsContainer
									items={props.node.bannerTabItems}
									createItem={this.createItem}
									updateItem={this.updateItem}
									deleteItem={this.deleteItem}
									onItemsChange={this.onItemsChange}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

const BannerTabsContainer = createFragmentContainer(
	BannerTabsList,
	graphql`
		fragment BannerTabsWidget_items on BannerTabItem @relay(plural: true) {
			id
			order
			title
			subtitle
			content
		}
	`,
)

BannerTabsContainer.prototype = React.Component.prototype
