import environment from '../../createRelayEnvironment'
import { commitMutation, graphql } from 'react-relay'

const createItem = ({ websiteId, imagepath, link, target, imageFile }) => {
	const mutation = graphql`
		mutation HeaderBannerQueries_createItem_Mutation($input: CreateHeaderBannerItemInput!) {
			createHeaderBannerItem(input: $input) {
				value {
					id
					imagepath
					link
					listtypeid
					target
				}
			}
		}
	`

	const variables = {
		input: {
			websiteId,
			imagepath,
			link,
			target,
		},
	}

	const files = [imageFile]
	const uploadables = {}
	Object.keys(files).forEach((key) => {
		uploadables['file_' + key] = files[key]
	})

	const updater = (store) => {
		console.log('DONE', store)

		const mutationRoot = store.getRootField('createHeaderBannerItem')
		const valueItem = mutationRoot.getLinkedRecord('value')

		const node = store.get(websiteId)
		const listItems = node.getLinkedRecords('headerBannerItems')
		if (listItems) {
			node.setLinkedRecords([...listItems, valueItem], 'headerBannerItems')
		}
	}

	return new Promise((resolve, reject) => {
		commitMutation(environment, {
			mutation,
			variables,
			uploadables,
			onCompleted: resolve,
			onError: reject,
			updater,
		})
	})
}

const updateItem = ({ websiteId, id, imagepath, link, target, imageFile, isImageAltered }) => {
	const mutation = graphql`
		mutation HeaderBannerQueries_updateItem_Mutation($input: UpdateHeaderBannerItemInput!) {
			updateHeaderBannerItem(input: $input) {
				value {
					id
					imagepath
					link
					listtypeid
					target
				}
			}
		}
	`

	const variables = {
		input: {
			websiteId,
			id,
			imagepath,
			link,
			target,
			isImageAltered,
		},
	}

	let uploadables = {}
	if (imageFile) {
		const files = [imageFile]
		Object.keys(files).forEach((key) => {
			uploadables['file_' + key] = files[key]
		})
	}

	return new Promise((resolve, reject) => {
		commitMutation(environment, {
			mutation,
			variables,
			uploadables,
			onCompleted: resolve,
			onError: reject,
		})
	})
}

export { createItem, updateItem }
