/**
 * @flow
 * @relayHash 7c1be6cc7a5033d396327766c0c999e3
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebEmployeeModal_employees_QueryResponse = {|
  +node: ?{| |};
|};
*/


/*
query WebEmployeeModal_employees_Query(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on WebEmployee {
      ...WebEmployeeModal_webEmployee
    }
    id
  }
}

fragment WebEmployeeModal_webEmployee on WebEmployee {
  id
  group
  initialsOrNameFormat
  internalText
  office
  showPhoto
  showEmail
  showFunction
  showPhone
  showPhoneWork
  socialMediaChannelOptions {
    label
    value
  }
  socialMediaChannelSelections
  employee {
    id
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebEmployeeModal_employees_Query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "WebEmployee",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "WebEmployeeModal_webEmployee",
                "args": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebEmployeeModal_employees_Query",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "WebEmployeeModal_employees_Query",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "type": "WebEmployee",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "group",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "initialsOrNameFormat",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "internalText",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "office",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhoto",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showEmail",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showFunction",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhone",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhoneWork",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "LabelValuePair",
                "name": "socialMediaChannelOptions",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "socialMediaChannelSelections",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query WebEmployeeModal_employees_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on WebEmployee {\n      ...WebEmployeeModal_webEmployee\n    }\n    id\n  }\n}\n\nfragment WebEmployeeModal_webEmployee on WebEmployee {\n  id\n  group\n  initialsOrNameFormat\n  internalText\n  office\n  showPhoto\n  showEmail\n  showFunction\n  showPhone\n  showPhoneWork\n  socialMediaChannelOptions {\n    label\n    value\n  }\n  socialMediaChannelSelections\n  employee {\n    id\n  }\n}\n"
};

module.exports = batch;
