import { commitMutation, fetchQuery, graphql } from 'react-relay'
import Environment from '../../createRelayEnvironment'
import store from '../../store'
import { updateCurrentWebsiteSettings } from '../../actions'
import { onMutationCompleted } from '../shared'

async function getAllSettings(websiteId) {
	const getAllSettingsQuery = graphql`
		query settingsQueries_getAllSettingsQuery($id: ID!) {
			node(id: $id) {
				... on Website {
					id
					name
					settings {
						...settingsQueries_settings @relay(mask: false)
					}
				}
			}
		}
	`
	const settingsResult = await fetchQuery(Environment, getAllSettingsQuery, {
		id: websiteId,
	})
	return settingsResult.node.settings
}

async function initSettingNames() {
	const brokerSettingsFetchQuery = graphql`
		query settingsQueriesBrokerSettingsQuery {
			__type(name: "BrokerSettings") {
				name
				fields {
					name
				}
			}
		}
	`
	const systemSettingsFetchQuery = graphql`
		query settingsQueriesSystemSettingsQuery {
			__type(name: "SystemSettings") {
				name
				fields {
					name
				}
			}
		}
	`
	graphql`
		fragment settingsQueries_settings on Settings {
			brokerSettings {
				websiteTheme
				vestigingPaginaNummer
				cmsWeergaveNummer
				medewerkersPaginaNummer
				footerNummer
				brochureDetailType
				backgroundcolor
				menucolor
				menufontcolor
				footerlinkcolor
				blokbackgroundcolor
				blokfontcolor
				linkcolor
				contentcolor
				contentbackgroundcolor
				objectstatusnieuwlabelcolor
				objectstatusnieuwfontcolor
				objectstatustekoophuurlabelcolor
				objectstatustekoophuurfontcolor
				objectstatusondervoorbehoudlabelcolor
				objectstatusondervoorbehoudfontcolor
				objectstatusverkochtlabelcolor
				objectstatusverkochtfontcolor
				objectstatusopenhuislabelcolor
				objectstatusopenhuisfontcolor
				objectstatusaangekochtlabelcolor
				objectstatusaangekochtfontcolor
				showOptaTerms
				websiteLogo
				hideMainLogo
				faviconPath
				websiteBackground
				backgroundRepeat
				enableOpgeslagenWoningen
				activeerMove
				homepageTitle
				homepageDescription
				homepageKeywords
				dagenVerkochtOpAanbodlijst
				aantalDagenVoorNieuwOpMarkt
				dagenOpVerkochtAanbodlijst
				actiefWonenZoekformulierOptie
				actiefBogZoekformulierOptie
				actiefNieuwbouwZoekformulierOptie
				actiefAlvZoekformulierOptie
				clusterMarkers
				enableHelpmee
				showCBSStatistieken
				googleTranslate
				googleTranslateIncludedLanguages
				googleMapsApiKeyMakelaar
				googleAnalyticsKey
				googleAnalytics4Key
				googleRemarketingKey
				googletagmanagerKey
				googleSiteVerificationKey
				facebookPixelKey
				bingSiteVerificationKey
				enableMakelaarTemplateCaptcha
				recaptchaWebsiteKey
				recaptchaSecret
				pageEffect
				mouseEffect
				ogZoekformulierMenu
				useVideoHeaderBanner
				youtubeHeaderVideoId
				enableSitekick
				enableSearcherForm
			}
			systemSettings {
				enableWonen
				enableBog
				enableNieuwbouw
				enableAlv
				enableVgmcomplex
				enableAankoop
				titleSetting
				addthisUser
				multiOfficeOptionType
				spotlightPrio
				enableDeeldiensten
				nieuwsPaginaNummer
				rssMaxAantal
				rssUrl
				enableBlog
				enableVerkopendeMakelaar
				aanbodlijstSorteerOptiesDefault
				aanbodlijstSorteerDefaultSelectedOrder
				portalType
				toonAantalObjectenInZoekformulierTitel
				kijkMijnHuisPixelEnabled
				scheme
				enableCustomWonenContactGegevens
				customWonenContactGegevensEmail
				customWonenContactGegevensTel
				enableCustomBogContactGegevens
				customBogContactGegevensEmail
				customBogContactGegevensTel
				enableCustomNieuwbouwContactGegevens
				customNieuwbouwContactGegevensEmail
				customNieuwbouwContactGegevensTel
				enableCustomAlvContactGegevens
				customAlvContactGegevensEmail
				customAlvContactGegevensTel
				enableCustomVgmcomplexContactGegevens
				customVgmcomplexContactGegevensEmail
				customVgmcomplexContactGegevensTel
			}
		}
	`
	const brokerSettingsResultSet = await fetchQuery(Environment, brokerSettingsFetchQuery)
	const brokerSettings = brokerSettingsResultSet.__type.fields
	console.log({ brokerSettings })

	const systemSettingsResultSet = await fetchQuery(Environment, systemSettingsFetchQuery)
	const systemSettings = systemSettingsResultSet.__type.fields

	const settingsNames = {
		systemSettings,
		brokerSettings,
	}

	console.log('settingsNames', settingsNames)

	return settingsNames
}

let settingsNames
const updateSettings = async (settings, websiteId) => {
	if (!settingsNames) {
		settingsNames = await initSettingNames()
	}

	const mutation = graphql`
		mutation settingsQueries_saveSettingsMutation($input: SaveSettingsInput!) {
			saveSettings(input: $input) {
				websiteId
				settings {
					...settingsQueries_settings
				}
			}
		}
	`

	const variables = {
		input: {
			settings,
			websiteId,
		},
	}

	const updateRedux = (relayStore) => {
		const newSettings = {
			brokerSettings: {},
			systemSettings: {},
		}

		const mutationResult = relayStore.getRootField('saveSettings')
		const websiteId = mutationResult.getValue('websiteId')
		const settings = mutationResult.getLinkedRecord('settings')

		const brokerSettingsStore = settings.getLinkedRecord('brokerSettings')
		settingsNames.brokerSettings.forEach((setting) => {
			const value = brokerSettingsStore.getValue(setting.name)
			newSettings.brokerSettings[setting.name] = value
		})

		const systemSettingsStore = settings.getLinkedRecord('systemSettings')
		settingsNames.systemSettings.forEach((setting) => {
			const value = systemSettingsStore.getValue(setting.name)
			newSettings.systemSettings[setting.name] = value
		})

		const action = updateCurrentWebsiteSettings(websiteId, newSettings)

		store.dispatch(action)
	}

	commitMutation(Environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		updater: updateRedux,
	})
}

export { updateSettings, getAllSettings }
