import { FieldTypes } from '../../../dnd/FieldRenderer'

export default ({ settings }) => ({
	id: 'rssFeedBlok',
	title: 'RSS feed',
	icon: 'rss',
	size: {
		minWidth: 1,
		maxWidth: 1,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			name: 'rssFeeds',
			label: 'URLs van de te tonen feeds, kommagescheiden',
			defaultValue: 'https://www.nvm.nl/rss.ashx',
			type: FieldTypes.TEXT,
			rules: [{ required: true, message: 'Vul een waarde in' }],
		},
		{
			name: 'rssFeedBlokNaam',
			label: 'Titel die boven dit blok komt te staan',
			defaultValue: 'RSS Feed',
			type: FieldTypes.TEXT,
			rules: [{ required: true, message: 'Vul een waarde in' }],
		},
	],
})
