import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../../createRelayEnvironment'
import CKEditor from '../../CKEditor'
import FontAwesome from 'react-fontawesome'
import Form from '../../general/form/form/Form'
import { Input } from '../../general'
import { Col, Collapse, Row } from 'antd'
import { onMutationCompleted } from '../../shared/index'

const Panel = Collapse.Panel

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string,
		formTitle: PropTypes.string,
		formContent: PropTypes.string,
		formInformation: PropTypes.string,
		formLandingContent: PropTypes.string,
	}

	save = (item) => {
		const mutation = graphql`
			mutation DeeldienstenFormContentEditorMutation($input: UpdateDeeldienstenPageInput!) {
				updateDeeldienstenPage(input: $input) {
					value {
						id
						title
						link
						formTitle
						formContent
						formInformation
						formLandingContent
					}
				}
			}
		`

		const variables = {
			input: item,
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	render() {
		const { pageId, formTitle, formContent, formInformation, formLandingContent } = this.props
		return (
			<DeeldienstenFormContentEditor
				pageId={pageId}
				formTitle={formTitle}
				formContent={formContent}
				formInformation={formInformation}
				formLandingContent={formLandingContent}
				updateItem={this.save}
			/>
		)
	}
}

class DeeldienstenFormContentEditor extends React.Component {
	static propTypes = {
		pageId: PropTypes.string,
		formTitle: PropTypes.string,
		formContent: PropTypes.string,
		formInformation: PropTypes.string,
		formLandingContent: PropTypes.string,
	}

	onSubmit = (values) => {
		const item = {
			id: this.props.pageId,
			formTitle: values.formTitle,
			formContent: values.formContent,
			formInformation: values.formInformation,
			formLandingContent: values.formLandingContent,
		}
		this.props.updateItem(item)
		return true
	}

	render() {
		const { pageId, formTitle, formContent, formInformation, formLandingContent } = this.props
		return (
			<div className="deeldiensten-form" style={{ marginBottom: '20px' }}>
				<Form
					ref={(c) => {
						this.form = c
					}}
					onSubmit={this.onSubmit}
				>
					<Collapse style={{ marginTop: '20px', marginBottom: '20px' }} accordion>
						<Panel header="Hoofdtekst" id="hoofdtekst" forceRender={true}>
							<Input label="Titel" name="formTitle" initialValue={formTitle || ''} rules={[{ required: false }]} />
							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={pageId !== null ? 'formContent' + pageId : 'formContent-new'}
										name="formContent"
										initialValue={formContent || ''}
									/>
								</Col>
							</Row>
						</Panel>
						<Panel header="Aanvullende tekst" id="aanvullendetekst" forceRender={true}>
							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={pageId !== null ? 'formInformation' + pageId : 'formInformation-new'}
										name="formInformation"
										initialValue={formInformation || ''}
									/>
								</Col>
							</Row>
						</Panel>
						<Panel header="Landingspagina tekst" id="landingpagetekst" forceRender={true}>
							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={pageId !== null ? 'formLanding' + pageId : 'formLanding-new'}
										name="formLandingContent"
										initialValue={formLandingContent || ''}
									/>
								</Col>
							</Row>
						</Panel>
					</Collapse>
					<div style={{ marginTop: '10px', marginBottom: '10px' }}>
						<FontAwesome name="info-circle" size="lg" style={{ color: '#1890ff' }} /> Wanneer deze velden leeg zijn,
						wordt de standaard tekst getoond.
					</div>
				</Form>
			</div>
		)
	}
}
