/**
 * @flow
 * @relayHash 87a59176e6be5d6d948c6672bddbcfc1
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebEmployeeQueries_updateWebEmployee_MutationVariables = {|
  input: {
    employeeId: string;
    group?: ?string;
    id?: ?string;
    initialsOrNameFormat?: ?number;
    internalText?: ?string;
    office: number;
    showEmail?: ?boolean;
    showFunction?: ?boolean;
    showPhone?: ?boolean;
    showPhoneWork?: ?boolean;
    showPhoto?: ?boolean;
    socialMediaChannelSelections?: ?$ReadOnlyArray<?string>;
    websiteId?: ?string;
  };
|};
export type WebEmployeeQueries_updateWebEmployee_MutationResponse = {|
  +updateWebEmployee: ?{|
    +value: ?{| |};
  |};
|};
*/


/*
mutation WebEmployeeQueries_updateWebEmployee_Mutation(
  $input: UpdateWebEmployeeInput!
) {
  updateWebEmployee(input: $input) {
    value {
      ...WebEmployeeQueries_payloadFields
      id
    }
  }
}

fragment WebEmployeeQueries_payloadFields on WebEmployee {
  id
  group
  initialsOrNameFormat
  internalText
  office
  showPhoto
  showEmail
  showFunction
  showPhone
  showPhoneWork
  socialMediaChannelOptions {
    label
    value
  }
  socialMediaChannelSelections
  employee {
    id
    title
    initials
    firstName
    middleName
    lastName
    gender
    functionDescription
    email
    mobilePhoneNr
    workPhoneNr
    photoUrl(width: 200, height: 200)
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateWebEmployeeInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebEmployeeQueries_updateWebEmployee_Mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateWebEmployeeInput!"
          }
        ],
        "concreteType": "WebEmployeePayload",
        "name": "updateWebEmployee",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "WebEmployee",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "WebEmployeeQueries_payloadFields",
                "args": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebEmployeeQueries_updateWebEmployee_Mutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateWebEmployeeInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "WebEmployeeQueries_updateWebEmployee_Mutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateWebEmployeeInput!"
          }
        ],
        "concreteType": "WebEmployeePayload",
        "name": "updateWebEmployee",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "WebEmployee",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "group",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "initialsOrNameFormat",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "internalText",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "office",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhoto",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showEmail",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showFunction",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhone",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "showPhoneWork",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "LabelValuePair",
                "name": "socialMediaChannelOptions",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "socialMediaChannelSelections",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "initials",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "middleName",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "gender",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "functionDescription",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "mobilePhoneNr",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "workPhoneNr",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "height",
                        "value": 200,
                        "type": "Int!"
                      },
                      {
                        "kind": "Literal",
                        "name": "width",
                        "value": 200,
                        "type": "Int!"
                      }
                    ],
                    "name": "photoUrl",
                    "storageKey": "photoUrl{\"height\":200,\"width\":200}"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation WebEmployeeQueries_updateWebEmployee_Mutation(\n  $input: UpdateWebEmployeeInput!\n) {\n  updateWebEmployee(input: $input) {\n    value {\n      ...WebEmployeeQueries_payloadFields\n      id\n    }\n  }\n}\n\nfragment WebEmployeeQueries_payloadFields on WebEmployee {\n  id\n  group\n  initialsOrNameFormat\n  internalText\n  office\n  showPhoto\n  showEmail\n  showFunction\n  showPhone\n  showPhoneWork\n  socialMediaChannelOptions {\n    label\n    value\n  }\n  socialMediaChannelSelections\n  employee {\n    id\n    title\n    initials\n    firstName\n    middleName\n    lastName\n    gender\n    functionDescription\n    email\n    mobilePhoneNr\n    workPhoneNr\n    photoUrl(width: 200, height: 200)\n  }\n}\n"
};

module.exports = batch;
