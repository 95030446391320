import React from 'react'
import PropTypes from 'prop-types'
import RewriteRules from './RewriteRules'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps, onMutationCompleted } from '../shared/index'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	createRewriteRule = (rule) => {
		const mutation = graphql`
			mutation RewriteRulesWidgetMutation($input: CreateRewriteRuleInput!) {
				createRewriteRule(input: $input) {
					value {
						id
						pattern
						replace
						parameters
						status
					}
				}
			}
		`

		const variables = {
			input: { ...rule, websiteId: this.props.websiteId },
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createRewriteRule')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.websiteId)
			const listItems = node.getLinkedRecords('rewriteRules')
			if (listItems) {
				node.setLinkedRecords([valueItem, ...listItems], 'rewriteRules')
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateRewriteRule = (rule) => {
		const mutation = graphql`
			mutation RewriteRulesWidgetUpdateMutation($input: UpdateRewriteRuleInput!) {
				updateRewriteRule(input: $input) {
					value {
						id
						pattern
						replace
						parameters
						status
					}
				}
			}
		`

		const variables = {
			input: {
				...rule,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	deleteRewriteRules = (deleteIds) => {
		const { websiteId } = this.props

		const mutation = graphql`
			mutation RewriteRulesWidgetDeleteMutation($input: DeleteRewriteRulesInput!) {
				deleteRewriteRules(input: $input) {
					deletedIds
				}
			}
		`

		const variables = {
			input: { deleteIds: deleteIds },
		}

		const updater = (store) => {
			const websiteNode = store.get(websiteId)
			const originalList = websiteNode.getLinkedRecords('rewriteRules')

			if (!originalList) {
				return
			}

			const mutationRoot = store.getRootField('deleteRewriteRules')
			const deletedIds = mutationRoot.getValue('deletedIds')

			const filteredList = [...originalList].filter((item) => deletedIds.indexOf(item.getDataID()) === -1)
			websiteNode.setLinkedRecords(filteredList, 'rewriteRules')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	render() {
		const { errorMessage, websiteId } = this.props
		return (
			<div>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
					}}
					query={graphql`
						query RewriteRulesWidget_Query($id: ID!) {
							node(id: $id) {
								... on Website {
									id
									rewriteRules {
										...RewriteRulesWidget_items
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{errorMessage || 'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<RewriteRulesContainer
									items={props.node.rewriteRules}
									createRewriteRule={this.createRewriteRule}
									updateRewriteRule={this.updateRewriteRule}
									deleteRewriteRules={this.deleteRewriteRules}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

const RewriteRulesContainer = createFragmentContainer(
	RewriteRules,
	graphql`
		fragment RewriteRulesWidget_items on RewriteRule @relay(plural: true) {
			id
			pattern
			replace
			parameters
			status
		}
	`,
)

RewriteRulesContainer.prototype = React.Component.prototype
