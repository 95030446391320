import React from 'react'
import './ThemeSettingsPage.css'
import ThemeSection from './ThemeSection'
import HeaderSection from './HeaderSection'
import FooterSection from './FooterSection'
import VariantSection from './VariantSection'
import ListingSection from './ListingSection'
import PresentationSection from './PresentationSection'
import GeneralSection from './GeneralSection'
import WebsiteLogoSection from './WebsiteLogoSection'
import Joyride from 'react-joyride'
import 'animate.css'
import WebEmployeesSection from './WebEmployeesSection'

class ThemeSettingsPage extends React.Component {
	state = {
		active: this.initialState,
		activeSection: null,
		animateCurrent: false,
		animateOthers: false,
		steps: [],
		showGuider: true,
	}

	addSteps = (newSteps) => {
		console.log('NEWSTEPS : ', newSteps)
		const allSteps = this.state.steps.concat(newSteps)
		this.setState({ steps: allSteps })
	}

	render() {
		const { steps, showGuider } = this.state
		const { route, router } = this.props

		return (
			<div>
				<Joyride
					ref={(ref) => (this.joyride = ref)}
					run={showGuider} // or some other boolean for when you want to start it
					debug={false}
					steps={steps}
					continuous={true}
				/>

				<WebsiteLogoSection />

				<ThemeSection />

				<HeaderSection addSteps={this.addSteps} />

				<FooterSection />

				<VariantSection />

				<WebEmployeesSection />

				<ListingSection />

				<PresentationSection />

				<GeneralSection />
			</div>
		)
	}

	bindOpen(section) {
		return () => {
			this.setState({ activeSection: section, animateOthers: true }, () => {
				setTimeout(() => {
					this.setState({ animateCurrent: true })
				}, 10)
			})
		}
	}

	close = (section) => {
		this.setState({ activeSection: null, animateCurrent: true }, () => {
			setTimeout(() => {
				this.setState({ animateOthers: true })
			}, 10)
		})
	}

	isVisible(section) {
		return this.state.active[section]
	}
}

export default ThemeSettingsPage
