import { PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'
import CKEditor from '../CKEditor'
import { Input } from '../general'
import Form from '../general/form/form/Form'
import './CmsSubContentForm.css'

export default class extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		createItem: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
		label: PropTypes.string.isRequired,
		setDirtyState: PropTypes.func,
	}

	constructor(props) {
		super(props)
		this.state = {
			isEditMode: false,
		}
	}

	onOpenEditMode = () => {
		const { isEditMode } = this.state
		if (isEditMode) {
			return
		}
		this.setState({ isEditMode: true })
	}

	onCloseEditMode = () => {
		this.setState({ isEditMode: false })
	}

	onCreateNew = () => {
		this.setState({
			isEditMode: true,
		})
	}

	onSubmit = (values) => {
		const item = {
			...this.props.item,
			title: values.title,
			content: values.content,
		}

		if (item.id === undefined) {
			this.props.createItem(item)
		} else {
			this.props.updateItem(item)
		}
		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
		this.onCloseEditMode()
		return true
	}

	onCancel = () => {
		if (this.props.setDirtyState) {
			this.props.setDirtyState(false)
		}
		this.onCloseEditMode()
	}

	onDeleteClick = (event) => {
		event.stopPropagation()
		this.props.deleteItem(this.props.item)
	}

	renderAddButton = () => {
		const { label } = this.props
		return (
			<div className="contentblok">
				<div className="plus" onClick={this.onCreateNew}>
					<PlusOutlined className={'icon'} />
					<span className={'label'}>{label}</span>
				</div>
			</div>
		)
	}

	renderInformation = () => {
		const { item, label } = this.props
		return (
			<div className="infopanel">
				<FontAwesome name="times" size="lg" className="icon delete-icon" onClick={(e) => this.onDeleteClick(e)} />
				<div className="title">{label}</div>
				<div className="subtitle">{item ? item.title : ''}</div>
			</div>
		)
	}

	onValuesChange = (values) => {
		if (this.props.setDirtyState) {
			this.props.setDirtyState(true)
		}
	}

	renderEditPanel = () => {
		const { item } = this.props
		return (
			<div className="editpanel">
				<div className="editpanel-content">
					<Form
						ref={(c) => {
							this.form = c
						}}
						onSubmit={this.onSubmit}
						showCancel={true}
						onCancel={this.onCancel}
						onValuesChange={this.onValuesChange}
					>
						<Input label="Titel" name="title" initialValue={item ? item.title : ''} />
						<Row gutter={8}>
							<Col span={24}>
								<CKEditor
									id={item !== null && item.id !== null ? 'cmsSubContent' + item.id : 'cmsSubContent-new'}
									name="content"
									initialValue={item ? item.content : ''}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		)
	}

	render() {
		const { isEditMode } = this.state
		const { item } = this.props

		if (item === null && !isEditMode) {
			return this.renderAddButton()
		}

		return (
			<div className="contentblok" onClick={this.onOpenEditMode}>
				{!isEditMode && this.renderInformation()}
				{isEditMode && this.renderEditPanel()}
			</div>
		)
	}
}
