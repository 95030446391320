/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteFragment} from 'relay-runtime';
export type WebEmployeeModalCard_employee = {|
  +id: string;
  +systemid: ?number;
  +title: ?string;
  +initials: ?string;
  +firstName: ?string;
  +middleName: ?string;
  +lastName: ?string;
  +gender: ?string;
  +functionDescription: ?string;
  +email: ?string;
  +mobilePhoneNr: ?string;
  +workPhoneNr: ?string;
  +photoUrl: ?string;
|};
*/


const fragment /*: ConcreteFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebEmployeeModalCard_employee",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "systemid",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "initials",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "firstName",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "middleName",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "lastName",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "gender",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "functionDescription",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "email",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "mobilePhoneNr",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "workPhoneNr",
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 40,
          "type": "Int!"
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 40,
          "type": "Int!"
        }
      ],
      "name": "photoUrl",
      "storageKey": "photoUrl{\"height\":40,\"width\":40}"
    }
  ],
  "type": "Employee"
};

module.exports = fragment;
