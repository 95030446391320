//TODO: pure function van maken
import Layout from '../pages/homepage/Layout'

function reorder(nodeList, movingNodeKey) {
	moveAllDown(nodeList, movingNodeKey)
	moveAllUp(nodeList)
}

function nodesDirectlyAbove(nodeList, node) {
	return nodeList.filter((n) => n.y + n.height === node.y && !(n.x + n.width <= node.x || node.x + node.width <= n.x))
}

function moveAllUp(nodeList) {
	nodeList
		.sort((a, b) => a.y - b.y)
		.forEach((n) => {
			while (nodesDirectlyAbove(nodeList, n).length === 0) {
				if (n.y <= 0) {
					return
				}
				n.y -= 1
			}
		})
}

function moveAllDown(nodeList, movingNodeKey) {
	const layout = new Layout(nodeList)

	const currentNode = nodeList.find((node) => node.key === movingNodeKey)

	const intersectingNodes = layout.getIntersects(currentNode)

	// sort on row number and filter only the first row
	let prevY = -1
	const firstRowNodes = []
	intersectingNodes
		.sort((a, b) => a.y > b.y)
		.forEach((node) => {
			if (prevY === -1 || node.y !== prevY) {
				firstRowNodes.push(node)
				prevY = node.y
			} else {
				return
			}
		})

	moveBy(nodeList, intersectingNodes, currentNode.height)

	return nodeList
}

function moveBy(nodeList, nodesToMove, heightDelta) {
	nodesToMove.forEach((node) => {
		const below = nodesDirectlyBelow(nodeList, node)
		moveBy(nodeList, below, heightDelta)
		node.y += heightDelta
	})
}

function nodesDirectlyBelow(nodeList, node) {
	return nodeList.filter(
		(n) => n.y === node.y + node.height && !(n.x + n.width <= node.x || node.x + node.width <= n.x),
	)
}

function clamp(value, min, max) {
	return Math.min(Math.max(value, min), max)
}

function range(amount) {
	const result = []
	for (let i = 0; i <= amount; i++) {
		result.push(i)
	}
	return result
}

export { moveAllUp, clamp, reorder, range, nodesDirectlyBelow }
