import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import './NestableMenuListItemHandle.css'
import { Modal, Tooltip } from 'antd'
import { SetInActive } from '../general'

export default class NestableMenuListItemHandle extends Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.any.isRequired,
		}),
		openUpdateModal: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			isVisible: false,
			isTooltipVisible: false,
		}
	}

	onMouseOver() {
		this.setState({ isVisible: true, isTooltipVisible: true })
	}
	onMouseLeave() {
		this.setState({ isVisible: false, isTooltipVisible: false })
	}

	onClick = (e) => {
		e.stopPropagation()
		const { openUpdateModal, item } = this.props
		openUpdateModal(item)
	}

	onDeleteClick = (e) => {
		e.stopPropagation()
		const { deleteItem, item } = this.props
		Modal.confirm({
			content: 'Hierbij wordt het geselecteerde menu item en alle daarbij behorende submenu items verwijderd.',
			title: 'Let op!',
			okText: 'Verwijderen',
			okType: 'danger',
			cancelText: 'Annuleren',
			onOk: () => deleteItem(item.id),
		})
	}

	render() {
		const { item } = this.props
		const { isVisible, isTooltipVisible } = this.state
		return (
			<div
				className={'nestable-list-item-handle ' + (item.inactive ? ' inactive' : '')}
				onMouseOver={() => this.onMouseOver()}
				onMouseLeave={() => this.onMouseLeave()}
				onClick={this.onClick}
			>
				<div className="handle-title">
					<Tooltip title={item.title} visible={isTooltipVisible && item.title.length > 14} placement="topLeft">
						{item.title}
					</Tooltip>
				</div>
				<div className="linkpreview">({item.link === '' ? 'linkgroep' : item.link})</div>
				<SetInActive nodeId={item.id} inactive={item.inactive} extraClass={isVisible ? '' : 'hidden'} />
				<FontAwesome
					className={'menu-delete ' + (isVisible ? '' : 'hidden')}
					name="trash"
					onClick={this.onDeleteClick}
					size="2x"
				/>
			</div>
		)
	}
}
