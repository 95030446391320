import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps, onMutationCompleted } from '../shared/'
import MenuBuilder from './MenuBuilder'
import { deleteMenuItem, onMenuItemsOrderChange, updateMenuItem } from './mutations'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	createItem = (item) => {
		const mutation = graphql`
			mutation HeaderMenuWidgetCreateMutation($input: CreateMenuItemInput!) {
				createHeaderMenuItem(input: $input) {
					value {
						id
						order
						parentId
						title
						link
						cmsContentId
						inactive
					}
				}
			}
		`

		const variables = {
			input: { ...item, websiteId: this.props.websiteId },
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createHeaderMenuItem')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.websiteId)
			const listItems = node.getLinkedRecords('headerMenuItems')
			if (listItems) {
				node.setLinkedRecords([...listItems, valueItem], 'headerMenuItems')
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted('Het menu item is aangemaakt.'),
			onError: (err) => console.error(err),
			updater,
		})
	}

	deleteItem = (item) => {
		deleteMenuItem(item, this.props.websiteId, 'headerMenuItems')
	}

	onItemsChange = (items) => {
		onMenuItemsOrderChange(items)
	}

	render() {
		const { errorMessage, websiteId } = this.props

		return (
			<div>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
					}}
					query={graphql`
						query HeaderMenuWidget_Query($id: ID!) {
							node(id: $id) {
								... on Website {
									id
									headerMenuItems {
										...HeaderMenuWidget_items
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{errorMessage || 'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<MenuWidget
									items={props.node.headerMenuItems}
									onItemsChange={this.onItemsChange}
									deleteItem={this.deleteItem}
									updateItem={updateMenuItem}
									createItem={this.createItem}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

const MenuWidget = createFragmentContainer(
	MenuBuilder,
	graphql`
		fragment HeaderMenuWidget_items on MenuItem @relay(plural: true) {
			id
			order
			parentId
			title
			link
			cmsContentId
			inactive
		}
	`,
)

MenuWidget.prototype = React.Component.prototype
