import React, { Component } from 'react'
import './Dashboard.css'

export default class NoWebsitePage extends Component {
	render() {
		return (
			<div className="dashboard-container">
				<span className="title">Welkom in het vernieuwde website beheer!</span>

				<br />
				<br />

				<p className="subtitle" style={{ textAlign: 'center' }}>
					Het nieuwe website beheer is speciaal voor Website 3.0. Neem contact op met uw accountmanager voor de
					mogelijkheden of wissel terug naar het oude website beheer door middel van de blauwe terug knop.
				</p>
			</div>
		)
	}
}
