import { FieldTypes } from '../../../dnd/FieldRenderer'

export default ({ settings }) => {
	const possibleOptionsBySetting = new Map()
	possibleOptionsBySetting.set('enableWonen', { value: 'wonen', label: 'Wonen' })
	possibleOptionsBySetting.set('enableAlv', { value: 'alv', label: 'ALV' })
	possibleOptionsBySetting.set('enableBog', { value: 'bog', label: 'BOG' })
	possibleOptionsBySetting.set('enableVgmcomplex', { value: 'vgmcomplex', label: 'VGM' })
	possibleOptionsBySetting.set('enableNieuwbouw', { value: 'nieuwbouw', label: 'Nieuwbouw' })

	const options = []
	for (let [key, value] of possibleOptionsBySetting.entries()) {
		const settingValue = settings[key]
		if (settingValue === true) {
			options.push(value)
		}
	}

	return {
		id: 'googleMapBlok',
		title: 'Google Maps',
		icon: 'map-marker',
		size: {
			minWidth: 1,
			maxWidth: 3,
			defaultWidth: 1,

			minHeight: 1,
			maxHeight: 1,
			defaultHeight: 1,
		},
		isDisabled: false,
		fieldTypes: [
			{
				name: 'googleMapModule',
				label: 'Module',
				help: 'Type objecten die getoond worden in de google kaart',
				placeholder: '',
				type: FieldTypes.SELECT,
				rules: [{ required: true, message: 'Vul een waarde in' }],
				defaultValue: options.length >= 1 ? options[0].value : null,
				options: options,
			},
		],
	}
}
