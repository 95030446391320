/**
 * @flow
 * @relayHash 4063325fea08319cd1c223f6415c6c05
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type DeeldienstenRightSubContentEditorCreateMutationVariables = {|
  input: {
    content?: ?string;
    pageId: string;
    title?: ?string;
  };
|};
export type DeeldienstenRightSubContentEditorCreateMutationResponse = {|
  +createDeeldienstenRightCmsSubContent: ?{|
    +value: {|
      +id: string;
      +title: ?string;
      +content: ?string;
    |};
  |};
|};
*/


/*
mutation DeeldienstenRightSubContentEditorCreateMutation(
  $input: CreateCmsSubContentInput!
) {
  createDeeldienstenRightCmsSubContent(input: $input) {
    value {
      id
      title
      content
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateCmsSubContentInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeeldienstenRightSubContentEditorCreateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateCmsSubContentInput!"
          }
        ],
        "concreteType": "CmsSubContentPayload",
        "name": "createDeeldienstenRightCmsSubContent",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsSubContent",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "DeeldienstenRightSubContentEditorCreateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateCmsSubContentInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "DeeldienstenRightSubContentEditorCreateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateCmsSubContentInput!"
          }
        ],
        "concreteType": "CmsSubContentPayload",
        "name": "createDeeldienstenRightCmsSubContent",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsSubContent",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation DeeldienstenRightSubContentEditorCreateMutation(\n  $input: CreateCmsSubContentInput!\n) {\n  createDeeldienstenRightCmsSubContent(input: $input) {\n    value {\n      id\n      title\n      content\n    }\n  }\n}\n"
};

module.exports = batch;
