import { ClockCircleOutlined } from '@ant-design/icons'
import React from 'react'

const Soon = ({ title }) => {
	return (
		<div style={{ display: 'inline-block', color: 'orange', marginBottom: 20 }}>
			<ClockCircleOutlined style={{ marginRight: 10 }} />
			<i>{title}</i>
		</div>
	)
}

export default Soon
