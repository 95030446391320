/**
 * @flow
 * @relayHash 0cee06d6be5b14f42ede3b9407cf19e3
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type NieuwsWidgetDeleteMutationVariables = {|
  input: {
    id: string;
  };
|};
export type NieuwsWidgetDeleteMutationResponse = {|
  +deleteNewsItem: ?{|
    +deletedId: string;
  |};
|};
*/


/*
mutation NieuwsWidgetDeleteMutation(
  $input: EntityIdInput!
) {
  deleteNewsItem(input: $input) {
    deletedId
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NieuwsWidgetDeleteMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "EntityDeletedPayload",
        "name": "deleteNewsItem",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "NieuwsWidgetDeleteMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "NieuwsWidgetDeleteMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "EntityDeletedPayload",
        "name": "deleteNewsItem",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation NieuwsWidgetDeleteMutation(\n  $input: EntityIdInput!\n) {\n  deleteNewsItem(input: $input) {\n    deletedId\n  }\n}\n"
};

module.exports = batch;
