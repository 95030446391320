import { FieldTypes } from '../../../dnd/FieldRenderer'

export default ({ settings }) => ({
	id: 'cms',
	title: 'Cms',
	icon: 'file',
	size: {
		minWidth: 1,
		maxWidth: 10,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 10,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{ name: 'title', label: 'Titel', type: FieldTypes.TEXT, defaultValue: '' },
		{
			name: 'titleLevel',
			label: 'Titel tag Level',
			type: FieldTypes.RADIO,
			defaultValue: '2',
			options: [
				{ value: '1', label: 'H1' },
				{ value: '2', label: 'H2' },
			],
		},
		{ name: 'content', label: 'Text', type: FieldTypes.HTML, defaultValue: '' },
	],
})
