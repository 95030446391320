import React from 'react'
import PropTypes from 'prop-types'
import CKEditor from '../CKEditor'
import { Form, Input, DatePicker, Select } from '../general'
import { Col, Row } from 'antd'
import moment from 'moment'
import './CmsEditor.less'
import { CMS_FORM_OPTIONS, CMS_STATUS_OPTIONS } from './cmsconstants'

export default class CmsEditorComponent extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
		item: PropTypes.object,
		createItem: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		setDirtyState: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			item: props.item,
			originalItem: props.item,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState({
			item: nextProps.item,
			originalItem: nextProps.item,
		})
	}

	onSubmit = (values) => {
		const { createItem, updateItem, setDirtyState, pageId } = this.props
		const { item } = this.state
		const cmsItem = { ...item, ...values }

		cmsItem.link = undefined
		cmsItem.publicationStartDate =
			values.publicationStartDate !== null ? values.publicationStartDate.format('YYYY-MM-DD') : null
		cmsItem.publicationEndDate =
			values.publicationEndDate !== null ? values.publicationEndDate.format('YYYY-MM-DD') : null

		if (item === null) {
			createItem(pageId, cmsItem)
		} else {
			updateItem(cmsItem)
		}

		setDirtyState(false)
		return true
	}

	onCancel = () => {
		this.setState({
			item: { ...this.state.originalItem },
		})
		this.props.setDirtyState(false)
	}

	onValuesChange = (values) => {
		this.props.setDirtyState(true)
	}

	render() {
		const { item } = this.state
		return (
			<div className="xmlpage-cms-editor">
				<Form
					ref={(c) => {
						this.form = c
					}}
					onSubmit={this.onSubmit}
					onValuesChange={this.onValuesChange}
				>
					<Input
						className="titleInput"
						name="title"
						label="Titel"
						initialValue={item ? item.title : ''}
						required={true}
						maxLength={250}
					/>

					<Input
						label="Browser Titel"
						name="browserTitle"
						initialValue={item ? item.browserTitle : ''}
						maxLength={250}
					/>
					<Input
						label="Browser Omschrijving"
						name="browserDescription"
						initialValue={item ? item.browserDescription : ''}
						maxLength={250}
					/>

					<Input
						label="Browser Trefwoorden"
						name="browserKeywords"
						initialValue={item ? item.browserKeywords : ''}
						disabled={item === null || item.browserKeywords === null || item.browserKeywords.length === 0}
						help="Trefwoorden worden niet meer gebruikt door Google voor het indexeren van de pagina. Reeds ingevoerde trefwoorden kunnen nog gewijzigd worden, voor nieuwe pagina's is dit niet meer mogelijk."
						maxLength={250}
					/>

					<Row gutter={8}>
						<Col span={5}>
							<Select
								label="Status"
								name="status"
								initialValue={item === null || item.status === null ? 'FINAL' : item.status}
								options={CMS_STATUS_OPTIONS}
							/>
						</Col>
						<Col span={5}>
							<DatePicker
								label="Publicatie vanaf"
								name="publicationStartDate"
								initialValue={
									item !== null && item.publicationStartDate ? moment(item.publicationStartDate, 'YYYY-MM-DD') : null
								}
								placeholder="Publicatie vanaf"
								required={true}
							/>
						</Col>
						<Col span={5}>
							<DatePicker
								label="tot"
								name="publicationEndDate"
								initialValue={
									item !== null && item.publicationEndDate ? moment(item.publicationEndDate, 'YYYY-MM-DD') : null
								}
								placeholder="Publicatie tot"
							/>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Select
								label="Formulier"
								name="cmsForm"
								initialValue={item === null || item.cmsForm === null ? 'NONE' : item.cmsForm}
								options={CMS_FORM_OPTIONS}
							/>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<CKEditor
								id={item !== null && item.id !== null ? 'xmlPageCms' + item.id : 'xmlPageCms-new'}
								name="content"
								initialValue={item ? item.content : ''}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}
