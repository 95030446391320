const CMS_FORM_OPTIONS = [
	{ label: 'Geen', value: 'NONE' },
	{ label: 'Contactformulier', value: 'CONTACT_FORM' },
	{ label: 'Bel mij formulier', value: 'CALL_ME_FORM' },
]

const CMS_STATUS_OPTIONS = [
	{ label: 'Concept', value: 'CONCEPT' },
	{ label: 'Definitief', value: 'FINAL' },
]

export { CMS_FORM_OPTIONS, CMS_STATUS_OPTIONS }
