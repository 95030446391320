/**
 * @flow
 * @relayHash a0651f37315bd5e5e029456e72c99e4a
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type HeaderBannerQueries_updateItem_MutationVariables = {|
  input: {
    id: string;
    link?: ?string;
    target?: ?string;
    websiteId: string;
    isImageAltered?: ?boolean;
  };
|};
export type HeaderBannerQueries_updateItem_MutationResponse = {|
  +updateHeaderBannerItem: ?{|
    +value: ?{|
      +id: string;
      +imagepath: ?string;
      +link: ?string;
      +listtypeid: ?string;
      +target: ?string;
    |};
  |};
|};
*/


/*
mutation HeaderBannerQueries_updateItem_Mutation(
  $input: UpdateHeaderBannerItemInput!
) {
  updateHeaderBannerItem(input: $input) {
    value {
      id
      imagepath
      link
      listtypeid
      target
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateHeaderBannerItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderBannerQueries_updateItem_Mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateHeaderBannerItemInput!"
          }
        ],
        "concreteType": "HeaderBannerItemPayload",
        "name": "updateHeaderBannerItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "ListItemImageRotator",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagepath",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "listtypeid",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "target",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "HeaderBannerQueries_updateItem_Mutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateHeaderBannerItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "HeaderBannerQueries_updateItem_Mutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateHeaderBannerItemInput!"
          }
        ],
        "concreteType": "HeaderBannerItemPayload",
        "name": "updateHeaderBannerItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "ListItemImageRotator",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagepath",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "listtypeid",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "target",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation HeaderBannerQueries_updateItem_Mutation(\n  $input: UpdateHeaderBannerItemInput!\n) {\n  updateHeaderBannerItem(input: $input) {\n    value {\n      id\n      imagepath\n      link\n      listtypeid\n      target\n    }\n  }\n}\n"
};

module.exports = batch;
