import React from 'react'
import PropTypes from 'prop-types'
import FilePicker, { FILTER_TYPES } from '../general/filepicker/FilePicker'
import SortableList from '../sortablelist/SortableList'
import Thumbnail from '../headerbanner/Thumbnail'
import FontAwesome from 'react-fontawesome'
import './HeaderBannerWidget.css'
import { HEADER_BANNER_SIZES_PER_THEME, ItemTypes } from '../../constants'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import HeaderBannerPanel from './HeaderBannerPanel'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared/index'
import { createItem, updateItem } from './HeaderBannerQueries'
import { deleteItem } from '../shared/mutations'
import { ImageEditor } from '../general'

const Mode = {
	ADD: Symbol('ADD'),
	EDIT: Symbol('EDIT'),
	CROP: Symbol('CROP'),
}

@connect(currentWebsiteProps)
class HeaderBannerWidgetContainer extends React.Component {
	render() {
		const { websiteId, websiteName, websiteSettings } = this.props
		const websiteTheme = websiteSettings.brokerSettings.websiteTheme

		if (websiteTheme === null) {
			return <div>Selecteer eerst een thema.</div>
		}

		return (
			<div className="headerbanner-list-container">
				<div className="headerbanner-list">
					<QueryRenderer
						environment={environment}
						variables={{
							id: websiteId,
						}}
						query={graphql`
							query HeaderBannerWidgetQuery($id: ID!) {
								node(id: $id) {
									... on Website {
										id
										headerBannerItems {
											...HeaderBannerWidget_items
										}
									}
								}
							}
						`}
						render={({ done, error, props, retry, stale }) => {
							if (error) {
								console.log('error', error)
								return <div>Error</div>
							} else if (props) {
								console.log('rendering', props)
								if (!websiteSettings) {
									// wait for websiteSettings to be loaded
									return null
								}
								return (
									<HeaderBannerWidget
										items={props.node.headerBannerItems}
										websiteId={websiteId}
										websiteName={websiteName}
										websiteTheme={websiteTheme}
									/>
								)
							} else if (done) {
								return <div>done</div>
							}
							return <div>?</div>
						}}
					/>
				</div>
			</div>
		)
	}
}

class HeaderBannerWidget extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		websiteName: PropTypes.string.isRequired,
		websiteTheme: PropTypes.string.isRequired,
	}

	state = {
		mode: null,
		currentItem: null, // :HeaderBannerItem
		showPicker: false,
		cropImagePath: null,
		imageBlobUrl: null,
		isImageAltered: false,
	}

	UNSAFE_componentWillReceiveProps(next) {
		if (next.items !== this.props.items) {
			this.setState({ items: next.items })
		}
	}

	render() {
		const { currentItem, showPicker, mode, cropImagePath, imageBlobUrl } = this.state
		const { items, websiteTheme } = this.props

		const headerSizes = HEADER_BANNER_SIZES_PER_THEME[websiteTheme]
		const { width: bannerWidth, height: bannerHeight } = headerSizes

		return (
			<div>
				<SortableList
					items={items}
					renderChildren={(childProps, item) => (
						<div onClick={() => this.onItemClick(item)}>
							<Thumbnail image={item.imagepath} />
							{childProps.isHover && (
								<FontAwesome
									name="times"
									size="lg"
									className="icon delete-icon"
									onClick={(e) => this.onDeleteClick(e, item)}
								/>
							)}
						</div>
					)}
					renderAddButton={this.renderAddButton}
					dropTargetType={ItemTypes.HEADER_THUMBNAIL}
					onItemsChange={(items) => {
						/* to be implemented */
					}}
				/>

				{cropImagePath && (
					<ImageEditor
						width={bannerWidth || 1000}
						height={bannerHeight || 1000}
						aspectRatio={null}
						showResolutionWarning={bannerWidth ? true : false}
						imageUrl={cropImagePath}
						onCrop={this.onImageCropped}
						onCancel={this.onCloseImageEditor}
						mimeType={'image/jpeg'}
						quality={0.8}
					/>
				)}

				<TransitionGroup>
					{mode && currentItem && (
						<CSSTransition classNames="editpanel" timeout={{ exit: 500, enter: 800 }}>
							<HeaderBannerPanel
								imagepath={imageBlobUrl ? imageBlobUrl : currentItem.imagepath}
								target={currentItem.target}
								link={currentItem.link}
								onSave={this.onSave}
								onCancel={this.closeHeaderBannerPanel}
								onImageClick={this.onImageClick}
							/>
						</CSSTransition>
					)}
				</TransitionGroup>

				{showPicker && (
					<FilePicker
						onCancel={this.closePicker}
						onSelectFile={(cmsFile: CmsFile) => this.onImagePicked(cmsFile)}
						filter={{ q: '', type: FILTER_TYPES.IMAGES }}
					/>
				)}
			</div>
		)
	}

	onImageClick = () => {
		const { currentItem } = this.state
		this.setState({
			cropImagePath: currentItem.imagepath,
			isImageAltered: true,
		})
	}

	renderAddButton = () => {
		return (
			<div className="plus" onClick={this.openPicker}>
				<span>+</span>
			</div>
		)
	}

	onDeleteClick = async (e, item) => {
		const { websiteId } = this.props
		e.stopPropagation()

		await deleteItem(item.id, websiteId, 'headerBannerItems')
	}

	onImagePicked = (cmsFile: CmsFile) => {
		this.setState({
			cropImagePath: cmsFile.staticUrl,
			mode: Mode.ADD,
		})
	}

	onCloseImageEditor = () => {
		this.setState({
			cropImagePath: null,
		})
	}

	onImageCropped = async (blob) => {
		const { mode } = this.state
		if (mode === Mode.ADD) {
			this.setState({
				imageBlobUrl: URL.createObjectURL(blob),
				currentItem: {},
			})
		} else if (mode === Mode.EDIT) {
			this.setState({
				imageBlobUrl: URL.createObjectURL(blob),
			})
		}

		this.onCloseImageEditor()
	}

	onItemClick = (bannerItem: HeaderBannerItem) => {
		this.setState({
			currentItem: bannerItem,
			mode: Mode.EDIT,
		})
	}

	onSave = async (newItem: HeaderPanelData) => {
		const { currentItem, imageBlobUrl } = this.state
		const { mode } = this.state

		let blob
		let imageFile

		if (imageBlobUrl) {
			const response = await fetch(imageBlobUrl)
			blob = await response.blob()
			const fileName = 'file-' + Date.now() + '.jpg'
			imageFile = new File([blob], fileName, { type: 'image/jpeg' })
		}

		if (mode === Mode.ADD) {
			await createItem({
				websiteId: this.props.websiteId,
				link: newItem.link,
				target: newItem.target,
				imageFile: imageFile,
			})
		} else {
			await updateItem({
				websiteId: this.props.websiteId,
				id: currentItem.id,
				link: newItem.link,
				target: newItem.target,
				imageFile: imageFile,
				isImageAltered: this.state.isImageAltered,
			})
		}

		this.closeHeaderBannerPanel()
	}

	closeHeaderBannerPanel = () => {
		const { imageBlobUrl } = this.state
		if (imageBlobUrl) {
			// free memory
			URL.revokeObjectURL(imageBlobUrl)
		}
		this.setState({
			showPicker: false,
			currentItem: null,
			mode: null,
			imageBlobUrl: null,
		})
	}

	openPicker = async () => {
		this.setState({
			showPicker: true,
			currentItem: null,
		})
	}

	closePicker = () => {
		this.setState({
			showPicker: false,
		})
	}
}

HeaderBannerWidget = createFragmentContainer(
	HeaderBannerWidget,
	graphql`
		fragment HeaderBannerWidget_items on ListItemImageRotator @relay(plural: true) {
			id
			imagepath
			link
			listtypeid
			target
		}
	`,
)

HeaderBannerWidget.prototype = React.Component.prototype

export default HeaderBannerWidgetContainer
