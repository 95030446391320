import _ from 'lodash'

let WIDGET_TYPES

export default class LayoutConfig {
	widgets = {}
	/**
	 *
	 * @param dndId the id from de DnD Layout (so not the id from WIDGET_TYPES)
	 * @returns {Widget}
	 */
	getWidget(dndId) {
		return this.widgets[dndId]
	}

	/**
	 *
	 * @param dndId
	 * @param widget Widget
	 */
	addWidget(dndId, widget) {
		this.widgets[dndId] = widget
	}

	removeWidget(dndId) {
		delete this.widgets[dndId]
	}

	getWidgets() {
		return this.widgets
	}
}

class Widget {
	// static propTypes = {
	// 	widgetxmlname: PropTypes.string.isRequired,
	// 	parameters: PropTypes.shape({
	// 		name: PropTypes.string.isRequired,
	// 		value: PropTypes.string.isRequired,
	// 	}),
	// }

	constructor(widgetxmlname, parameters) {
		//PropTypes.checkPropTypes(Widget.propTypes, config, 'config', 'Widget');
		this.id = widgetxmlname
		this.values = parameters
	}

	getWidgetXmlName() {
		return this.id
	}

	getType() {
		const type = _.find(WIDGET_TYPES, { id: this.id })
		if (!type) {
			throw new Error(`No widget with name "${this.id}" found`)
		}
		return type
	}

	getParameters() {
		return this.values
	}

	/**
	 *
	 * @param values [{ name, value }]
	 */
	setValues(values) {
		this.values = values
	}

	/**
	 *
	 * @returns {name|value|*}
	 */
	getValues() {
		return this.values
	}

	/**
	 * Should we keep this information here? Then what is the single source of truth, Layout.js or this place? It's
	 * pretty darn handy though, so for now let's do it.
	 *
	 * TODO: get the position from Layout somehow
	 *
	 * @param position
	 */
	setPosition(position) {
		this.position = position
	}

	getPosition() {
		return this.position
	}

	getSize() {
		const { width, height } = this.position
		return { width, height }
	}
}

const registerWidgetTypes = (types) => (WIDGET_TYPES = types)

export { Widget, registerWidgetTypes }
