import _ from 'lodash'

export default class Layout {
	static COLS = 3

	/**
	 *
	 * @param layout
	 * [
	 *      {key, x ,y, width, height},
	 *      ...
	 * ]
	 *
	 */
	constructor(layout) {
		this.layout = layout
	}

	updateLayout(layout) {
		this.layout = layout
	}

	getRows() {
		const layout = [...this.layout]
		if (layout.length === 0) {
			return 0
		}
		// sort on which item's bottom has the lowest position
		layout.sort((a, b) => a.y + a.height - (b.y + b.height))

		// now we know that the last item (plus it's height) is the number of rows needed for this dndLayout
		const lastNode = _.last(layout)

		return lastNode.y + lastNode.height
	}

	_intersect(a, b) {
		return !(a.x + a.width <= b.x || b.x + b.width <= a.x || a.y + a.height <= b.y || b.y + b.height <= a.y)
	}

	/**
	 * get Blocks ordered by position from left to right, top to bottom
	 *
	 * @returns [{key, x ,y, width, height}, ...]
	 */
	getLayout() {
		const layout = [...this.layout]
		layout.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y)
		return layout
	}

	getNodeByXY(x, y) {
		const node = { x, y, width: 1, height: 1 }
		const intersects = this.layout.filter((item) => this._intersect(item, node))

		if (intersects.length === 0) {
			return null
		}
		if (intersects.length > 1) {
			throw new Error(`Error while searching for node ${JSON.stringify(node)}: 
				should not find more than 1 node at a position, found ${intersects.length}`)
		}

		return intersects[0]
	}

	isFree(node) {
		for (let i = 0; i < this.layout.length; i++) {
			if (this._intersect(this.layout[i], node)) {
				return false
			}
		}
		return true
	}

	getIntersects(node) {
		return this.layout
			.filter((n) => n.key !== node.key) // filter out itself first
			.filter((a) => this._intersect(a, node)) // find intersecting nodes
	}

	addPosition(position) {
		this.layout.push(position)
	}

	removePosition(key) {
		const index = _.findIndex(this.layout, { key: key })
		console.log('Removing', key, index)
		console.log(this.layout)
		this.layout.splice(index, 1)
	}

	/**
	 *
	 * Transform de data uit de database van de huidige format naar nieuwe format, waar DnD mee kan werken.
	 *
	 * De data uit de db ziet er zo uit:
	 *    [
	 *        {width, height, ...},
	 *        {width, height, ...}
	 *    ]
	 *
	 *    en dat moet zo worden:
	 *
	 *    [
	 *        {x, y, width, height, ...},
	 *        {x, y, width, height, ...}
	 *    ]
	 *
	 *    Die x en y berekenen we hier en dan kunnen we het meegeven aan DnD.
	 *
	 * @param
	 * [
	 *      {width, height, ...},
	 *      {width, height, ...}
	 * ]
	 *
	 * @returns
	 *  [
	 *      {x, y, width, height, ...},
	 *      {x, y, width, height, ...}
	 * ]
	 *
	 */
	static newFromDbLegacyFormat(widthHeightArray) {
		const newFormat = []
		widthHeightArray.forEach((item, index) => {
			const { width, height: tempHeight, ...rest } = item

			// er zit in de database blijkbaar -1 waardes, hack dat hier weg
			// @see https://jira.realworks.nl/browse/BMW-1494
			const height = tempHeight < 0 ? 1 : tempHeight

			if (index === 0) {
				newFormat.push({ x: 0, y: 0, width, height, ...rest })
				return
			}

			let x = 0
			let y = 0

			while (!new Layout(newFormat).isFree({ x, y, width, height })) {
				x = (x + 1) % 3
				y = x === 0 ? y + 1 : y
			}

			newFormat.push({ x, y, width, height, ...rest })
		})

		const layout = newFormat
			// we don't need the emptyBlock placeholder blocks anymore now that we have positioned (x,y) all blocks
			.filter((item) => item.widgetxmlname !== 'emptyBlock')

			// inject a key so (which is required for DnD) so that we can map the blocks in DnD to our own widget configurations
			.map((item, index) => {
				return { key: index, x: item.x, y: item.y, width: item.width, height: item.height }
			})

		return new Layout(layout)
	}
}
