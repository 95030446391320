import { Widget } from './LayoutConfig'

/**
 * should give an array of block width blocks placeholder block on empty spaces
 *
 * @param layout Layout
 * @param layoutConfig WidgetConfigManager
 * @returns {Array}
 */
const prepareDbLegacyFormat = (layout, layoutConfig) => {
	const rows = layout.getRows()
	const cols = 3

	const processed = new Set()
	const includingEmptyBlocks = []

	for (let y = 0; y < rows; y++) {
		for (let x = 0; x < cols; x++) {
			const position = layout.getNodeByXY(x, y)

			if (position === null) {
				const placeholder = new Widget('emptyBlock', [])
				placeholder.setPosition({ x, y, width: 1, height: 1 })

				includingEmptyBlocks.push(placeholder)
			} else {
				if (processed.has(position)) {
					continue
				}

				const widget = layoutConfig.getWidget(position.key)
				if (!widget) {
					throw new Error('widget == null')
				}

				includingEmptyBlocks.push(widget)
				processed.add(position)
			}
		}
	}

	return includingEmptyBlocks
}

export { prepareDbLegacyFormat }
