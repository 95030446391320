import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps } from './shared/index'

@connect(currentWebsiteProps)
export default class extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		includeAllDepartmentOption: PropTypes.bool,
	}

	render() {
		const { onDepartmentSelected, websiteId, includeAllDepartmentOption } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query DepartmentSelectorQuery($id: ID!) {
						node(id: $id) {
							... on Website {
								id
								departments {
									...DepartmentSelector_items
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div>Er is een fout opgetreden</div>
					} else if (props) {
						return (
							<DepartmentSelector
								items={props.node.departments}
								onDepartmentSelected={onDepartmentSelected}
								includeAllDepartmentOption={includeAllDepartmentOption}
							/>
						)
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class DepartmentSelectorComponent extends Component {
	static propTypes = {
		includeAllDepartmentOption: PropTypes.bool,
	}

	static defaultProps = {
		includeAllDepartmentOption: false,
	}

	onSelectChange = (value) => {
		this.props.onDepartmentSelected(value === null ? value : value.value)
	}

	render() {
		const { includeAllDepartmentOption } = this.props

		let options = this.props.items.map((edge) => {
			return { value: edge.departmentid, label: edge.name }
		})

		if (includeAllDepartmentOption) {
			options.unshift({ value: '', label: 'Alle vestigingen' })
		}

		return (
			<Select
				name="form-field-name"
				options={options}
				clearable={!includeAllDepartmentOption}
				onChange={this.onSelectChange}
				placeholder="Selecteer een vestiging"
			/>
		)
	}
}

const DepartmentSelector = createFragmentContainer(
	DepartmentSelectorComponent,
	graphql`
		fragment DepartmentSelector_items on WebsiteDepartment @relay(plural: true) {
			id
			name
			departmentid
		}
	`,
)

DepartmentSelector.prototype = React.Component.prototype
