function makePreviewUrlWithWidthAndHeightParameters(previewUrl, width, height) {
	let newUrl = previewUrl.replace(/height=(\d)*/i, 'height=' + height)
	newUrl = newUrl.replace(/width=(\d)*/i, 'width=' + width)

	return newUrl
}

function assertTrue(value, message = 'Invalid value (this should be true)') {
	if (value !== true) {
		throw new Error(message)
	}
}

export { makePreviewUrlWithWidthAndHeightParameters, assertTrue }
