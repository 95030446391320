import React, { PureComponent } from 'react'
import { Alert } from 'antd'

/**
 * Dit component wordt gerenderd door {@link RenderErrorBoundary} wanneer tijdens <code>render()</code> een error optreedt.
 */
export default class RenderError extends PureComponent {
	static propTypes = {}

	render() {
		return (
			<Alert
				type="error"
				message="Er is een fout opgetreden"
				description="Probeer de pagina te herladen of probeer het later nogmaals"
			/>
		)
	}
}
