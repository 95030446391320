/**
 * @flow
 * @relayHash 1350e4391f33a14d2add90404156102b
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type NewsLetterWidgetUpdateMutationVariables = {|
  input: {
    cmsContent?: ?{
      browserDescription?: ?string;
      browserKeywords?: ?string;
      browserTitle?: ?string;
      content?: ?string;
      id: string;
      link?: ?string;
      publicationEndDate?: ?any;
      publicationStartDate?: ?any;
      status?: ?"CONCEPT" | "FINAL";
      teaserText?: ?string;
      title?: ?string;
    };
    id: string;
    order?: ?number;
    subject?: ?string;
  };
|};
export type NewsLetterWidgetUpdateMutationResponse = {|
  +updateNewsLetterItem: ?{|
    +value: {|
      +id: string;
      +order: ?number;
      +subject: ?string;
      +inactive: ?boolean;
      +cmsContent: ?{|
        +id: string;
        +title: ?string;
        +link: ?string;
        +content: ?string;
        +browserTitle: ?string;
        +browserDescription: ?string;
        +browserKeywords: ?string;
        +status: ?"CONCEPT" | "FINAL";
        +publicationStartDate: ?any;
        +publicationEndDate: ?any;
      |};
    |};
  |};
|};
*/


/*
mutation NewsLetterWidgetUpdateMutation(
  $input: UpdateNewsLetterItemInput!
) {
  updateNewsLetterItem(input: $input) {
    value {
      id
      order
      subject
      inactive
      cmsContent {
        id
        title
        link
        content
        browserTitle
        browserDescription
        browserKeywords
        status
        publicationStartDate
        publicationEndDate
      }
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateNewsLetterItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsLetterWidgetUpdateMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateNewsLetterItemInput!"
          }
        ],
        "concreteType": "NewsLetterItemPayload",
        "name": "updateNewsLetterItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "NewsLetterItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "subject",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "CmsContent",
                "name": "cmsContent",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserTitle",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserDescription",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserKeywords",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationStartDate",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationEndDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "NewsLetterWidgetUpdateMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateNewsLetterItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "NewsLetterWidgetUpdateMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateNewsLetterItemInput!"
          }
        ],
        "concreteType": "NewsLetterItemPayload",
        "name": "updateNewsLetterItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "NewsLetterItem",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "subject",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "inactive",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "CmsContent",
                "name": "cmsContent",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserTitle",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserDescription",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "browserKeywords",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationStartDate",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "publicationEndDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation NewsLetterWidgetUpdateMutation(\n  $input: UpdateNewsLetterItemInput!\n) {\n  updateNewsLetterItem(input: $input) {\n    value {\n      id\n      order\n      subject\n      inactive\n      cmsContent {\n        id\n        title\n        link\n        content\n        browserTitle\n        browserDescription\n        browserKeywords\n        status\n        publicationStartDate\n        publicationEndDate\n      }\n    }\n  }\n}\n"
};

module.exports = batch;
