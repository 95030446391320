import React from 'react'
import PropTypes from 'prop-types'
import { ActionHeader, Checkbox, Form, Input, SwitchButton } from '../../general'
import { ActionLink } from '../../general/form/actionheader/ActionHeader'
import { Tabs } from 'antd'
import { connect } from 'react-redux'
import { updateSettings } from '../../settings/settingsQueries'
import _ from 'lodash'

const { TabPane } = Tabs

const mapStateToProps = (state) => ({
	websiteId: state.basePage.websiteId,
	websiteSettings: state.basePage.websiteSettings,
	isHelpdeskUser: state.viewer.isHelpdeskUser,
})

@connect(mapStateToProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		websiteSettings: PropTypes.object.isRequired,
		isHelpdeskUser: PropTypes.bool.isRequired,
	}

	state = {
		enableCustomWonenContactGegevens: false,
		enableCustomBogContactGegevens: false,
		enableCustomNieuwbouwContactGegevens: false,
		enableCustomAlvContactGegevens: false,
		enableCustomVgmcomplexContactGegevens: false,
	}

	checkboxFields = [
		'enableCustomWonenContactGegevens',
		'enableCustomBogContactGegevens',
		'enableCustomNieuwbouwContactGegevens',
		'enableCustomAlvContactGegevens',
		'enableCustomVgmcomplexContactGegevens',
	]

	componentDidMount() {
		this.updateSettings(this.props.websiteSettings.systemSettings)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.websiteSettings !== this.props.websiteSettings) {
			this.updateSettings(nextProps.websiteSettings.systemSettings)
		}
	}

	updateSettings(systemSettings) {
		const newValues = _.pick(systemSettings, this.checkboxFields)
		this.setState(newValues)
	}

	onSubmit = (values) => {
		const { websiteId } = this.props
		let dataToMutate = Object.entries(values).map(([key, value]) => {
			return {
				name: key,
				value: value,
			}
		})
		updateSettings(dataToMutate, websiteId)
	}

	render() {
		const { websiteId, websiteSettings } = this.props
		const { showCBSStatistieken } = websiteSettings.brokerSettings
		const {
			enableCustomWonenContactGegevens,
			customWonenContactGegevensEmail,
			customWonenContactGegevensTel,
			enableCustomBogContactGegevens,
			customBogContactGegevensEmail,
			customBogContactGegevensTel,
			enableCustomNieuwbouwContactGegevens,
			customNieuwbouwContactGegevensEmail,
			customNieuwbouwContactGegevensTel,
			enableCustomAlvContactGegevens,
			customAlvContactGegevensEmail,
			customAlvContactGegevensTel,
			enableCustomVgmcomplexContactGegevens,
			customVgmcomplexContactGegevensEmail,
			customVgmcomplexContactGegevensTel,
		} = websiteSettings.systemSettings

		const {
			enableCustomWonenContactGegevens: enableCustomWonenContactGegevensLocal,
			enableCustomBogContactGegevens: enableCustomBogContactGegevensLocal,
			enableCustomNieuwbouwContactGegevens: enableCustomNieuwbouwContactGegevensLocal,
			enableCustomAlvContactGegevens: enableCustomAlvContactGegevensLocal,
			enableCustomVgmcomplexContactGegevens: enableCustomVgmcomplexContactGegevensLocal,
		} = this.state

		const { enableWonen, enableBog, enableNieuwbouw, enableAlv, enableVgmcomplex } = websiteSettings.systemSettings

		return (
			<div className="theme-wrapper">
				<ActionHeader title="Presentatie pagina">
					<ActionLink label="Annuleren" />
				</ActionHeader>

				<Form key={'presentationSectionForm' + websiteId} onSubmit={this.onSubmit} onValuesChange={this.onValuesChange}>
					<Tabs defaultActiveKey={'general'}>
						<TabPane tab="Algemeen" key="general">
							<SwitchButton
								name={'showCBSStatistieken'}
								label="Toon CBS statistieken bij objectpresentatie"
								initialValue={showCBSStatistieken ? showCBSStatistieken : 'false'}
							/>
						</TabPane>

						{enableWonen && (
							<TabPane tab="Wonen" key="wonen">
								<Checkbox
									name={'enableCustomWonenContactGegevens'}
									label="Aparte contact gegevens Wonen"
									initialValue={enableCustomWonenContactGegevens ? enableCustomWonenContactGegevens : false}
								/>
								<Input
									name={'customWonenContactGegevensEmail'}
									label="Email"
									type="text"
									initialValue={customWonenContactGegevensEmail ? customWonenContactGegevensEmail : ''}
									disabled={!enableCustomWonenContactGegevensLocal}
								/>
								<Input
									name={'customWonenContactGegevensTel'}
									label="Telefoon nr."
									type="text"
									initialValue={customWonenContactGegevensTel ? customWonenContactGegevensTel : ''}
									disabled={!enableCustomWonenContactGegevensLocal}
								/>
							</TabPane>
						)}

						{enableBog && (
							<TabPane tab="BOG" key="bog">
								<Checkbox
									name={'enableCustomBogContactGegevens'}
									label="Aparte contact gegevens BOG"
									initialValue={enableCustomBogContactGegevens ? enableCustomBogContactGegevens : false}
								/>
								<Input
									name={'customBogContactGegevensEmail'}
									label="Email"
									type="text"
									initialValue={customBogContactGegevensEmail ? customBogContactGegevensEmail : ''}
									disabled={!enableCustomBogContactGegevensLocal}
								/>
								<Input
									name={'customBogContactGegevensTel'}
									label="Telefoon nr."
									type="text"
									initialValue={customBogContactGegevensTel ? customBogContactGegevensTel : ''}
									disabled={!enableCustomBogContactGegevensLocal}
								/>
							</TabPane>
						)}

						{enableNieuwbouw && (
							<TabPane tab="Nieuwbouw" key="nieuwbouw">
								<Checkbox
									name={'enableCustomNieuwbouwContactGegevens'}
									label="Aparte contact gegevens Nieuwbouw"
									initialValue={enableCustomNieuwbouwContactGegevens ? enableCustomNieuwbouwContactGegevens : false}
								/>
								<Input
									name={'customNieuwbouwContactGegevensEmail'}
									label="Email"
									type="text"
									initialValue={customNieuwbouwContactGegevensEmail ? customNieuwbouwContactGegevensEmail : ''}
									disabled={!enableCustomNieuwbouwContactGegevensLocal}
								/>
								<Input
									name={'customNieuwbouwContactGegevensTel'}
									label="Telefoon nr."
									type="text"
									initialValue={customNieuwbouwContactGegevensTel ? customNieuwbouwContactGegevensTel : ''}
									disabled={!enableCustomNieuwbouwContactGegevensLocal}
								/>
							</TabPane>
						)}

						{enableAlv && (
							<TabPane tab="ALV" key="alv">
								<Checkbox
									name={'enableCustomAlvContactGegevens'}
									label="Aparte contact gegevens ALV"
									initialValue={enableCustomAlvContactGegevens ? enableCustomAlvContactGegevens : false}
								/>
								<Input
									name={'customAlvContactGegevensEmail'}
									label="Email"
									type="text"
									initialValue={customAlvContactGegevensEmail ? customAlvContactGegevensEmail : ''}
									disabled={!enableCustomAlvContactGegevensLocal}
								/>
								<Input
									name={'customAlvContactGegevensTel'}
									label="Telefoon nr."
									type="text"
									initialValue={customAlvContactGegevensTel ? customAlvContactGegevensTel : ''}
									disabled={!enableCustomAlvContactGegevensLocal}
								/>
							</TabPane>
						)}

						{enableVgmcomplex && (
							<TabPane tab="VGM" key="vgm">
								<Checkbox
									name={'enableCustomVgmcomplexContactGegevens'}
									label="Aparte contact gegevens VGM"
									initialValue={enableCustomVgmcomplexContactGegevens ? enableCustomVgmcomplexContactGegevens : false}
								/>
								<Input
									name={'customVgmcomplexContactGegevensEmail'}
									label="Email"
									type="text"
									initialValue={customVgmcomplexContactGegevensEmail ? customVgmcomplexContactGegevensEmail : ''}
									disabled={!enableCustomVgmcomplexContactGegevensLocal}
								/>
								<Input
									name={'customVgmcomplexContactGegevensTel'}
									label="Telefoon nr."
									type="text"
									initialValue={customVgmcomplexContactGegevensTel ? customVgmcomplexContactGegevensTel : ''}
									disabled={!enableCustomVgmcomplexContactGegevensLocal}
								/>
							</TabPane>
						)}
					</Tabs>
				</Form>
			</div>
		)
	}

	onValuesChange = (values) => {
		const newValues = _.pick(values, this.checkboxFields)
		this.setState(newValues)
	}
}
