import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import './WebEmployeeItem.css'

export default class WebEmployeeComponent extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		firstName: PropTypes.string,
		photoUrl: PropTypes.string,
		onDeleteClick: PropTypes.func.isRequired,
		onClick: PropTypes.func.isRequired,
	}

	state = { isHover: false }

	onMouseOver = () => {
		this.setState({ isHover: true })
	}
	onMouseLeave = () => {
		this.setState({ isHover: false })
	}

	onDeleteClick = (e) => {
		e.stopPropagation()

		const { id } = this.props
		this.props.onDeleteClick(id)
	}

	render() {
		const { isHover } = this.state
		const { onClick, firstName, photoUrl } = this.props
		const opacity = 1 // isDragging ? 0 : 1

		return (
			<div
				onClick={onClick}
				className="showemployee"
				style={{ opacity }}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
			>
				<img className="picture" src={photoUrl} alt="employee" onClick={this.showModal} />
				<p className="name">{firstName}</p>
				<span className={classNames('delete-employee', isHover ? 'visible' : '')}>
					<FontAwesome name="times" size="lg" className="icon delete-icon" onClick={this.onDeleteClick} />
				</span>
			</div>
		)
	}
}
