import React from 'react'
import PropTypes from 'prop-types'
import MenuList from './MenuList'
import MenuWizard from './MenuWizard'
import { addGuiderSteps } from '../../reducers/guiderSteps'
import { connect } from 'react-redux'

const mapDispatchToProps = (dispatch) => ({
	addSteps(steps) {
		dispatch(addGuiderSteps(steps))
	},
})

@connect(null, mapDispatchToProps)
class MenuBuilder extends React.Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			showMenuModal: false,
			parentId: null,
			selectedItem: null,
		}
	}

	componentDidMount() {
		setTimeout(() => {
			const steps = [
				{
					title: 'Menu',
					text: 'Voeg hier gemakkelijk en snel menu items toe. De menu items kunnen heel eenvoudig worden versleept naar een andere positie.\n',
					selector: '.menu-section',
					isFixed: true,
				},
			]
			this.props.addSteps(steps)
		}, 1000)
	}

	openCreateModal = (parentId) => {
		this.setState({
			showMenuModal: true,
			parentId: parentId,
			selectedItem: null,
		})
	}

	openUpdateModal = (item) => {
		this.setState({
			selectedItem: item,
			showMenuModal: true,
		})
	}

	closeMenuModal = () => {
		this.setState({ selectedItem: null, parentId: null, showMenuModal: false })
	}

	/**
	 * De graphql api geeft het menu als een standaard lijstje. Het menulist component gebruikt
	 * een nested structuur. Hier converteren we de resultaten van graphql naar het formaat
	 * van de MenuList.
	 *
	 * Als we ooit eens tijd hebben om het component aan te passen, kunnen we deze conversie code
	 * er tussenuit halen.
	 * @param items
	 */
	convertItems = (items) => {
		const orderedItems = [...items]
		//Sorteer array op order veld. Door mutaties kan de volgorde in de relay store afwijken.
		orderedItems.sort((a, b) => {
			return a.order - b.order
		})
		return this.buildNestedMenuList(orderedItems, null)
	}

	buildNestedMenuList = (items, parentId) => {
		const menu = []
		for (let item of items) {
			if (parentId !== item.parentId) {
				continue
			}
			let nestedItem = { ...item, children: this.buildNestedMenuList(items, item.id) }
			menu.push(nestedItem)
		}
		return menu
	}

	buildOrderMutationList = (orderList, items) => {
		items.forEach((item, index) => {
			orderList.push({ ...item, children: null })
			if (item.children !== null && item.children.length > -1) {
				this.buildOrderMutationList(orderList, item.children)
			}
		})
	}

	onItemsChange = (items) => {
		const orderList = []
		this.buildOrderMutationList(orderList, items)
		this.props.onItemsChange(orderList)
	}

	render() {
		const { showMenuModal, parentId, selectedItem } = this.state
		const { createItem, updateItem, deleteItem, items } = this.props
		const convertedItems = this.convertItems(items)
		return (
			<div>
				<MenuList
					itemsFromServer={convertedItems}
					onItemsChange={this.onItemsChange}
					deleteItem={deleteItem}
					openCreateModal={this.openCreateModal}
					openUpdateModal={this.openUpdateModal}
				/>
				<MenuWizard
					showMenuModal={showMenuModal}
					parentId={parentId}
					item={selectedItem}
					closeMenuModal={this.closeMenuModal}
					createMenuItem={createItem}
					updateMenuItem={updateItem}
				/>
			</div>
		)
	}
}

export default MenuBuilder
