/**
 * @flow
 * @relayHash 7c9776045ff3b8c6619b27a9524b315d
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type CreateCmsPageModalMutationVariables = {|
  input: {
    link: string;
    title: string;
    websiteId: string;
  };
|};
export type CreateCmsPageModalMutationResponse = {|
  +createCmsPage: ?{|
    +value: {|
      +id: string;
      +title: ?string;
      +link: ?string;
      +pageType: ?"BLOG" | "CMS" | "DEELDIENSTEN" | "DISCLAIMER" | "NEWS" | "PRODUCTIERECHTEN" | "SUBSCRIBER" | "WONENSUBSCRIBER" | "XML";
    |};
  |};
|};
*/


/*
mutation CreateCmsPageModalMutation(
  $input: CreateCmsPageInput!
) {
  createCmsPage(input: $input) {
    value {
      id
      title
      link
      pageType
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateCmsPageInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCmsPageModalMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateCmsPageInput!"
          }
        ],
        "concreteType": "CmsPagePayload",
        "name": "createCmsPage",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsPage",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "pageType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "CreateCmsPageModalMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateCmsPageInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "CreateCmsPageModalMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateCmsPageInput!"
          }
        ],
        "concreteType": "CmsPagePayload",
        "name": "createCmsPage",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsPage",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "pageType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation CreateCmsPageModalMutation(\n  $input: CreateCmsPageInput!\n) {\n  createCmsPage(input: $input) {\n    value {\n      id\n      title\n      link\n      pageType\n    }\n  }\n}\n"
};

module.exports = batch;
