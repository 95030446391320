import React, { Component } from 'react'
import { Tabs } from 'antd'
import { currentWebsiteProps } from '../../shared'
import SliderComponent from './SliderComponent'
import { connect } from 'react-redux'
import {
	BROCHURE_VARIANTS_PER_THEME,
	CMS_VARIANTS_PER_THEME,
	EMPLOYEE_VARIANTS_PER_THEME,
	OFFICE_VARIANTS_PER_THEME,
} from '../../../constants'
import { updateSettings } from '../../settings/settingsQueries'
import { ActionHeader } from '../../general'

const TabPane = Tabs.TabPane

@connect(currentWebsiteProps)
export default class VariantSection extends Component {
	render() {
		const { websiteSettings, websiteId } = this.props
		const { brokerSettings } = websiteSettings

		const theme = brokerSettings.websiteTheme

		if (theme === null) {
			return (
				<div className="posh-wrapper theme-wrapper joyride-pagina">
					<ActionHeader title="Pagina varianten" />
					<p>Selecteer eerst een thema.</p>
				</div>
			)
		}

		return (
			<div className="posh-wrapper theme-wrapper joyride-pagina">
				<ActionHeader title="Pagina varianten" />

				<Tabs defaultActiveKey={'Vestiging'}>
					<TabPane tab="Vestiging" key="Vestiging">
						<SliderComponent
							items={OFFICE_VARIANTS_PER_THEME[theme]}
							onChange={(value) => {
								updateSettings(
									[
										{
											name: 'vestigingPaginaNummer',
											value: value,
										},
									],
									websiteId,
								)
							}}
							currentItem={
								websiteSettings &&
								websiteSettings.brokerSettings &&
								websiteSettings.brokerSettings.vestigingPaginaNummer
							}
						/>
					</TabPane>

					<TabPane tab="CMS" key="CMS">
						<SliderComponent
							items={CMS_VARIANTS_PER_THEME[theme]}
							onChange={(value) => {
								updateSettings(
									[
										{
											name: 'cmsWeergaveNummer',
											value: value,
										},
									],
									websiteId,
								)
							}}
							currentItem={
								websiteSettings && websiteSettings.brokerSettings && websiteSettings.brokerSettings.cmsWeergaveNummer
							}
						/>
					</TabPane>

					<TabPane tab="Medewerker" key="Medewerker">
						<SliderComponent
							items={EMPLOYEE_VARIANTS_PER_THEME[theme]}
							onChange={(value) => {
								updateSettings(
									[
										{
											name: 'medewerkersPaginaNummer',
											value: value,
										},
									],
									websiteId,
								)
							}}
							currentItem={
								websiteSettings &&
								websiteSettings.brokerSettings &&
								websiteSettings.brokerSettings.medewerkersPaginaNummer
							}
						/>
					</TabPane>

					<TabPane tab="Brochure" key="Brochure">
						<SliderComponent
							items={BROCHURE_VARIANTS_PER_THEME[theme]}
							onChange={(value) => {
								updateSettings(
									[
										{
											name: 'brochureDetailType',
											value: value,
										},
									],
									websiteId,
								)
							}}
							currentItem={
								websiteSettings && websiteSettings.brokerSettings && websiteSettings.brokerSettings.brochureDetailType
							}
						/>
					</TabPane>
				</Tabs>
			</div>
		)
	}
}
