import React, { Component } from 'react'
import { ActionHeader } from '../general'
import { Button } from 'antd'
import './AdminSettings.css'
import { connect } from 'react-redux'
import { currentWebsiteProps, onMutationCompleted } from '../shared'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'

@connect(currentWebsiteProps)
export default class WebsiteInitPanel extends Component {
	initWebsite = () => {
		const { websiteId } = this.props
		const mutation = graphql`
			mutation WebsiteInitPanelMutation($input: WebsiteInitInput!) {
				initWebsite(input: $input) {
					id
				}
			}
		`

		const variables = {
			input: { websiteId: websiteId },
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted('Alle vereiste onderdelen zijn aangemaakt.'),
			onError: (err) => console.error(err),
		})
	}

	render() {
		return (
			<div className="theme-picker-container theme-wrapper init-website">
				<ActionHeader title="Initialiseer website" />
				<Button type="primary" onClick={this.initWebsite}>
					Initialiseer website
				</Button>
			</div>
		)
	}
}
