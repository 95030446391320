const initialState = {
	websiteSelectorId: null,
	websiteId: null,
}

const basePage = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CURRENT_WEBSITE_ID':
			return {
				...state,
				websiteSelectorId: action.websiteId,
				websiteName: action.websiteName,
			}
		case 'UPDATE_CURRENT_WEBSITE_SETTINGS':
			return {
				...state,
				websiteId: action.websiteId,
				websiteSettings: action.websiteSettings,
			}
		default:
			return state
	}
}

export { basePage }
