import { fetchQuery, graphql } from 'react-relay'
import Environment from '../../createRelayEnvironment'

async function isHelpdeskUser() {
	const helpdeskUserQuery = graphql`
		query viewerQueries_helpdeskUserQuery {
			viewer {
				id
				helpdeskUser
			}
		}
	`
	const result = await fetchQuery(Environment, helpdeskUserQuery)
	return result.viewer.helpdeskUser
}

export { isHelpdeskUser }
