import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './MenuWizard.css'
import CmsPageSelector from '../CmsPageSelector'
import DepartmentSelector from '../DepartmentSelector'

import { Checkbox, CheckboxGroup, Input, RadioGroup, Select, Form, Modal } from '../general'
import { Col, Row } from 'antd'

const linkTypes = [
	{ value: '', label: '', title: '' },
	{ value: 'aanbod', label: 'Aanbod', title: 'Aanbod' },
	{ value: 'sociaal', label: 'Sociaal', title: 'Sociaal' },
	{ value: 'vestiging', label: 'Kantoor informatie', title: 'Kantoor informatie' },
	{ value: 'inschrijvingen', label: 'Inschrijvingen', title: 'Inschrijvingen' },
	{ value: 'cms', label: 'CMS' },
	{ value: 'groep', label: 'Groep' },
	{ value: 'extern', label: 'Externe link' },
]
const aanbodTypes = [
	{ label: '', title: '', value: '', link: '' },
	{ label: 'Woningaanbod', title: 'Woningaanbod', value: 'wonen', link: '/aanbod/woningaanbod/' },
	{ label: 'Bedrijfsaanbod', title: 'Bedrijfsaanbod', value: 'bog', link: '/aanbod/bedrijfsaanbod/' },
	{ label: 'Agrarisch aanbod', title: 'Agrarisch aanbod', value: 'alv', link: '/aanbod/alvaanbod/' },
	{ label: 'Nieuwbouwaanbod', title: 'Nieuwbouwaanbod', value: 'nieuwbouw', link: '/aanbod/nieuwbouwaanbod/' },
	{ label: 'Vastgoedmanagement', title: 'Vastgoedmanagement', value: 'vgm', link: '/aanbod/vastgoedmanagement/' },
	{ label: 'Aangekocht', title: 'Aangekocht', value: 'aankoop', link: '/aangekocht/' },
]

const pageTypes = [
	{ label: 'Nieuws', value: '/nieuws/' },
	{ label: 'Blog', value: '/blog/' },
	{ label: 'Vestigingen', value: '/overons/vestigingen/' },
	{ label: 'Medewerkers', value: '/overons/medewerkers/' },
	{ label: 'Route', value: '/overons/route/' },
	{ label: 'Contact', value: '/overons/contact/' },
	{ label: 'Zoekopdracht', value: '/diensten/gratis_zoekopdracht/' },
	{ label: 'Zoekopdracht nieuwbouw', value: '/diensten/inschrijven_nieuwbouw/' },
	{ label: 'Zoekopdracht huurwoning', value: '/diensten/inschrijven_huurwoning/' },
]

const MenuWizardPanel = ({ children, tabId, selected }) => {
	if (tabId !== selected) {
		return null
	}
	return <div className="wizardpanel">{children}</div>
}

class MenuWizard extends Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.any.isRequired,
			title: PropTypes.string,
			link: PropTypes.string,
		}),
		showMenuModal: PropTypes.bool.isRequired,
		createMenuItem: PropTypes.func.isRequired,
		updateMenuItem: PropTypes.func.isRequired,
		closeMenuModal: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			selected: 'aanbod',
			baseurl: '',
			filters: {},
			aanbodSelected: '',
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const item = nextProps.item
		let selected = ''
		if (item === null) {
			selected = 'aanbod'
		} else {
			if (item.link === null || item.link === '') {
				selected = 'groep'
			}
		}
		this.setState({
			selected: selected,
		})
	}

	onLinkTypeChange = (e) => {
		if (e === 'groep') {
			this.form.setFieldsValue({
				link: '',
			})
		}
		this.setState({
			selected: e,
			//Clear aanbodfilters.
			filters: {},
			aanbodSelected: '',
		})

		if (e === 'groep') {
			this.form.setFieldsValue({
				link: '',
			})
		}
	}

	onAanbodChange = (e) => {
		const { aanbodSelected } = this.state
		let type = aanbodTypes.filter((type) => type.value === e)[0]

		//Reset the openhuis/koophuur fields if we switch between aanbod tabs.
		if (aanbodSelected !== '' && aanbodSelected !== type.value) {
			this.form.setFieldsValue({
				openhuis: [],
				koophuur: '',
			})
		}

		this.form.setFieldsValue({
			title: type.title,
			link: type.link,
		})

		this.setState({
			aanbodSelected: type.value,
			baseurl: type.link,
			//clear filters because they are different for the other og types.
			filters: {},
		})
	}

	buildLink = (filters) => {
		let url = this.state.baseurl
		for (let key of Object.keys(filters)) {
			url = url.concat(filters[key])
		}

		this.setState({
			filters: filters,
		})

		this.form.setFieldsValue({
			link: url,
		})
	}

	onFilterSelected = (e) => {
		let filters = { ...this.state.filters }
		if (e.target.checked) {
			filters[e.target.name] = e.target.value
		} else {
			filters[e.target.name] = ''
		}

		this.buildLink(filters)
	}

	onOpenHuisSelected = (checkedOptions) => {
		let filters = { ...this.state.filters }
		if (checkedOptions.length === 1) {
			filters['openhuis'] = checkedOptions[0]
		} else {
			filters['openhuis'] = ''
		}

		this.buildLink(filters)
	}

	onOptionSelected = (e) => {
		const pageType = pageTypes.filter((type) => type.value === e.target.value)[0]
		this.setState({
			baseurl: pageType.value,
		})

		this.form.setFieldsValue({
			title: pageType.label,
			link: pageType.value,
		})
	}

	onDepartmentSelected = (value) => {
		let filters = { ...this.state.filters }

		if (value === null || value === '') {
			filters['departments'] = ''
		} else {
			filters['departments'] = 'vestiging-' + value + '/'
		}

		this.buildLink(filters)
	}

	onPageSelected = (value) => {
		let title = ''
		let link = ''
		let baseurl = ''

		if (value !== null) {
			title = value.title
			link = value.link
			baseurl = value.link
		}

		this.setState({
			baseurl: baseurl,
		})
		this.form.setFieldsValue({
			title: title,
			link: link,
		})
	}

	onSubmit = (values) => {
		const { item, createMenuItem, updateMenuItem, closeMenuModal } = this.props
		if (item === undefined || item === null) {
			createMenuItem({ title: values.title, link: values.link, parentId: this.props.parentId })
		} else {
			const updateItem = { id: item.id, title: values.title, link: values.link, inactive: values.inactive }
			updateMenuItem(updateItem)
		}
		this.reset()
		closeMenuModal()
	}

	onCancel = () => {
		const { closeMenuModal } = this.props
		this.reset()
		closeMenuModal()
	}

	reset = () => {
		this.form.resetFields()
		this.setState({
			selected: 'aanbod',
			aanbodSelected: '',
			baseurl: '',
			filters: {},
		})
	}

	renderKoopHuurField = () => {
		return (
			<Col span={12}>
				<RadioGroup
					name="koophuur"
					initialValue=""
					onChange={this.onFilterSelected}
					label="Koop/Huur"
					options={[
						{ value: 'koop/', label: 'Koop' },
						{ value: 'huur/', label: 'Huur' },
						{ value: '', label: 'Beide' },
						{ value: 'archief/', label: 'Archief' },
					]}
				/>
			</Col>
		)
	}

	renderOpenhuisField = () => {
		return (
			<Col span={12}>
				<CheckboxGroup
					name="openhuis"
					onChange={this.onOpenHuisSelected}
					label="Openhuis"
					options={[{ value: 'open-huis/', label: 'Openhuis' }]}
				/>
			</Col>
		)
	}

	renderWizardOptions = () => {
		const { selected } = this.state
		return (
			<React.Fragment>
				<Select
					label="Soort pagina"
					name="soortpagina"
					initialValue={selected}
					options={linkTypes}
					onChange={this.onLinkTypeChange}
				/>

				<div className="wizardoptions">
					<MenuWizardPanel tabId="aanbod" selected={this.state.selected}>
						<Row>
							<Col>
								<Select name="ogsoort" onChange={this.onAanbodChange} label="OG Soort" options={aanbodTypes} />
							</Col>
						</Row>

						<MenuWizardPanel tabId="wonen" selected={this.state.aanbodSelected}>
							<Row gutter={24}>
								<Col span={12}>
									<label>Vestigingen</label>
									<DepartmentSelector
										onDepartmentSelected={this.onDepartmentSelected}
										includeAllDepartmentOption={true}
									/>
								</Col>
								{this.renderKoopHuurField()}
							</Row>
							<Row gutter={24}>
								<Col span={12}>
									<RadioGroup
										name="bouwvorm"
										initialValue=""
										onChange={this.onFilterSelected}
										label="Bouwvorm"
										options={[
											{ value: 'bestaande-bouw/', label: 'Bestaande bouw' },
											{ value: 'nieuwbouw/', label: 'Nieuwbouw' },
											{ value: '', label: 'Beide' },
										]}
									/>
								</Col>
								{this.renderOpenhuisField()}
							</Row>
						</MenuWizardPanel>
						<MenuWizardPanel tabId="bog" selected={this.state.aanbodSelected}>
							<Row gutter={24}>
								{this.renderKoopHuurField()}
								{this.renderOpenhuisField()}
							</Row>
						</MenuWizardPanel>
						<MenuWizardPanel tabId="alv" selected={this.state.aanbodSelected}>
							<Row gutter={24}>
								{this.renderKoopHuurField()}
								{this.renderOpenhuisField()}
							</Row>
						</MenuWizardPanel>
						<MenuWizardPanel tabId="nieuwbouw" selected={this.state.aanbodSelected}>
							<Row gutter={24}>
								<Col span={12}>{this.renderKoopHuurField()}</Col>
							</Row>
						</MenuWizardPanel>
						<MenuWizardPanel tabId="vgm" selected={this.state.aanbodSelected} />
						<MenuWizardPanel tabId="aankoop" selected={this.state.aanbodSelected} />
					</MenuWizardPanel>
					<MenuWizardPanel tabId="sociaal" selected={this.state.selected}>
						<Row gutter={24}>
							<Col span={12}>
								<RadioGroup
									name="social"
									initialValue=""
									onChange={this.onOptionSelected}
									label=""
									options={[
										{ value: '/nieuws/', label: 'Nieuws' },
										{ value: '/blog/', label: 'Blog' },
									]}
								/>
							</Col>
						</Row>
					</MenuWizardPanel>
					<MenuWizardPanel tabId="vestiging" selected={this.state.selected}>
						<Row gutter={24}>
							<Col span={24}>
								<RadioGroup
									name="vestiging"
									initialValue=""
									onChange={this.onOptionSelected}
									label=""
									options={[
										{ value: '/overons/vestigingen/', label: 'Vestigingen' },
										{ value: '/overons/medewerkers/', label: 'Medewerkers' },
										{ value: '/overons/route/', label: 'Route' },
										{ value: '/overons/contact/', label: 'Contact' },
									]}
								/>
							</Col>
						</Row>
					</MenuWizardPanel>
					<MenuWizardPanel tabId="inschrijvingen" selected={this.state.selected}>
						<Row gutter={24}>
							<Col span={12}>
								<RadioGroup
									name="inschrijvingen"
									initialValue=""
									onChange={this.onOptionSelected}
									label=""
									options={[
										{ value: '/diensten/gratis_zoekopdracht/', label: 'Zoekopdracht' },
										{ value: '/diensten/inschrijven_nieuwbouw/', label: 'Zoekopdracht nieuwbouw' },
										{ value: '/diensten/inschrijven_huurwoning/', label: 'Zoekopdracht huurwoning' },
									]}
								/>
							</Col>
						</Row>
					</MenuWizardPanel>
					<MenuWizardPanel tabId="cms" selected={this.state.selected}>
						<Row gutter={24}>
							<Col span={12}>
								<CmsPageSelector onPageSelected={this.onPageSelected} />
							</Col>
						</Row>
					</MenuWizardPanel>
				</div>
			</React.Fragment>
		)
	}

	render() {
		const { item, showMenuModal } = this.props

		const { selected } = this.state
		let linkDisabled = true
		if (selected === '' || selected === 'extern') {
			linkDisabled = false
		}

		return (
			<Modal onCancel={this.onCancel} visible={showMenuModal}>
				<div className="menuwizard">
					<div className="linkpanel">
						<Form
							ref={(c) => {
								this.form = c
							}}
							onSubmit={this.onSubmit}
						>
							{this.renderWizardOptions()}

							<Row gutter={24}>
								<Col span={12}>
									<Input label="Titel" name="title" initialValue={item ? item.title : ''} required={true} />
								</Col>

								{selected !== 'groep' && (
									<Col span={12}>
										<Input
											label="Link"
											name="link"
											initialValue={item ? item.link : ''}
											disabled={linkDisabled}
											required={true}
										/>
									</Col>
								)}
							</Row>

							{item && (
								<Row>
									<Col span={12}>
										<Checkbox name="inactive" label="Inactief" initialValue={item.inactive} />
									</Col>
								</Row>
							)}
						</Form>
					</div>
				</div>
			</Modal>
		)
	}
}

export default MenuWizard
