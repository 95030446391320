export default ({ settings }) => ({
	id: 'contactFormulier',
	title: 'Contactformulier',
	icon: 'envelope',
	description: 'Voor deze widget zijn geen instellingen nodig',
	size: {
		minWidth: 1,
		maxWidth: 3,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [],
})
