import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TITLE } from './regexps.js'

export default ({ settings }) => {
	const possibleOptionsBySetting = new Map()
	possibleOptionsBySetting.set('enableWonen', { value: 'wonen', label: 'Wonen' })
	possibleOptionsBySetting.set('enableAlv', { value: 'alv', label: 'ALV' })
	possibleOptionsBySetting.set('enableBog', { value: 'bog', label: 'BOG' })
	possibleOptionsBySetting.set('enableVgmComplex', { value: 'vgmcomplex', label: 'VGM' })

	const options = []
	for (let [key, value] of possibleOptionsBySetting.entries()) {
		const settingValue = settings[key]
		if (settingValue === true) {
			options.push(value)
		}
	}

	return {
		id: 'zoekformulierUitPreset',
		title: 'Zoekformulier',
		icon: 'search',
		size: {
			defaultWidth: 1,
			defaultHeight: 1,

			minWidth: 1,
			maxWidth: 3,

			minHeight: 1,
			maxHeight: 1,
		},
		isDisabled: false,
		fieldTypes: [
			{
				name: 'zoekformulierModule',
				label: 'Type',
				help: 'Naar welk objecttype huizen gezocht moeten worden.',
				placeholder: '...',
				type: FieldTypes.SELECT,
				rules: [
					{
						required: true,
						message: 'Maak een keuze',
						pattern: TITLE,
					},
				],
				defaultValue: options.length >= 1 ? options[0].value : null,
				options: options,
			},
		],
	}
}
