import React, { Component } from 'react'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../createRelayEnvironment'
import { Select } from 'antd'
import _ from 'lodash'

const Option = Select.Option

export default class extends Component {
	render() {
		const { onWebsiteSelected, onWebsitesLoaded } = this.props
		return (
			<QueryRenderer
				environment={environment}
				query={graphql`
					query WebsiteSelector_Query {
						viewer {
							Websites {
								...WebsiteSelector_items
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						console.log(error)
						return <div>Er is een fout opgetreden</div>
					} else if (props) {
						return (
							<WebsiteSelector
								style={this.props.style}
								items={props.viewer.Websites}
								onWebsiteSelected={onWebsiteSelected}
								onWebsitesLoaded={onWebsitesLoaded}
							/>
						)
					} else {
						return <div>Loading...</div>
					}
				}}
			/>
		)
	}
}

class WebsiteSelectorComponent extends React.Component {
	constructor(props) {
		super(props)

		const options = this.props.items.map((edge) => {
			return { value: edge.id, title: edge.name, label: edge.name, id: edge.id }
		})

		this.state = {
			options: options,
			selectedValue: options.length > 0 ? options[0].id : null,
		}
	}

	componentDidMount = () => {
		const { options, selectedValue: websiteId } = this.state
		const websiteOption = _.find(options, { value: websiteId })
		if (websiteOption) {
			this.props.onWebsiteSelected(websiteId, websiteOption.title)
		}
		this.props.onWebsitesLoaded(options)
	}

	onSelectChange = (id) => {
		const { options } = this.state

		this.setState({
			selectedValue: id,
		})

		const websiteName = _.find(options, { value: id }).title

		this.props.onWebsiteSelected(id, websiteName)
	}

	render() {
		const { selectedValue, options } = this.state
		const { style } = this.props

		return (
			<Select
				className="guider-website-selector"
				defaultValue={selectedValue}
				style={{ width: 250, ...style }}
				onChange={this.onSelectChange}
			>
				{options.map((item) => (
					<Option key={item.id} value={item.id}>
						{item.title}
					</Option>
				))}
			</Select>
		)
	}
}

const WebsiteSelector = createFragmentContainer(
	WebsiteSelectorComponent,
	graphql`
		fragment WebsiteSelector_items on Website @relay(plural: true) {
			id
			name
		}
	`,
)

WebsiteSelector.prototype = React.Component.prototype
