/**
 * @flow
 * @relayHash 22c6b28fc579f251c15d86fdfe310bdd
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type FilePickerQueryResponse = {|
  +viewer: {|
    +id: string;
    +CmsFiles: $ReadOnlyArray<{| |}>;
  |};
|};
*/


/*
query FilePickerQuery(
  $folder: String!
  $directoryId: Int
  $maxNumberOfResults: Int
) {
  viewer {
    id
    CmsFiles(folder: $folder, directoryId: $directoryId, maxNumberOfResults: $maxNumberOfResults) {
      ...FilePicker_items
      id
    }
  }
}

fragment FilePicker_items on CmsFile {
  id
  directoryId
  fileId
  previewUrl
  staticUrl
  name
  type
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "folder",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "directoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilePickerQuery",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "directoryId",
                "variableName": "directoryId",
                "type": "Int"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "maxNumberOfResults",
                "variableName": "maxNumberOfResults",
                "type": "Int"
              }
            ],
            "concreteType": "CmsFile",
            "name": "CmsFiles",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FilePicker_items",
                "args": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "FilePickerQuery",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "folder",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "directoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "FilePickerQuery",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "directoryId",
                "variableName": "directoryId",
                "type": "Int"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "maxNumberOfResults",
                "variableName": "maxNumberOfResults",
                "type": "Int"
              }
            ],
            "concreteType": "CmsFile",
            "name": "CmsFiles",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "type": "CmsFile",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "directoryId",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "fileId",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "previewUrl",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "staticUrl",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "type",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query FilePickerQuery(\n  $folder: String!\n  $directoryId: Int\n  $maxNumberOfResults: Int\n) {\n  viewer {\n    id\n    CmsFiles(folder: $folder, directoryId: $directoryId, maxNumberOfResults: $maxNumberOfResults) {\n      ...FilePicker_items\n      id\n    }\n  }\n}\n\nfragment FilePicker_items on CmsFile {\n  id\n  directoryId\n  fileId\n  previewUrl\n  staticUrl\n  name\n  type\n}\n"
};

module.exports = batch;
