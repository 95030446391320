import React from 'react'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import PropTypes from 'prop-types'
import './FilePicker.css'
import environment from '../../../createRelayEnvironment'
import { Dropzone, Form, Input, UploadButton } from '../../general'
import { DeleteOutlined, FilePdfOutlined, FolderOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Modal, Tooltip } from 'antd'
import classnames from 'classnames'
import { createDir, deleteFiles, uploadFiles } from './mutations'
import { makePreviewUrlWithWidthAndHeightParameters } from '../helper'

const ButtonGroup = Button.Group

const FILTER_TYPES = {
	ALL: 'all',
	IMAGES: 'images',
	DOCUMENTS: 'documents',
}

export default class FilePicker extends React.Component {
	static propTypes = {
		/**
		 * @params cmsFile: CmsFile
		 */
		onSelectFile: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		fullScreen: PropTypes.bool,
		accept: PropTypes.string,
		filter: PropTypes.shape({
			// query string for searching on filename
			q: PropTypes.string,
			// file type
			type: PropTypes.oneOf(Object.values(FILTER_TYPES)),
		}),
		isInIframe: PropTypes.bool,
	}

	static defaultProps = {
		fullScreen: false,
		accept: '*/*',
		filter: {
			q: '',
			type: FILTER_TYPES.ALL,
		},
	}

	state = {
		showModal: true,
		filter: Object.assign({}, FilePicker.defaultProps.filter, this.props.filter),
		dropzoneActive: false,
		currentDir: '',
		directoryId: null,
		showCreateFolderModal: false,
		selectedImage: null,
		windowHeight: window.innerHeight,
		maxNumberOfResults: 100,
	}

	componentDidMount() {
		const { fullScreen } = this.props

		if (fullScreen) {
			this.listenForWindowResize()
		}
	}

	componentWillUnmount() {
		const { fullScreen } = this.props

		if (fullScreen) {
			//TODO: fix this, doesn't seem tot work
			// window.removeEventListener('onresize', this._updateWindowHeight)
		}
	}

	listenForWindowResize() {
		window.addEventListener('resize', this._updateWindowHeight)
	}

	_updateWindowHeight = () => {
		console.log('resizing')
		this.setState({ windowHeight: window.innerHeight })
	}

	renderFilePicker = () => {
		const { filter, currentDir, directoryId, showCreateFolderModal, selectedImage, maxNumberOfResults } = this.state
		const { accept } = this.props

		let path = currentDir
		if (path.endsWith('/')) {
			path = path.substr(0, path.length - 2)
		}
		const parents = []
		const crumbs = [].concat(
			path.split('/').map((dirName) => {
				parents.push(dirName)

				const crumb = {
					label: dirName === '' ? 'Home' : dirName,
					path: parents.join('/'),
				}

				return crumb
			}),
		)
		return (
			<div className="cmsfilepicker-container">
				<Dropzone onDrop={this.uploadFiles}>
					<Form showSubmit={false} className={'topBar'}>
						<ButtonGroup>
							<UploadButton onFileChanged={this.onFileChanged} mutltiple={true} accept={accept} />
							<Button type="primary" icon={<PlusOutlined />} onClick={this.onCreateFolderClick}>
								Nieuwe map
							</Button>
						</ButtonGroup>

						<Input
							style={{ width: '200px', marginLeft: 30, alignSelf: 'center' }}
							placeholder="Zoeken..."
							name={'zoeken'}
							type="text"
							onChange={this.onFilterQueryChange}
						/>

						<label style={{ width: '150px', marginLeft: 40 }}>Max aantal resultaten</label>
						<Input
							style={{ width: '50px', alignSelf: 'center' }}
							placeholder="100"
							name={'aantal'}
							type="number"
							onChange={this.onMaxResultsChange}
							rules={[
								{
									pattern: '^([0-9]{1,3}|1000)$',
									message: 'Het getal moet tussen de 1 en 1000 zijn',
								},
							]}
						/>
					</Form>

					<Breadcrumb separator={<RightOutlined />} style={{ margin: 20 }}>
						{crumbs.map((crumb) => (
							<Breadcrumb.Item key={crumb.path}>
								<a onClick={() => this.selectFolder(crumb.path)}>{crumb.label}</a>
							</Breadcrumb.Item>
						))}
					</Breadcrumb>

					<br />
					<br />

					<QueryRenderer
						environment={environment}
						variables={{ folder: currentDir, directoryId: directoryId, maxNumberOfResults: maxNumberOfResults }}
						query={graphql`
							query FilePickerQuery($folder: String!, $directoryId: Int, $maxNumberOfResults: Int) {
								viewer {
									id
									CmsFiles(folder: $folder, directoryId: $directoryId, maxNumberOfResults: $maxNumberOfResults) {
										...FilePicker_items
									}
								}
							}
						`}
						render={({ error, props }) => {
							if (error) {
								return <div>Er is een error opgetreden</div>
							} else if (props) {
								// store the viewer id for later use to update the store after upload succeeds
								this.viewerId = props.viewer.id

								return (
									<ImageList
										items={props.viewer.CmsFiles}
										onSelectFile={this.onFileSelect}
										filter={filter}
										selectedImage={selectedImage}
										onDeleteFile={this.onDeleteFile}
									/>
								)
							} else {
								return <div>Laden...</div>
							}
						}}
					/>
				</Dropzone>

				<Modal visible={showCreateFolderModal} onCancel={() => this.setState({ showCreateFolderModal: false })}>
					<Form ref={(ref) => (this.createFolderForm = ref)} onSubmit={this.onCreateFolderSubmit}>
						<Input name={'name'} label={'Directoy naam'} />
					</Form>
				</Modal>
			</div>
		)
	}

	render() {
		const { windowHeight } = this.state
		const { fullScreen, isInIframe } = this.props

		if (isInIframe) {
			return this.renderFilePicker()
		}

		return (
			<div>
				<Modal
					title="Mijn bestanden"
					visible={fullScreen ? true : this.state.showModal}
					onOk={this.pickAndCloseEditor}
					onCancel={this.onCancel}
					width={fullScreen ? '100%' : '80%'}
					style={{
						top: fullScreen ? 0 : null /* let ant decide */,
						paddingBottom: 0,
					}}
					bodyStyle={{
						height: fullScreen ? windowHeight - 108 : '80%',
						overflowY: 'auto',
						padding: 0,
					}}
					maskClosable={!fullScreen}
				>
					{this.renderFilePicker()}
				</Modal>
			</div>
		)
	}

	onFilterQueryChange = (e) => {
		const { filter } = { ...this.state }
		filter.q = e.target.value

		this.setState({ filter })
	}

	onMaxResultsChange = (e) => {
		const maxResultsNumber = e.target.value

		if (maxResultsNumber % 1 !== 0) {
			return
		}
		if (maxResultsNumber === '') {
			this.setState({ maxNumberOfResults: 100 })
		} else {
			this.setState({ maxNumberOfResults: maxResultsNumber })
		}
	}

	onCreateFolderClick = () => {
		this.setState({ showCreateFolderModal: true })
	}

	onFileChanged = (e) => {
		this.uploadFiles(e.target.files)
	}

	uploadFiles = (files) => {
		const { currentDir, directoryId, maxNumberOfResults } = this.state
		uploadFiles(currentDir, directoryId, files, this.viewerId, maxNumberOfResults)
	}

	onDeleteFile = async (file) => {
		const { currentDir, directoryId, maxNumberOfResults } = this.state
		Modal.confirm({
			title: 'Wilt u dit bestand verwijderen?',
			content: "Dit bestand wordt voor u en uw collega's verwijderd en kan niet ongedaan worden gemaakt",

			okText: 'Verwijderen',
			okType: 'danger',
			onOk: async () => {
				await deleteFiles(currentDir, directoryId, [file.name], [file.fileId], this.viewerId, maxNumberOfResults)
			},

			cancelText: 'Annuleren',
		})
	}

	onCreateFolderSubmit = async (values) => {
		try {
			let parent = this.state.currentDir
			let parentDirectoryId = this.state.directoryId
			await createDir(parent, parentDirectoryId, values.name)
			//TODO: figure out how to open the newly created folder.
			this.setState({
				//currentDir: parent + '/' + values.name,
				showCreateFolderModal: false,
			})

			this.createFolderForm.resetFields()
		} catch (e) {
			console.error('Error while creating dir', e)
		}
	}

	onFileSelect = (cmsFile: CmsFile) => {
		if (cmsFile.type === 'FILE') {
			this.setState({ selectedImage: cmsFile }, this.pickAndCloseEditor)
			return
		}

		const { currentDir } = this.state
		let nextDir = null
		if (cmsFile.name === '..') {
			nextDir = currentDir.substr(0, currentDir.lastIndexOf('/'))
		} else {
			nextDir = currentDir + '/' + cmsFile.name
		}
		console.log(cmsFile)
		console.log(cmsFile.directoryId)
		this.selectFolder(nextDir, cmsFile.directoryId)
	}

	selectFolder(dir, directoryId) {
		this.setState({
			currentDir: dir,
			directoryId: directoryId,
		})
	}

	pickAndCloseEditor = () => {
		const { selectedImage } = this.state
		if (selectedImage) {
			this.props.onSelectFile(selectedImage)
		}
		this.onCancel()
	}

	onCancel = () => {
		this.setState({ showModal: false })
		setTimeout(() => {
			this.props.onCancel()
		}, 500)
	}
}

const ImageListComponent = ({ selectedImage, onSelectFile, items, filter: { q, type }, onDeleteFile }) => {
	let images = items
		.filter((image) => image.name.indexOf(q) > -1)
		.filter((image) => {
			if (image.type === 'FOLDER') {
				return true
			}

			switch (type) {
				case FILTER_TYPES.ALL:
					return true
				case FILTER_TYPES.IMAGES:
					return image.type === 'FILE' && /\.(jpg|jpeg|png|gif)$/i.test(image.name)
				case FILTER_TYPES.DOCUMENTS:
					return image.type === 'FILE' && (image.name.endsWith('.xls') || image.name.endsWith('.pdf'))

				default:
					console.error('Invalid filter type: ' + type)
					return true
			}
		})

	return (
		<div className="cms-thumbnail-container">
			{images.map((image) => (
				<Tooltip title={image.name} key={image.name}>
					{/*{image.type === 'FOLDER' && */}
					{/*<Icon name="folder" style={{ fontSize: 183 }} onClick={() => onSelectFile(image)} />*/}
					{/*}*/}
					<div
						key={image.previewUrl}
						onClick={() => onSelectFile(image)}
						className={classnames('thumbnail', {
							folder: image.type === 'FOLDER',
							file: image.type === 'FILE',
							selected: selectedImage && selectedImage.previewUrl === image.previewUrl,
						})}
					>
						{image.type === 'FILE' && (
							<div
								className={'preview'}
								style={{
									backgroundImage: `url(${makePreviewUrlWithWidthAndHeightParameters(image.previewUrl, 130, 130)})`,
								}}
							>
								{image.name.endsWith('.pdf') && <FilePdfOutlined className="pdf-icon" />}
								<DeleteOutlined
									className="delete"
									onClick={(e) => {
										e.stopPropagation()
										onDeleteFile(image)
									}}
								/>
							</div>
						)}
						{image.type === 'FOLDER' && <FolderOutlined className="folder" />}
						<div className="name">{image.name}</div>
					</div>
				</Tooltip>
			))}
		</div>
	)
}

const ImageList = createFragmentContainer(
	ImageListComponent,
	graphql`
		fragment FilePicker_items on CmsFile @relay(plural: true) {
			id
			directoryId
			fileId
			previewUrl
			staticUrl
			name
			type
		}
	`,
)
ImageList.prototype = React.Component.prototype
export { FILTER_TYPES }
