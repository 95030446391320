import React from 'react'
import { Input, RadioGroup, Select } from '../general'
import CKEditor from '../CKEditor'
import RealWrappedForm from '../general/form/form/Form'

const FieldTypes = {
	TEXT: 'TEXT',
	NUMBER: 'NUMBER',
	COLOR: 'COLOR',
	SELECT: 'SELECT',
	FUNCTION: 'FUNCTION',
	RADIO: 'RADIO',
	HTML: 'HTML',
}

const renderField = (field, values, form: RealWrappedForm) => {
	if (typeof field.component === 'function') {
		const Comp = field.component
		return <Comp parameters={values} form={form} />
	}

	switch (field.type) {
		case FieldTypes.TEXT:
			return <Input {...field} initialValue={values[field.name] || field.defaultValue} />

		case FieldTypes.SELECT:
			if (!field.options) {
				throw new Error('Options passed select field cannot be null or undefined, please check this')
			}

			return <Select {...field} initialValue={values[field.name] || field.defaultValue} />

		case FieldTypes.RADIO:
			return (
				<RadioGroup
					name={field.name}
					rules={field.rules}
					initialValue={values[field.name] || field.defaultValue}
					label={field.label}
					help={field.help}
					options={field.options}
				/>
			)

		case FieldTypes.HTML:
			return (
				<CKEditor
					id={'cmsblok' + Math.round(Math.random() * 10000)}
					name={field.name}
					initialValue={values[field.name] || field.defaultValue}
				/>
			)

		default:
			return <div style={{ color: 'red' }}>(unknown field: {field.type})</div>
	}
}

export { FieldTypes, renderField }
