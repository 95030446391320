import React from 'react'
import { ActionHeader, ActionLink } from '../../general'
import './ThemeSettingsPage.css'
import { Tabs } from 'antd'
import { HeaderPartnerLogoWidget } from '../../partnerlogo'
import { HeaderMenuWidget } from '../../menubuilder/index'
import HeaderBannerWidget from '../../headerbanner/HeaderBannerWidget'
import 'animate.css'
import { setGuiderSteps } from '../../../reducers/guiderSteps'
import { connect } from 'react-redux'
import { VideoBannerSettings } from '../../settings'
import { currentWebsiteProps } from './../../shared'

const TabPane = Tabs.TabPane

const mapDispatchToProps = (dispatch) => ({
	setSteps(steps) {
		dispatch(setGuiderSteps(steps))
	},
})

@connect(currentWebsiteProps, mapDispatchToProps)
export default class HeaderSection extends React.Component {
	// state = {
	// 	visible: false,
	// }
	// hide = () => {
	// 	this.setState({
	// 		visible: false,
	// 	})
	// }
	// handleVisibleChange = (visible) => {
	// 	this.setState({ visible })
	// }

	componentDidMount() {
		setTimeout(() => {
			const steps = [
				{
					title: 'Header instellingen',
					text: "Bewerk hier alle onderdelen die zich bevinden in de header zoals; het menu, de header banners en de partnerlogo's.",
					selector: '.header-section',
					isFixed: true,
				},
			]
			this.props.setSteps(steps)
		}, 800)
	}

	render() {
		const { websiteId } = this.props
		// const contentBannerHeader = (
		// 	<div>
		// 		<p>Information about the item</p>
		// 		<img
		// 			style={{ width: '400px', height: 'auto' }}
		// 			src="https://static.realworks.nl/cms/10000/preview-banner-header.png"
		// 			alt=""
		// 		/>
		// 		<br />
		// 		<hr />
		// 		<div>
		// 			<a onClick={this.hide}>Close</a>
		// 		</div>
		// 	</div>
		// )
		// const contentHeaderLogo = (
		// 	<div>
		// 		<p>Information about the item</p>
		// 		<img
		// 			style={{ width: '400px', height: 'auto' }}
		// 			src="https://static.realworks.nl/cms/10000/preview-header-logo.png"
		// 			alt=""
		// 		/>
		// 		<br />
		// 		<hr />
		// 		<div>
		// 			<a onClick={this.hide}>Close</a>
		// 		</div>
		// 	</div>
		// )
		// const contentpartnerLogo = (
		// 	<div>
		// 		<p>Information about the item</p>
		// 		<img
		// 			style={{ width: '400px', height: 'auto' }}
		// 			src="https://static.realworks.nl/cms/10000/preview-partner-logo.png"
		// 			alt=""
		// 		/>
		// 		<br />
		// 		<hr />
		// 		<div>
		// 			<a onClick={this.hide}>Close</a>
		// 		</div>
		// 	</div>
		// )
		return (
			<div className="theme-wrapper header-section">
				<ActionHeader title="Header">
					<ActionLink label="Annuleren" />
				</ActionHeader>
				<Tabs defaultActiveKey={'menu'}>
					<TabPane tab="Menu" key="menu" className="menu-section">
						<HeaderMenuWidget />
					</TabPane>
					<TabPane tab="Banners" key="banners">
						{/*<Popover*/}
						{/*placement="right"*/}
						{/*title="Banner info"*/}
						{/*trigger="click"*/}
						{/*content={contentBannerHeader}*/}
						{/*visible={this.state.visible}*/}
						{/*onVisibleChange={this.handleVisibleChange}*/}
						{/*>*/}
						{/*<Icon type="info-circle" style={{ fontSize: 18, color: '#1890ff', cursor: 'pointer' }} />*/}
						{/*</Popover>*/}
						<HeaderBannerWidget />
					</TabPane>
					<TabPane tab="Partner logo's" key="partnerLogo">
						{/*<Popover placement="right" title="Partner logo info" trigger="click" content={contentpartnerLogo} visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>*/}
						{/*<Icon type="info-circle" style={{ fontSize: 18, color: '#1890ff', cursor: "pointer" }}/>*/}
						{/*</Popover>*/}
						<HeaderPartnerLogoWidget />
					</TabPane>
					{/*<TabPane tab="Website logo" key="websiteLogo">*/}
					{/*<Popover placement="right" title="Header logo info" trigger="click" content={contentHeaderLogo} visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>*/}
					{/*<Icon type="info-circle" style={{ fontSize: 18, color: '#1890ff', cursor: "pointer" }}/>*/}
					{/*</Popover>*/}
					{/*<EntityImageEditor/>*/}
					{/*</TabPane>*/}
					<TabPane tab="Video banner" key="videoBanner">
						<VideoBannerSettings key={'videobannersettings' + websiteId} />
					</TabPane>
				</Tabs>
			</div>
		)
	}
}
