import React from 'react'
import { AdminSettings, RewriteRulesWidget, WebsiteInitPanel } from '../settings'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
	return {
		isHelpdeskUser: state.viewer.isHelpdeskUser,
	}
}

@connect(mapStateToProps)
export default class AdminPage extends React.Component {
	render() {
		const { isHelpdeskUser } = this.props
		if (!isHelpdeskUser) {
			return <div />
		}
		return (
			<div>
				<WebsiteInitPanel />
				<AdminSettings />
				<RewriteRulesWidget />
			</div>
		)
	}
}
