/**
 * @flow
 * @relayHash 47dc92550f3cfd0c68e3d35ae591662e
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type MaskSelect_getMask_QueryResponse = {|
  +viewer: {|
    +mask: ?$ReadOnlyArray<?{| |}>;
  |};
|};
*/


/*
query MaskSelect_getMask_Query(
  $entity: String!
  $field: String!
  $isUserMask: Boolean = false
) {
  viewer {
    mask(entity: $entity, field: $field, isUserMask: $isUserMask) {
      ...MaskSelect_options
    }
    id
  }
}

fragment MaskSelect_options on LabelValuePair {
  label
  value
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "entity",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "field",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "isUserMask",
        "type": "Boolean",
        "defaultValue": false
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MaskSelect_getMask_Query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "entity",
                "variableName": "entity",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "field",
                "variableName": "field",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "isUserMask",
                "variableName": "isUserMask",
                "type": "Boolean"
              }
            ],
            "concreteType": "LabelValuePair",
            "name": "mask",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "MaskSelect_options",
                "args": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "MaskSelect_getMask_Query",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "entity",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "field",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "isUserMask",
        "type": "Boolean",
        "defaultValue": false
      }
    ],
    "kind": "Root",
    "name": "MaskSelect_getMask_Query",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "entity",
                "variableName": "entity",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "field",
                "variableName": "field",
                "type": "String!"
              },
              {
                "kind": "Variable",
                "name": "isUserMask",
                "variableName": "isUserMask",
                "type": "Boolean"
              }
            ],
            "concreteType": "LabelValuePair",
            "name": "mask",
            "plural": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "LabelValuePair",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "value",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query MaskSelect_getMask_Query(\n  $entity: String!\n  $field: String!\n  $isUserMask: Boolean = false\n) {\n  viewer {\n    mask(entity: $entity, field: $field, isUserMask: $isUserMask) {\n      ...MaskSelect_options\n    }\n    id\n  }\n}\n\nfragment MaskSelect_options on LabelValuePair {\n  label\n  value\n}\n"
};

module.exports = batch;
