/**
 * @flow
 * @relayHash 06724be691079aad698e699b0f4e641d
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebsiteSelector_QueryResponse = {|
  +viewer: {|
    +Websites: $ReadOnlyArray<{| |}>;
  |};
|};
*/


/*
query WebsiteSelector_Query {
  viewer {
    Websites {
      ...WebsiteSelector_items
      id
    }
    id
  }
}

fragment WebsiteSelector_items on Website {
  id
  name
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebsiteSelector_Query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "Website",
            "name": "Websites",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "WebsiteSelector_items",
                "args": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebsiteSelector_Query",
  "query": {
    "argumentDefinitions": [],
    "kind": "Root",
    "name": "WebsiteSelector_Query",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "Website",
            "name": "Websites",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "type": "Website",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "name",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query WebsiteSelector_Query {\n  viewer {\n    Websites {\n      ...WebsiteSelector_items\n      id\n    }\n    id\n  }\n}\n\nfragment WebsiteSelector_items on Website {\n  id\n  name\n}\n"
};

module.exports = batch;
