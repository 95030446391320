/**
 * @flow
 * @relayHash 153d4fbb1d24aa1b6c77b42da3f1f789
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsUploadMutationVariables = {|
  directory: string;
  parentDirectoryId?: ?number;
  maxNumberOfResults?: ?number;
|};
export type mutationsUploadMutationResponse = {|
  +uploadFiles: ?{|
    +value: $ReadOnlyArray<?{|
      +id: string;
      +previewUrl: string;
      +name: string;
      +type: "FILE" | "FOLDER";
      +staticUrl: ?string;
      +directoryId: ?number;
      +fileId: ?string;
    |}>;
  |};
|};
*/


/*
mutation mutationsUploadMutation(
  $directory: String!
  $parentDirectoryId: Int
  $maxNumberOfResults: Int
) {
  uploadFiles(directory: $directory, parentDirectoryId: $parentDirectoryId, maxNumberOfResults: $maxNumberOfResults) {
    value {
      id
      previewUrl
      name
      type
      staticUrl
      directoryId
      fileId
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "directory",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsUploadMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "directory",
            "variableName": "directory",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "maxNumberOfResults",
            "variableName": "maxNumberOfResults",
            "type": "Int"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "uploadFiles",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "type",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "staticUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "directoryId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "fileId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsUploadMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "directory",
        "type": "String!",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "parentDirectoryId",
        "type": "Int",
        "defaultValue": null
      },
      {
        "kind": "LocalArgument",
        "name": "maxNumberOfResults",
        "type": "Int",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsUploadMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "directory",
            "variableName": "directory",
            "type": "String!"
          },
          {
            "kind": "Variable",
            "name": "maxNumberOfResults",
            "variableName": "maxNumberOfResults",
            "type": "Int"
          },
          {
            "kind": "Variable",
            "name": "parentDirectoryId",
            "variableName": "parentDirectoryId",
            "type": "Int"
          }
        ],
        "concreteType": "CmsFilePayload",
        "name": "uploadFiles",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsFile",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "type",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "staticUrl",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "directoryId",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "fileId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsUploadMutation(\n  $directory: String!\n  $parentDirectoryId: Int\n  $maxNumberOfResults: Int\n) {\n  uploadFiles(directory: $directory, parentDirectoryId: $parentDirectoryId, maxNumberOfResults: $maxNumberOfResults) {\n    value {\n      id\n      previewUrl\n      name\n      type\n      staticUrl\n      directoryId\n      fileId\n    }\n  }\n}\n"
};

module.exports = batch;
