/**
 * Reducer
 */

const initialState = {
	steps: [],
	addedSteps: [],
}
const guider = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_STEPS':
			return {
				...state,
				steps: state.steps.concat(action.steps),
				addedSteps: state.steps.concat(action.steps),
			}

		case 'SET_STEPS':
			return {
				...state,
				steps: action.steps,
			}

		default:
			return state
	}
}

const addGuiderSteps = (steps) => {
	return {
		type: 'ADD_STEPS',
		steps: steps,
	}
}

const setGuiderSteps = (steps) => {
	return {
		type: 'SET_STEPS',
		steps: steps,
	}
}

export { guider, addGuiderSteps, setGuiderSteps }
