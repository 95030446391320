import React from 'react'
import PropTypes from 'prop-types'
import './Thumbnail.css'

export default class Thumbnail extends React.Component {
	static propTypes = {
		image: PropTypes.string.isRequired,
		onThumbnailClick: PropTypes.func,
	}

	render() {
		let { image, onClick } = this.props
		let compStyles = {}

		//TODO: REMOVE THIS SHITTY CONSTRUCTION
		if (image.startsWith('/pub')) {
			image = 'http://www.realworks.nl' + image
		}

		let backgroundImage = `url(${image})`
		compStyles = { backgroundImage: backgroundImage }

		return <div style={{ ...compStyles }} className="thumbnail-container" onClick={onClick} />
	}
}
