import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WebEmployee from './WebEmployeeItem'
import './WebEmployeesWidget.css'
import SortableList from '../sortablelist/SortableList'
import { ItemTypes } from '../../constants'
import { Modal } from 'antd'
import WebEmployeeModal from './WebEmployeeModal'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import { deleteItem } from '../shared/mutations'
import { updateOrder } from './WebEmployeeQueries'
import _ from 'lodash'
import * as propTypes from '../types/propTypes'

@connect(currentWebsiteProps)
export default class WebEmployeesWidgetContainer extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	render() {
		const { websiteId } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query WebEmployeesWidget_employees_Query($id: ID!) {
						viewer {
							departmentOptions {
								label
								value
							}
							employeeOptions {
								label
								value
							}
						}
						node(id: $id) {
							... on Website {
								id
								webEmployees {
									...WebEmployeesWidget_webEmployees
								}
							}
						}
					}
				`}
				render={({ error, props }) => {
					if (error) {
						return <div>Er is een error opgetreden</div>
					} else if (props) {
						const { webEmployees } = props.node
						return (
							<WebEmployeesWidget
								websiteId={websiteId}
								webEmployees={webEmployees}
								departmentOptions={props.viewer.departmentOptions}
								employeeOptions={props.viewer.employeeOptions}
							/>
						)
					} else {
						return <div>Laden...</div>
					}
				}}
			/>
		)
	}
}

class EmployeesWidgetComponent extends Component {
	static propTypes = {
		webEmployees: PropTypes.array.isRequired,
		employeeOptions: propTypes.LABEL_VALUE_PAIR,
		departmentOptions: propTypes.LABEL_VALUE_PAIR,
		websiteId: PropTypes.string.isRequired,
	}

	state = {
		webEmployees: this.props.webEmployees,
		currentEmployee: null,
		showModal: false,
	}

	debouncedUpdateOrder = _.debounce(updateOrder, 1000)

	onDeleteClick = (id) => {
		const { websiteId } = this.props
		deleteItem(id, websiteId, 'webEmployees')
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.webEmployees !== this.props.webEmployees) {
			this.setState({ webEmployees: nextProps.webEmployees })
		}
	}

	render() {
		const { employeeOptions, departmentOptions } = this.props

		const { currentEmployee, showModal, webEmployees } = this.state

		return (
			<div className="employeeswidget clearfix">
				<SortableList
					items={webEmployees}
					onItemsReset={this.onItemsReset}
					onItemsChange={this.onItemsChange}
					renderChildren={this.renderEmployee}
					renderAddButton={this.renderAddButton}
					dropTargetType={ItemTypes.EMPLOYEE}
				/>

				{showModal && (
					<Modal
						width={900}
						title={currentEmployee ? currentEmployee.employee.firstName : 'Nieuw medewerker'}
						visible={showModal}
						onCancel={this.closeModal}
						footer={false}
					>
						<WebEmployeeModal
							key={currentEmployee && currentEmployee.id}
							id={currentEmployee && currentEmployee.id}
							onSave={this.closeModal}
							employeeOptions={employeeOptions}
							departmentOptions={departmentOptions}
						/>
					</Modal>
				)}
			</div>
		)
	}

	onItemsChange = (webEmployees) => {
		// optimistic update
		this.setState({ webEmployees })

		const orderIds = webEmployees.map((item, index) => {
			return {
				id: item.id,
				order: index,
			}
		})

		this.debouncedUpdateOrder(orderIds)
	}

	renderAddButton = () => {
		return (
			<div className="addemployee" onClick={this.showAddModal}>
				<span>+</span>
			</div>
		)
	}

	renderEmployee = (childProps, item) => {
		return (
			<WebEmployee
				photoUrl={item.employee.photoUrl}
				firstName={item.employee.firstName}
				key={item.id}
				id={item.id}
				onDeleteClick={this.onDeleteClick}
				onClick={() => this.showEmployee(item)}
			/>
		)
	}

	showEmployee = (item) => {
		this.setState({
			currentEmployee: item,
			showModal: true,
		})
	}

	showAddModal = () => {
		this.setState({
			showModal: true,
			currentEmployee: null,
		})
	}

	closeModal = () => {
		this.setState({ showModal: false })
	}
}

const WebEmployeesWidget = createFragmentContainer(
	EmployeesWidgetComponent,
	graphql`
		fragment WebEmployeesWidget_webEmployees on WebEmployee @relay(plural: true) {
			id
			employee {
				firstName
				photoUrl(width: 200, height: 200)
			}
		}
	`,
)

WebEmployeesWidget.prototype = React.Component.prototype
