import React from 'react'
import PropTypes from 'prop-types'
import NewsLetterList from './NewsLetterList'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../../createRelayEnvironment'
import { connect } from 'react-redux'
import {
	buildOrderMutationList,
	currentWebsiteProps,
	onMutationCompleted,
	removeDeletedIDReferenceFromStore,
} from '../../shared/index'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	updateItem = (item) => {
		const mutation = graphql`
			mutation NewsLetterWidgetUpdateMutation($input: UpdateNewsLetterItemInput!) {
				updateNewsLetterItem(input: $input) {
					value {
						id
						order
						subject
						inactive
						cmsContent {
							id
							title
							link
							content
							browserTitle
							browserDescription
							browserKeywords
							status
							publicationStartDate
							publicationEndDate
						}
					}
				}
			}
		`

		const variables = {
			input: {
				id: item.id,
				order: item.order,
				subject: item.subject,
				cmsContent: item.cmsContent,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	deleteItem = (item) => {
		const { websiteId } = this.props

		const mutation = graphql`
			mutation NewsLetterWidgetDeleteMutation($input: EntityIdInput!) {
				deleteNewsLetterItem(input: $input) {
					deletedId
				}
			}
		`

		const variables = {
			input: { id: item.id },
		}

		const configs = [
			{
				type: 'NODE_DELETE',
				deletedIDFieldName: 'deletedId',
			},
		]

		const updater = (store) => {
			removeDeletedIDReferenceFromStore(store, websiteId, 'deleteNewsLetterItem', 'deletedId', 'newsLetterItems')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			configs,
			updater,
		})
	}

	onItemsChange = (items) => {
		const updateList = buildOrderMutationList(items)

		const mutation = graphql`
			mutation NewsLetterWidgetOrderMutation($input: UpdateOrderInput!) {
				updateNewsLetterListOrder(input: $input) {
					value {
						id
						order
					}
				}
			}
		`

		const variables = {
			input: {
				items: updateList,
			},
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
		})
	}

	render() {
		const { errorMessage, websiteId } = this.props
		return (
			<div>
				<h3 className="margin-bottom-15">Nieuwsbrief</h3>
				<QueryRenderer
					environment={environment}
					variables={{
						id: websiteId,
					}}
					query={graphql`
						query NewsLetterWidget_Query($id: ID!) {
							node(id: $id) {
								... on Website {
									id
									newsLetterItems {
										...NewsLetterWidget_items
									}
								}
							}
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return <div>{errorMessage || 'Er is een error opgetreden'}</div>
						} else if (props) {
							return (
								<NewsLetterContainer
									items={props.node.newsLetterItems}
									onItemsChange={this.onItemsChange}
									createItem={this.createItem}
									updateItem={this.updateItem}
									deleteItem={this.deleteItem}
								/>
							)
						} else {
							return <div>Laden...</div>
						}
					}}
				/>
			</div>
		)
	}
}

const NewsLetterContainer = createFragmentContainer(
	NewsLetterList,
	graphql`
		fragment NewsLetterWidget_items on NewsLetterItem @relay(plural: true) {
			id
			order
			subject
			inactive
			cmsContent {
				id
				title
				link
				content
				browserTitle
				browserDescription
				browserKeywords
				status
				publicationStartDate
				publicationEndDate
			}
		}
	`,
)

NewsLetterContainer.prototype = React.Component.prototype
