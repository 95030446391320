import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Radio, Form } from 'antd'

const { Item: FormItem } = Form
const RadioGroup = Radio.Group

const RadioGroupComponent = ({ name, label, help, initialValue, options, rules, onChange }) => {
	return (
		<FormItem name={name} initialValue={initialValue} rules={rules} label={label} help={help}>
			<RadioGroup onChange={onChange}>
				{options.map((option) => (
					<Radio key={option.value} value={option.value}>
						{option.label}
					</Radio>
				))}
			</RadioGroup>
		</FormItem>
	)
}

RadioGroupComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	rules: PropTypes.array,
	onChange: PropTypes.func,
}

export default RadioGroupComponent

/**
 * Tbv storybook
 */
export { RadioGroupComponent }
