import {
	CloudOutlined,
	CopyOutlined,
	EditOutlined,
	FileOutlined,
	HomeOutlined,
	SearchOutlined,
	ShareAltOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../createRelayEnvironment'
import { setGuiderSteps } from '../reducers/guiderSteps'
import { Form, Select } from './general'
import './PageSelector.css'

export default class extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		selectedValue: PropTypes.object.isRequired, //TODO: make this not required
		onPageSelected: PropTypes.func.isRequired,
		onLoad: PropTypes.func.isRequired,
	}

	render() {
		const { selectedValue, onPageSelected, websiteId, onLoad } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query PageSelectorQuery($id: ID!) {
						node(id: $id) {
							... on Website {
								id
								pages {
									...PageSelector_items
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						console.error('Error', error)
						return <div>Er is een fout opgetreden. Probeer het later nogmaals.</div>
					} else if (props) {
						return (
							<PageSelector
								items={props.node.pages}
								selectedValue={selectedValue}
								onPageSelected={onPageSelected}
								onLoad={onLoad}
							/>
						)
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	setSteps(steps) {
		dispatch(setGuiderSteps(steps))
	},
})

@connect(null, mapDispatchToProps)
class PageSelectorComponent extends Component {
	static propTypes = {
		selectedValue: PropTypes.object.isRequired, //TODO: make this not required
		items: PropTypes.array.isRequired,
		onPageSelected: PropTypes.func,
		onLoad: PropTypes.func.isRequired,
	}

	icons = {
		DEELDIENSTEN: <ShareAltOutlined style={{ color: 'orange' }} />,
		NEWS: <CopyOutlined style={{ color: 'orange' }} />,
		XML: <EditOutlined style={{ color: 'orange' }} />,
		BLOG: <CopyOutlined style={{ color: 'orange' }} />,
		SUBSCRIBER: <SearchOutlined style={{ color: 'orange' }} />,
		WONENSUBSCRIBER: <SearchOutlined style={{ color: 'orange' }} />,
		DISCLAIMER: <FileOutlined style={{ color: 'orange' }} />,
		PRODUCTIERECHTEN: <FileOutlined style={{ color: 'orange' }} />,
	}

	constructor(props) {
		super(props)
		this.form = React.createRef()
	}

	onSelectChange = (value) => {
		let selectedOption = this.props.items.find((option) => {
			return option.link === value
		})
		this.props.onPageSelected(this.convertToOption(selectedOption))
	}

	componentDidMount() {
		this.considerOnLoad(this.props)

		this.props.setSteps([
			{
				title: "Pagina's",
				text: (
					<span>
						Beheer hier alle pagina's. Probeer eens te <b>zoeken</b> door te typen.
					</span>
				),
				selector: '.guider-page-selector',
				position: 'left',
				isFixed: true,
			},
		])
	}

	UNSAFE_componentWillReceiveProps(next) {
		if (next.selectedValue === this.props.selectedValue) {
			return
		}
		this.considerOnLoad(next)
	}

	considerOnLoad(props) {
		const { onLoad, selectedValue, items } = props

		const selectedPage = items.find((option) => {
			return option.link === selectedValue.value
		})

		this.form.current.setFieldsValue({
			cmsForm: selectedValue.value,
		})

		onLoad(this.convertToOption(selectedPage))
	}

	getGroupedOptions = () => {
		const { items } = this.props

		let algemeen = {
			label: 'Algemeen',
			options: [],
		}
		let cms = {
			label: 'CMS',
			options: [],
		}

		items.forEach((page) => {
			let parent
			if (page.pageType === 'CMS') {
				parent = cms
			} else {
				parent = algemeen
			}

			parent.options.push(this.convertToOption(page))
		})

		return [algemeen, cms]
	}

	convertToOption = (page) => {
		let iconComp

		if (page.pageType === 'XML' && page.link === '/') {
			iconComp = <HomeOutlined style={{ color: 'orange' }} />
		} else if (page.pageType === 'CMS') {
			iconComp = <CloudOutlined style={{ color: '#108ee9' }} />
		} else {
			iconComp = this.icons['' + page.pageType]
		}

		return {
			value: page.link,
			title: page.title,
			label: (
				<div style={{ marginLeft: 10 }}>
					<div>
						{iconComp}
						<span style={{ marginLeft: 10 }}>{page.title}</span>
						<span className="url">{page.link}</span>
					</div>
				</div>
			),
			id: page.id,
			pageType: page.pageType,
		}
	}

	render() {
		const { selectedValue } = this.props
		const options = this.getGroupedOptions()

		return (
			<div className="page-selector guider-page-selector">
				<Form showSubmit={false} ref={this.form}>
					<Select
						dropdownClassName="page-selector-ant-component"
						name="cmsForm"
						initialValue={selectedValue ? selectedValue.value : ''}
						onChange={this.onSelectChange}
						groupedOptions={options}
						size={60}
						filterOption={(input, option) => {
							if (option.label) {
								return false
							}
							const allOptions = options
								.map((group) => {
									return group.options
								})
								.flat()
							const currentOptionInAllOptions = allOptions.find((o) => {
								return o.value === option.value
							})

							if (!currentOptionInAllOptions) {
								return false
							}
							if (currentOptionInAllOptions.title && currentOptionInAllOptions.value) {
								return (
									currentOptionInAllOptions.title.toLowerCase().includes(input.toLowerCase()) ||
									currentOptionInAllOptions.value.toLowerCase().includes(input.toLowerCase())
								)
							} else if (currentOptionInAllOptions.title) {
								return currentOptionInAllOptions.title.toLowerCase().includes(input.toLowerCase())
							} else if (currentOptionInAllOptions.value) {
								return currentOptionInAllOptions.value.toLowerCase().includes(input.toLowerCase())
							}
						}}
					/>
				</Form>
			</div>
		)
	}
}

const PageSelector = createFragmentContainer(
	PageSelectorComponent,
	graphql`
		fragment PageSelector_items on Page @relay(plural: true) {
			id
			title
			link
			pageType
		}
	`,
)

PageSelector.prototype = React.Component.prototype
