import React from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import { ActionHeader, Checkbox, Form } from '../../general'
import EntityImageEditor from '../../websitelogo/EntityImageEditor'
import FaviconWidget from '../../favicon/FaviconWidget'
import { currentWebsiteProps } from '../../shared'
import { updateSettings } from '../../settings/settingsQueries'
import { Checkbox as Cb } from 'antd'
@connect(currentWebsiteProps)
export default class extends React.Component {
	onValuesChange = (values) => {
		const { websiteId } = this.props
		let dataToMutate = Object.entries(values).map(([key, value]) => {
			return {
				name: key,
				value: value,
			}
		})
		updateSettings(dataToMutate, websiteId)
	}
	render() {
		const { websiteId, websiteSettings } = this.props
		const { hideMainLogo } = websiteSettings.brokerSettings
		return (
			<div className="theme-wrapper">
				<ActionHeader title="Logo's" />

				<Tabs>
					<Tabs.TabPane tab={'Website logo'} key={'Logo'}>
						<Form key={'hideMainLogo' + websiteId} onValuesChange={this.onValuesChange} showSubmit={false}>
							<Checkbox initialValue={hideMainLogo} name={'hideMainLogo'} label="Verberg logo" />
						</Form>
						{!hideMainLogo && <EntityImageEditor settingName={'websiteLogo'} />}
					</Tabs.TabPane>
					<Tabs.TabPane tab={'Favicon'} key={'favicon'}>
						<FaviconWidget />
					</Tabs.TabPane>
				</Tabs>
			</div>
		)
	}
}
