import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Select as AntSelect, Form } from 'antd'

const { Item: FormItem } = Form
const { Option, OptGroup } = AntSelect

const Select = ({
	name,
	entity,
	col,
	isUserMask,
	label,
	help,
	initialValue,
	options,
	groupedOptions,
	rules,
	onChange,
	disabled,
	dropdownClassName,
	filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
	mode,
}) => {
	if (!name) {
		throw new Error("Specify 'name' property on Select component")
	}
	return (
		<FormItem name={name} rules={rules} initialValue={initialValue} label={label} help={help}>
			<AntSelect
				showSearch={true}
				mode={mode}
				onChange={onChange}
				disabled={disabled}
				optionFilterProp="children"
				filterOption={filterOption}
				dropdownClassName={dropdownClassName}
			>
				{options &&
					options.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				{groupedOptions &&
					groupedOptions.map((option, index) => {
						return (
							<OptGroup key={index} label={option.label}>
								{option.options.map((suboption) => (
									<Option key={suboption.value} value={suboption.value}>
										{suboption.label}
									</Option>
								))}
							</OptGroup>
						)
					})}
			</AntSelect>
		</FormItem>
	)
}

Select.propTypes = {
	name: PropTypes.string.isRequired,

	label: PropTypes.string,
	help: PropTypes.string,
	rules: PropTypes.array,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		}),
	),
	groupedOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.string.isRequired,
					label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
				}),
			),
		}),
	),
	initialValue: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	filterOption: PropTypes.func,
	dropdownClassName: PropTypes.string,
	mode: PropTypes.oneOf(['default', 'multiple', 'tags']),
}
Select.defaultProps = {
	isUserMask: false,
}

export default Select
export { Select }
