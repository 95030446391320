import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NestableMenuListItemHandle from './NestableMenuListItemHandle'
import NestableMenuList from './NestableMenuList'
import { DragSource, DropTarget } from 'react-dnd'
import { ItemTypes } from '../../constants'
import flow from 'lodash/flow'
import './NestableMenuListItem.css'

const source = {
	beginDrag(props) {
		return {
			id: props.id,
			parent: props.parent,
			children: props.item.children,
		}
	},

	endDrag(props, monitor) {
		console.log('end drop')
		props.confirmDragAction()
	},

	isDragging(props, monitor) {
		return props.id === monitor.getItem().id
	},
}

const target = {
	canDrop() {
		return false
	},

	hover(props, monitor) {
		const { id: draggedId } = monitor.getItem()
		const { id: overId, maxLevel } = props

		if (draggedId === overId || draggedId === props.parent) {
			return
		}
		if (!monitor.isOver({ shallow: true })) {
			return
		}

		if (props.parent != null) {
			var itemDepth = props.depthOf(monitor.getItem())
			//Als het te verslepen item al groter/even groot is dan maxLevel, dan weten we dat hem niet mogen droppen binnen
			//een parent (welke dan ook).
			if (itemDepth >= maxLevel) {
				return
			}

			var parentLevel = props.getLevelOfItem(props.parent)
			if (parentLevel + itemDepth > maxLevel) {
				return
			}
		}
		console.log('move item')
		props.move(draggedId, overId, props.parent)
	},
}

class NestableMenuListItem extends Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.any.isRequired,
		}),
	}

	renderChildren() {
		const { item, move, deleteItem, level, maxLevel, depthOf, getLevelOfItem, openCreateModal, openUpdateModal } =
			this.props

		let childLevel = level + 1
		if (childLevel > maxLevel) {
			return ''
		}

		return (
			<NestableMenuList
				items={item.children.filter((item) => item !== null)}
				parent={item.id}
				move={move}
				deleteItem={deleteItem}
				getLevelOfItem={getLevelOfItem}
				level={childLevel}
				depthOf={depthOf}
				openCreateModal={openCreateModal}
				openUpdateModal={openUpdateModal}
				maxLevel={maxLevel}
				confirmDragAction={this.props.confirmDragAction}
			/>
		)
	}

	render() {
		const { connectDropTarget, connectDragPreview, connectDragSource, item, isDragging, deleteItem, openUpdateModal } =
			this.props

		return connectDropTarget(
			connectDragPreview(
				connectDragSource(
					<li key={item.id} className={'nestable-list-item ' + (isDragging ? 'is-dragging' : '')}>
						<NestableMenuListItemHandle item={item} openUpdateModal={openUpdateModal} deleteItem={deleteItem} />
						{this.renderChildren()}
					</li>,
				),
			),
		)
	}
}

export default flow(
	DragSource(ItemTypes.MENUITEM, source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	})),
	DropTarget(ItemTypes.MENUITEM, target, (connect) => ({
		connectDropTarget: connect.dropTarget(),
	})),
)(NestableMenuListItem)
