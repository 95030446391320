import environment from '../../../createRelayEnvironment'
import { commitMutation, graphql } from 'react-relay'
import { onMutationCompleted } from '../../shared'

const createItem = (pageId, item) => {
	const mutation = graphql`
		mutation XMLPageCmsQueriesCreateMutation($input: CreateXMLPageCmsContentInput!) {
			createXMLPageCmsContent(input: $input) {
				value {
					id
					link
					title
					content
					teaserText

					status
					publicationStartDate
					publicationEndDate

					browserTitle
					browserDescription
					browserKeywords
					cmsForm
				}
			}
		}
	`

	const variables = {
		input: {
			pageId: pageId,
			...item,
		},
	}

	const updater = (store) => {
		const mutationRoot = store.getRootField('createXMLPageCmsContent')
		const cmsContent = mutationRoot.getLinkedRecord('value')
		const node = store.get(pageId)
		node.setLinkedRecord(cmsContent, 'content')
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		updater,
	})
}

const updateItem = (item) => {
	const mutation = graphql`
		mutation XMLPageCmsQueriesUpdateMutation($input: UpdateXMLPageCmsContentInput!) {
			updateXMLPageCmsContent(input: $input) {
				value {
					id
					link
					title
					content
					teaserText

					status
					publicationStartDate
					publicationEndDate

					browserTitle
					browserDescription
					browserKeywords
					cmsForm
				}
			}
		}
	`

	const variables = {
		input: {
			...item,
		},
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
	})
}

const createSubscriberPageLandingContent = (pageId, item) => {
	const mutation = graphql`
		mutation XMLPageCmsQueriesCreateLandingContentMutation($input: CreateXMLPageCmsContentInput!) {
			createSubscriberPageLandingContent(input: $input) {
				value {
					id
					link
					title
					content
					teaserText

					status
					publicationStartDate
					publicationEndDate

					browserTitle
					browserDescription
					browserKeywords
					cmsForm
				}
			}
		}
	`

	const variables = {
		input: {
			pageId: pageId,
			...item,
		},
	}

	const updater = (store) => {
		const mutationRoot = store.getRootField('createSubscriberPageLandingContent')
		const cmsContent = mutationRoot.getLinkedRecord('value')
		const node = store.get(pageId)
		node.setLinkedRecord(cmsContent, 'landingContent')
	}

	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onMutationCompleted(),
		onError: (err) => console.error(err),
		updater,
	})
}

export { createItem, updateItem, createSubscriberPageLandingContent }
