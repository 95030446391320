import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Input, Form } from 'antd'
import WithLayout from './WithLayout'
import withValidationRules from './WithValidationRules'

const { TextArea } = Input
const { Item: FormItem } = Form

const TextAreaComponent = ({
	name,
	label,
	help,
	initialValue,
	rules,
	placeholder,
	disabled,
	onChange,
	className,
	addonAfter,
	style,
}) => {
	return (
		<WithLayout
			render={({ layout }) => {
				const formItemLayout =
					layout === 'horizontal'
						? {
								labelCol: { span: 4 },
								wrapperCol: { span: 14 },
						  }
						: null

				return (
					<FormItem name={name} rules={rules} initialValue={initialValue} label={label} help={help} {...formItemLayout}>
						<TextArea
							style={style}
							className={className}
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
							addonAfter={addonAfter}
						/>
					</FormItem>
				)
			}}
		/>
	)
}

TextAreaComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.string,
	rules: PropTypes.array,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.object,
	addonAfter: PropTypes.object,
}

export default withValidationRules(TextAreaComponent)

/**
 * Tbv storybook
 */
export { TextAreaComponent }
