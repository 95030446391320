import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Dashboard.css'
import { setGuiderSteps } from '../../reducers/guiderSteps'

const mapDispatchToProps = (dispatch) => ({
	setSteps(steps) {
		dispatch(setGuiderSteps(steps))
	},
})

@connect(null, mapDispatchToProps)
export default class Dashboard extends Component {
	showGuider = () => {
		this.props.setSteps([
			{
				title: 'Websites',
				text: 'Selecteer hier de website om te beheren.',
				selector: '.guider-website-selector',
				spotlightClicks: true,
			},
			{
				title: 'Dashboard',
				text: 'Op het dashboard (de huidige pagina) zullen diverse updates en aanbevelingen worden getoond die relevant zijn voor de website.',
				selector: '[href="/"]',
				spotlightClicks: true,
			},
			{
				title: "Pagina's",
				text: "Hier zijn alle pagina's te beheren",
				selector: '[href="/pages/%2F"]',
				spotlightClicks: true,
				callback: (data, router) => router.push('/pages/' + encodeURIComponent('/')),
			},
		])
	}

	render() {
		return (
			<div className="dashboard-container">
				<span className="title">Welkom in het vernieuwde website beheer!</span>

				<br />
				<br />

				<ol className={'steps'}>
					<li>
						<span className="count">1</span>
						<span className={'intro-title'}>Selecteer rechtsboven de website die je wilt beheren</span>
					</li>
					<li>
						<span className="count">2</span>
						<span className={'intro-title'}>Onder het tabblad Pagina's beheer je de volgende zaken:</span>
						<ul className={'extra-steps'}>
							<li>alle pagina's die op je website getoond worden</li>
							<li>de homepage en bijbehorende widgets</li>
						</ul>
					</li>
					<li>
						<span className="count">3</span>
						<span className={'intro-title'}>Onder het tabblad Instellingen beheer je de volgende zaken:</span>
						<ul className={'extra-steps'}>
							<li>Welk website thema en kleuren</li>
							<li>Alle Logo's</li>
							<li>Menu structuur</li>
							<li>Header en video banners</li>
							<li>Footer</li>
							<li>Pagina varianten</li>
							<li>Medewerkers</li>
							<li>Aanbod pagina</li>
							<li>en overige instellingen</li>
						</ul>
					</li>
				</ol>

				<p className="subtitle">Bekijk ook onze FAQ's voor verder uitleg over deze onderdelen</p>

				{/*<Button onClick={this.showGuider} data-guider-id="start-tour" type="primary" icon={'caret-right'}>*/}
				{/*Start de tour*/}
				{/*</Button>*/}
			</div>
		)
	}
}
