import React from 'react'
import { BlogWidget } from '../../../list'

class Blog extends React.Component {
	render() {
		/* eslint-disable no-unreachable */
		return (
			<div>
				<p>Deze widget is gekoppeld aan uw blog pagina.</p>
				<BlogWidget />
			</div>
		)
	}
}

export default ({ settings }) => {
	return {
		id: 'blog',
		title: 'Blog',
		icon: 'pencil',
		size: {
			minWidth: 1,
			maxWidth: 3,
		},
		isDisabled: false,
		fieldTypes: [
			{
				component: (props) => <Blog />,
			},
		],
	}
}
