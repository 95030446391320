import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { Form, MaskSelect, Select, TextAreaComponent, TreeSelect } from '../general/'
import { createWebEmployee, updateWebEmployee } from './WebEmployeeQueries'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import CheckboxGroup from '../general/form/fieldtypes/CheckboxGroup'
import * as propTypes from '../types/propTypes'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import WebEmployeeModalCard from './WebEmployeeModalCard'

const PUBLISH_OPTIONS = [
	{ label: 'Foto', value: 'showPhoto' },
	{ label: 'Functieomschrijving', value: 'showFunction' },
	{ label: 'Email', value: 'showEmail' },
	{ label: 'Telefoon (werk)', value: 'showPhoneWork' },
	{ label: 'Telefoon (mobiel)', value: 'showPhone' },
]

@connect(currentWebsiteProps)
export default class WebEmployeeModal extends React.Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		websiteId: PropTypes.string.isRequired,
		departmentOptions: propTypes.LABEL_VALUE_PAIR,
		employeeOptions: propTypes.LABEL_VALUE_PAIR,
		onSave: PropTypes.func.isRequired,
	}

	render() {
		const { id, websiteId, onSave, employeeOptions, departmentOptions } = this.props

		const isNew = id === null
		if (isNew) {
			return (
				<FormEmployeeComponent
					onSave={onSave}
					webEmployee={null}
					websiteId={websiteId}
					employeeOptions={employeeOptions}
					departmentOptions={departmentOptions}
				/>
			)
		}

		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: id,
				}}
				query={graphql`
					query WebEmployeeModal_employees_Query($id: ID!) {
						node(id: $id) {
							... on WebEmployee {
								...WebEmployeeModal_webEmployee
							}
						}
					}
				`}
				render={({ error, props }) => {
					if (error) {
						return <div>Er is een error opgetreden</div>
					} else if (props) {
						return (
							<FormEmployeeComponent
								onSave={onSave}
								webEmployee={props.node}
								websiteId={websiteId}
								employeeOptions={employeeOptions}
								departmentOptions={departmentOptions}
							/>
						)
					} else {
						return <div>Laden...</div>
					}
				}}
			/>
		)
	}
}

const FormEmployeeComponent = createFragmentContainer(
	class extends Component {
		static propTypes = {
			websiteId: PropTypes.string.isRequired,
			onSave: PropTypes.func.isRequired,
			departmentOptions: propTypes.LABEL_VALUE_PAIR,
			employeeOptions: propTypes.LABEL_VALUE_PAIR,

			webEmployee: PropTypes.shape({
				id: PropTypes.string.isRequired,
				employee: PropTypes.shape({
					id: PropTypes.string.isRequired,
					title: PropTypes.string,
					initials: PropTypes.string,
					firstName: PropTypes.string,
					middleName: PropTypes.string,
					lastName: PropTypes.string,
					gender: PropTypes.string,
					functionDescription: PropTypes.string,
					email: PropTypes.string,
					mobilePhoneNr: PropTypes.string,
					workPhoneNr: PropTypes.string,
					photoUrl: PropTypes.string,
				}).isRequired,
				initialsOrNameFormat: PropTypes.number,
				group: PropTypes.string,
				internalText: PropTypes.string,
				office: PropTypes.number,
				showPhoto: PropTypes.bool.isRequired,
				showEmail: PropTypes.bool.isRequired,
				showFunction: PropTypes.bool.isRequired,
				showPhone: PropTypes.bool.isRequired,
				showPhoneWork: PropTypes.bool.isRequired,
				socialMediaChannelOptions: PropTypes.array.isRequired,
				socialMediaChannelSelections: PropTypes.array.isRequired,
			}),
		}

		state = {
			employeeId: this.props.webEmployee && this.props.webEmployee.employee.id,
		}

		render() {
			const { webEmployee, departmentOptions, employeeOptions } = this.props
			const { employeeId } = this.state

			const {
				initialsOrNameFormat,
				group,
				office,
				internalText,
				socialMediaChannelOptions,
				socialMediaChannelSelections,
			} = webEmployee || {}

			const selectedPublishOptions = webEmployee
				? PUBLISH_OPTIONS.map((item) => {
						return webEmployee[item.value] === true ? item.value : null
				  }).filter((item) => item !== null)
				: []

			return (
				<div className="formemployee">
					<Form onSubmit={this.onSubmit}>
						{/* vestiging */}
						<Row gutter={12} type="flex" align="middle" className="vestiging">
							<Col span={16}>
								<Select
									label={'Vestiging'}
									options={departmentOptions}
									name={'office'}
									initialValue={office && office + ''}
									rules={[
										{
											required: true,
											message: 'Selecteer een vestiging',
										},
									]}
								/>
							</Col>
						</Row>

						<Select
							name={'employeeId'}
							label={'Medewerker'}
							options={employeeOptions}
							initialValue={employeeId}
							rules={[
								{
									required: true,
									message: 'Selecteer een medewerker',
								},
							]}
							onChange={this.onEmployeeChange}
						/>

						{employeeId && <WebEmployeeModalCard employeeId={employeeId} />}

						<br />

						<MaskSelect
							label={'Groepering'}
							entity={'cms.medewerker'}
							col={'mwgroepering'}
							name={'group'}
							isUserMask={true}
							initialValue={group}
						/>

						<MaskSelect
							label={'Toon Voorletters/naam'}
							entity={'cms.medewerker'}
							col={'mwtoonvoorlroepn'}
							name={'initialsOrNameFormat'}
							initialValue={initialsOrNameFormat && initialsOrNameFormat + ''}
							rules={[{ required: true, message: 'Maak een keuze' }]}
						/>

						<CheckboxGroup
							label={'Vrijgave'}
							name={'options'}
							options={PUBLISH_OPTIONS}
							initialValue={selectedPublishOptions}
						/>

						<TreeSelect
							label={'Toon social media '}
							options={socialMediaChannelOptions}
							name={'socialMediaChannelSelections'}
							initialValue={socialMediaChannelSelections}
						/>
						<TextAreaComponent label={'Internet tekst'} rows={11} name={'internalText'} initialValue={internalText} />
					</Form>
				</div>
			)
		}

		onEmployeeChange = (value) => {
			this.setState({ employeeId: value })
		}

		onSubmit = async (values) => {
			const { onSave, websiteId, webEmployee } = this.props
			const { options, ...rest } = values

			// spread the array from the checkboxgroup to object values
			options.forEach((name) => {
				rest[name] = true
			})

			const isNew = webEmployee === null
			if (isNew) {
				await createWebEmployee(rest, websiteId)
			} else {
				await updateWebEmployee(webEmployee.id, rest)
			}

			onSave()
		}
	},
	graphql`
		fragment WebEmployeeModal_webEmployee on WebEmployee {
			id
			group
			initialsOrNameFormat
			internalText
			office
			showPhoto
			showEmail
			showFunction
			showPhone
			showPhoneWork
			socialMediaChannelOptions {
				label
				value
			}
			socialMediaChannelSelections
			employee {
				id
			}
		}
	`,
)

FormEmployeeComponent.prototype = React.Component.prototype
