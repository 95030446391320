/**
 * @flow
 * @relayHash d20ed5175196d04b97680cb7d1647aeb
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type HeaderPartnerLogoWidgetDeleteMutationVariables = {|
  input: {
    id: string;
  };
|};
export type HeaderPartnerLogoWidgetDeleteMutationResponse = {|
  +deleteHeaderPartnerLogo: ?{|
    +deletedId: string;
  |};
|};
*/


/*
mutation HeaderPartnerLogoWidgetDeleteMutation(
  $input: EntityIdInput!
) {
  deleteHeaderPartnerLogo(input: $input) {
    deletedId
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderPartnerLogoWidgetDeleteMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "EntityDeletedPayload",
        "name": "deleteHeaderPartnerLogo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "HeaderPartnerLogoWidgetDeleteMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "HeaderPartnerLogoWidgetDeleteMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "EntityDeletedPayload",
        "name": "deleteHeaderPartnerLogo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation HeaderPartnerLogoWidgetDeleteMutation(\n  $input: EntityIdInput!\n) {\n  deleteHeaderPartnerLogo(input: $input) {\n    deletedId\n  }\n}\n"
};

module.exports = batch;
