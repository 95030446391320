import { FieldTypes } from '../../../dnd/FieldRenderer'

export default ({ settings }) => ({
	id: 'nieuwsbriefInschrijfBlok',
	title: 'Inschrijven nieuwsbrief',
	icon: 'envelope',
	size: {
		minWidth: 1,
		maxWidth: 1,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			name: 'nieuwsbriefInschrijfBlokTitel',
			label: 'Titel',
			help: 'Titel die boven dit blok komt te staan',
			defaultValue: 'Inschrijven nieuwsbrief',
			type: FieldTypes.TEXT,
			rules: [{ required: true, message: 'Vul een waarde in' }],
		},
	],
})
