import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { TreeSelect as AntTreeSelect, Form } from 'antd'

const SHOW_PARENT = AntTreeSelect.SHOW_PARENT
const { Item: FormItem } = Form

class TreeSelect extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		label: PropTypes.string,
		help: PropTypes.string,
		initialValue: PropTypes.array,

		// see https://ant.design/components/tree-select/, the actual datastructure is different, but this datastructure
		// is consistent with Select and it works!:)
		options: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
				children: PropTypes.array,
			}),
		).isRequired,
	}

	state = {
		value: ['0-0-0'],
	}

	onChange = (value) => {
		console.log('onChange ', value)
		this.setState({ value })
	}

	render() {
		const { name, rules, initialValue, options, label, help } = this.props

		return (
			<FormItem name={name} initialValue={initialValue} rules={rules} label={label} help={help}>
				<AntTreeSelect
					treeData={options}
					onChange={this.onChange}
					treeCheckable={true}
					showCheckedStrategy={SHOW_PARENT}
					searchPlaceholder={'Please select'}
				/>
			</FormItem>
		)
	}
}

export default TreeSelect
