/**
 * @flow
 * @relayHash 939836755944e60b5a52c67b3a7f84c5
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type BlogWidgetOrderMutationVariables = {|
  input: {
    items: $ReadOnlyArray<{
      id: string;
      order: number;
    }>;
  };
|};
export type BlogWidgetOrderMutationResponse = {|
  +updateBlogListOrder: ?{|
    +value: $ReadOnlyArray<{|
      +id: string;
      +order: ?number;
    |}>;
  |};
|};
*/


/*
mutation BlogWidgetOrderMutation(
  $input: UpdateOrderInput!
) {
  updateBlogListOrder(input: $input) {
    value {
      id
      order
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogWidgetOrderMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateOrderInput!"
          }
        ],
        "concreteType": "CmsListOrderPayload",
        "name": "updateBlogListOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsListItem",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "BlogWidgetOrderMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "BlogWidgetOrderMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateOrderInput!"
          }
        ],
        "concreteType": "CmsListOrderPayload",
        "name": "updateBlogListOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "CmsListItem",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation BlogWidgetOrderMutation(\n  $input: UpdateOrderInput!\n) {\n  updateBlogListOrder(input: $input) {\n    value {\n      id\n      order\n    }\n  }\n}\n"
};

module.exports = batch;
