import { AppstoreOutlined, DashboardOutlined, SettingOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import './BaseNavigator.css'
import { withRouter } from '../helpers/general/WithRouterHoc'

const SubMenu = Menu.SubMenu

const MENU = [
	{ title: 'Dashboard', url: '/', icon: <DashboardOutlined /> },
	{ title: "Pagina's", url: '/pages/' + encodeURIComponent('/'), icon: <AppstoreOutlined /> },
	{ title: 'Instellingen', url: '/settings', icon: <SettingOutlined /> },
]

class BaseNavigator extends Component {
	static propTypes = {
		className: PropTypes.string,
		isHelpdeskUser: PropTypes.bool,
		router: PropTypes.object,
	}

	static defaultProps = {
		className: 'basenav',
	}

	state = {
		current: null,
	}

	componentDidMount() {
		this.setState({
			current: this.props.router.location.pathname,
		})
	}

	handleClick = (e) => {
		this.setState({ current: e.key })
	}

	render() {
		return (
			<Menu
				items={this.getMenu(MENU)}
				onClick={this.handleClick}
				selectedKeys={[this.state.current]}
				mode="horizontal"
			/>
		)
	}

	getMenu = (menu) => {
		const { isHelpdeskUser, router } = this.props

		const menuItems = menu.map((menuItem, index) => {
			let baseMenuItem = { key: menuItem.title }
			if (menuItem.icon) {
				baseMenuItem = { ...baseMenuItem, icon: menuItem.icon }
			}
			if (menuItem.url) {
				baseMenuItem = {
					...baseMenuItem,
					label: <NavLink to={menuItem.url}>{menuItem.title}</NavLink>,
				}
			} else {
				baseMenuItem = { ...baseMenuItem, label: menuItem.title }
			}
			if (menuItem.submenu) {
				baseMenuItem = { ...baseMenuItem, submenu: this.getMenu(menuItem.submenu) }
			}
			return baseMenuItem
		})

		if (isHelpdeskUser) {
			menuItems.push({
				label: (
					<NavLink className={'servicedesk'} to={'/admin'}>
						Admin instellingen
					</NavLink>
				),
				key: 'Admin instellingen',
			})
		}

		return menuItems
	}
}

BaseNavigator.contextTypes = {
	router: PropTypes.object,
	location: PropTypes.object,
}

export default withRouter(BaseNavigator)
