import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Card, Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'

export default class extends React.Component {
	static propTypes = {
		employeeId: PropTypes.string.isRequired,
	}

	render() {
		const { employeeId } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: employeeId,
				}}
				query={graphql`
					query WebEmployeeModalCard_getEmployee_Query($id: ID!) {
						node(id: $id) {
							... on Employee {
								...WebEmployeeModalCard_employee
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div>Error</div>
					} else if (props) {
						return <WebEmployeeModalCard employee={props.node} />
					} else if (done) {
						return <div>done</div>
					}
					return <div>?</div>
				}}
			/>
		)
	}
}
const WebEmployeeModalCardComponent = ({ employee }) => {
	return (
		<div style={{ background: '#ECECEC', padding: '20px' }}>
			<Card
				title={
					<Row>
						<Col span={20}>
							<img
								src={employee.photoUrl}
								style={{ borderRadius: '100%', marginRight: 10 }}
								width={40}
								height={40}
								alt={'employee'}
							/>
							{employee.title} {employee.initials} {employee.firstName} {employee.middleName} {employee.lastName}
						</Col>
						<Col>
							<a style={{ fontSize: 14 }} onClick={() => openEmployeWindow(employee.systemid)}>
								Open medewerker
							</a>
						</Col>
					</Row>
				}
				bordered={true}
				style={{ padding: 10 }}
			>
				<Col span={12}>
					{employee.email && (
						<div>
							<MailOutlined /> {employee.email}
						</div>
					)}
					{employee.workPhoneNr && (
						<div>
							<PhoneOutlined /> {employee.workPhoneNr}
						</div>
					)}
					{employee.mobilePhoneNr && (
						<div>
							<PhoneOutlined /> {employee.mobilePhoneNr}
						</div>
					)}
				</Col>
				<Col span={12}>
					{employee.functionDescription && (
						<div>
							<div>
								<b>Functieomschrijving:</b>
							</div>
							{employee.functionDescription}
						</div>
					)}
				</Col>
			</Card>
		</div>
	)
}

function openEmployeWindow(employeeSystemid) {
	const width = 1024
	const height = 640
	var left = window.innerWidth / 2 - width / 2
	var top = window.innerHeight / 2 - height / 2
	window.open(
		`${window.GLOBAL_CONSTANTS_CRM_PATH}/servlets/objects/rela.employee/modify?_systemid=${employeeSystemid}`,
		`rela_employee_modify_${employeeSystemid}`,
		`height=${height},width=${width},top=${top},left=${left}`,
	)
}

const WebEmployeeModalCard = createFragmentContainer(
	WebEmployeeModalCardComponent,
	graphql`
		fragment WebEmployeeModalCard_employee on Employee {
			id
			systemid
			title
			initials
			firstName
			middleName
			lastName
			gender
			functionDescription
			email
			mobilePhoneNr
			workPhoneNr
			photoUrl(width: 40, height: 40)
		}
	`,
)

WebEmployeeModalCard.prototype = React.Component.prototype
