import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../general'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../../../createRelayEnvironment'

const MaskSelect = (props) => {
	const { col, entity, isUserMask } = props

	return (
		<QueryRenderer
			environment={environment}
			variables={{
				entity,
				field: col,
				isUserMask,
			}}
			query={graphql`
				query MaskSelect_getMask_Query($entity: String!, $field: String!, $isUserMask: Boolean = false) {
					viewer {
						mask(entity: $entity, field: $field, isUserMask: $isUserMask) {
							...MaskSelect_options
						}
					}
				}
			`}
			render={({ error, props: graphqlProps }) => {
				if (error) {
					return <div>Er is een error opgetreden</div>
				} else if (graphqlProps) {
					return <DatabaseSelectComponent {...props} options={graphqlProps.viewer.mask} />
				} else {
					return <div>Laden...</div>
				}
			}}
		/>
	)
}

const MaskSelectComponent = (props) => {
	// don't pass the MaskSelect prop values to the Select component
	const { entity, col, isUserMask, ...selectProps } = props

	return <Select {...selectProps} />
}

MaskSelect.propTypes = {
	...Select.propTypes,
	entity: PropTypes.string.isRequired,
	col: PropTypes.string.isRequired,

	/** Indien gespecificeerd dan worden de opties uit de database gehaald */
	isUserMask: PropTypes.bool,
}
MaskSelect.defaultProps = {
	isUserMask: false,
}

const DatabaseSelectComponent = createFragmentContainer(
	MaskSelectComponent,
	graphql`
		fragment MaskSelect_options on LabelValuePair @relay(plural: true) {
			label
			value
		}
	`,
)

DatabaseSelectComponent.prototype = React.Component.prototype

export default MaskSelect
export { MaskSelect }
