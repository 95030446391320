import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import DashboardPage from './components/pages/DashboardPage'
import EditorPage from './components/pages/EditorPage'
import BasePage from './components/pages/BasePage'
import SettingsPage from './components/pages/SettingsPage'
import AdminPage from './components/pages/AdminPage'
import FilePickerPage from './components/pages/FilePickerPage'
import NotFound from './components/pages/NotFound'
import { setAuthorizationToken } from './createRelayEnvironment'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Alert, ConfigProvider } from 'antd'
import nlNL from 'antd/lib/locale-provider/nl_NL'
import moment from 'moment'
import 'moment/locale/nl'
import RenderErrorBoundary from './components/errors/RenderErrorBoundary'
import store from './store'
import { ThemeSettingsPage } from './components/pages/settings'

moment.locale('nl')

@DragDropContext(HTML5Backend)
export default class extends React.Component {
	state = {
		error: null,
		loaded: false,
	}

	async componentDidMount() {
		this.initToken()
	}

	async initToken() {
		let token = null
		if (process.env.REACT_APP_TOKEN) {
			token = process.env.REACT_APP_TOKEN
		} else if (window.GLOBAL_CONSTANTS_TOKENSERVER) {
			token = await this.fetchToken()
		} else {
			alert('Invalid token')
			return
		}

		setAuthorizationToken(token)

		this.setState({ loaded: true })
	}

	async fetchToken() {
		try {
			const response = await fetch(window.GLOBAL_CONSTANTS_TOKENSERVER, {
				credentials: 'include',
			})
			const token = await response.text()

			return token
		} catch (e) {
			console.error('Error while initializing token', e)
			this.setState({
				error: {
					title:
						'Fout opgetreden' +
						(process.env.NODE_ENV === 'development'
							? "  | the following message doesn't show in production: Are you logged in? "
							: ''),
					message: 'Er is iets fout gegaan. Probeer het nogmaals.',
				},
			})
		}
	}

	render() {
		const { error, loaded } = this.state

		if (error) {
			return <Alert banner={true} closable={false} description={error.message} message={error.title} type="error" />
		}

		if (loaded) {
			return <RouterConfig />
		}

		return <div>Laden...</div>
	}
}

const RouterConfig = () => (
	<RenderErrorBoundary>
		<ConfigProvider locale={nlNL}>
			<Provider store={store}>
				<BrowserRouter basename="">
					<Routes>
						<Route path="/components">
							<Route path="filepicker" element={<FilePickerPage />} />
						</Route>
						<Route path="/" element={<BasePage />}>
							<Route index element={<DashboardPage />} />
							<Route path="pages/:selectedPage" element={<EditorPage />} />
							<Route path="settings" element={<SettingsPage />} />
							<Route path="theme" element={<ThemeSettingsPage />} />
							<Route path="admin" element={<AdminPage />} />
							<Route path="*" element={<NotFound />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</Provider>
		</ConfigProvider>
	</RenderErrorBoundary>
)
