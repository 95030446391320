export const ItemTypes = {
	CARD: 'card',
	MENUITEM: 'NestableMenuListItem',
	HEADER_THUMBNAIL: 'header_thumbnail',
	NIEUWS_LIST_ITEM: 'NieuwsListItem',
	PARTNER_LOGO: 'PartnerLogo',
	BANNER_TABS_LIST: 'BannerTabsList',
	EMPLOYEE: 'Employee',
}

export const THEMES = [
	{
		label: 'Thema A',
		value: 'theme-a-new',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-a-new/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-a-new/preview-large.png',
	},
	{
		label: 'Thema B',
		value: 'theme-b',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-b/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-b/preview-large.png',
	},
	{
		label: 'Thema C',
		value: 'theme-c',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-c/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-c/preview-large.png',
	},
	{
		label: 'Thema D',
		value: 'theme-d',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-d/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-d/preview-large.png',
	},
	{
		label: 'Thema E',
		value: 'theme-e',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-e/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-e/preview-large.png',
	},
	{
		label: 'Thema F',
		value: 'theme-f',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-f/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-f/preview-large.png',
	},
	{
		label: 'Thema G',
		value: 'theme-g',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-g/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-g/preview-large.png',
	},
	{
		label: 'Thema H',
		value: 'theme-h',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-h/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-h/preview-large.png',
	},
	{
		label: 'Thema I',
		value: 'theme-i',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-i/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-i/preview-large.png',
	},
	{
		label: 'Thema J',
		value: 'theme-j',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-j/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-j/preview-large.png',
	},
	{
		label: 'Thema K',
		value: 'theme-k',
		urlThumbSmall: 'https://crm.realworks.nl/pub/img/settingslider/theme-k/preview.png',
		urlThumbLarge: 'https://crm.realworks.nl/pub/img/settingslider/theme-k/preview-large.png',
	},
]

//FOOTER_IMAGES_PER_THEME

const visibleTabs = [
	{},
	{ showLogo: true },
	{ showLogo: true, showText: true },
	{ showLogo: true, showText: true },
	{ showLogo: true },
	{ showText: true },
	{ showLogo: true },
	{ showLogo: true, showMenu: true },
	{ showLogo: true },
	{ showLogo: true },
]

function addForTheme(themeName) {
	const imageUrls = []
	for (let i = 0; i < 10; i++) {
		imageUrls.push({
			label: `Footer ${i + 1}`,
			value: `${i + 1}`,
			urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/footer/${i + 1}.png`,
			urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/footer/${i + 1}-large.png`,
			showText: visibleTabs[i].showText || false,
			showLogo: visibleTabs[i].showLogo || false,
			showMenu: visibleTabs[i].showMenu || false,
		})
	}

	return imageUrls
}

export const FOOTER_IMAGES_PER_THEME = {}

FOOTER_IMAGES_PER_THEME['theme-a-new'] = addForTheme('a-new')
FOOTER_IMAGES_PER_THEME['theme-b'] = addForTheme('b')
FOOTER_IMAGES_PER_THEME['theme-c'] = addForTheme('c')
FOOTER_IMAGES_PER_THEME['theme-d'] = addForTheme('d')
FOOTER_IMAGES_PER_THEME['theme-e'] = addForTheme('e')
FOOTER_IMAGES_PER_THEME['theme-f'] = addForTheme('f')
FOOTER_IMAGES_PER_THEME['theme-g'] = addForTheme('g')
FOOTER_IMAGES_PER_THEME['theme-h'] = addForTheme('h')
FOOTER_IMAGES_PER_THEME['theme-i'] = addForTheme('i')
FOOTER_IMAGES_PER_THEME['theme-j'] = addForTheme('j')
FOOTER_IMAGES_PER_THEME['theme-k'] = addForTheme('k')

// OFFICE_VARIANTS_PER_THEM

function addOfficeForTheme(themeName) {
	const arrayForTheme = []
	for (let i = 0; i < 3; i++) {
		arrayForTheme.push({
			label: `Vestiging ${i + 1}`,
			value: `${i + 1}`,
			urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/vestigingweergave/${i + 1}.png`,
			urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/vestigingweergave/${
				i + 1
			}-large.png`,
		})
	}
	return arrayForTheme
}

export const OFFICE_VARIANTS_PER_THEME = {}

OFFICE_VARIANTS_PER_THEME['theme-a-new'] = addOfficeForTheme('a-new')
OFFICE_VARIANTS_PER_THEME['theme-b'] = addOfficeForTheme('b')
OFFICE_VARIANTS_PER_THEME['theme-c'] = addOfficeForTheme('c')
OFFICE_VARIANTS_PER_THEME['theme-d'] = addOfficeForTheme('d')
OFFICE_VARIANTS_PER_THEME['theme-e'] = addOfficeForTheme('e')
OFFICE_VARIANTS_PER_THEME['theme-f'] = addOfficeForTheme('f')
OFFICE_VARIANTS_PER_THEME['theme-g'] = addOfficeForTheme('g')
OFFICE_VARIANTS_PER_THEME['theme-h'] = addOfficeForTheme('h')
OFFICE_VARIANTS_PER_THEME['theme-i'] = addOfficeForTheme('i')
OFFICE_VARIANTS_PER_THEME['theme-j'] = addOfficeForTheme('j')
OFFICE_VARIANTS_PER_THEME['theme-k'] = addOfficeForTheme('k')

// CMS_VARIANTS_PER_THEME

function addCmsForTheme(themeName) {
	const arrayForTheme = []
	for (let i = 0; i < 3; i++) {
		arrayForTheme.push({
			label: `CMS ${i + 1}`,
			value: `${i + 1}`,
			urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/cmsweergave/${i + 1}.png`,
			urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/cmsweergave/${i + 1}-large.png`,
		})
	}
	return arrayForTheme
}

export const CMS_VARIANTS_PER_THEME = {}

CMS_VARIANTS_PER_THEME['theme-a-new'] = addCmsForTheme('a-new')
CMS_VARIANTS_PER_THEME['theme-b'] = addCmsForTheme('b')
CMS_VARIANTS_PER_THEME['theme-c'] = addCmsForTheme('c')
CMS_VARIANTS_PER_THEME['theme-d'] = addCmsForTheme('d')
CMS_VARIANTS_PER_THEME['theme-e'] = addCmsForTheme('e')
CMS_VARIANTS_PER_THEME['theme-f'] = addCmsForTheme('f')
CMS_VARIANTS_PER_THEME['theme-g'] = addCmsForTheme('g')
CMS_VARIANTS_PER_THEME['theme-h'] = addCmsForTheme('h')
CMS_VARIANTS_PER_THEME['theme-i'] = addCmsForTheme('i')
CMS_VARIANTS_PER_THEME['theme-j'] = addCmsForTheme('j')
CMS_VARIANTS_PER_THEME['theme-k'] = addCmsForTheme('k')

// EMPLOYEE_VARIANTS_PER_THEME

function addEmployeeForTheme(themeName) {
	const arrayForTheme = []
	for (let i = 0; i < 4; i++) {
		arrayForTheme.push({
			label: `Medewerker ${i + 1}`,
			value: `${i + 1}`,
			urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/medewerkerweergave/${
				i + 1
			}.png`,
			urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/medewerkerweergave/${
				i + 1
			}-large.png`,
		})
	}
	return arrayForTheme
}

export const EMPLOYEE_VARIANTS_PER_THEME = {}

EMPLOYEE_VARIANTS_PER_THEME['theme-a-new'] = addEmployeeForTheme('a-new')
EMPLOYEE_VARIANTS_PER_THEME['theme-b'] = addEmployeeForTheme('b')
EMPLOYEE_VARIANTS_PER_THEME['theme-c'] = addEmployeeForTheme('c')
EMPLOYEE_VARIANTS_PER_THEME['theme-d'] = addEmployeeForTheme('d')
EMPLOYEE_VARIANTS_PER_THEME['theme-e'] = addEmployeeForTheme('e')
EMPLOYEE_VARIANTS_PER_THEME['theme-f'] = addEmployeeForTheme('f')
EMPLOYEE_VARIANTS_PER_THEME['theme-g'] = addEmployeeForTheme('g')
EMPLOYEE_VARIANTS_PER_THEME['theme-h'] = addEmployeeForTheme('h')
EMPLOYEE_VARIANTS_PER_THEME['theme-i'] = addEmployeeForTheme('i')
EMPLOYEE_VARIANTS_PER_THEME['theme-j'] = addEmployeeForTheme('j')
EMPLOYEE_VARIANTS_PER_THEME['theme-k'] = addEmployeeForTheme('k')

// BROCHURE_VARIANTS_PER_THEME

function addBrochureForTheme(themeName) {
	const arrayForTheme = []
	for (let i = 0; i < 2; i++) {
		if (i === 0) {
			arrayForTheme.push({
				label: `Download link`,
				value: `link`,
				urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/brochure/link.png`,
				urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/brochure/link-large.png`,
			})
		} else if (i === 1) {
			arrayForTheme.push({
				label: `Formulier`,
				value: `form`,
				urlThumbSmall: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/brochure/form.png`,
				urlThumbLarge: `https://crm.realworks.nl/pub/img/settingslider/theme-${themeName}/brochure/form-large.png`,
			})
		}
	}
	return arrayForTheme
}

export const BROCHURE_VARIANTS_PER_THEME = {}

BROCHURE_VARIANTS_PER_THEME['theme-a-new'] = addBrochureForTheme('a-new')
BROCHURE_VARIANTS_PER_THEME['theme-b'] = addBrochureForTheme('b')
BROCHURE_VARIANTS_PER_THEME['theme-c'] = addBrochureForTheme('c')
BROCHURE_VARIANTS_PER_THEME['theme-d'] = addBrochureForTheme('d')
BROCHURE_VARIANTS_PER_THEME['theme-e'] = addBrochureForTheme('e')
BROCHURE_VARIANTS_PER_THEME['theme-f'] = addBrochureForTheme('f')
BROCHURE_VARIANTS_PER_THEME['theme-g'] = addBrochureForTheme('g')
BROCHURE_VARIANTS_PER_THEME['theme-h'] = addBrochureForTheme('h')
BROCHURE_VARIANTS_PER_THEME['theme-i'] = addBrochureForTheme('i')
BROCHURE_VARIANTS_PER_THEME['theme-j'] = addBrochureForTheme('j')
BROCHURE_VARIANTS_PER_THEME['theme-k'] = addBrochureForTheme('k')

export const HEADER_BANNER_SIZES_PER_THEME = {
	'theme-a-new': { width: 922, height: 160 },
	'theme-b': { width: 922, height: 160 },
	'theme-c': { width: 922, height: 160 },
	'theme-d': { width: 922, height: 160 },
	'theme-e': { width: 900, height: 200 },
	'theme-f': { width: 2100, height: 550 },
	'theme-g': { width: 1920, height: 1080 },
	'theme-h': { width: 2200, height: 600 },
	'theme-i': { width: 2200, height: 520 },
	'theme-j': {},
	'theme-k': {},
}

export const NEWS_TYPE_RSS = '1'
export const NEWS_TYPE_NIEUWS = '2'
export const NEWS_TYPE_CMS = '3'
