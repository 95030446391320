/**
 * @flow
 * @relayHash 2ea392db1f9c22e3804881b3c6fbfd65
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type WebEmployeeModalCard_getEmployee_QueryResponse = {|
  +node: ?{| |};
|};
*/


/*
query WebEmployeeModalCard_getEmployee_Query(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Employee {
      ...WebEmployeeModalCard_employee
    }
    id
  }
}

fragment WebEmployeeModalCard_employee on Employee {
  id
  systemid
  title
  initials
  firstName
  middleName
  lastName
  gender
  functionDescription
  email
  mobilePhoneNr
  workPhoneNr
  photoUrl(width: 40, height: 40)
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebEmployeeModalCard_getEmployee_Query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Employee",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "WebEmployeeModalCard_employee",
                "args": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "WebEmployeeModalCard_getEmployee_Query",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "id",
        "type": "ID!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "WebEmployeeModalCard_getEmployee_Query",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id",
            "type": "ID!"
          }
        ],
        "concreteType": null,
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "type": "Employee",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "systemid",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "initials",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "firstName",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "middleName",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "lastName",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "gender",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "functionDescription",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "email",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "mobilePhoneNr",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "workPhoneNr",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "height",
                    "value": 40,
                    "type": "Int!"
                  },
                  {
                    "kind": "Literal",
                    "name": "width",
                    "value": 40,
                    "type": "Int!"
                  }
                ],
                "name": "photoUrl",
                "storageKey": "photoUrl{\"height\":40,\"width\":40}"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "query WebEmployeeModalCard_getEmployee_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Employee {\n      ...WebEmployeeModalCard_employee\n    }\n    id\n  }\n}\n\nfragment WebEmployeeModalCard_employee on Employee {\n  id\n  systemid\n  title\n  initials\n  firstName\n  middleName\n  lastName\n  gender\n  functionDescription\n  email\n  mobilePhoneNr\n  workPhoneNr\n  photoUrl(width: 40, height: 40)\n}\n"
};

module.exports = batch;
