import React from 'react'
import PropTypes from 'prop-types'
import { renderField } from './FieldRenderer'
import Form from '../general/form/form/Form'
import { propTypes } from '../pages/homepage/WidgetTypes'

export default class WidgetEditPanel extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		fields: propTypes.fieldTypes,
		values: PropTypes.object.isRequired,
		onValuesChange: PropTypes.func,
	}

	state = {
		// wait for the form to be mounted so we can pass the formRef to the fields (which might be used to update fieldsValue programatically
		formMounted: false,
	}

	componentDidMount() {
		this.setState({ formMounted: true })
	}

	render() {
		const { title, fields, values, description, onValuesChange } = this.props
		return (
			<Form ref={(ref) => (this.formRef = ref)} showSubmit={false} onValuesChange={onValuesChange}>
				<h1>{title}</h1>
				{description && <p>{description}</p>}

				<br />

				{this.state.formMounted === true &&
					fields.map((field, index) => {
						return <div key={field.name || index}>{renderField(field, values, this.formRef)}</div>
					})}
			</Form>
		)
	}
}
