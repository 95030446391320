import React, { Component } from 'react'
import { Form } from '../general'
import FilePicker from '../general/filepicker/FilePicker'
import * as PropTypes from 'prop-types'
import { updateSettings } from '../settings/settingsQueries'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import { Alert, Button, Popconfirm } from 'antd'

@connect(currentWebsiteProps)
export default class FaviconWidget extends Component {
	state = {
		showFilePicker: false,
	}

	render() {
		const { websiteSettings } = this.props
		const { showFilePicker } = this.state

		if (!websiteSettings) {
			return <div>Laden...</div>
		}

		const { faviconPath } = websiteSettings.brokerSettings

		return (
			<Form onSubmit={this.props.prop} showSubmit={false}>
				{showFilePicker && <FilePicker onSelectFile={this.onSelectFile} onCancel={this.onCancel} />}
				{!faviconPath && (
					<div>
						<Alert
							message={'U heeft nog geen favicon toegevoegd, upload deze voor herkenbaarheid van uw website'}
							type={'warning'}
						/>
						<br />
						<Button type={'primary'} onClick={this.onAddClick}>
							Favicon toevoegen
						</Button>
					</div>
				)}
				{faviconPath && (
					<div>
						<img
							src={faviconPath}
							style={{ maxWidth: 400, maxHeight: 400, display: 'block', marginBottom: 30 }}
							alt={'favicon'}
						/>
						<Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.onDeleteClick}>
							<Button type={'danger'}>Verwijderen</Button>
						</Popconfirm>
					</div>
				)}
			</Form>
		)
	}

	onDeleteClick = () => {
		const { websiteId } = this.props
		updateSettings([{ name: 'faviconPath', value: '' }], websiteId)
	}

	onAddClick = () => {
		this.setState({ showFilePicker: true })
	}
	onCancel = () => {
		this.setState({ showFilePicker: false })
	}

	onSelectFile = (cmsFile) => {
		const { websiteId } = this.props
		updateSettings([{ name: 'faviconPath', value: cmsFile.staticUrl }], websiteId)
	}
}

FaviconWidget.propTypes = {
	prop: PropTypes.func,
	onSelectFile: PropTypes.func,
	onCancel: PropTypes.func,
	brokerSettings: PropTypes.any,
}
