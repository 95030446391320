import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'
import CmsSubContentForm from './CmsSubContentForm'
import { deleteCmsSubContent, updateCmsSubContent } from './CmsSubContentHelper'
import { onMutationCompleted } from '../shared'

export default class extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		pageId: PropTypes.string.isRequired,
		setDirtyState: PropTypes.func.isRequired,
	}

	createItem = (item) => {
		const mutation = graphql`
			mutation CmsFormSubContentEditorCreateMutation($input: CreateCmsSubContentInput!) {
				createCmsFormSubContent(input: $input) {
					value {
						id
						title
						content
					}
				}
			}
		`

		const variables = {
			input: {
				title: item.title,
				content: item.content,
				pageId: this.props.pageId,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('createCmsFormSubContent')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const node = store.get(this.props.pageId)
			node.setLinkedRecord(valueItem, 'formSubContent')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	updateItem = (item) => {
		const { pageId } = this.props
		updateCmsSubContent(item, pageId, 'formSubContent')
	}
	deleteItem = (item) => {
		deleteCmsSubContent(item)
	}

	render() {
		return (
			<CmsSubContentForm
				item={this.props.item}
				createItem={this.createItem}
				updateItem={this.updateItem}
				deleteItem={this.deleteItem}
				setDirtyState={this.props.setDirtyState}
				label="Formulier tekst"
			/>
		)
	}
}
