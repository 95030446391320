import React from 'react'
import { ActionHeader, Form, Input, Select, SwitchButton } from '../../general'
import { Col, message, Row, Tabs } from 'antd'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { updateSettings } from '../../settings/settingsQueries'

const { TabPane } = Tabs

const FIELD_NAMES = {
	activeerMove: 'Toon Move.nl inlog banner',
	showOptaTerms: 'Toon cookie melding',
	enableOpgeslagenWoningen: 'Toon opgeslagen woningen widget',
	enableSitekick: 'Activeer Sitekick',
	enableSearcherForm: 'Toon Move zoekopdracht formulier',
	homepageTitle: 'Title',
	homepageDescription: 'Omschrijving',
	homepageKeywords: 'Trefwoorden',
	googleTranslate: 'Google translate',
	googleTranslateIncludedLanguages: 'Vertaal naar talen',
	googleMapsApiKeyMakelaar: 'Google maps api key',
	googleAnalyticsKey: 'Google Analytics',
	googleAnalytics4Key: 'Google Analytics 4',
	googleRemarketingKey: 'Google Remarketing',
	googletagmanagerKey: 'Google Tag Manager',
	googleSiteVerificationKey: 'Google Site Verification',
	enableMakelaarTemplateCaptcha: 'Recaptcha',
	recaptchaWebsiteKey: 'Recaptcha Sleutel',
	recaptchaSecret: 'Recaptcha geheim',
	facebookPixelKey: 'Facebook pixel key',
	bingSiteVerificationKey: 'Bing Site Verification',
	pageEffect: 'Pagina effect',
}

const mapStateToProps = (state) => ({
	websiteSelectorId: state.basePage.websiteSelectorId,
	websiteId: state.basePage.websiteId,
	websiteName: state.basePage.websiteName,
	websiteSettings: state.basePage.websiteSettings,
	isHelpdeskUser: state.viewer.isHelpdeskUser,
})

@connect(mapStateToProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		websiteSettings: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		const recaptchaEnabled = props.websiteSettings.brokerSettings.enableMakelaarTemplateCaptcha
		const showRecaptchaFields = recaptchaEnabled === 'true'
		this.state = {
			showRecaptchaFields: showRecaptchaFields,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const recaptchaEnabled = nextProps.websiteSettings.brokerSettings.enableMakelaarTemplateCaptcha
		const showRecaptchaFields = recaptchaEnabled === 'true'
		this.setState({
			showRecaptchaFields: showRecaptchaFields,
		})
	}

	onRecaptchaChange = (value) => {
		if (!value) {
			this.form.setFieldsValue({
				recaptchaWebsiteKey: '',
				recaptchaSecret: '',
			})
		}

		this.setState({
			showRecaptchaFields: value,
		})
	}

	onSubmit = (values) => {
		const { websiteId } = this.props
		let dataToMutate = Object.entries(values).map(([key, value]) => {
			return {
				name: key,
				value: value.toString(),
			}
		})
		console.log(dataToMutate)
		updateSettings(dataToMutate, websiteId)
	}

	onValidationError = (err) => {
		Object.keys(err).forEach((key) => {
			message.error(FIELD_NAMES[key] + ' : ' + err[key].errors[0].message)
		})
	}

	checkForIllegalAPIValues = (rule, value, callback) => {
		const illegalValuesArray = ['http', '<', '>', 'www.', ';']

		illegalValuesArray.forEach((illegalArrayItem) => {
			if (value.includes(illegalArrayItem)) {
				callback(`Dit veld mag de volgende waardes niet bevatten: ${illegalValuesArray.join(' ')} `)
			}
		})
		callback()
	}

	render() {
		const { websiteId, websiteSettings, isHelpdeskUser } = this.props
		console.log(websiteSettings)
		const { showRecaptchaFields } = this.state
		const brokerSettings = websiteSettings.brokerSettings
		const {
			googleTranslate,
			googleMapsApiKeyMakelaar,
			googleAnalyticsKey,
			googleAnalytics4Key,
			googleRemarketingKey,
			googletagmanagerKey,
			googleSiteVerificationKey,
			enableMakelaarTemplateCaptcha,
			facebookPixelKey,
			bingSiteVerificationKey,
			recaptchaWebsiteKey,
			recaptchaSecret,
			googleTranslateIncludedLanguages,
		} = brokerSettings
		return (
			<div className="theme-wrapper">
				<ActionHeader title="Algemeen" />
				<Form
					key={'generalSectionForm' + websiteId}
					onSubmit={this.onSubmit}
					onValidationError={this.onValidationError}
					ref={(c) => {
						this.form = c
					}}
				>
					<Tabs defaultActiveKey={'general'}>
						<TabPane tab="Divers" key="general" forceRender={true}>
							<GeneralSettings isHelpdeskUser={isHelpdeskUser} brokerSettings={brokerSettings} />
						</TabPane>

						<TabPane tab="SEO" key="seo" forceRender={true}>
							<SeoSettings brokerSettings={brokerSettings} />
						</TabPane>

						<TabPane tab="Social Media" key="social" forceRender={true}>
							<Tabs defaultActiveKey={'google'} tabPosition="left">
								<TabPane tab="Google" key="google" forceRender={true}>
									<Row gutter={8}>
										<Col span={8}>
											<Select
												name={'googleTranslate'}
												label="Google translate"
												options={[
													{ value: '', label: 'Geen' },
													{ value: 'dropdown', label: 'Dropdown' },
													{ value: 'flag', label: 'Vlaggen' },
												]}
												initialValue={googleTranslate ? googleTranslate : ''}
											/>
										</Col>
										<Col span={8}>
											<Input
												name={'googleTranslateIncludedLanguages'}
												label="Vertaal naar Talen"
												rules={[
													{
														pattern: '^([a-zA-Z]*,?)*$',
														message: 'Dit moet een komma gescheiden lijstje zijn van landcodes. Bijvoorbeeld: nl,en',
													},
												]}
												type="text"
												initialValue={googleTranslateIncludedLanguages ? googleTranslateIncludedLanguages : ''}
											/>
										</Col>
										<Col span={8} />
									</Row>

									<Input
										name={'googleMapsApiKeyMakelaar'}
										label="Google maps api key"
										type="text"
										initialValue={googleMapsApiKeyMakelaar ? googleMapsApiKeyMakelaar : ''}
										maxLength={50}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
									<Input
										name={'googleAnalyticsKey'}
										label="Google Analytics"
										type="text"
										initialValue={googleAnalyticsKey ? googleAnalyticsKey : ''}
										maxLength={30}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
									<Input
										name={'googleAnalytics4Key'}
										label="Google Analytics 4"
										type="text"
										initialValue={googleAnalytics4Key ? googleAnalytics4Key : ''}
										maxLength={30}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
									<Input
										name={'googleRemarketingKey'}
										label="Google Remarketing"
										type="text"
										initialValue={googleRemarketingKey ? googleRemarketingKey : ''}
										maxLength={30}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
									<Input
										name={'googletagmanagerKey'}
										label="Google Tag Manager"
										type="text"
										initialValue={googletagmanagerKey ? googletagmanagerKey : ''}
										maxLength={30}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
									<Input
										name={'googleSiteVerificationKey'}
										label="Google Site Verification"
										type="text"
										initialValue={googleSiteVerificationKey ? googleSiteVerificationKey : ''}
										maxLength={50}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>

									<Row gutter={8}>
										<Col span={8}>
											<SwitchButton
												name={'enableMakelaarTemplateCaptcha'}
												label={'Recaptcha'}
												onChange={this.onRecaptchaChange}
												initialValue={enableMakelaarTemplateCaptcha ? enableMakelaarTemplateCaptcha : 'false'}
											/>
										</Col>
										<Col span={8}>
											<Input
												name={'recaptchaWebsiteKey'}
												label="Sleutel"
												type="text"
												required={showRecaptchaFields}
												disabled={!showRecaptchaFields}
												initialValue={recaptchaWebsiteKey ? recaptchaWebsiteKey : ''}
												maxLength={60}
												rules={[
													{
														validator: this.checkForIllegalAPIValues,
													},
												]}
											/>
										</Col>
										<Col span={8}>
											<Input
												name={'recaptchaSecret'}
												label="Geheim"
												type="text"
												required={showRecaptchaFields}
												disabled={!showRecaptchaFields}
												initialValue={recaptchaSecret ? recaptchaSecret : ''}
												maxLength={60}
												rules={[
													{
														validator: this.checkForIllegalAPIValues,
													},
												]}
											/>
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Facebook" key="facebook" forceRender={true}>
									<Input
										name={'facebookPixelKey'}
										label="Facebook pixel key"
										type="text"
										initialValue={facebookPixelKey ? facebookPixelKey : ''}
										maxLength={40}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
								</TabPane>

								<TabPane tab="Microsoft" key="microsoft" forceRender={true}>
									<Input
										name={'bingSiteVerificationKey'}
										label="Bing Site Verification"
										type="text"
										initialValue={bingSiteVerificationKey ? bingSiteVerificationKey : ''}
										maxLength={40}
										rules={[
											{
												validator: this.checkForIllegalAPIValues,
											},
										]}
									/>
								</TabPane>
							</Tabs>
						</TabPane>

						<TabPane tab="Extra" key="extra" forceRender={true}>
							<Select
								name={'pageEffect'}
								label="Pagina effect"
								help="Een extra toevoeging voor bijzondere gelegenheden!"
								options={[
									{ value: '', label: 'Geen' },
									{ value: '1', label: 'Sneeuw' },
									{ value: '2', label: 'Veel sneew' },
									{ value: '3', label: 'Vuurwerk' },
								]}
								initialValue={brokerSettings.pageEffect ? brokerSettings.pageEffect : ''}
							/>
							{/*
								Santa removed because this causes Google errors. :'(
								Maybe we can try and fix this in the future.
								https://jira.realworks.nl/browse/BMW-3058
							*/}
							{/*<Select
								name={'mouseEffect'}
								label="Muis effect"
								options={[{ value: '', label: 'Geen' }, { value: '1', label: 'Kerstman' }]}
								initialValue={brokerSettings.mouseEffect ? brokerSettings.mouseEffect : ''}
							/>*/}
						</TabPane>
					</Tabs>
				</Form>
			</div>
		)
	}
}

const GeneralSettings = ({ isHelpdeskUser, brokerSettings }) => {
	const { activeerMove, showOptaTerms, enableOpgeslagenWoningen, enableSitekick, enableSearcherForm } = brokerSettings
	return (
		<div>
			<SwitchButton
				name={'activeerMove'}
				label="Toon Move.nl inlog banner"
				help="Hiermee kunnen klanten direct vanaf uw eigen website doorklikken naar move.nl. De bovenstaande instelling komt daarmee te vervallen."
				initialValue={'' + activeerMove}
			/>
			<SwitchButton
				name={'showOptaTerms'}
				label="Toon cookie melding"
				type="text"
				help="Toon een cookies melding, volgens overheidsstandaarden is dit verplicht."
				initialValue={showOptaTerms !== null ? showOptaTerms : 'false'}
				// TODO: currently retrieve 1 instead of true/false from the API (must Boolean)
			/>
			<SwitchButton
				name={'enableOpgeslagenWoningen'}
				label="Toon opgeslagen woningen widget"
				type="text"
				initialValue={enableOpgeslagenWoningen != null ? enableOpgeslagenWoningen : 'false'}
			/>
			<SwitchButton
				name={'enableSearcherForm'}
				label="Toon zoekopdracht formulier"
				type="text"
				help="Toon het zoekformulier op de website zodat gebruikers een zoekopdracht kunnen aanmaken."
				initialValue={enableSearcherForm != null ? enableSearcherForm : false}
			/>

			{isHelpdeskUser && (
				<div className={'servicedesk'} style={{ marginBottom: '10px' }}>
					<SwitchButton
						name={'enableSitekick'}
						label="Activeer Sitekick"
						type="text"
						initialValue={enableSitekick != null ? enableSitekick : 'false'}
					/>
				</div>
			)}
		</div>
	)
}

const SeoSettings = ({ brokerSettings }) => {
	const { homepageTitle, homepageDescription, homepageKeywords } = brokerSettings
	return (
		<div>
			<Input
				name={'homepageTitle'}
				label="Title"
				type="text"
				help="De title van uw homepage t.b.v. zoekmachines"
				initialValue={homepageTitle ? homepageTitle : ''}
			/>
			<Input
				name={'homepageDescription'}
				label="Omschrijving"
				type="text"
				help="De omschrijving van uw homepage t.b.v. zoekmachines"
				initialValue={homepageDescription ? homepageDescription : ''}
			/>
			<Input
				name={'homepageKeywords'}
				label="Trefwoorden"
				type="text"
				help="De trefwoorden voor uw homepage t.b.v. zoekmachines"
				initialValue={homepageKeywords ? homepageKeywords : ''}
			/>
		</div>
	)
}
