import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Input, Form } from 'antd'
import WithLayout from './WithLayout'
import withValidationRules from './WithValidationRules'

const { Item: FormItem } = Form

const InputComponent = ({
	name,
	label,
	help,
	initialValue,
	rules,
	placeholder,
	disabled,
	onChange,
	className,
	addonAfter,
	style,
	maxLength,
}) => {
	return (
		<WithLayout
			render={({ layout }) => {
				const formItemLayout =
					layout === 'horizontal'
						? {
								labelCol: { span: 4 },
								wrapperCol: { span: 14 },
						  }
						: null

				return (
					<FormItem name={name} rules={rules} initialValue={initialValue} label={label} help={help} {...formItemLayout}>
						<Input
							style={style}
							className={className}
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
							addonAfter={addonAfter}
							maxLength={maxLength}
						/>
					</FormItem>
				)
			}}
		/>
	)
}

InputComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.string,
	rules: PropTypes.array,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.object,
	addonAfter: PropTypes.object,
	maxLength: PropTypes.number,
}

export default withValidationRules(InputComponent)

/**
 * Tbv storybook
 */
export { InputComponent }
