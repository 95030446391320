import React from 'react'
import './PartnerLogoList.css'
import PartnerLogoWizard from './PartnerLogoWizard'
import SortableList from '../sortablelist/SortableList'
import Thumbnail from '../headerbanner/Thumbnail'
import FontAwesome from 'react-fontawesome'
import { ItemTypes } from '../../constants'
import { Form, Input, ImageEditor, FilePicker, FILTER_TYPES } from '../general'
import { cloneDeep } from 'lodash'
import { Button, Col, Row } from 'antd'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export default class PartnerLogoList extends React.Component {
	initialState = {
		isAddMode: false,
		showImagePicker: false,
		showPartnerWizard: false,
		showImageEditor: false,
		enableImageEditor: false,

		imagePath: '',
	}

	constructor(props) {
		super(props)

		this.state = {
			...this.initialState,
			items: this.props.items,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.state.items === nextProps.items) {
			return
		}

		this.setState({
			items: cloneDeep(nextProps.items),
		})
	}

	render() {
		const { isAddMode, showImagePicker, showPartnerWizard } = this.state
		const items = [...this.state.items]
		items.sort((a, b) => {
			return a.order - b.order
		})

		return (
			<div className="partnerlogo-list-container">
				<div className="partnerlogo-list">
					<SortableList
						items={items}
						onItemsReset={this.onItemsReset}
						onItemsChange={this.onItemsChange}
						renderChildren={this.renderChildren}
						renderAddButton={this.renderAddButton}
						dropTargetType={ItemTypes.PARTNER_LOGO}
					/>
				</div>
				<TransitionGroup>{isAddMode && this.renderAddPanel()}</TransitionGroup>

				{showImagePicker && (
					<FilePicker
						onCancel={this.closeImagePicker}
						onSelectFile={(url) => this.onImagePicked(url)}
						filter={{ q: '', type: FILTER_TYPES.IMAGES }}
					/>
				)}
				{showPartnerWizard && (
					<PartnerLogoWizard
						onClose={this.closePartnerWizard}
						onSelectFile={(url) => this.onPartnerLogoPicked(url)}
						filter={{ q: '', type: FILTER_TYPES.IMAGES }}
					/>
				)}
			</div>
		)
	}

	renderChildren = (childProps, item) => {
		const { isHover } = childProps
		return (
			<div>
				<Thumbnail image={item.imagePath} />
				{isHover && (
					<FontAwesome
						name="times"
						size="lg"
						className="icon delete-icon"
						onClick={(e) => this.onDeleteClick(e, item)}
					/>
				)}
			</div>
		)
	}

	renderAddButton = () => {
		return (
			<div className="plus" onClick={this.onAddClick}>
				<span>+</span>
			</div>
		)
	}

	onDeleteClick = (e, item) => {
		e.stopPropagation()
		this.props.deleteItem(item)
	}

	onItemsReset = (items) => {
		this.setState({ items: items })
	}

	onItemsChange = (items, dragActionFinished) => {
		if (items === this.state.items) {
			return
		}
		let clonedItems = cloneDeep(items)
		clonedItems.map((item, index) => {
			item.order = index + 1
			return item
		})
		if (dragActionFinished) {
			this.props.onItemsChange(items)
		}
		this.setState({ items: clonedItems })
	}

	onAddClick = () => {
		this.setState({ isAddMode: !this.state.isAddMode })
	}

	openImagePicker = () => {
		this.setState({ showImagePicker: true })
	}

	closeImagePicker = () => {
		this.setState({ showImagePicker: false })
	}

	onImagePicked = (cmsFile: CmsFile) => {
		this.setState({
			imagePath: cmsFile.staticUrl,
			enableImageEditor: true,
		})
	}

	onPartnerLogoPicked = (item) => {
		this.form.setFieldsValue({
			title: item.title,
			subtitle: item.subtitle,
			link: item.link,
		})
		this.setState({
			imagePath: item.imagePath,
			enableImageEditor: false,
		})
	}

	onSelectPartnerWizard = () => {
		this.setState({ showPartnerWizard: true })
	}

	closePartnerWizard = () => {
		this.setState({ showPartnerWizard: false })
	}

	onClosePanel = () => {
		this.setState({
			...this.initialState,
		})
	}

	onSubmit = (values) => {
		const { imagePath } = this.state
		const { title, subtitle, link } = values

		if (imagePath === '') {
			alert('Selecteer een afbeelding')
		} else {
			const item2 = {
				title: title,
				subtitle: subtitle,
				link: link,
				imagePath: imagePath,
			}

			this.props.createItem(item2)
			this.onClosePanel()
		}
	}

	onShowImageEditor = () => {
		//TODO: Enable this again when the imageslicer is working correctly.
		// const {enableImageEditor} = this.state;
		// if (!enableImageEditor) {
		// 	return;
		// }
		// this.setState({showImageEditor: true});
	}

	onCloseImageEditor = () => {
		this.setState({ showImageEditor: false })
	}

	onSaveImage = (url) => {
		this.setState({
			imagePath: url,
		})
		this.onCloseImageEditor()
	}

	renderIconButtons = () => {
		return (
			<Row className="wizardbuttons" type="flex" justify="space-around" align="middle">
				<Col span={6}>
					<Button type="primary" size={'large'} onClick={this.onSelectPartnerWizard}>
						Selecteer standaard icoon
					</Button>
				</Col>
				<Col span={6}>
					<Button size={'large'} onClick={this.openImagePicker}>
						Selecteer eigen bestand
					</Button>
				</Col>
			</Row>
		)
	}

	deleteIcon = (e) => {
		e.stopPropagation()
		this.setState({ imagePath: '', showImageEditor: false })
	}

	renderIconThumbnail = (imagePath) => {
		const { showImageEditor } = this.state
		return (
			<div>
				<div className="icon-thumbnail" onClick={(e) => this.onShowImageEditor()}>
					<Thumbnail image={imagePath} />
					<FontAwesome name="times" size="lg" className="icon delete-icon" onClick={this.deleteIcon} />
				</div>
				{showImageEditor && (
					<ImageEditor imageUrl={imagePath} onSave={this.onSaveImage} onCancel={this.onCloseImageEditor} />
				)}
			</div>
		)
	}

	renderAddPanel = () => {
		const { imagePath } = this.state

		return (
			<CSSTransition classNames="editpanel" timeout={{ enter: 800, exit: 500 }}>
				<div className="partnerlogo-panel">
					<h2 className="title">Icoon toevoegen </h2>

					<Form
						ref={(c) => {
							this.form = c
						}}
						onSubmit={this.onSubmit}
						showSubmit={false}
					>
						{imagePath === '' && this.renderIconButtons()}
						{imagePath !== '' && this.renderIconThumbnail(imagePath)}

						<Input required={true} name={'title'} label="Titel" type="text" />
						<Input name={'subtitle'} label="Ondertitel" type="text" />
						<Input
							required={true}
							name={'link'}
							label="Link"
							type="text"
							rules={[
								{
									pattern: '^(http:\\/|https:\\/)?\\/(.*)$',
									message: 'Link moet beginnen met http://, https:// of /',
								},
							]}
						/>

						<div className="button-row">
							<Button type="primary" htmlType="submit">
								Bewaar
							</Button>
							<Button type="button" onClick={this.onClosePanel}>
								Annuleren
							</Button>
						</div>
					</Form>
				</div>
			</CSSTransition>
		)
	}
}
