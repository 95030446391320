import React from 'react'
import PropTypes from 'prop-types'
import './CmsListItem.css'
import FontAwesome from 'react-fontawesome'
import { DatePicker, EditableImage, Form, Input, Select, SetInActive } from '../../general'
import { Button, Col, Divider, Modal, Row } from 'antd'
import moment from 'moment'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import CKEditor from '../../CKEditor'
import { cloneDeep } from 'lodash'

export default class NieuwsListItem extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		isDraggable: PropTypes.bool,
		isDragging: PropTypes.bool,
		enableEditMode: PropTypes.func,
		disableEditMode: PropTypes.func,
		removeItem: PropTypes.func.isRequired,
	}

	constructor(props) {
		//Als het een nieuw item betreft dan kan het component alleen in editMode beginnen. Bij de overige modes
		//is dat niet het geval, maar triggert isEditMode pas wanneer je op de editknop drukt.
		const isEditMode = props.mode === 'NEW' ? true : false
		super(props)
		this.state = {
			isHover: false,
			isEditMode: isEditMode,
			item: props.item,
			url: props.item.imagePath ? props.item.imagePath : null,
			blobUrl: null,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.item !== nextProps.item) {
			this.setState({
				item: nextProps.item,
				url: nextProps.item.imagePath ? nextProps.item.imagePath : null,
			})
		}
	}

	onMouseOver() {
		if (this.state.isEditMode) {
			return
		}
		this.setState({ isHover: true })
	}
	onMouseLeave() {
		if (this.state.isEditMode) {
			return
		}
		this.setState({ isHover: false })
	}

	onClosePanel = () => {
		this.setState({ isEditMode: false })
		this.props.disableEditMode()
	}

	onOpenPanel = () => {
		this.setState({ isEditMode: true })
		this.props.enableEditMode()
	}

	onDelete = (e) => {
		e.stopPropagation()
		const { removeItem, item } = this.props
		Modal.confirm({
			content: 'Hierbij worden de vermelding uit het overzicht en de achterliggende pagina verwijderd ',
			title: 'Let op!',
			okText: 'Verwijderen',
			okType: 'danger',
			cancelText: 'Annuleren',
			onOk: () => removeItem(item),
		})
	}

	onSubmit = (values) => {
		const { url } = this.state

		const item = cloneDeep(this.state.item)
		item.imagePath = url
		item.cmsContent.title = values.title
		item.cmsContent.link = values.link
		item.cmsContent.content = values.content
		item.cmsContent.teaserText = values.teaserText
		item.cmsContent.status = values.status
		item.cmsContent.publicationStartDate =
			values.publicationStartDate !== null ? values.publicationStartDate.format('YYYY-MM-DD') : null
		item.cmsContent.publicationEndDate =
			values.publicationEndDate !== null ? values.publicationEndDate.format('YYYY-MM-DD') : null

		item.cmsContent.browserTitle = values.browserTitle
		item.cmsContent.browserDescription = values.browserDescription
		item.cmsContent.browserKeywords = values.browserKeywords

		if (this.props.mode && this.props.mode === 'NEW') {
			this.props.createItem(item, this.onClosePanel)
		}
		if (this.props.mode && this.props.mode === 'EDIT') {
			this.props.editItem(item)
			this.onClosePanel()
		}
		return true
	}

	onCancel = () => {
		this.onClosePanel()
	}

	onImageChange = (url, blobUrl) => {
		this.setState({
			url: url,
			blobUrl: blobUrl,
		})
	}

	renderEditPanel = () => {
		const { item } = this.state
		return (
			<CSSTransition classNames="editpanel" timeout={{ exit: 300, enter: 500 }}>
				<div className="editpanel">
					<div className="editpanel-content">
						<Form
							ref={(c) => {
								this.form = c
							}}
							onSubmit={this.onSubmit}
							showSubmit={false}
							showCancel={false}
							onCancel={this.onCancel}
						>
							<div className="editpanel-news-wrap">
								<div className="editpanel-news-inner editpanel-news-image">
									<EditableImage
										imagePath={item.imagePath}
										thumbnailWidth={150}
										thumbnailHeight={150}
										onImageChange={this.onImageChange}
									/>
								</div>
								<div className="editpanel-news-inner">
									<Input label="Titel" name="title" initialValue={item.cmsContent.title} maxLength={250} />
									<Input label="Teaser tekst" name="teaserText" initialValue={item.cmsContent.teaserText} />
								</div>
							</div>

							<Divider type="horizontal" />

							{this.props.mode === 'NEW' && (
								<Input
									label="Link"
									name="link"
									initialValue={item.cmsContent.link}
									required={true}
									maxLength={95}
									rules={[
										{
											pattern: '^[!\\/][\\/[a-zA-Z\\d-_]*\\/]*$',
											message: 'De link mag geen spaties bevatten en moet beginnen en eindigen met een /',
										},
									]}
								/>
							)}
							{this.props.mode === 'EDIT' && (
								<Input
									label="Link"
									name="link"
									initialValue={item.cmsContent.link}
									disabled={true}
									required={true}
									help="Het wijzigen van een link is op dit moment niet mogelijk."
								/>
							)}

							<Input
								label="Browser Titel"
								name="browserTitle"
								initialValue={item.cmsContent.browserTitle}
								maxLength={250}
							/>
							<Input
								label="Browser Omschrijving"
								name="browserDescription"
								maxLength={250}
								initialValue={item.cmsContent.browserDescription}
							/>
							<Input
								label="Browser Trefwoorden"
								name="browserKeywords"
								initialValue={item.browserKeywords}
								disabled={item.cmsContent.browserKeywords === null || item.cmsContent.browserKeywords.length === 0}
								help="Trefwoorden worden niet meer gebruikt door Google voor het indexeren van de pagina. Reeds ingevoerde trefwoorden kunnen nog gewijzigd worden, voor nieuwe pagina's is dit niet meer mogelijk."
								maxLength={250}
							/>
							<Row gutter={8}>
								<Col span={8}>
									<Select
										label="Status"
										name="status"
										initialValue={item.cmsContent.status === null ? item.status : 'FINAL'}
										rules={[{ required: true }]}
										options={[
											{ label: 'Concept', value: 'CONCEPT' },
											{ label: 'Definitief', value: 'FINAL' },
										]}
									/>
								</Col>
								<Col span={8}>
									<DatePicker
										label="Start Date"
										name="publicationStartDate"
										initialValue={
											item.cmsContent.publicationStartDate
												? moment(item.cmsContent.publicationStartDate, 'YYYY-MM-DD')
												: null
										}
										placeholder="Publicatie vanaf"
										required={true}
									/>
								</Col>
								<Col span={8}>
									<DatePicker
										label="End Date"
										name="publicationEndDate"
										initialValue={
											item.cmsContent.publicationEndDate
												? moment(item.cmsContent.publicationEndDate, 'YYYY-MM-DD')
												: null
										}
										placeholder="Publicatie tot"
									/>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={24}>
									<CKEditor
										id={item !== null && item.id !== null ? 'cmsList' + item.id : 'cmsList-new'}
										name="content"
										initialValue={item.cmsContent.content || ''}
									/>
								</Col>
							</Row>
							<Row gutter={12}>
								<Col span={5}>
									<Button
										type="primary"
										onClick={() => {
											this.form.submit()
										}}
									>
										Bewaar
									</Button>
								</Col>
								<Col span={5} offset={1}>
									<Button
										type="secondary"
										onClick={() => {
											this.onCancel()
										}}
									>
										Annuleren
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</CSSTransition>
		)
	}

	render() {
		const { isDragging, isDraggable, mode } = this.props
		const { isEditMode, item } = this.state
		return (
			<div
				className={
					'nieuwslist-item' +
					(isDragging ? ' is-dragging' : '') +
					(isEditMode ? ' editmode' : '') +
					(item.inactive ? ' inactive' : '') +
					(isDraggable ? ' draggable' : '')
				}
				onMouseOver={() => this.onMouseOver()}
				onMouseLeave={() => this.onMouseLeave()}
			>
				<div className="nieuwslist-item-handle">
					<div className="nieuwslist-item-title">{item.cmsContent.title}</div>
					<div className="nieuwslist-item-link">
						{item.cmsContent.link !== '' ? '(' + item.cmsContent.link + ')' : ''}
					</div>
					{mode !== 'NEW' && (
						<div className={'nieuwslist-item-buttonpanel ' + (this.state.isHover && isDraggable ? '' : 'hidden')}>
							<SetInActive nodeId={item.id} inactive={item.inactive} />
							<FontAwesome className="nieuwslist-item-icon" name="pencil" onClick={this.onOpenPanel} size="2x" />
							<FontAwesome className="nieuwslist-item-icon" name="trash" onClick={this.onDelete} size="2x" />
						</div>
					)}
				</div>

				<TransitionGroup>{this.state.isEditMode && this.renderEditPanel()}</TransitionGroup>
			</div>
		)
	}
}
