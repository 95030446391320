import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TITLE } from './regexps'

export default ({ settings }) => {
	const possibleOptionsBySetting = new Map()
	possibleOptionsBySetting.set('enableWonen', { value: 'wonen', label: 'Wonen' })
	possibleOptionsBySetting.set('enableAlv', { value: 'alv', label: 'ALV' })
	possibleOptionsBySetting.set('enableBog', { value: 'bog', label: 'BOG' })
	possibleOptionsBySetting.set('enableVgmcomplex', { value: 'vgmcomplex', label: 'VGM' })

	const options = []
	for (let [key, value] of possibleOptionsBySetting.entries()) {
		const settingValue = settings[key]
		if (settingValue === true) {
			options.push(value)
		}
	}

	return {
		id: 'huisVdWeek',
		title: 'Woning van de week',
		icon: 'home',
		size: {
			minWidth: 1,
			maxWidth: 1,
			defaultWidth: 1,

			minHeight: 1,
			maxHeight: 1,
			defaultHeight: 1,
		},
		isDisabled: false,
		fieldTypes: [
			{
				name: 'huisvdweekTitel',
				label: 'Woning van de week',
				help: 'De titel die boven dit blok komt te staan',
				type: FieldTypes.TEXT,
				pattern: TITLE,
				defaultValue: '',
			},
			{
				name: 'huisvdweekModule',
				label: 'Type',
				help: 'Het objecttype huis wat getoond moet worden',
				rules: [
					{
						required: true,
						message: 'Maak een keuze',
					},
				],
				type: FieldTypes.SELECT,
				defaultValue: options.length >= 1 ? options[0].value : null,
				options: options,
			},
		],
	}
}
