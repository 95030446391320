/**
 * @flow
 * @relayHash 78933633abf6673ae2a8e886e639a295
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type settingsQueriesBrokerSettingsQueryResponse = {|
  +__type: ?{|
    +name: ?string;
    +fields: ?$ReadOnlyArray<{|
      +name: string;
    |}>;
  |};
|};
*/


/*
query settingsQueriesBrokerSettingsQuery {
  __type(name: "BrokerSettings") {
    name
    fields {
      name
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsQueriesBrokerSettingsQuery",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "name",
            "value": "BrokerSettings",
            "type": "String!"
          }
        ],
        "concreteType": "__Type",
        "name": "__type",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "name",
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "__Field",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "__type{\"name\":\"BrokerSettings\"}"
      }
    ],
    "type": "Query"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "settingsQueriesBrokerSettingsQuery",
  "query": {
    "argumentDefinitions": [],
    "kind": "Root",
    "name": "settingsQueriesBrokerSettingsQuery",
    "operation": "query",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "name",
            "value": "BrokerSettings",
            "type": "String!"
          }
        ],
        "concreteType": "__Type",
        "name": "__type",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "name",
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "__Field",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "__type{\"name\":\"BrokerSettings\"}"
      }
    ]
  },
  "text": "query settingsQueriesBrokerSettingsQuery {\n  __type(name: \"BrokerSettings\") {\n    name\n    fields {\n      name\n    }\n  }\n}\n"
};

module.exports = batch;
