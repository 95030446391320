import React from 'react'
import PropTypes from 'prop-types'
import './FormSection.css'
import { Card } from 'antd'

const FormSection = ({ title, children }) => {
	return <Card title={title}>{children}</Card>
}

FormSection.propTypes = {
	title: PropTypes.object,
}

export default FormSection
