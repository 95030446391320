import React, { Component } from 'react'
import { ActionHeader, Checkbox, Form, Input, Select } from '../general'
import { Col, Row, Tabs } from 'antd'
import './AdminSettings.css'
import 'animate.css'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import { updateSettings } from './settingsQueries'

const TabPane = Tabs.TabPane

@connect(currentWebsiteProps)
export default class AdminSettings extends Component {
	onSubmit = (values) => {
		const { websiteId } = this.props

		const systemSettings = []

		Object.keys(values).forEach((key) => {
			systemSettings.push({
				name: key,
				value: values[key],
			})
		})

		updateSettings(systemSettings, websiteId)
	}

	render() {
		const { websiteId } = this.props
		const systemSettings = this.props.websiteSettings.systemSettings
		return (
			<div className="theme-picker-container theme-wrapper admin-settings">
				<ActionHeader title="Admin Settings" />
				<Form key={'adminSettingsForm' + websiteId} onSubmit={this.onSubmit}>
					<Tabs defaultActiveKey="modulen">
						<TabPane tab="Og Modules" key="modulen" forceRender={true}>
							<Row>
								<Col span={12}>
									<Checkbox name="enableWonen" label="Wonen" initialValue={systemSettings.enableWonen} />
									<Checkbox name="enableBog" label="BOG" initialValue={systemSettings.enableBog} />
									<Checkbox name="enableNieuwbouw" label="Nieuwbouw" initialValue={systemSettings.enableNieuwbouw} />
								</Col>
								<Col span={12}>
									<Checkbox name="enableAlv" label="ALV" initialValue={systemSettings.enableAlv} />
									<Checkbox name="enableVgmcomplex" label="VGM" initialValue={systemSettings.enableVgmcomplex} />
									<Checkbox name="enableAankoop" label="Aankoop" initialValue={systemSettings.enableAankoop} />
								</Col>
							</Row>
						</TabPane>
						<TabPane tab="Algemeen" key="algemeen" forceRender={true}>
							<Input
								name="titleSetting"
								initialValue={systemSettings.titleSetting ? systemSettings.titleSetting : ''}
								label="Title"
							/>
						</TabPane>
						<TabPane tab="Widget parameters" key="widgets-parameters" forceRender={true}>
							<Row gutter={24}>
								<Col md={12}>
									<Input
										name="addthisUser"
										label="Addthis gebruikerid"
										initialValue={systemSettings.addthisUser ? systemSettings.addthisUser : ''}
									/>
								</Col>
								<Col md={12}>
									<Input
										name="spotlightPrio"
										label="Spotlight prioriteit"
										initialValue={systemSettings.spotlightPrio ? systemSettings.spotlightPrio : ''}
									/>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col md={12}>
									<Select
										name="multiOfficeOptionType"
										label="Vestigingkeuze - Toon:"
										initialValue={systemSettings.multiOfficeOptionType ? systemSettings.multiOfficeOptionType : 'name'}
										options={[
											{ value: 'name', label: 'Vestigings naam' },
											{ value: 'city', label: 'Vestigings plaats' },
										]}
									/>
								</Col>
							</Row>
						</TabPane>
						<TabPane tab="Website 3.0" key="website-3.0" forceRender={true}>
							<Row gutter={24}>
								<Col md={12}>
									<Checkbox
										name="enableDeeldiensten"
										label="Deeldiensten"
										initialValue={systemSettings.enableDeeldiensten}
									/>
									<Select
										name="nieuwsPaginaNummer"
										label="Nieuwspagina variant"
										initialValue={systemSettings.nieuwsPaginaNummer ? systemSettings.nieuwsPaginaNummer : '2'}
										options={[
											{ value: '2', label: 'NieuwsLijst' },
											{ value: '0', label: 'Geen' },
											{ value: '1', label: 'RSS' },
											{ value: '3', label: 'CMS' },
										]}
									/>
									<Select
										name="rssMaxAantal"
										label="RSS Max aantal items"
										initialValue={systemSettings.rssMaxAantal ? systemSettings.rssMaxAantal : '10'}
										options={[
											{ value: '10', label: '10' },
											{ value: '20', label: '20' },
											{ value: '30', label: '30' },
											{ value: '40', label: '40' },
											{ value: '50', label: '50' },
											{ value: '60', label: '60' },
											{ value: '70', label: '70' },
											{ value: '80', label: '80' },
											{ value: '100', label: '100' },
											{ value: '9999', label: 'Alles' },
										]}
									/>
									<Input
										name="rssUrl"
										initialValue={systemSettings.rssUrl ? systemSettings.rssUrl : ''}
										label="RSS urls (| gescheiden)"
									/>
									<Select
										name="enableBlog"
										label="Blog"
										initialValue={'' + systemSettings.enableBlog}
										options={[
											{ value: 'true', label: 'Aan' },
											{ value: 'false', label: 'Uit' },
										]}
									/>
									<Select
										name="enableVerkopendeMakelaar"
										label="Enableverkopendemakelaar"
										initialValue={'' + systemSettings.enableVerkopendeMakelaar}
										options={[
											{ value: 'false', label: 'Uit' },
											{ value: 'true', label: 'Aan' },
										]}
									/>
								</Col>
								<Col md={12}>
									<Select
										name="aanbodlijstSorteerOptiesDefault"
										label="Aanbodlijst sortering"
										initialValue={
											systemSettings.aanbodlijstSorteerOptiesDefault
												? systemSettings.aanbodlijstSorteerOptiesDefault
												: 'date'
										}
										options={[
											{ value: 'date', label: 'Datum' },
											{ value: 'city', label: 'Stad' },
											{ value: 'price', label: 'Prijs' },
										]}
									/>
									<Select
										name="aanbodlijstSorteerDefaultSelectedOrder"
										label="Sorteer richting"
										initialValue={
											systemSettings.aanbodlijstSorteerDefaultSelectedOrder
												? systemSettings.aanbodlijstSorteerDefaultSelectedOrder
												: ''
										}
										options={[
											{ value: '', label: 'Standaard' },
											{ value: 'asc', label: 'A-Z' },
											{ value: 'desc', label: 'Z-A' },
										]}
									/>
									<Select
										name="portalType"
										label="Portaal"
										initialValue={systemSettings.portalType ? systemSettings.portalType : 'none'}
										options={[
											{ value: 'cms', label: 'Aan' },
											{ value: 'none', label: 'Uit' },
										]}
									/>
									<Select
										name="toonAantalObjectenInZoekformulierTitel"
										label="Toon aantal objecten in zoekformulier titel"
										initialValue={'' + systemSettings.toonAantalObjectenInZoekformulierTitel}
										options={[
											{ value: 'false', label: 'Nee' },
											{ value: 'true', label: 'Ja' },
										]}
									/>
									<Select
										name="kijkMijnHuisPixelEnabled"
										label="KijkMijnHuis Pixel"
										initialValue={'' + systemSettings.kijkMijnHuisPixelEnabled}
										options={[
											{ value: 'false', label: 'Nee' },
											{ value: 'true', label: 'Ja' },
										]}
									/>
									<Select
										name="scheme"
										label="Website scheme"
										initialValue={systemSettings.scheme ? systemSettings.scheme : 'https'}
										options={[
											{ value: 'https', label: 'https' },
											{ value: 'http', label: 'http' },
										]}
									/>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</Form>
			</div>
		)
	}
}
