import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import { ItemTypes } from '../../constants'
import './NestableMenuList.css'
import NestableMenuListItem from './NestableMenuListItem'

const target = {
	hover(props, monitor) {
		const { id: draggedId, parent } = monitor.getItem()
		const { maxLevel } = props

		if (!monitor.isOver({ shallow: true })) {
			return
		}

		if (parent === props.parent || draggedId === props.parent) {
			return
		}

		if (props.parent != null) {
			var itemDepth = props.depthOf(monitor.getItem())
			//Als het te verslepen item al groter/even groot is dan maxLevel, dan weten we dat hem niet mogen droppen binnen
			//een parent (welke dan ook).
			if (itemDepth >= maxLevel) {
				return
			}
			var parentLevel = props.getLevelOfItem(props.parent)
			if (parentLevel + itemDepth > maxLevel) {
				return
			}
		}

		props.move(draggedId, props.id, props.parent)
	},
}

class NestableMenuList extends Component {
	static propTypes = {
		items: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.any.isRequired,
			}),
		),
	}

	static defaultProps = {
		items: [],
	}

	state = {
		mouseover: true,
	}

	renderAddButton() {
		const { parent, openCreateModal, level, maxLevel } = this.props

		if (level > maxLevel) {
			return ''
		}

		return (
			<Button
				icon={<PlusOutlined />}
				className={'addNewButton'}
				type={'dashed'}
				onClick={() => openCreateModal(parent)}
				style={{ marginBottom: '10px' }}
			>
				Nieuw item
			</Button>
		)
	}

	render() {
		const {
			connectDropTarget,
			items,
			parent,
			move,
			deleteItem,
			level,
			maxLevel,
			depthOf,
			getLevelOfItem,
			openCreateModal,
			openUpdateModal,
		} = this.props
		return connectDropTarget(
			<ol className={'nestable-list ' + (items.length === 0 ? 'empty' : '')}>
				{items.map((item) => {
					return (
						<NestableMenuListItem
							key={item.id}
							item={item}
							id={item.id}
							parent={parent}
							move={move}
							level={level}
							maxLevel={maxLevel}
							deleteItem={deleteItem}
							getLevelOfItem={getLevelOfItem}
							depthOf={depthOf}
							openCreateModal={openCreateModal}
							openUpdateModal={openUpdateModal}
							confirmDragAction={this.props.confirmDragAction}
						/>
					)
				})}

				{this.renderAddButton()}
			</ol>,
		)
	}
}

export default DropTarget(ItemTypes.MENUITEM, target, (connect) => ({
	connectDropTarget: connect.dropTarget(),
}))(NestableMenuList)
