import React from 'react'
import PropTypes from 'prop-types'

const withValidationRules = (WrappedComponent) => {
	const Wrapped = (props, context) => {
		if (!props.name) {
			throw new Error("property 'name' is undefined for component " + WrappedComponent.name)
		}
		const { rules, required } = props
		let allRules = []
		if (rules) {
			allRules.push(...rules)
		}
		if (required !== undefined && typeof required !== 'boolean') {
			throw new Error('Value of required is not a boolean for ' + WrappedComponent.name + ' with name ' + props.name)
		}

		if (required === true) {
			allRules.push({ required: true, message: 'Dit veld is verplicht' })
		}
		return <WrappedComponent {...props} rules={allRules} />
	}

	Wrapped.contextTypes = {
		required: PropTypes.bool,
		rules: PropTypes.array,
	}

	return Wrapped
}

export default withValidationRules
