import React from 'react'
import { ActionHeader, Form, Select } from '../../general'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { currentWebsiteProps } from '../../shared'
import { updateSettings } from '../../settings/settingsQueries'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		websiteSettings: PropTypes.object.isRequired,
	}

	onSubmit = (values) => {
		const { websiteId } = this.props
		let dataToMutate = Object.entries(values).map(([key, value]) => {
			return {
				name: key,
				value: value,
			}
		})
		updateSettings(dataToMutate, websiteId)
	}

	render() {
		const { websiteId, websiteSettings } = this.props
		const {
			dagenVerkochtOpAanbodlijst,
			aantalDagenVoorNieuwOpMarkt,
			dagenOpVerkochtAanbodlijst,
			actiefWonenZoekformulierOptie,
			actiefBogZoekformulierOptie,
			actiefNieuwbouwZoekformulierOptie,
			actiefAlvZoekformulierOptie,
			clusterMarkers,
			enableHelpmee,
		} = websiteSettings.brokerSettings

		const { enableWonen, enableBog, enableNieuwbouw, enableAlv } = websiteSettings.systemSettings

		return (
			<div className="theme-wrapper">
				<ActionHeader title="Aanbod" />

				<Form key={'listingSectionForm' + websiteId} onSubmit={this.onSubmit}>
					<Select
						name={'dagenVerkochtOpAanbodlijst'}
						label="Dagen verkocht op aanbodlijst"
						options={[
							{ value: '0', label: 'Niet tonen' },
							{ value: '7', label: 'Een week' },
							{ value: '14', label: 'Twee weken' },
							{ value: '21', label: 'Drie weken' },
							{ value: '30', label: 'Een maand' },
							{ value: '60', label: 'Twee maanden' },
							{ value: '90', label: 'Drie maanden' },
							{ value: '182', label: 'Half jaar' },
							{ value: '365', label: 'Een jaar' },
							{ value: '1095', label: 'Drie jaar' },
							{ value: '1460', label: 'Vier jaar' },
							{ value: '1825', label: 'Vijf jaar' },
						]}
						initialValue={dagenVerkochtOpAanbodlijst ? dagenVerkochtOpAanbodlijst : '0'}
					/>
					<Select
						name={'aantalDagenVoorNieuwOpMarkt'}
						label="Dagen nieuw op site"
						options={[
							{ value: '0', label: 'Niet tonen' },
							{ value: '7', label: 'Een week' },
							{ value: '14', label: 'Twee weken' },
							{ value: '21', label: 'Drie weken' },
							{ value: '30', label: 'Een maand' },
						]}
						initialValue={aantalDagenVoorNieuwOpMarkt ? aantalDagenVoorNieuwOpMarkt : '0'}
					/>
					<Select
						name={'dagenOpVerkochtAanbodlijst'}
						label="Dagen op archief aanbodlijst"
						options={[
							{ value: '0', label: 'Niet tonen' },
							{ value: '7', label: 'Een week' },
							{ value: '14', label: 'Twee weken' },
							{ value: '21', label: 'Drie weken' },
							{ value: '30', label: 'Een maand' },
							{ value: '60', label: 'Twee maanden' },
							{ value: '90', label: 'Drie maanden' },
							{ value: '182', label: 'Half jaar' },
							{ value: '365', label: 'Een jaar' },
							{ value: '1095', label: 'Drie jaar' },
							{ value: '1460', label: 'Vier jaar' },
							{ value: '1825', label: 'Vijf jaar' },
						]}
						initialValue={dagenOpVerkochtAanbodlijst ? dagenOpVerkochtAanbodlijst : '0'}
					/>

					{enableWonen && (
						<Select
							name={'actiefWonenZoekformulierOptie'}
							label="Actief wonen zoekformulier optie"
							options={[
								{ value: 'beide', label: 'Beide' },
								{ value: 'koop', label: 'Koop' },
								{ value: 'huur', label: 'Huur' },
							]}
							initialValue={actiefWonenZoekformulierOptie ? actiefWonenZoekformulierOptie : 'beide'}
						/>
					)}
					{enableBog && (
						<Select
							name={'actiefBogZoekformulierOptie'}
							label="Actief BOG zoekformulier optie"
							options={[
								{ value: 'beide', label: 'Beide' },
								{ value: 'koop', label: 'Koop' },
								{ value: 'huur', label: 'Huur' },
							]}
							initialValue={actiefBogZoekformulierOptie ? actiefBogZoekformulierOptie : 'beide'}
						/>
					)}
					{enableNieuwbouw && (
						<Select
							name={'actiefNieuwbouwZoekformulierOptie'}
							label="Actief nieuwbouw zoekformulier optie"
							options={[
								{ value: 'koop', label: 'Koop' },
								{ value: 'huur', label: 'Huur' },
							]}
							initialValue={actiefNieuwbouwZoekformulierOptie ? actiefNieuwbouwZoekformulierOptie : 'koop'}
						/>
					)}
					{enableAlv && (
						<Select
							name={'actiefAlvZoekformulierOptie'}
							label="Actief agrarisch zoekformulier optie"
							options={[
								{ value: 'beide', label: 'Beide' },
								{ value: 'koop', label: 'Koop' },
								{ value: 'huur', label: 'Huur' },
							]}
							initialValue={actiefAlvZoekformulierOptie ? actiefAlvZoekformulierOptie : 'beide'}
						/>
					)}
					<Select
						name={'clusterMarkers'}
						label="Aanbod in Google Maps"
						options={[
							{ value: 'true', label: 'Gegroepeerd' },
							{ value: 'false', label: 'Los' },
						]}
						initialValue={clusterMarkers ? clusterMarkers : 'true'}
					/>
					<Select
						name={'enableHelpmee'}
						label="Activeer HelpMee op uw website 3.0"
						options={[
							{ value: 'true', label: 'Ja' },
							{ value: 'false', label: 'Nee' },
						]}
						initialValue={enableHelpmee ? enableHelpmee : 'false'}
					/>
				</Form>
			</div>
		)
	}
}
