import React, { Component } from 'react'
import { Form, Input, Select } from '../general'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../shared'
import { updateSettings } from './settingsQueries'

@connect(currentWebsiteProps)
export default class VideoBannerSettings extends Component {
	brokerSettings = this.props.websiteSettings.brokerSettings

	state = {
		videoIdFieldDisabled:
			this.brokerSettings.useVideoHeaderBanner !== null ? !this.brokerSettings.useVideoHeaderBanner : true,
	}

	onSubmit = (values) => {
		const { websiteId } = this.props

		const systemSettings = []

		Object.keys(values).forEach((key) => {
			systemSettings.push({
				name: key,
				value: values[key],
			})
		})

		updateSettings(systemSettings, websiteId)
	}

	onShowVideoChange = (value) => {
		this.setState({
			videoIdFieldDisabled: value === 'false',
		})
		this.form.setFields({
			youtubeHeaderVideoId: {
				value: '',
				errors: null,
			},
		})
	}

	render() {
		const { videoIdFieldDisabled } = this.state
		const { websiteId } = this.props

		return (
			<Form
				key={'videoBannerSettingsForm' + websiteId}
				onSubmit={this.onSubmit}
				ref={(c) => {
					this.form = c
				}}
			>
				<Select
					name="useVideoHeaderBanner"
					label="Toon een video in de header"
					initialValue={
						this.brokerSettings.useVideoHeaderBanner ? '' + this.brokerSettings.useVideoHeaderBanner : 'false'
					}
					onChange={this.onShowVideoChange}
					options={[
						{ value: 'false', label: 'Nee' },
						{ value: 'true', label: 'Ja' },
					]}
				/>
				<Input
					name="youtubeHeaderVideoId"
					label="Youtube video id"
					disabled={videoIdFieldDisabled}
					required={!videoIdFieldDisabled}
					help={
						<div>
							Voer hier de ID in van de video, dat is het <span style={{ color: 'red' }}>rode gedeelte</span> uit de
							voorbeeld url https://www.youtube.com/watch?v=<span style={{ color: 'red' }}>abc123456789</span>
						</div>
					}
					initialValue={this.brokerSettings.youtubeHeaderVideoId}
				/>
			</Form>
		)
	}
}
