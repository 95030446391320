import React from 'react'
import { ActionHeader } from '../../general'
import WebEmployeesWidget from '../../webemployees/WebEmployeesWidget'

const WebEmployeesSection = () => {
	return (
		<div className="theme-wrapper">
			<ActionHeader title="Medewerkers" />
			<WebEmployeesWidget />
		</div>
	)
}

export default WebEmployeesSection
