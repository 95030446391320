/**
 * @flow
 * @relayHash 9d43b376c10f4eea24e4bbbfeedb709b
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type BasePageSwitchMutationVariables = {|
  value: boolean;
|};
export type BasePageSwitchMutationResponse = {|
  +switchToOldWebsiteBeheer: {|
    +value: ?boolean;
  |};
|};
*/


/*
mutation BasePageSwitchMutation(
  $value: Boolean!
) {
  switchToOldWebsiteBeheer(value: $value) {
    value
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "value",
        "type": "Boolean!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BasePageSwitchMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "value",
            "variableName": "value",
            "type": "Boolean!"
          }
        ],
        "concreteType": "SwitchToOldWebsiteBeheerPayload",
        "name": "switchToOldWebsiteBeheer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "BasePageSwitchMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "value",
        "type": "Boolean!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "BasePageSwitchMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "value",
            "variableName": "value",
            "type": "Boolean!"
          }
        ],
        "concreteType": "SwitchToOldWebsiteBeheerPayload",
        "name": "switchToOldWebsiteBeheer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation BasePageSwitchMutation(\n  $value: Boolean!\n) {\n  switchToOldWebsiteBeheer(value: $value) {\n    value\n  }\n}\n"
};

module.exports = batch;
