/**
 * @flow
 * @relayHash 80fc47ea4900a78882879618c37f76bc
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type HomePageEditorSaveConfigMutationVariables = {|
  input: {
    blocks: $ReadOnlyArray<{
      height: number;
      order: number;
      parameters?: ?$ReadOnlyArray<?{
        name: string;
        value: string;
      }>;
      widgetxmlname: string;
      width: number;
    }>;
    websiteId: string;
  };
|};
export type HomePageEditorSaveConfigMutationResponse = {|
  +saveHomepageLayout: ?{|
    +blocks: $ReadOnlyArray<?{|
      +width: ?number;
      +height: ?number;
      +widgetxmlname: string;
      +parameters: $ReadOnlyArray<?{|
        +name: string;
        +value: string;
      |}>;
    |}>;
  |};
|};
*/


/*
mutation HomePageEditorSaveConfigMutation(
  $input: HomepageLayoutInput!
) {
  saveHomepageLayout(input: $input) {
    blocks {
      width
      height
      widgetxmlname
      parameters {
        name
        value
      }
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "HomepageLayoutInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomePageEditorSaveConfigMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "HomepageLayoutInput!"
          }
        ],
        "concreteType": "HomepageConfig",
        "name": "saveHomepageLayout",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "HomepageBlock",
            "name": "blocks",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "width",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "height",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "widgetxmlname",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "BlockParameter",
                "name": "parameters",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "HomePageEditorSaveConfigMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "HomepageLayoutInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "HomePageEditorSaveConfigMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "HomepageLayoutInput!"
          }
        ],
        "concreteType": "HomepageConfig",
        "name": "saveHomepageLayout",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "HomepageBlock",
            "name": "blocks",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "width",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "height",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "widgetxmlname",
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "args": null,
                "concreteType": "BlockParameter",
                "name": "parameters",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "args": null,
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation HomePageEditorSaveConfigMutation(\n  $input: HomepageLayoutInput!\n) {\n  saveHomepageLayout(input: $input) {\n    blocks {\n      width\n      height\n      widgetxmlname\n      parameters {\n        name\n        value\n      }\n    }\n  }\n}\n"
};

module.exports = batch;
