import React from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from '../../../general'
import { range } from 'lodash'
import { NieuwsWidget } from '../../../list'
import Soon from '../../../general/soon/Soon'
import { NEWS_TYPE_CMS, NEWS_TYPE_NIEUWS, NEWS_TYPE_RSS } from '../../../../constants'

class Nieuwslijst extends React.Component {
	static propTypes = {
		parameters: PropTypes.object.isRequired,
	}

	state = {
		typeValue: this.props.nieuwsPaginaNummer,
	}

	render() {
		const { typeValue } = this.state
		const { parameters } = this.props

		/* eslint-disable no-unreachable */
		return (
			<div>
				{/*<Select disabled name="nieuwsPaginaNummer"*/}
				{/*label="Type"*/}
				{/*options={[*/}
				{/*{value: TYPE_NIEUWS, label: "Nieuws"},*/}
				{/*{value: TYPE_RSS, label: "RSS"},*/}
				{/*{value: TYPE_CMS, label: "CMS"}*/}
				{/*]}*/}
				{/*onChange={(typeValue, label) => {*/}
				{/*console.log("TypeValue", typeValue, label);*/}
				{/*this.setState({typeValue})*/}
				{/*}}*/}
				{/*initialValue={typeValue} */}
				{/*/>*/}

				{typeValue === NEWS_TYPE_RSS && (
					<div>
						<Soon title={'Neem contact op met uw accountmanager voor het wijzigen van de url.'} />

						<Input disabled name="rssUrl" label="RSS url" initialValue={parameters.rssUrl} />
						<Select
							disabled
							initialValue={parameters.rssMaxAantal}
							name="rssMaxAantal"
							label="Max. aantal items"
							options={range(10, 100, 10)
								.map((value) => ({
									value: String(value),
									label: String(value),
								}))
								.concat({ value: String(999), label: 'Alles' })}
						/>
					</div>
				)}

				{typeValue === NEWS_TYPE_NIEUWS && (
					<div>
						<p>Deze widget is gekoppeld aan uw nieuwspagina.</p>
						<NieuwsWidget />
					</div>
				)}

				{typeValue === NEWS_TYPE_CMS && (
					<div>
						Uw bestaande pagina voor nieuwsitems wordt gebruikt voor al uw nieuws widgets.
						{/*<Input name={'title'} rules={[{required: true, message: "Vul een waarde in"}]} />*/}
						{/*<CKEditor  name={'content'} initialValue={''} />*/}
					</div>
				)}
			</div>
		)
	}
}

export default ({ settings }) => {
	return {
		id: 'nieuws',
		title: 'Nieuwslijst',
		icon: 'newspaper-o',
		size: {
			minWidth: 2,
			maxWidth: 2,
		},
		isDisabled: false,
		fieldTypes: [
			{
				component: (props) => {
					return <Nieuwslijst {...props} nieuwsPaginaNummer={settings.nieuwsPaginaNummer} />
				},
			},
		],
	}
}
