import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ItemTypes } from '../../constants'
import SortableList from '../sortablelist/SortableList'
import BannerTabsListItem from './BannerTabsListItem'

export default class BannerTabsList extends Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		createItem: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
		onItemsChange: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			items: cloneDeep(props.items),
			isDraggable: true,
			showNewPanel: false,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.state.items === nextProps.items) {
			return
		}
		this.setState({
			items: cloneDeep(nextProps.items),
		})
	}

	onItemsReset = (items) => {
		this.setState({ items: items })
	}

	onItemsChange = (items, dragActionFinished) => {
		let clonedItems = cloneDeep(items)
		console.log('cloned', clonedItems)
		clonedItems.map((item, index) => {
			item.order = '' + (index + 1)
			return item
		})
		console.log('ordered', clonedItems)
		if (dragActionFinished) {
			this.props.onItemsChange(clonedItems)
		}
		this.setState({ items: clonedItems })
	}

	render() {
		const items = [...this.state.items]
		const { isDraggable } = this.state
		items.sort((a, b) => {
			return a.order - b.order
		})
		return (
			<div className="bannertabs-list-container">
				<div className="bannertabs-list">
					<SortableList
						items={items}
						onItemsReset={this.onItemsReset}
						onItemsChange={this.onItemsChange}
						renderChildren={this.renderChildren}
						isDraggable={isDraggable}
						dropTargetType={ItemTypes.BANNER_TABS_LIST}
					/>
					{this.renderNewItem()}
				</div>
				<Button icon={<PlusOutlined />} className={'addNewButton'} type={'dashed'} onClick={this.onNewItemClick}>
					Nieuw
				</Button>
			</div>
		)
	}

	renderChildren = (childProps, item) => {
		const { isDraggable, isDragging } = childProps
		const { createItem, updateItem, deleteItem } = this.props
		return (
			<BannerTabsListItem
				item={item}
				createItem={createItem}
				updateItem={updateItem}
				deleteItem={deleteItem}
				isDraggable={isDraggable}
				isDragging={isDragging}
				mode="EDIT"
				enableEditMode={this.enableEditMode}
				disableEditMode={this.disableEditMode}
			/>
		)
	}

	renderNewItem() {
		if (!this.state.showNewPanel) {
			return ''
		}

		const { createItem, updateItem, deleteItem } = this.props
		const newItem = {
			title: '',
			subtitle: '',
			content: '',
		}
		return (
			<BannerTabsListItem
				item={newItem}
				createItem={createItem}
				updateItem={updateItem}
				deleteItem={deleteItem}
				isDraggable={false}
				isDragging={false}
				mode="NEW"
				enableEditMode={this.enableEditMode}
				disableEditMode={this.disableEditMode}
			/>
		)
	}

	onNewItemClick = () => {
		this.setState({
			isDraggable: false,
			showNewPanel: true,
		})
	}

	enableEditMode = () => {
		this.setState({
			isDraggable: false,
		})
	}

	disableEditMode = () => {
		this.setState({
			isDraggable: true,
			showNewPanel: false,
		})
	}
}
