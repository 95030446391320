/**
 * @flow
 * @relayHash 737b98e15d261bacfdc596a57072c5d4
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type RewriteRulesWidgetDeleteMutationVariables = {|
  input: {
    deleteIds: $ReadOnlyArray<string>;
  };
|};
export type RewriteRulesWidgetDeleteMutationResponse = {|
  +deleteRewriteRules: ?{|
    +deletedIds: $ReadOnlyArray<string>;
  |};
|};
*/


/*
mutation RewriteRulesWidgetDeleteMutation(
  $input: DeleteRewriteRulesInput!
) {
  deleteRewriteRules(input: $input) {
    deletedIds
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "DeleteRewriteRulesInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RewriteRulesWidgetDeleteMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "DeleteRewriteRulesInput!"
          }
        ],
        "concreteType": "RewriteRulesDeletedPayload",
        "name": "deleteRewriteRules",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedIds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "RewriteRulesWidgetDeleteMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "DeleteRewriteRulesInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "RewriteRulesWidgetDeleteMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "DeleteRewriteRulesInput!"
          }
        ],
        "concreteType": "RewriteRulesDeletedPayload",
        "name": "deleteRewriteRules",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedIds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation RewriteRulesWidgetDeleteMutation(\n  $input: DeleteRewriteRulesInput!\n) {\n  deleteRewriteRules(input: $input) {\n    deletedIds\n  }\n}\n"
};

module.exports = batch;
