import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Fingerprint2 from 'fingerprintjs2'
//Order is important. The less file should be imported after app, otherwise the ant theme is not merged with our custom less config.
import App from './App'
import './index.css'
/**
 * polyfills
 */
import 'canvas-toBlob'

let fingerprint = null
const isProduction = process.env.NODE_ENV === 'production'

const getFingerPrint = async () => {
	Fingerprint2.getV18({ excludes: { webgl: true, webglVendorAndRenderer: true } }, (murmur, _) => {
		fingerprint = murmur
	})
}
setTimeout(getFingerPrint, 100)

const onError = (report) => {
	// // https://docs.bugsnag.com/platforms/browsers/react/customizing-error-reports/
	if (!isProduction) {
		return false
	}
	report.user = {
		fingerprint,
		id: fingerprint, // altijd id omdat Bugsnag hieromheen hoop functionaliteit bouwt
	}
}

export const bugsnagConfig = {
	apiKey: process.env.REACT_APP_BUGSNAG_APIKEY || '',
	collectUserIp: false,
	plugins: [new BugsnagPluginReact()],
}

Bugsnag.start({
	...bugsnagConfig,
	// Handles uncaught js errors
	onError,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
	<ErrorBoundary onError={onError}>
		<App />
	</ErrorBoundary>,
	document.getElementById('root'),
)

// Let's just turn off ServiceWorkers in CRA 1.0 to prevent BMW-2954
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then((registration) => {
		registration.unregister()
	})
}
