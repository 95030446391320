import React from 'react'
import PropTypes from 'prop-types'
import './WidgetPicker.css'
import classnames from 'classnames'
import WidgetPickerItem from './WidgetPickerItem'
import WidgetEditPanel from './WidgetEditPanel'
import { Input, Tabs } from 'antd'
import WidgetPreview from './DragLayer'

const { TabPane } = Tabs

class WidgetPicker extends React.Component {
	static propTypes = {
		activeTab: PropTypes.oneOf(['widgets', 'details']),
		onTabChange: PropTypes.func, // in case you want to pull up the tab state
		className: PropTypes.string,
		widgets: PropTypes.array.isRequired,
		activeWidgetConfig: PropTypes.shape({
			title: PropTypes.string.isRequired,
			description: PropTypes.string,
			fields: WidgetEditPanel.propTypes.fields,
			fieldValues: PropTypes.object.isRequired,
		}),
		style: PropTypes.object,
		onFieldsChange: PropTypes.func,
	}
	static defaultProps: {
		activeTab: 1,
	}

	state = {
		activeTab: this.props.activeTab,
	}

	UNSAFE_componentWillReceiveProps(next) {
		if (next.activeTab !== this.state.activeTab) {
			this.setState({ activeTab: next.activeTab })
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		// prevent expensive rendering when dragging an item (which triggers rendering on mousemove)
		// Let op: dit gaat er vanuit dat overige props niet runtime veranderen
		return (
			nextState.activeTab !== this.state.activeTab ||
			nextState.filter !== this.state.filter ||
			(this.props.activeWidgetConfig &&
				nextProps.activeWidgetConfig &&
				nextProps.activeWidgetConfig.key !== this.props.activeWidgetConfig.key)
		)
	}

	render() {
		const { widgets, className, activeWidgetConfig, style, onValuesChange } = this.props
		const { activeTab, filter } = this.state

		return (
			<div className={classnames('widgetpicker', className)} style={style}>
				<WidgetPreview onOffsetChange={this.props.onPreviewOffsetChange} />

				<Tabs defaultActiveKey={'widgets'} activeKey={activeTab} onChange={this.onSelect} style={{ overflowY: 'auto' }}>
					<TabPane tab="Widgets" key="widgets">
						<Input.Search placeholder="zoeken..." name={'test'} onChange={this.onFilterInputChange} />
						<WidgetList types={this.filterWidgets(widgets, filter)} />
					</TabPane>

					<TabPane tab="Opmaak" key="details">
						{!activeWidgetConfig && 'Selecteer een widget om de configuratie te veranderen'}
						{activeWidgetConfig && (
							<WidgetEditPanel
								key={activeWidgetConfig.key}
								onValuesChange={onValuesChange}
								title={activeWidgetConfig.title}
								description={activeWidgetConfig.description}
								fields={activeWidgetConfig.fields}
								values={activeWidgetConfig.fieldValues}
								showSubmit={activeWidgetConfig.showSubmit}
							/>
						)}
					</TabPane>
				</Tabs>
			</div>
		)
	}

	onSelect = (key) => {
		this.props.onTabChange(key)
	}

	onFilterInputChange = (e) => {
		this.setState({ filter: e.target.value })
	}

	filterWidgets = (widgets, filter) => {
		if (!filter) {
			return widgets
		}

		return widgets.filter((widget) => widget.title.toLowerCase().indexOf(filter.toLowerCase()) > -1)
	}
}

const WidgetList = ({ types }) => {
	return (
		<div className="widgetlist">
			{types.map((widgetConfig) => (
				<WidgetPickerItem key={widgetConfig.id} widgetConfig={widgetConfig} />
			))}
		</div>
	)
}

export default WidgetPicker
