import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from 'antd'
import './ActionHeader.css'

const ActionHeader = ({ title, children }) => {
	return (
		<div className="action-header">
			<h2 className="title">{title}</h2>
			{children}
		</div>
	)
}

ActionHeader.propTypes = {
	title: PropTypes.string.isRequired,
}

const RoundedLink = ({ className, onClick, text }) => (
	<Button type="primary" className={classnames('rounded-link', className)} onClick={onClick}>
		{text}
	</Button>
)

RoundedLink.propTypes = {
	classNames: PropTypes.object,
	onClick: PropTypes.func,
	text: PropTypes.string.isRequired,
}

const ActionLink = ({ label, onClick }) => (
	<RoundedLink className="action animated fadeIn" onClick={onClick} text={label} />
)

ActionLink.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

const ActionFeedback = ({ type, message }) => (
	<RoundedLink className={classnames('feedback', 'animated fadeIn', type)} text={message} />
)

ActionFeedback.propTypes = {
	type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
	message: PropTypes.string.isRequired,
}

export default ActionHeader
export { ActionLink, ActionFeedback }
