import React from 'react'
import PropTypes from 'prop-types'
import SortableListItem from './SortableListItem'
import { removeItemFromArray } from './SortableListActions'

class Component extends React.PureComponent {
	static propTypes = {
		items: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				// ... and some more fields if you wished that are just being passed through
			}),
		),
		onItemsChange: PropTypes.func.isRequired,
		onItemsReset: PropTypes.func.isRequired,
		renderChildren: PropTypes.func.isRequired,
		renderAddButton: PropTypes.func,
		dropTargetType: PropTypes.string.isRequired,
	}

	lastConfirmedItemsState = [...this.props.items]

	render() {
		const { items, renderChildren, renderAddButton, dropTargetType } = this.props
		const isDraggable = this.props.isDraggable !== undefined ? this.props.isDraggable : true

		return (
			<div className="sortable-list">
				{items.map((item) => (
					<SortableListItem
						key={item.id}
						item={item}
						moveItem={this.moveItem}
						confirmDragAction={this.confirmDragAction}
						resetDragAction={this.resetDragAction}
						renderChildren={renderChildren}
						isDraggable={isDraggable}
						dropTargetType={dropTargetType}
					/>
				))}
				{renderAddButton !== undefined && renderAddButton()}
			</div>
		)
	}

	resetDragAction = () => {
		this.props.onItemsReset(this.lastConfirmedItemsState, false)
	}

	confirmDragAction = () => {
		this.lastConfirmedItemsState = [...this.props.items]
		this.props.onItemsChange([...this.lastConfirmedItemsState], true)
	}

	moveItem = (movedItem, afterItem) => {
		if (movedItem.id === afterItem.id) {
			return
		}

		const items = [...this.props.items]
		const index = items.indexOf(items.filter((v) => v.id === afterItem.id).shift())
		removeItemFromArray(movedItem, items)
		items.splice(index, 0, movedItem)
		this.props.onItemsChange(items, false)
	}
}

Component.propTypes = {}

export default Component
