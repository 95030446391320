/**
 * @flow
 * @relayHash 5ecc59e566406b2395d81675979ac9ad
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type DeeldienstenFormContentEditorMutationVariables = {|
  input: {
    formContent: string;
    formInformation: string;
    formLandingContent: string;
    formTitle: string;
    id: string;
  };
|};
export type DeeldienstenFormContentEditorMutationResponse = {|
  +updateDeeldienstenPage: ?{|
    +value: {|
      +id: string;
      +title: ?string;
      +link: ?string;
      +formTitle: ?string;
      +formContent: ?string;
      +formInformation: ?string;
      +formLandingContent: ?string;
    |};
  |};
|};
*/


/*
mutation DeeldienstenFormContentEditorMutation(
  $input: UpdateDeeldienstenPageInput!
) {
  updateDeeldienstenPage(input: $input) {
    value {
      id
      title
      link
      formTitle
      formContent
      formInformation
      formLandingContent
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateDeeldienstenPageInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeeldienstenFormContentEditorMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateDeeldienstenPageInput!"
          }
        ],
        "concreteType": "DeeldienstenPagePayload",
        "name": "updateDeeldienstenPage",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "DeeldienstenPage",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formTitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formContent",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formInformation",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formLandingContent",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "DeeldienstenFormContentEditorMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateDeeldienstenPageInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "DeeldienstenFormContentEditorMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateDeeldienstenPageInput!"
          }
        ],
        "concreteType": "DeeldienstenPagePayload",
        "name": "updateDeeldienstenPage",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "DeeldienstenPage",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "title",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formTitle",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formContent",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formInformation",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "formLandingContent",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation DeeldienstenFormContentEditorMutation(\n  $input: UpdateDeeldienstenPageInput!\n) {\n  updateDeeldienstenPage(input: $input) {\n    value {\n      id\n      title\n      link\n      formTitle\n      formContent\n      formInformation\n      formLandingContent\n    }\n  }\n}\n"
};

module.exports = batch;
