import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { currentWebsiteProps } from '../../../shared'

@connect(currentWebsiteProps)
class Instagram extends React.Component {
	static propTypes = {
		websiteName: PropTypes.string.isRequired,
	}

	render() {
		const { websiteName } = this.props

		const url =
			window.GLOBAL_CONSTANTS_CRM_PATH + '/servlets/objects/socialmedia.instagram/beheer?hostname=' + websiteName
		return (
			<div>
				<a onClick={() => window.open(url, 'Instagram instellingen', 'height=700,width=1024')}>
					Beheer uw Instagram account gegevens
				</a>{' '}
				om deze widget op uw website te tonen.
			</div>
		)
	}
}

export default ({ settings }) => ({
	id: 'instagramBlock',
	title: 'Instagram',
	icon: 'instagram',
	size: {
		minWidth: 1,
		maxWidth: 2,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 1,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			component: (props) => <Instagram />,
		},
	],
})
