import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TWITTER } from './regexps'

export default ({ settings }) => ({
	id: 'facebookLikesBoxBlok',
	title: 'Facebook',
	icon: 'facebook',
	size: {
		minWidth: 1,
		maxWidth: 3,
		defaultWidth: 1,

		minHeight: 1,
		maxHeight: 3,
		defaultHeight: 1,
	},
	isDisabled: false,
	fieldTypes: [
		{
			name: 'facebookLikesBoxUsername',
			label: 'Facebook id',
			help: 'De url wordt http://facebook.com/ingevoerde_id',
			type: FieldTypes.TEXT,
			rules: [{ required: true, message: 'Vul een waarde in', pattern: TWITTER }],
			defaultValue: '',
		},
		{
			name: 'facebookLikesBoxShowPosts',
			label: 'Toon Facebook posts',
			type: FieldTypes.RADIO,
			defaultValue: 'nee',
			rules: [{ required: true, message: 'Vul een waarde in' }],
			options: [
				{ value: 'ja', label: 'Ja' },
				{ value: 'nee', label: 'Nee' },
			],
		},
	],
})
