import React from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

export default class extends React.Component {
	static propTypes = {
		color: PropTypes.string,
		onChange: PropTypes.func,
	}

	state = {
		displayColorPicker: false,
		color: this.props.color,
	}

	UNSAFE_componentWillReceiveProps(next) {
		if (next.color !== this.props.color) {
			this.setState({ color: next.color })
		}
	}

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	}

	handleClose = () => {
		const { onChange } = this.props
		const { color } = this.state

		if (color && onChange) {
			onChange(this.state.color)
		}

		this.setState({ displayColorPicker: false })
	}

	handleChange = (color) => {
		this.setState({ color: color.hex })
	}

	render() {
		const { color } = this.state

		const styles = {
			container: {
				display: 'inline-block',
			},
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `${this.state.color}`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		}

		return (
			<div style={styles.container}>
				<div style={styles.swatch} onClick={this.handleClick}>
					<div style={styles.color} />
					{this.props.property}
				</div>
				{this.state.displayColorPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.handleClose} />
						<ChromePicker color={color ? color : undefined} onChangeComplete={this.handleChange} />
					</div>
				) : null}
			</div>
		)
	}
}
