import React from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { DatePicker, Form } from 'antd'
import withValidationRules from './WithValidationRules'
import moment from 'moment'

const { Item: FormItem } = Form

const DatePickerComponent = ({ name, label, help, initialValue, rules, placeholder }) => {
	return (
		<FormItem name={name} initialValue={initialValue} label={label} help={help} rules={rules}>
			<DatePicker placeholder={placeholder} format="DD-MM-YYYY" defaultPickerValue={moment()} />
		</FormItem>
	)
}

DatePickerComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	help: PropTypes.string,
	initialValue: PropTypes.object,
	rules: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
}

export default withValidationRules(DatePickerComponent)

/**
 * Tbv storybook
 */
export { DatePickerComponent }
