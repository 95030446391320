import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ActionHeader } from '../general'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row, Table } from 'antd'
import RewriteModal from './RewriteModal'

import '../pages/settings/ThemeSettingsPage.css'
import './RewriteRules.css'
import 'animate.css'

const ButtonGroup = Button.Group

// Here we define the colums structure of the table
const columns = [
	{
		title: 'Pattern',
		dataIndex: 'pattern',
	},
	{
		title: 'Replace',
		dataIndex: 'replace',
	},
	{
		title: 'Parameters',
		dataIndex: 'parameters',
	},
	{
		title: 'Status',
		dataIndex: 'status',
	},
]

export default class RewriteRules extends Component {
	static propTypes = {
		items: PropTypes.array,
		createRewriteRule: PropTypes.func.isRequired,
		updateRewriteRule: PropTypes.func.isRequired,
		deleteRewriteRules: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			visibleModal: false,
			selectedRows: [],
			selectedRowKeys: [],
		}
	}

	// Functions to show and hide Modal
	showModal = () => {
		this.setState({
			visibleModal: true,
		})
	}

	handleCancel = () => {
		this.setState({
			visibleModal: false,
		})
	}

	//Functions for Input Data
	handleSubmit = (values) => {
		const { selectedRewriteRule } = this.state

		if (selectedRewriteRule === undefined) {
			this.props.createRewriteRule(values)
		} else {
			const rewriteRule = { id: selectedRewriteRule.id, ...values }
			this.props.updateRewriteRule(rewriteRule)
		}

		this.setState({
			visibleModal: false,
			selectedRewriteRule: undefined,
			selectedRows: [],
			selectedRowKeys: [],
		})
	}

	//Function for Edition
	handleEdit = () => {
		const { selectedRows } = this.state
		const { items } = this.props
		const [itemEdit] = items.filter((itemData) => itemData.id === selectedRows[0].id)
		this.setState({
			visibleModal: true,
			selectedRewriteRule: itemEdit,
		})
	}

	//Function for Delete
	handleDelete = () => {
		const { selectedRows } = this.state
		const deletedIds = []

		selectedRows.forEach((item) => deletedIds.push(item.id))
		this.props.deleteRewriteRules(deletedIds)

		this.setState({
			selectedRows: [],
			selectedRowKeys: [],
		})
	}

	render() {
		const { visibleModal, selectedRewriteRule, selectedRowKeys } = this.state
		const { items } = this.props

		const rowSelection = {
			selectedRowKeys,
			onChange: (selectedRowKeys, selectedRows) => {
				this.setState({
					selectedRowKeys: selectedRowKeys,
					selectedRows: selectedRows,
				})
			},
		}

		const onRow = (record) => {
			return {
				onDoubleClick: () => {
					this.setState({
						selectedRewriteRule: record,
						editMode: true,
						visibleModal: true,
					})
				},
			}
		}

		return (
			<div className="theme-picker-container theme-wrapper">
				<ActionHeader title="Rewrite Rules" />
				<Row style={{ marginBottom: '15px' }} justify={'end'}>
					<Col span={24}>
						<ButtonGroup style={{ float: 'right' }}>
							<Button
								type="default"
								icon={<EditOutlined />}
								onClick={this.handleEdit}
								disabled={items.length === 0 || selectedRowKeys.length !== 1}
							/>
							<Button type="default" icon={<PlusOutlined />} onClick={this.showModal} />
							<Button
								type="default"
								icon={<DeleteOutlined />}
								onClick={this.handleDelete}
								disabled={items.length === 0 || selectedRowKeys.length === 0}
							/>
						</ButtonGroup>
						<RewriteModal
							visible={visibleModal}
							handleSubmit={this.handleSubmit}
							handleCancel={this.handleCancel}
							rewriteRule={selectedRewriteRule}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							rowSelection={rowSelection}
							pagination={false}
							columns={columns}
							rowKey="id"
							dataSource={items}
							scroll={{ y: 240 }}
							onRow={onRow}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}
