import React from 'react'
import PropTypes from 'prop-types'
import { commitMutation, graphql } from 'react-relay'
import environment from '../../createRelayEnvironment'
import CKEditor from '../CKEditor'
import Form from '../general/form/form/Form'
import { DatePicker, Input, Select } from '../general'
import { Col, Row } from 'antd'
import moment from 'moment'
import { currentWebsiteProps, onMutationCompleted } from '../shared'
import './CmsEditor.less'
import { connect } from 'react-redux'
import { CMS_FORM_OPTIONS, CMS_STATUS_OPTIONS } from './cmsconstants'
import { withBlocking } from '../../helpers/general/WithBlockingHoc'
import { withRouter } from '../../helpers/general/WithRouterHoc'

@connect(currentWebsiteProps)
class CmsPageContentEditor extends React.Component {
	static propTypes = {
		pageId: PropTypes.string,
		item: PropTypes.object,
		setDirtyState: PropTypes.func.isRequired,
		router: PropTypes.object.isRequired,
	}

	updateItem = (item) => {
		const { pageId } = this.props

		const mutation = graphql`
			mutation CmsPageContentEditorUpdateMutation($input: UpdateCmsPageContentInput!) {
				updateCmsPageContent(input: $input) {
					previousId
					value {
						id
						title
						link
						pageType
						pageDisplaySetting
						content {
							id
							link
							title
							content
							teaserText

							status
							publicationStartDate
							publicationEndDate

							browserTitle
							browserDescription
							browserKeywords
							cmsForm
						}
						subContent {
							id
							title
							content
						}
						popup {
							id
							title
							content
							cmsForm
						}
						formSubContent {
							id
							title
							content
						}
					}
				}
			}
		`

		const variables = {
			input: {
				pageId: pageId,
				...item,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('updateCmsPageContent')
			const valueItem = mutationRoot.getLinkedRecord('value')
			const previousId = mutationRoot.getValue('previousId')

			if (valueItem.getDataID() !== previousId) {
				const website = store.get(this.props.websiteId)
				const pages = website.getLinkedRecords('pages')
				if (pages) {
					const newPageList = [...pages, valueItem].filter((item) => item.getDataID() !== previousId)
					website.setLinkedRecords(newPageList, 'pages')
				}
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: onMutationCompleted(),
			onError: (err) => console.error(err),
			updater,
		})
	}

	render() {
		const { item, setDirtyState, router } = this.props
		return <CmsEditorComponent item={item} updateItem={this.updateItem} setDirtyState={setDirtyState} router={router} />
	}
}

class CmsEditorComponent extends React.Component {
	static propTypes = {
		item: PropTypes.object,
		updateItem: PropTypes.func.isRequired,
		setDirtyState: PropTypes.func.isRequired,
		router: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			item: props.item,
			originalItem: props.item,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState({
			item: nextProps.item,
		})
	}

	onSubmit = (values) => {
		const { updateItem, setDirtyState, router } = this.props
		const item = { ...this.state.item }

		item.title = values.title
		item.link = values.link
		item.content = values.content
		//item.teaserText = values.teaserText
		item.status = values.status
		item.publicationStartDate =
			values.publicationStartDate !== null ? values.publicationStartDate.format('YYYY-MM-DD') : null
		item.publicationEndDate = values.publicationEndDate !== null ? values.publicationEndDate.format('YYYY-MM-DD') : null

		item.browserTitle = values.browserTitle
		item.browserDescription = values.browserDescription
		item.browserKeywords = values.browserKeywords

		item.cmsForm = values.cmsForm

		updateItem(item)
		setDirtyState(false)

		setTimeout(() => {
			router.navigate('/pages/' + encodeURIComponent(item.link))
		}, 800)
		return true
	}

	onCancel = () => {
		this.setState({
			item: { ...this.state.originalItem },
		})
		this.props.setDirtyState(false)
	}

	onValuesChange = (values) => {
		this.props.setDirtyState(true)
	}

	render() {
		const { item } = this.state
		return (
			<div className="cms-editor">
				<Form
					ref={(c) => {
						this.form = c
					}}
					onSubmit={this.onSubmit}
					onValuesChange={this.onValuesChange}
				>
					<Input
						className="titleInput"
						label="Pagina titel"
						name="title"
						initialValue={item.title}
						required={true}
						maxLength={250}
					/>
					<Input label="Link" name="link" initialValue={item.link} required={true} maxLength={95} />

					<Input label="Browser Titel" name="browserTitle" initialValue={item.browserTitle} maxLength={250} />
					<Input
						label="Browser Omschrijving"
						name="browserDescription"
						initialValue={item.browserDescription}
						maxLength={250}
					/>

					<Input
						label="Browser Trefwoorden"
						name="browserKeywords"
						initialValue={item.browserKeywords}
						disabled={item.browserKeywords === null || item.browserKeywords.length === 0}
						help="Trefwoorden worden niet meer gebruikt door Google voor het indexeren van de pagina. Reeds ingevoerde trefwoorden kunnen nog gewijzigd worden, voor nieuwe pagina's is dit niet meer mogelijk."
						maxLength={250}
					/>

					<Row gutter={8}>
						<Col span={5}>
							<Select
								label="Status"
								name="status"
								initialValue={item.status === null ? 'FINAL' : item.status}
								options={CMS_STATUS_OPTIONS}
							/>
						</Col>
						<Col span={5}>
							<DatePicker
								label="Publicatie vanaf"
								name="publicationStartDate"
								initialValue={item.publicationStartDate ? moment(item.publicationStartDate, 'YYYY-MM-DD') : null}
								placeholder="Publicatie vanaf"
								required={true}
							/>
						</Col>
						<Col span={5}>
							<DatePicker
								label="tot"
								name="publicationEndDate"
								initialValue={item.publicationEndDate ? moment(item.publicationEndDate, 'YYYY-MM-DD') : null}
								placeholder="Publicatie tot"
							/>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Select
								label="Formulier"
								name="cmsForm"
								initialValue={item.cmsForm === null ? 'NONE' : item.cmsForm}
								options={CMS_FORM_OPTIONS}
							/>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<CKEditor
								id={item !== null && item.id !== null ? 'cmsPageContent' + item.id : 'cmsPageContent-new'}
								name="content"
								initialValue={item.content || ''}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}

export default withRouter(withBlocking(CmsPageContentEditor))
