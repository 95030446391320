import environment from '../../../createRelayEnvironment'
import { commitMutation, graphql } from 'react-relay'

const uploadFiles = (dir, parentDirectoryId, files, viewerId, maxNumberOfResults) => {
	const mutation = graphql`
		mutation mutationsUploadMutation($directory: String!, $parentDirectoryId: Int, $maxNumberOfResults: Int) {
			uploadFiles(
				directory: $directory
				parentDirectoryId: $parentDirectoryId
				maxNumberOfResults: $maxNumberOfResults
			) {
				value {
					id
					previewUrl
					name
					type
					staticUrl
					directoryId
					fileId
				}
			}
		}
	`

	// assert that the dir starts with "/", so that the path is rightly constructed between owner and directory
	// @see CMSFile.java:composeUrl()
	const dirFormatted = dir.startsWith('/') ? dir : '/' + dir

	const variables = {
		directory: dirFormatted,
		parentDirectoryId: parentDirectoryId,
		maxNumberOfResults: maxNumberOfResults,
	}

	const uploadables = {}
	Object.keys(files).forEach((key) => {
		uploadables['file_' + key] = files[key]
	})

	const updater = (store) => {
		const mutationRoot = store.getRootField('uploadFiles')
		const valueItem = mutationRoot.getLinkedRecords('value')

		// add arguments to specify store location, see https://facebook.github.io/relay/docs/en/next/relay-store.html#recordproxy
		let args = { folder: dir, directoryId: parentDirectoryId, maxNumberOfResults: maxNumberOfResults }
		const node = store.get(viewerId)
		const listItems = node.getLinkedRecords('CmsFiles', args)
		if (listItems) {
			node.setLinkedRecords(valueItem, 'CmsFiles', args)
		}
	}

	return new Promise((resolve, reject) => {
		commitMutation(environment, {
			mutation,
			variables,
			uploadables,
			onCompleted: resolve,
			onError: reject,
			updater,
		})
	})
}

const createDir = (parent, parentDirectoryId, newDir) => {
	return new Promise((resolve, reject) => {
		const mutation = graphql`
			mutation mutationsCreateDirMutation($parent: String!, $parentDirectoryId: Int, $newDir: String!) {
				createDir(parent: $parent, parentDirectoryId: $parentDirectoryId, newDir: $newDir) {
					value {
						name
					}
				}
			}
		`

		const variables = {
			parent,
			parentDirectoryId,
			newDir,
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				resolve(response, errors)
			},
			onError: reject,
		})
	})
}

const deleteFiles = (directory, parentDirectoryId, filenames, fileIds, viewerId, maxNumberOfResults) => {
	return new Promise((resolve, reject) => {
		const mutation = graphql`
			mutation mutationsDeleteFilesMutation(
				$directory: String!
				$parentDirectoryId: Int
				$filenames: [String!]!
				$fileIds: [ID!]!
				$maxNumberOfResults: Int
			) {
				deleteFiles(
					directory: $directory
					parentDirectoryId: $parentDirectoryId
					filenames: $filenames
					fileIds: $fileIds
					maxNumberOfResults: $maxNumberOfResults
				) {
					value {
						id
						previewUrl
						name
						type
					}
				}
			}
		`

		const variables = {
			directory,
			filenames,
			fileIds,
			maxNumberOfResults: maxNumberOfResults,
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('deleteFiles')
			const valueItem = mutationRoot.getLinkedRecords('value')

			let args = { folder: directory, directoryId: parentDirectoryId, maxNumberOfResults: maxNumberOfResults }
			const node = store.get(viewerId)
			const listItems = node.getLinkedRecords('CmsFiles', args)
			if (listItems) {
				node.setLinkedRecords(valueItem, 'CmsFiles', args)
			}
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: resolve,
			onError: reject,
			updater,
		})
	})
}

export { uploadFiles, createDir, deleteFiles }
