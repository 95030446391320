import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './UploadButton.css'
import { UploadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const UploadButton = ({ onFileChanged, className, mutltiple, accept }) => {
	const inputRef = useRef(null)
	return (
		<Button
			type="primary"
			icon={<UploadOutlined />}
			onClick={() => {
				inputRef.current.click()
			}}
		>
			Bestand uploaden
			<input
				type="file"
				ref={inputRef}
				onChange={onFileChanged}
				className="fileinput"
				multiple={mutltiple}
				accept={accept}
				style={{ display: 'none' }}
			/>
		</Button>
	)
}

UploadButton.propTypes = {
	onFileChanged: PropTypes.func,
	className: PropTypes.string,
}

export default UploadButton
