import { PlusOutlined, UpSquareOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from '../../helpers/general/WithRouterHoc'
import { addGuiderSteps } from '../../reducers/guiderSteps'
import { CreateCmsPageModal } from '../cms'
import { NewsLetterWidget } from '../list/'
import PageSelector from '../PageSelector'
import { currentWebsiteProps } from '../shared'
import {
	BlogPageContainer,
	CmsPageContainer,
	DeeldienstenPageContainer,
	DisclaimerPageContainer,
	NewsPageContainer,
	ProductieRechtenPageContainer,
	SubscriberPageContainer,
	WonenSubscriberPageContainer,
} from '../websitepages/index'
import HomePageEditor from './homepage/HomePageEditor'

const defaultSelectedValue = { value: '/' }

const mapDispatchToProps = (dispatch) => ({
	addSteps(steps) {
		dispatch(addGuiderSteps(steps))
	},
})

@connect(currentWebsiteProps, mapDispatchToProps)
class CmsEditorPage extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
		websiteName: PropTypes.string.isRequired,
		router: PropTypes.object,
	}

	state = {
		selectedValue: defaultSelectedValue,
		isSaved: true,
		showCreateModal: false,
		page: null,
	}

	componentDidMount() {
		const { selectedPage } = this.props.router.params

		this.setState({
			selectedValue: {
				value: selectedPage,
			},
		})

		setTimeout(() => {
			this.props.addSteps([
				{
					title: 'Pagina toevoegen',
					text: <span>Voeg hier een pagina toe</span>,
					selector: '.add-button',
					position: 'left',
					isFixed: true,
				},
			])
		}, 500)
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		//console.log("Nedt", nextProps);
		if (nextProps.websiteId !== this.props.websiteId) {
			this.props.router.navigate('/pages/' + encodeURIComponent('/'))
			return
		}
		if (nextProps.router.params.selectedPage !== this.props.router.params.selectedPage) {
			this.setState({
				selectedValue: {
					value: nextProps.router.params.selectedPage,
				},
			})
		}
	}

	onSelectChange = (page) => {
		// Select.onChange passes item with value, creating a new page passes item with link.
		const pageLink = page.value ? page.value : page.link
		this.props.router.navigate('/pages/' + encodeURIComponent(pageLink))
	}

	openCreateModal = () => [
		this.setState({
			showCreateModal: true,
		}),
	]

	closeCreateModal = () => {
		this.setState({
			showCreateModal: false,
		})
	}

	onPageSelectorLoaded = (page) => {
		this.setState({ page })
	}

	renderPageComponent = () => {
		const { router, route } = this.props
		const { page } = this.state

		if (!page) {
			return
		}

		const selectedPage = page.value

		if (selectedPage === '/') {
			return <HomePageEditor />
		}

		let comp
		if (page.pageType === 'NEWS') {
			comp = <NewsPageContainer pageId={page.id} />
		} else if (page.pageType === 'BLOG') {
			comp = <BlogPageContainer pageId={page.id} />
		} else if (selectedPage === '/nieuwsbrief/') {
			comp = (
				<NewsLetterWidget errorMessage="Geen nieuwsbrieven gevonden. Maakt u nog geen gebruik van de Nieuwsbrief module, neem contact op met uw accountmanager!" />
			)
		} else if (page.pageType === 'CMS') {
			comp = <CmsPageContainer pageId={page.id} />
		} else if (page.pageType === 'DEELDIENSTEN') {
			comp = <DeeldienstenPageContainer pageId={page.id} />
		} else if (page.pageType === 'SUBSCRIBER') {
			comp = <SubscriberPageContainer pageId={page.id} />
		} else if (page.pageType === 'WONENSUBSCRIBER') {
			comp = <WonenSubscriberPageContainer pageId={page.id} />
		} else if (page.pageType === 'DISCLAIMER') {
			comp = <DisclaimerPageContainer pageId={page.id} />
		} else if (page.pageType === 'PRODUCTIERECHTEN') {
			comp = <ProductieRechtenPageContainer pageId={page.id} />
		} else {
			comp = <div>Fout bij ophalen van de pagina.</div>
		}

		return <div className="theme-picker-container theme-wrapper">{comp}</div>
	}

	render() {
		const { websiteName } = this.props
		const { showCreateModal, selectedValue } = this.state

		return (
			<div>
				<Pager>
					<Row align={'center'} type={'flex'} style={{ marginBottom: 20 }}>
						<Col>
							<a href={'https://' + websiteName} target={'_blank'}>
								<WebsiteTitle>{websiteName}</WebsiteTitle>
								<UpSquareOutlined />
							</a>
						</Col>
					</Row>
					<Row>
						<Col>
							<label>Selecteer pagina:</label>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col span={20}>
							<PageSelector
								onLoad={this.onPageSelectorLoaded}
								selectedValue={selectedValue}
								onPageSelected={this.onSelectChange}
								websiteId={this.props.websiteId}
							/>
						</Col>
						<Col span={4}>
							<Button className="add-button" type="primary" icon={<PlusOutlined />} onClick={this.openCreateModal} />
							<CreateCmsPageModal
								showModal={showCreateModal}
								closeModal={this.closeCreateModal}
								onPageCreated={this.onSelectChange}
							/>
						</Col>
					</Row>
				</Pager>
				{this.renderPageComponent()}
			</div>
		)
	}
}

const WebsiteTitle = styled.span`
	color: black;
	font-size: 20px;
	margin-right: 10px;
	text-decoration: none;

	&:hover {
		color: #39acff;
	}
`

const Pager = styled.div`
	width: 600px;
	margin: 30px auto 0 auto;
`

export default withRouter(CmsEditorPage)
