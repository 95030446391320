/**
 * @flow
 * @relayHash a58e2e807d75a9650c4880dc9a4d0ff1
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsOrderMutationVariables = {|
  input: {
    items: $ReadOnlyArray<{
      id: string;
      order: number;
      parentId?: ?string;
    }>;
  };
|};
export type mutationsOrderMutationResponse = {|
  +updateMenuOrder: ?{|
    +value: $ReadOnlyArray<{|
      +id: string;
      +order: ?number;
      +parentId: ?string;
    |}>;
  |};
|};
*/


/*
mutation mutationsOrderMutation(
  $input: UpdateMenuItemOrderInput!
) {
  updateMenuOrder(input: $input) {
    value {
      id
      order
      parentId
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateMenuItemOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsOrderMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateMenuItemOrderInput!"
          }
        ],
        "concreteType": "MenuItemOrderPayload",
        "name": "updateMenuOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsOrderMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "UpdateMenuItemOrderInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsOrderMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "UpdateMenuItemOrderInput!"
          }
        ],
        "concreteType": "MenuItemOrderPayload",
        "name": "updateMenuOrder",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "MenuItem",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "order",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "parentId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsOrderMutation(\n  $input: UpdateMenuItemOrderInput!\n) {\n  updateMenuOrder(input: $input) {\n    value {\n      id\n      order\n      parentId\n    }\n  }\n}\n"
};

module.exports = batch;
