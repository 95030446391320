import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Modal, Select } from '../general'
import { Col } from 'antd'

const RewriteModal = ({ visible, handleSubmit, rewriteRule, handleCancel }) => {
	const form = useRef(null)

	const onClose = () => {
		handleCancel()
		form.current.resetFields()
	}

	const onSubmit = (values) => {
		handleSubmit(values)
		form.current.resetFields()
	}

	return (
		<div>
			<Modal visible={visible} onCancel={onClose}>
				<Col span={22}>
					<Form ref={form} onSubmit={onSubmit}>
						<Input
							name="pattern"
							placeholder="pattern"
							required={true}
							initialValue={rewriteRule ? rewriteRule.pattern : ''}
						/>
						<Input
							name="replace"
							placeholder="replace"
							required={true}
							initialValue={rewriteRule ? rewriteRule.replace : ''}
						/>
						<Input
							name="parameters"
							placeholder="parameters"
							initialValue={rewriteRule ? rewriteRule.parameters : ''}
						/>
						<Select
							placeholder="status"
							name="status"
							initialValue={rewriteRule ? rewriteRule.status : '301'}
							options={[{ label: '301', value: '301' }]}
						/>
					</Form>
				</Col>
			</Modal>
		</div>
	)
}

RewriteModal.propTypes = {
	visible: PropTypes.bool,
	handleCancel: PropTypes.func,
	rewriteRule: PropTypes.object,
	handleSubmit: PropTypes.func,
}

export default RewriteModal
