/**
 * @flow
 * @relayHash 42dbaa061673e6f35ff0c90105016092
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type HeaderBannerQueries_createItem_MutationVariables = {|
  input: {
    link?: ?string;
    target?: ?string;
    websiteId: string;
  };
|};
export type HeaderBannerQueries_createItem_MutationResponse = {|
  +createHeaderBannerItem: ?{|
    +value: ?{|
      +id: string;
      +imagepath: ?string;
      +link: ?string;
      +listtypeid: ?string;
      +target: ?string;
    |};
  |};
|};
*/


/*
mutation HeaderBannerQueries_createItem_Mutation(
  $input: CreateHeaderBannerItemInput!
) {
  createHeaderBannerItem(input: $input) {
    value {
      id
      imagepath
      link
      listtypeid
      target
    }
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateHeaderBannerItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderBannerQueries_createItem_Mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateHeaderBannerItemInput!"
          }
        ],
        "concreteType": "HeaderBannerItemPayload",
        "name": "createHeaderBannerItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "ListItemImageRotator",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagepath",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "listtypeid",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "target",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "HeaderBannerQueries_createItem_Mutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "CreateHeaderBannerItemInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "HeaderBannerQueries_createItem_Mutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "CreateHeaderBannerItemInput!"
          }
        ],
        "concreteType": "HeaderBannerItemPayload",
        "name": "createHeaderBannerItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "args": null,
            "concreteType": "ListItemImageRotator",
            "name": "value",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "imagepath",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "link",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "listtypeid",
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "args": null,
                "name": "target",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation HeaderBannerQueries_createItem_Mutation(\n  $input: CreateHeaderBannerItemInput!\n) {\n  createHeaderBannerItem(input: $input) {\n    value {\n      id\n      imagepath\n      link\n      listtypeid\n      target\n    }\n  }\n}\n"
};

module.exports = batch;
