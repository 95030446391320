import { FieldTypes } from '../../../dnd/FieldRenderer'
import { TITLE, TWITTER } from './regexps'

export default ({ settings }) => ({
	id: 'twidget',
	title: 'Twitter',
	icon: 'twitter',
	size: {
		defaultWidth: 1,
		minWidth: 1,
		maxWidth: 1,
		defaultHeight: 1,
		minHeight: 1,
		maxHeight: 2,
	},
	isDisabled: false,
	fieldTypes: [
		{
			name: 'gebruikerId',
			label: 'Gebruiker ID',
			help: 'Twitter gebruikersnaam',
			placeholder: 'Twitter ID',
			type: FieldTypes.TEXT,
			defaultValue: 'nvmog',
			rules: [{ required: true, pattern: TWITTER }],
		},
		{
			name: 'twitterfeedwidgetTitel',
			label: 'Titel',
			help: 'De titel die boven dit blok komt te staan',
			placeholder: 'Titel',
			type: FieldTypes.TEXT,
			defaultValue: '',
			rules: [{ required: true, pattern: TITLE }],
		},
	],
})
