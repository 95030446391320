import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

class RealModal extends React.Component {
	static propTypes = {
		onCancel: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
		width: PropTypes.number,
	}

	static defaultProps = {
		width: 900,
	}

	render() {
		const { children, width } = this.props
		return (
			<Modal footer={null} width={width} onCancel={this.props.onCancel} visible={this.props.visible} destroyOnClose>
				{children}
			</Modal>
		)
	}
}

export default RealModal
