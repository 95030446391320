import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { ItemTypes } from '../../../constants'
import SortableList from '../../sortablelist/SortableList'
import CmsListItem from './CmsListItem'

export default class Component extends React.PureComponent {
	static propTypes = {
		items: PropTypes.array.isRequired,
		onItemsChange: PropTypes.func.isRequired, // pass changes door aan de parent
	}

	constructor(props) {
		super(props)

		this.state = {
			items: cloneDeep(this.props.items),
			isDraggable: true,
			showNewPanel: false,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.state.items === nextProps.items) {
			return
		}

		this.setState({
			items: cloneDeep(nextProps.items),
		})
	}

	render() {
		const items = [...this.state.items]
		items.sort((a, b) => {
			return b.order - a.order
		})
		return (
			<div className="nieuws-list-container">
				<Button className={'addNewButton'} icon={<PlusOutlined />} type={'dashed'} onClick={this.onNewItemClick}>
					Nieuw artikel
				</Button>
				<div className="nieuws-list">
					{this.renderNewItem()}
					<SortableList
						items={items}
						onItemsReset={this.onItemsReset}
						onItemsChange={this.onItemsChange}
						renderChildren={this.renderChildren}
						isDraggable={this.state.isDraggable}
						dropTargetType={ItemTypes.NIEUWS_LIST_ITEM}
					/>
				</div>
			</div>
		)
	}

	onItemsReset = (items) => {
		this.setState({ items: items })
	}

	onItemsChange = (items, dragActionFinished) => {
		let clonedItems = cloneDeep(items)
		clonedItems.reverse().map((item, index) => {
			item.order = '' + (index + 1)
			return item
		})
		clonedItems.reverse()
		if (dragActionFinished) {
			this.props.onItemsChange(clonedItems)
		}
		this.setState({ items: clonedItems })
	}

	renderChildren = (childProps, item) => {
		const { isDraggable, isDragging } = childProps
		const { createItem, updateItem, deleteItem } = this.props
		return (
			<div>
				<CmsListItem
					item={item}
					createItem={createItem}
					editItem={updateItem}
					removeItem={deleteItem}
					isDraggable={isDraggable}
					isDragging={isDragging}
					mode="EDIT"
					enableEditMode={this.enableEditMode}
					disableEditMode={this.disableEditMode}
				/>
			</div>
		)
	}

	renderNewItem() {
		const { createItem, updateItem, deleteItem } = this.props

		if (!this.state.showNewPanel) {
			return ''
		}

		const item2 = {
			id: '',
			renderId: 'item' + new Date().getTime(),
			order: '',
			cmsContent: {
				title: '',
				link: '',
				teaserText: '',
				browserTitle: '',
				browserDescription: '',
				browserKeywords: '',
				content: '',
			},
		}

		return (
			<CmsListItem
				item={item2}
				createItem={createItem}
				editItem={updateItem}
				removeItem={deleteItem}
				isDraggable={false}
				mode="NEW"
				enableEditMode={this.enableEditMode}
				disableEditMode={this.disableEditMode}
				hideNewPanel={this.hideNewPanel}
			/>
		)
	}

	onNewItemClick = () => {
		this.setState({
			isDraggable: false,
			showNewPanel: true,
		})
	}

	enableEditMode = () => {
		this.setState({
			isDraggable: false,
		})
	}

	disableEditMode = () => {
		this.setState({
			isDraggable: true,
			showNewPanel: false,
		})
	}
}
