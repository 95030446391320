/**
 * @flow
 * @relayHash 4eb7092db2c9274243fa5d6bbd024a7c
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteBatch} from 'relay-runtime';
export type mutationsDeleteMutationVariables = {|
  input: {
    id: string;
  };
|};
export type mutationsDeleteMutationResponse = {|
  +deleteMenuItem: ?{|
    +deletedIds: $ReadOnlyArray<string>;
  |};
|};
*/


/*
mutation mutationsDeleteMutation(
  $input: EntityIdInput!
) {
  deleteMenuItem(input: $input) {
    deletedIds
  }
}
*/

const batch /*: ConcreteBatch*/ = {
  "fragment": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsDeleteMutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "MenuDeletedPayload",
        "name": "deleteMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedIds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "id": null,
  "kind": "Batch",
  "metadata": {},
  "name": "mutationsDeleteMutation",
  "query": {
    "argumentDefinitions": [
      {
        "kind": "LocalArgument",
        "name": "input",
        "type": "EntityIdInput!",
        "defaultValue": null
      }
    ],
    "kind": "Root",
    "name": "mutationsDeleteMutation",
    "operation": "mutation",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "EntityIdInput!"
          }
        ],
        "concreteType": "MenuDeletedPayload",
        "name": "deleteMenuItem",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "args": null,
            "name": "deletedIds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "text": "mutation mutationsDeleteMutation(\n  $input: EntityIdInput!\n) {\n  deleteMenuItem(input: $input) {\n    deletedIds\n  }\n}\n"
};

module.exports = batch;
