import React from 'react'
import PropTypes from 'prop-types'
import './PartnerLogoWizard.css'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps } from './../shared/index'
import { Form, Input } from '../general'
import { Col, Modal, Row, Tooltip } from 'antd'

@connect(currentWebsiteProps)
export default class extends React.Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		onSelectFile: PropTypes.func.isRequired,
	}

	state = {
		showModal: true,
		filter: '',
	}

	render() {
		const { filter } = this.state
		const { websiteId } = this.props

		return (
			<div>
				<Modal
					title="Standaard iconen"
					visible={this.state.showModal}
					onOk={this.close}
					onCancel={this.close}
					width={'60%'}
					wrapClassName={'parnerlogowizard-container'}
				>
					<Row>
						<Col>
							<Form showSubmit={false}>
								<Input
									name={'zoeken'}
									label="Zoeken"
									type="text"
									onChange={(e) => this.setState({ filter: e.target.value })}
								/>
							</Form>
							<QueryRenderer
								environment={environment}
								variables={{
									id: websiteId,
								}}
								query={graphql`
									query PartnerLogoWizardQuery($id: ID!) {
										node(id: $id) {
											... on Website {
												id
												defaultPartnerLogos {
													...PartnerLogoWizard_images
												}
											}
										}
									}
								`}
								render={({ error, props }) => {
									if (error) {
										return <div>Er is een error opgetreden</div>
									} else if (props) {
										return (
											<ImageList
												onSelectFile={this.pickAndCloseEditor}
												images={props.node.defaultPartnerLogos}
												filter={filter}
											/>
										)
									} else {
										return <div>Laden...</div>
									}
								}}
							/>
						</Col>
					</Row>
				</Modal>
			</div>
		)
	}

	pickAndCloseEditor = (image) => {
		const { onSelectFile } = this.props
		console.log(image)
		onSelectFile(image)
		this.close()
	}

	close = () => {
		this.setState({ showModal: false })
		setTimeout(() => {
			this.props.onClose()
		}, 500)
	}
}

class ImageListComponent extends React.Component {
	render() {
		const { images, onSelectFile } = this.props
		const filter = this.props.filter.toLowerCase()
		const filteredImages = images.filter((image) => image.title.toLowerCase().indexOf(filter) > -1)
		return (
			<div className="thumbmnail-container">
				{filteredImages.map((image) => (
					<Tooltip title={image.title} key={image.imagePath}>
						<div
							onClick={() => onSelectFile(image)}
							style={{ backgroundImage: `url(https://www.realworks.nl${image.imagePath})` }}
							className="thumbmnail"
						/>
					</Tooltip>
				))}
			</div>
		)
	}
}

const ImageList = createFragmentContainer(
	ImageListComponent,
	graphql`
		fragment PartnerLogoWizard_images on PartnerLogo @relay(plural: true) {
			id
			title
			subtitle
			link
			imagePath
		}
	`,
)

ImageList.prototype = React.Component.prototype
