import { useSearchParams } from 'react-router-dom'

export function withSearchParams(Component) {
	function ComponentWithSearchParamsProp(props) {
		const [searchParams, setSearchParams] = useSearchParams()
		return <Component {...props} searchParams={searchParams} setSearchParams={setSearchParams} />
	}

	return ComponentWithSearchParamsProp
}
