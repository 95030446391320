import React, { Component } from 'react'
import Dnd from '../../dnd/Dnd'
import WidgetPicker from '../../dnd/WidgetPicker'
import Widget from './Widget'
import { reorder } from '../../dnd/helper'
import _ from 'lodash'
import WIDGET_TYPES from './WidgetTypes'
import './HomePagedEditor.css'
import { Button, message } from 'antd'
import { addGuiderSteps } from '../../../reducers/guiderSteps'
import environment from '../../../createRelayEnvironment'
import { commitMutation, createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import PropTypes from 'prop-types'
import { currentWebsiteProps } from '../../shared'
import { connect } from 'react-redux'
import Layout from './Layout'
import WidgetConfigManager, { registerWidgetTypes, Widget as WidgetConfig } from './LayoutConfig'
import { prepareDbLegacyFormat } from './helper'
import { assertTrue } from '../../general/helper'
import { withBlocking } from '../../../helpers/general/WithBlockingHoc'

const MAX_COLS = 3
const COL_WIDTH = 170
const COL_HEIGHT = 170

@connect(currentWebsiteProps)
export default class extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	render() {
		const { websiteId, router } = this.props

		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query HomePageEditorQuery($id: ID!) {
						node(id: $id) {
							id
							... on Website {
								...HomePageEditor_config
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						console.error('Error', error)
						return <div>Er is een fout opgetreden. Probeer het later nogmaal.</div>
					} else if (props) {
						return <HomePageEditor config={props.node} />
					} else {
						return <div>Laden...</div>
					}
				}}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	addSteps(steps) {
		dispatch(addGuiderSteps(steps))
	},
})

@connect(currentWebsiteProps, mapDispatchToProps)
class HomePageEditorComponent extends Component {
	static propTypes = {
		config: PropTypes.object.isRequired,
		setPrompt: PropTypes.func,
	}

	// keep a copy of the layout for rendering performance.
	// TODO: eliminate this field to have a single source of truth
	dndLayout = null

	state = {
		// mapping van welk item in DND welke type widget is
		dndLayout: [],

		//
		widgetPreviewState: {
			initialOffset: {
				x: 0,
				y: 0,
			},
			currentOffset: {
				x: 0,
				y: 1,
			},
			isDragging: false,
		},
		activeDndId: null,
		activeTab: 'widgets',
		isSaved: true,
		isSaving: false,
	}

	constructor(props) {
		super(props)

		const { homepageConfig } = this.props.config
		const settings = this.props.config.settings.systemSettings

		this.widgetTypes = WIDGET_TYPES({ settings })
		registerWidgetTypes(this.widgetTypes)

		// we don't work with placeholder blocks ('emptyBlock) anymore, so construct a layout without these
		this.dndLayout = Layout.newFromDbLegacyFormat(homepageConfig.blocks)

		// the same for bookkeeping of the configurations per widget
		const blocks = homepageConfig.blocks.filter((block) => block.widgetxmlname !== 'emptyBlock')

		// create the book-keeping of the configuations (field values, position) of the widgets
		this.widgetConfigManager = new WidgetConfigManager()

		// fill the book-keeping from current configuration in the database (or whatever the datasource was)
		this.dndLayout.getLayout().forEach((position) => {
			const config = blocks[position.key]

			const widget = new WidgetConfig(config.widgetxmlname, config.parameters)
			widget.setPosition(position)

			this.widgetConfigManager.addWidget(position.key, widget)
		})
	}
	//Disable this for now, doesn't completely work.
	// UNSAFE_componentWillReceiveProps = (nextProps) => {
	// 	const { homepageConfig } = nextProps.config
	//
	// 	// we don't work with placeholder blocks ('emptyBlock) anymore, so construct a layout without these
	// 	this.dndLayout = Layout.newFromDbLegacyFormat(homepageConfig.blocks)
	//
	// 	// the same for bookkeeping of the configurations per widget
	// 	const blocks = homepageConfig.blocks.filter((block) => block.widgetxmlname !== 'emptyBlock')
	//
	// 	// fill the book-keeping from current configuration in the database (or whatever the datasource was)
	// 	this.dndLayout.getLayout().forEach((position) => {
	// 		const config = blocks[position.key]
	// 		const widget = new WidgetConfig(config.widgetxmlname, config.parameters)
	// 		widget.setPosition(position)
	// 		this.widgetConfigManager.addWidget(position.key, widget)
	// 	})
	// }

	componentWillUnmount() {
		const { setPrompt } = this.props
		// remove the routing hook
		setPrompt('', false)
	}

	componentDidMount() {
		setTimeout(() => {
			this.props.addSteps([
				{
					title: 'Pagina bewerken',
					text: 'Bewerk hier de pagina die hierboven is geselecteerd.',
					selector: '.homepage',
					position: 'left',
					isFixed: true,
					callback: (data, router) => router.navigate('/settings/'),
				},
			])
		}, 800 /* wait for pageselector elements first to be added, dirty way, but fast and Lean */)

		this.setState({ dndLayout: this.dndLayout.getLayout() })

		// TODO: enable this when implemented proper (now you cannot even change widget fields)
		//this.bindDeactivationClick()
	}

	componentDidUpdate(prevProps, prevState) {
		const { setPrompt } = this.props
		// listen for when user is navigating away from page, so we can notify if changes aren't saved
		setPrompt(
			'Weet u het zeker dat u de pagina wilt verlaten? Uw wijzigingen worden niet bewaard.',
			!this.state.isSaved,
		)
	}

	bindDeactivationClick() {
		const isChildOf = (child, parent) => {
			while (child && child !== parent) {
				child = child.parentNode
			}
			return !!child // convert from child (null?) to boolean
		}

		document.addEventListener('click', (e) => {
			if (!isChildOf(e.target, this._clickOutsideDetector)) {
				this.unFocusItem()
			}
		})
	}

	render() {
		const { dndLayout, widgetPreviewState, activeTab, activeDndId, isSaving, isSaved } = this.state

		let pickerWidget = null
		if (activeDndId !== null) {
			const activeWidget = this.widgetConfigManager.getWidget(activeDndId)

			//TODO: eliminate this step by changing datastructure for 'fieldValues' props in WidgetPicker
			const values = activeWidget.getValues().reduce((attr, item) => {
				attr[item.name] = item.value
				return attr
			}, {})

			pickerWidget = {
				title: activeWidget.getType().title,
				description: activeWidget.getType().description,
				fields: activeWidget.getType().fieldTypes,
				fieldValues: values,
				key: activeDndId,
			}
		}

		const rows = this.dndLayout.getRows() + 1 /* add an empty row so user cad add widgets at the bottom */

		return (
			<div className={'homepage'}>
				<div className="widgets-container">
					<div style={{ position: 'relative', marginRight: 100, marginTop: 20 }}>
						<Button
							type="primary"
							onClick={this.onSaveClick}
							loading={isSaving}
							style={{
								position: 'absolute',
								right: 0,
								top: -40,
							}}
							disabled={isSaved}
						>
							Opslaan
						</Button>

						<div ref={(ref) => (this._clickOutsideDetector = ref)}>
							<Dnd
								onItemAdd={this.onWidgetAdd}
								onItemRemove={this.onWidgetRemove}
								layout={dndLayout}
								widgetPreviewState={widgetPreviewState}
								onLayoutChange={this.onLayoutChange}
								maxColumns={MAX_COLS}
								maxRows={rows}
								colHeight={COL_HEIGHT}
								colWidth={COL_WIDTH}
								onItemFocus={this.onItemFocus}
								className="homepage-dnd"
								renderItem={(position) => (
									<Widget
										enableExpandHorizontal={this.canExpandHorizontal(position)}
										onExpandHorizontal={this.onExpandHorizontal}
										enableCollapseHorizontal={this.canCollapseHorizontal(position)}
										onCollapseHorizontal={this.onCollapseHorizontal}
										enableExpandVertical={this.canExpandVertical(position)}
										onExpandVertical={this.onExpandVertical}
										enableCollapseVertical={this.canCollapseVertical(position)}
										onCollapseVertical={this.onCollapseVertical}
										index={position.key}
										isActive={activeDndId === position.key}
										onTrash={() => this.onWidgetRemove(position.key)}
										{...this.widgetConfigManager.getWidget(position.key).getType()}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<WidgetPicker
					style={{ width: COL_WIDTH * 2 + 80, top: 250, height: window.innerHeight - 250 }}
					className="homepage-widgetpicker"
					activeTab={activeTab}
					onTabChange={(index) => this.setState({ activeTab: index })}
					configPanelActive={false}
					widgets={this.widgetTypes}
					activeWidgetConfig={pickerWidget}
					onValuesChange={this.onValuesChange}
					onPreviewOffsetChange={this.onPreviewOffsetChange}
				/>
			</div>
		)
	}

	onSaveClick = () => {
		// the server side relies on a datastrucute with placeholder blocks for empty spaces, so onject those boys here
		const includingEmptyBlocks = prepareDbLegacyFormat(this.dndLayout, this.widgetConfigManager)

		// then cosntruct the json object
		const config = includingEmptyBlocks.map((widget, index) => {
			// it appears that in the old cms drag and drop system for some widget the height should always be -1.
			// Fix that here, later this should be fixed on the website place if the old system is no more
			// See: https://jira.realworks.nl/browse/BMW-1956
			const negativeHeightHacks = ['spotlight', 'smoelenboek']
			const hackHeight = negativeHeightHacks.indexOf(widget.getWidgetXmlName()) > -1 ? -1 : widget.getSize().height

			return {
				order: index + 1,
				widgetxmlname: widget.getWidgetXmlName(),
				parameters: widget.getParameters(),
				width: widget.getSize().width,
				height: hackHeight,
			}
		})

		// and send it to server
		this.commitMutation(config)
	}

	commitMutation = (config) => {
		this.setState({ isSaving: true })
		const { id: websiteId } = this.props.config
		const mutation = graphql`
			mutation HomePageEditorSaveConfigMutation($input: HomepageLayoutInput!) {
				saveHomepageLayout(input: $input) {
					blocks {
						width
						height
						widgetxmlname
						parameters {
							name
							value
						}
					}
				}
			}
		`

		const variables = {
			input: {
				websiteId: websiteId,
				blocks: config,
			},
		}

		const updater = (store) => {
			const mutationRoot = store.getRootField('saveHomepageLayout')
			const mutationBlocks = mutationRoot.getLinkedRecords('blocks')
			const node = store.get(websiteId)
			const homePageConfig = node.getLinkedRecord('homepageConfig')
			homePageConfig.setLinkedRecords(mutationBlocks, 'blocks')
		}

		commitMutation(environment, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				this.setDirty(false)
				this.setState({ isSaving: false })
				message.success('Wijzingen zijn bewaard', 3)
			},
			onError: (err) => {
				this.setState({ isSaving: false })
				message.error('Er is een fout opgetreden. Probeer het nogmaals')
				console.error(err)
			},
			updater,
		})
	}

	/**
	 *
	 * @param values an object passed by the Antd framework (can be a single name:value pair, when triggered by setFieldsValue, but also all the formfields when a antd field is changed directly)
	 */
	onValuesChange = (values) => {
		const { activeDndId } = this.state

		// make a clone of the current parameters so we can modify it
		const parameters = [...this.widgetConfigManager.getWidget(activeDndId).getParameters()]

		Object.keys(values).forEach((key) => {
			let name = key
			let value = values[key]
			// replace current value with the new one (of add is when is doesn't exist, this can happen when
			// when a field has been added to a widget but the widget has already been added to the homepage)
			const param = parameters.find((param) => param.name === name)
			if (param === undefined) {
				parameters.push({ name, value })
			} else {
				const index = parameters.indexOf(param)
				parameters.splice(index, 1, { name, value })
			}
		})

		// set values for current widget
		this.widgetConfigManager.getWidget(activeDndId).setValues(parameters)
		this.setDirty(true)
	}

	onItemFocus = (dndId) => {
		this.setState({
			activeDndId: dndId,
			activeTab: 'details',
		})
	}

	unFocusItem = () => {
		this.setState({
			activeDndId: null,
			activeTab: 'widgets',
		})
	}

	onWidgetAdd = (position, widgetConfig) => {
		const config = widgetConfig.fieldTypes
			.map((fieldConfig) => {
				assertTrue(
					typeof fieldConfig === 'object',
					`array item in "fieldTypes" should be of type Object, but is '${typeof fieldConfig}'. Further widget info: ${JSON.stringify(
						widgetConfig,
					)}`,
				)
				console.log(fieldConfig)

				if (fieldConfig.name !== undefined) {
					return {
						name: fieldConfig.name,
						value: fieldConfig.defaultValue || '',
					}
				} else {
					// if a field has no name, it means that it renders it'w own content, assert that that is the case indeed here
					if (fieldConfig.component === undefined) {
						throw new Error('Component has no field name, but also no component which is mandatory')
					}

					// null values are removed later on
					return null
				}
			})
			.filter((fieldConfig) => fieldConfig !== null)

		// create new widget...
		const widget = new WidgetConfig(widgetConfig.id, config)
		widget.setPosition(position)

		// ...and add it to our book-keeping
		this.widgetConfigManager.addWidget(position.key, widget)

		// TODO: should this step be redundant?
		this.setState({ dndLayout: this.dndLayout.getLayout() })
	}

	onWidgetRemove = (activeDndId) => {
		//this.setState({activeDndId: null})
		// remove from our book-keeping
		// const {activeDndId} = this.state
		this.dndLayout.removePosition(activeDndId)
		this.widgetConfigManager.removeWidget(activeDndId)

		this.setState({
			activeDndId: null,
			activeTab: 'widgets',
			dndLayout: this.dndLayout.getLayout(),
		})
		this.setDirty(true)
	}

	onExpandHorizontal = (e, key) => {
		e.stopPropagation()
		this.resizeWidget(key, { increaseWidth: +1 })
	}

	onExpandVertical = (e, key) => {
		e.stopPropagation()
		this.resizeWidget(key, { increaseHeight: 1 })
	}

	canExpandHorizontal = (item) => {
		const widget = this.widgetConfigManager.getWidget(item.key)
		const { maxWidth } = widget.getType().size

		const size = widget.getSize()
		const position = widget.getPosition()

		return size.width < maxWidth && position.x + size.width < MAX_COLS
	}

	canExpandVertical = (item) => {
		const widget = this.widgetConfigManager.getWidget(item.key)
		const { maxHeight } = widget.getType().size

		const size = widget.getSize()

		return size.height < maxHeight
	}

	onCollapseHorizontal = (e, key) => {
		e.stopPropagation()
		this.resizeWidget(key, { increaseWidth: -1 })
	}

	onCollapseVertical = (e, key) => {
		e.stopPropagation()
		this.resizeWidget(key, { increaseHeight: -1 })
	}

	/**
	 * TODO: unit testen
	 */
	canCollapseHorizontal = (item) => {
		const props = this.widgetConfigManager.getWidget([item.key]).getType()
		const { minWidth } = props.size
		return item.width > minWidth
	}

	canCollapseVertical = (item) => {
		const props = this.widgetConfigManager.getWidget([item.key]).getType()
		const { minHeight } = props.size
		return item.height > minHeight
	}

	resizeWidget = (key, { increaseWidth = 0, increaseHeight = 0 }) => {
		this.setDirty(true)
		const { dndLayout } = this.state

		const newOrder = dndLayout.map((item) =>
			item.key === key
				? {
						...item,
						width: item.width + increaseWidth,
						height: item.height + increaseHeight,
				  }
				: item,
		)
		const position = _.find(dndLayout, { key: key })
		reorder(newOrder, position.key, position.height)

		this.dndLayout.updateLayout(newOrder)

		const newPosition = _.find(this.dndLayout.getLayout(), { key: key })
		this.widgetConfigManager.getWidget(position.key).setPosition(newPosition)

		this.setState({ dndLayout: newOrder })
	}

	onPreviewOffsetChange = (initialOffset, currentOffset) => {
		this.setState({ widgetPreviewState: { initialOffset, currentOffset } })
	}

	onLayoutChange = (layout) => {
		this.dndLayout.updateLayout(layout)
		this.setState({
			dndLayout: layout,
		})

		this.setDirty(true)
	}

	setDirty(isDirty) {
		this.setState({ isSaved: !isDirty })
	}
}

const HomePageEditor = createFragmentContainer(
	withBlocking(HomePageEditorComponent),
	graphql`
		fragment HomePageEditor_config on Website {
			id
			settings {
				systemSettings {
					enableAlv
					enableBog
					enableNieuwbouw
					enableWonen
					enableAankoop
					enableVgmcomplex
					nieuwsPaginaNummer
				}
			}
			homepageConfig {
				blocks {
					width
					height
					widgetxmlname
					parameters {
						name
						value
					}
				}
			}
		}
	`,
)

HomePageEditor.prototype = React.Component.prototype

//export default HomePageEditor;
