import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragLayer } from 'react-dnd'
import { DndTypes } from './Types'
import FontAwesome from 'react-fontawesome'
import './DragLayer.css'

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
}

function getItemStyles(props) {
	const { initialOffset, currentOffset } = props
	if (!initialOffset || !currentOffset) {
		return {}
	}

	let { x, y } = currentOffset

	const transform = `translate(${x}px, ${y}px)`
	return {
		transform,
		WebkitTransform: transform,
	}
}

@DragLayer((monitor) => ({
	item: monitor.getItem(),
	initialOffset: monitor.getInitialSourceClientOffset(),
	currentOffset: monitor.getSourceClientOffset(),
	isDragging: monitor.isDragging(),
}))
export default class WidgetPreview extends Component {
	static propTypes = {
		item: PropTypes.object,
		itemType: PropTypes.string,
		initialOffset: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		currentOffset: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		isDragging: PropTypes.bool.isRequired,
		// snapToGrid: PropTypes.bool.isRequired,
	}

	UNSAFE_componentWillReceiveProps(next) {
		const { isDragging } = next
		if (!isDragging) {
			return
		}
		const { currentOffset, initialOffset } = this.props
		this.props.onOffsetChange(initialOffset, currentOffset)
	}

	renderItem(type, item) {
		switch (type) {
			case DndTypes.WIDGET:
				return <div>title: {item.title} </div>
			default:
				return <div>title: leheeee </div>
		}
	}

	render() {
		const { item, isDragging } = this.props

		if (!isDragging) {
			return null
		}

		return (
			<div style={layerStyles}>
				<div style={getItemStyles(this.props)}>
					<div className="draglayer-container">
						<div className="layer">
							<FontAwesome name={item.icon} />
						</div>
					</div>
					<br />
				</div>
			</div>
		)
	}
}
