import React from 'react'
import PropTypes from 'prop-types'
import { graphql, QueryRenderer } from 'react-relay'
import environment from '../../createRelayEnvironment'
import { Col, Row } from 'antd'
import { XMLPageCmsEditor } from '../cms'
import { createItem, updateItem } from '../cms/xmlpage/XMLPageCmsQueries'

export default class extends React.Component {
	static propTypes = {
		pageId: PropTypes.string.isRequired,
	}

	render() {
		const { pageId } = this.props
		if (pageId === undefined || pageId === null) {
			return <div />
		}
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: pageId,
				}}
				query={graphql`
					query ProductieRechtenPageContainerQuery($id: ID!) {
						node(id: $id) {
							... on ProductieRechtenPage {
								id
								title
								link
								pageType
								content {
									id
									link
									title
									content
									teaserText

									status
									publicationStartDate
									publicationEndDate

									browserTitle
									browserDescription
									browserKeywords
									cmsForm
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <ProductieRechtenPagePageContainer item={props.node} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class ProductieRechtenPagePageContainer extends React.Component {
	static propTypes = {
		item: PropTypes.object,
	}

	setContentDirtyState = (value) => {}

	render() {
		const { item } = this.props
		if (item === null || item === undefined) {
			return <div className="productierechtenpage-container">Pagina niet gevonden.</div>
		}
		return (
			<div className="productierechtenpage-container">
				<Row gutter={24}>
					<Col span={24}>
						<h2>{item.title}</h2>
						<XMLPageCmsEditor
							item={item.content}
							pageId={item.id}
							setDirtyState={this.setContentDirtyState}
							createItem={createItem}
							updateItem={updateItem}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}
