/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteFragment} from 'relay-runtime';
export type WebEmployeesWidget_webEmployees = $ReadOnlyArray<{|
  +id: string;
  +employee: ?{|
    +firstName: ?string;
    +photoUrl: ?string;
  |};
|}>;
*/


const fragment /*: ConcreteFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "WebEmployeesWidget_webEmployees",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "args": null,
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "name": "employee",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "firstName",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "height",
              "value": 200,
              "type": "Int!"
            },
            {
              "kind": "Literal",
              "name": "width",
              "value": 200,
              "type": "Int!"
            }
          ],
          "name": "photoUrl",
          "storageKey": "photoUrl{\"height\":200,\"width\":200}"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebEmployee"
};

module.exports = fragment;
