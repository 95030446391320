import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '@ant-design/compatible/assets/index.css'
import { Select, Form } from 'antd'
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay'
import environment from '../createRelayEnvironment'
import { connect } from 'react-redux'
import { currentWebsiteProps } from './shared/index'

const { Item: FormItem } = Form
const { Option } = Select

@connect(currentWebsiteProps)
export default class extends Component {
	static propTypes = {
		websiteId: PropTypes.string.isRequired,
	}

	render() {
		const { onPageSelected, websiteId } = this.props
		return (
			<QueryRenderer
				environment={environment}
				variables={{
					id: websiteId,
				}}
				query={graphql`
					query CmsPageSelectorQuery($id: ID!) {
						node(id: $id) {
							... on Website {
								id
								cmsPages {
									...CmsPageSelector_options
								}
							}
						}
					}
				`}
				render={({ done, error, props, retry, stale }) => {
					if (error) {
						return <div />
					} else if (props) {
						return <CmsPageSelector options={props.node.cmsPages} onPageSelected={onPageSelected} />
					} else {
						return <div />
					}
				}}
			/>
		)
	}
}

class CmsPageSelectorComponent extends Component {
	state = {
		selectedValue: null,
	}

	onSelectChange = (value) => {
		let selectedOption = this.props.options.find((option) => {
			return option.link === value
		})

		this.setState({
			selectedValue: value,
		})

		this.props.onPageSelected(selectedOption)
	}

	render() {
		const { options } = this.props
		const { selectedValue } = this.state

		const displayOptions = options.map((item) => {
			return { value: item.link, title: item.title, label: item.title + ' (' + item.link + ')', id: item.id }
		})

		return (
			<FormItem name="form-field-name" label={'Selecteer een pagina'}>
				<Select value={selectedValue} onChange={this.onSelectChange}>
					{displayOptions &&
						displayOptions.map((option) => (
							<Option key={option.value} value={option.value}>
								{option.label}
							</Option>
						))}
				</Select>
			</FormItem>
		)
	}
}

const CmsPageSelector = createFragmentContainer(
	CmsPageSelectorComponent,
	graphql`
		fragment CmsPageSelector_options on CmsPage @relay(plural: true) {
			id
			title
			link
		}
	`,
)

CmsPageSelector.prototype = React.Component.prototype
