/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type {ConcreteFragment} from 'relay-runtime';
export type settingsQueries_settings = {|
  +brokerSettings: {|
    +websiteTheme: ?string;
    +vestigingPaginaNummer: ?string;
    +cmsWeergaveNummer: ?string;
    +medewerkersPaginaNummer: ?string;
    +footerNummer: ?string;
    +brochureDetailType: ?string;
    +backgroundcolor: ?string;
    +menucolor: ?string;
    +menufontcolor: ?string;
    +footerlinkcolor: ?string;
    +blokbackgroundcolor: ?string;
    +blokfontcolor: ?string;
    +linkcolor: ?string;
    +contentcolor: ?string;
    +contentbackgroundcolor: ?string;
    +objectstatusnieuwlabelcolor: ?string;
    +objectstatusnieuwfontcolor: ?string;
    +objectstatustekoophuurlabelcolor: ?string;
    +objectstatustekoophuurfontcolor: ?string;
    +objectstatusondervoorbehoudlabelcolor: ?string;
    +objectstatusondervoorbehoudfontcolor: ?string;
    +objectstatusverkochtlabelcolor: ?string;
    +objectstatusverkochtfontcolor: ?string;
    +objectstatusopenhuislabelcolor: ?string;
    +objectstatusopenhuisfontcolor: ?string;
    +objectstatusaangekochtlabelcolor: ?string;
    +objectstatusaangekochtfontcolor: ?string;
    +showOptaTerms: ?string;
    +websiteLogo: ?string;
    +hideMainLogo: ?boolean;
    +faviconPath: ?string;
    +websiteBackground: ?string;
    +backgroundRepeat: ?string;
    +enableOpgeslagenWoningen: ?string;
    +activeerMove: ?boolean;
    +homepageTitle: ?string;
    +homepageDescription: ?string;
    +homepageKeywords: ?string;
    +dagenVerkochtOpAanbodlijst: ?string;
    +aantalDagenVoorNieuwOpMarkt: ?string;
    +dagenOpVerkochtAanbodlijst: ?string;
    +actiefWonenZoekformulierOptie: ?string;
    +actiefBogZoekformulierOptie: ?string;
    +actiefNieuwbouwZoekformulierOptie: ?string;
    +actiefAlvZoekformulierOptie: ?string;
    +clusterMarkers: ?string;
    +enableHelpmee: ?string;
    +showCBSStatistieken: ?string;
    +googleTranslate: ?string;
    +googleTranslateIncludedLanguages: ?string;
    +googleMapsApiKeyMakelaar: ?string;
    +googleAnalyticsKey: ?string;
    +googleAnalytics4Key: ?string;
    +googleRemarketingKey: ?string;
    +googletagmanagerKey: ?string;
    +googleSiteVerificationKey: ?string;
    +facebookPixelKey: ?string;
    +bingSiteVerificationKey: ?string;
    +enableMakelaarTemplateCaptcha: ?string;
    +recaptchaWebsiteKey: ?string;
    +recaptchaSecret: ?string;
    +pageEffect: ?string;
    +mouseEffect: ?string;
    +ogZoekformulierMenu: ?string;
    +useVideoHeaderBanner: ?boolean;
    +youtubeHeaderVideoId: ?string;
    +enableSitekick: ?string;
    +enableSearcherForm: boolean;
  |};
  +systemSettings: {|
    +enableWonen: ?boolean;
    +enableBog: ?boolean;
    +enableNieuwbouw: ?boolean;
    +enableAlv: ?boolean;
    +enableVgmcomplex: ?boolean;
    +enableAankoop: ?boolean;
    +titleSetting: ?string;
    +addthisUser: ?string;
    +multiOfficeOptionType: ?string;
    +spotlightPrio: ?string;
    +enableDeeldiensten: ?boolean;
    +nieuwsPaginaNummer: ?string;
    +rssMaxAantal: ?string;
    +rssUrl: ?string;
    +enableBlog: ?boolean;
    +enableVerkopendeMakelaar: ?boolean;
    +aanbodlijstSorteerOptiesDefault: ?string;
    +aanbodlijstSorteerDefaultSelectedOrder: ?string;
    +portalType: ?string;
    +toonAantalObjectenInZoekformulierTitel: ?boolean;
    +kijkMijnHuisPixelEnabled: ?boolean;
    +scheme: ?string;
    +enableCustomWonenContactGegevens: ?boolean;
    +customWonenContactGegevensEmail: ?string;
    +customWonenContactGegevensTel: ?string;
    +enableCustomBogContactGegevens: ?boolean;
    +customBogContactGegevensEmail: ?string;
    +customBogContactGegevensTel: ?string;
    +enableCustomNieuwbouwContactGegevens: ?boolean;
    +customNieuwbouwContactGegevensEmail: ?string;
    +customNieuwbouwContactGegevensTel: ?string;
    +enableCustomAlvContactGegevens: ?boolean;
    +customAlvContactGegevensEmail: ?string;
    +customAlvContactGegevensTel: ?string;
    +enableCustomVgmcomplexContactGegevens: ?boolean;
    +customVgmcomplexContactGegevensEmail: ?string;
    +customVgmcomplexContactGegevensTel: ?string;
  |};
|};
*/


const fragment /*: ConcreteFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsQueries_settings",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "args": null,
      "concreteType": "BrokerSettings",
      "name": "brokerSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "websiteTheme",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "vestigingPaginaNummer",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "cmsWeergaveNummer",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "medewerkersPaginaNummer",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "footerNummer",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "brochureDetailType",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "backgroundcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "menucolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "menufontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "footerlinkcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "blokbackgroundcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "blokfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "linkcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "contentcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "contentbackgroundcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusnieuwlabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusnieuwfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatustekoophuurlabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatustekoophuurfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusondervoorbehoudlabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusondervoorbehoudfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusverkochtlabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusverkochtfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusopenhuislabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusopenhuisfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusaangekochtlabelcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "objectstatusaangekochtfontcolor",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "showOptaTerms",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "websiteLogo",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "hideMainLogo",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "faviconPath",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "websiteBackground",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "backgroundRepeat",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableOpgeslagenWoningen",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "activeerMove",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "homepageTitle",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "homepageDescription",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "homepageKeywords",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "dagenVerkochtOpAanbodlijst",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "aantalDagenVoorNieuwOpMarkt",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "dagenOpVerkochtAanbodlijst",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "actiefWonenZoekformulierOptie",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "actiefBogZoekformulierOptie",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "actiefNieuwbouwZoekformulierOptie",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "actiefAlvZoekformulierOptie",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "clusterMarkers",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableHelpmee",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "showCBSStatistieken",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleTranslate",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleTranslateIncludedLanguages",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleMapsApiKeyMakelaar",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleAnalyticsKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleAnalytics4Key",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleRemarketingKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googletagmanagerKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "googleSiteVerificationKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "facebookPixelKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "bingSiteVerificationKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableMakelaarTemplateCaptcha",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "recaptchaWebsiteKey",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "recaptchaSecret",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "pageEffect",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "mouseEffect",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "ogZoekformulierMenu",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "useVideoHeaderBanner",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "youtubeHeaderVideoId",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableSitekick",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableSearcherForm",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "args": null,
      "concreteType": "SystemSettings",
      "name": "systemSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableWonen",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableBog",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableNieuwbouw",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableAlv",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableVgmcomplex",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableAankoop",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "titleSetting",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "addthisUser",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "multiOfficeOptionType",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "spotlightPrio",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableDeeldiensten",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "nieuwsPaginaNummer",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "rssMaxAantal",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "rssUrl",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableBlog",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableVerkopendeMakelaar",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "aanbodlijstSorteerOptiesDefault",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "aanbodlijstSorteerDefaultSelectedOrder",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "portalType",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "toonAantalObjectenInZoekformulierTitel",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "kijkMijnHuisPixelEnabled",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "scheme",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableCustomWonenContactGegevens",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customWonenContactGegevensEmail",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customWonenContactGegevensTel",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableCustomBogContactGegevens",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customBogContactGegevensEmail",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customBogContactGegevensTel",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableCustomNieuwbouwContactGegevens",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customNieuwbouwContactGegevensEmail",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customNieuwbouwContactGegevensTel",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableCustomAlvContactGegevens",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customAlvContactGegevensEmail",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customAlvContactGegevensTel",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "enableCustomVgmcomplexContactGegevens",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customVgmcomplexContactGegevensEmail",
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "args": null,
          "name": "customVgmcomplexContactGegevensTel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Settings"
};

module.exports = fragment;
