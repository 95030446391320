import React from 'react'
import BannerTabsWidget from '../../../bannertabs/BannerTabsWidget'
import Soon from '../../../general/soon/Soon'

export default ({ settings }) => {
	return {
		id: 'bannerTabsSlider',
		title: 'Actie slider',
		icon: 'list-alt',
		description: 'Een slider van acties op uw website',
		size: {
			minWidth: 2,
			maxWidth: 2,
			defaultWidth: 2,
		},
		isDisabled: false,
		fieldTypes: [
			{
				component: (props) => {
					return (
						<div>
							<Soon title={'Het wijzigen of toevoegen van een lijst zal binnenkort beschikbaar zijn'} />
							<BannerTabsWidget listId={props.parameters.cmslistid || '2'} />
						</div>
					)
				},
			},
		],
	}
}
